import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import * as ROUTES from '../../constants/routes';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class HeaderAccount extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      active: props.active ?? '',
      type: props.type ?? '',
      title: props.title ?? 'Account',
    };
  }


  renderAffiliates() {
    var uname = Firebase.auth().currentUser.displayName
    if(uname !== "staff") {
      return (
        <Link to={ROUTES.AFFILIATES} className={this.state.active === 'affiliates' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:account.affiliate')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderSpaces() {
    var uname = Firebase.auth().currentUser.displayName
    if(uname !== "staff") {
      return (
        <Link to={ROUTES.ACCOUNTSPACES} className={this.state.active === 'spaces' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:account.spaces')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }
  

  render() {
    return (
      <div className="track">
        <Link to={ROUTES.ACCOUNT} className={this.state.active === 'details' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:account.details')}</p>
          <div className="bg"></div>
        </Link>
        {this.renderSpaces()}
        <Link to={ROUTES.ACCOUNTBADGES} className={this.state.active === 'badges' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:account.achievements')}</p>
          <div className="bg"></div>
        </Link>
        {this.renderAffiliates()}
      </div>
    )
  }
}


export default withTranslation(['nav'])(HeaderAccount);