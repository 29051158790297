import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import HelperBilling from '../../helper/Billing';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import WidgetProgram from '../../components/Widgets/program';
import WidgetComments from '../../components/Widgets/comments';
import InfoData from '../../components/Form/info';
import ListClient from '../../components/List/client';
import ModalItems from '../../components/Modals/items';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalOptions from '../../components/Modals/options';
import ModalBlock from '../../components/Modals/block';
import ModalBookings from '../../components/Modals/bookings';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class GroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      clients: global.clients,
      clientsList: global.clients,
      program: null,
      dur: 30,
      preview: true,
      private: '',
      name: '',
      notes: '',
      desc: '',
      hrs: [],
      min: [],
      date: Moment().format('DD/MM/YYYY'),
      hour: '00',
      mins: '00',
      custom: [],
      cdur: 75,
      valid: true,
      showMax: false,
      max: 0,
      error: [false, false],
      editing: false,
      title: 'Group Session',
      back: ROUTES.CALENDAR,
      // Client attendance
      showModalClient: false,
      hiddenClient: 'hidden',
      search: '',
      selected: [],
      selectedNo: [],
      selectType: 'show',
      invited: [],
      group: [],
      added: [],
      clientType: 'attendance',
      trainerId: global.uidUser,
      trainerName: global.userName,
      link: '',
      showBook: false,
      dateBook: Moment().format('DD/MM/YYYY'),
      hourBook: '00',
      minsBook: '00',
      showBookCancel: false,
      dateBookCancel: Moment().add(14, 'days').format('DD/MM/YYYY'),
      hourBookCancel: '00',
      minsBookCancel: '00',
      bookedState: 'button',
      writeComment: false,
      editComment: '',
      avail: false,
      timeEdit: false,
      activity: [],
      client: null,
      hnotes: [],
      block: null,
      showModalDelete: false,
      showModalSpark: false,
      showModalPrograms: false,
      showModalReplace: false,
      showModalBlock: false,
      showModalNotes: false,
      showModalBookings: false,
      showModalReminder: false,
    };
  }


  componentDidMount() {
    HelperPage.checkMessage()
    Connector.setUser()
    window.scrollTo(0, 0)
    this._isMounted = true

    global.linkCal = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.setPrograms())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    Connector.loadEmailUsage()

    this.configureData()
    this.setPrograms()

    if(this.props.location.pathname.indexOf('availability') !== -1) {
      document.title = 'Available 1:1 Session - PT Mate'
      window.Intercom("update")
    } else {
      document.title = 'Group Session - PT Mate'
      window.Intercom("update")
    }

    if(global.clientTokens.length === 0) {
      for(var client of global.clients) {
        if(client.data.uid !== '') {
          Connector.getClientTokenAll(client.data.uid, client.id)
        }
      }
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var back = ROUTES.CALENDAR

      var id = arr[arr.length-1]
      var item = null
      var cl = null
      var found = false
      var dur = 30
      var notes = ''
      var desc = ''
      var day = ''
      var hrs = ''
      var min = ''
      var preview = true
      var title = lang.t('calendar:session.group')
      var max = 0
      var show = false
      var name = ''
      var sel = []
      var selNo = []
      var priv = ''
      var invited = []
      var program = null
      var tid = global.uidUser
      var tname = global.userName
      var link = ''
      var dayBook = '01/01/1900'
      var hrsBook = '00'
      var minBook = '00'
      var showBook = false
      var dayBookCancel = '01/01/2100'
      var hrsBookCancel = '00'
      var minBookCancel = '00'
      var showBookCancel = false
      var group = []

      back = global.currentPage
      if(global.linkCli !== '') {
        back = global.linkCli
      }

      if(id !== '') {
        // Sessions
        for(var session of global.sessions) {
          if(session.id === id) {
            found = true
            item = session
            dur = session.data.duration
            notes = session.data.notes
            day = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
            hrs = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
            min = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
            name = session.data.client
            var avail = false
            program = session.program[0]
            if(session.data.client !== '') {
              title = session.data.client
            }
            if(!session.data.preview && session.data.preview !== undefined) {
              preview = false
            }
            if(session.data.max !== undefined && session.data.max > 0) {
              max = session.data.max
              show = true
            }
            if(session.data.clients !== undefined) {
              sel = session.data.clients
            }
            if(session.data.noshows !== undefined) {
              selNo = session.data.noshows
            }
            if(session.data.invitees !== undefined && session.data.invitees.length > 0) {
              priv = 'private'
              invited = session.data.invitees
            }
            if(session.data.groups !== undefined && session.data.groups.length > 0) {
              priv = 'group'
              group = session.data.groups
            }
            if(session.data.trainerId !== undefined) {
              tid = session.data.trainerId
              tname = session.data.trainerName
            }
            if(session.data.link !== undefined) {
              link = session.data.link
            }
            if(session.data.availability !== undefined) {
              avail = session.data.availability
            }
            if(session.data.desc !== undefined) {
              desc = session.data.desc
            }
            if(session.data.unlocked !== undefined) {
              dayBook = Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
              hrsBook = Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm').format('HH')
              minBook = Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm').format('mm')
              if(session.data.unlocked !== '01/01/1900 00:00' && Moment(session.data.unlocked, 'DD/MM/YYYY HH:mm') > Moment('01/01/2021', 'DD/MM/YYYY')) {
                showBook = true
              } else {
                dayBook = Moment().format('DD/MM/YYYY')
              }
            }
            if(session.data.locked !== undefined) {
              dayBookCancel = Moment(session.data.locked, 'X').format('DD/MM/YYYY')
              hrsBookCancel = Moment(session.data.locked, 'X').format('HH')
              minBookCancel = Moment(session.data.locked, 'X').format('mm')
              if(Moment(session.data.locked, 'X') < Moment(session.data.timestamp, 'X')) {
                showBookCancel = true
              } else {
                dayBookCancel = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
                hrsBookCancel = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
                minBookCancel = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
              }
            }
          }
        }
        // Archive
        if(!found) {
          for(var ar of global.archive) {
            if(ar.id === id) {
              found = true
              item = ar
              dur = ar.data.duration
              notes = ar.data.notes
              day = Moment(ar.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
              hrs = Moment(ar.data.date, 'DD/MM/YYYY HH:mm').format('HH')
              min = Moment(ar.data.date, 'DD/MM/YYYY HH:mm').format('mm')
              name = ar.data.client
              program = ar.program[0]
              if(ar.data.client !== '') {
                title = ar.data.client
              }
              if(!ar.data.preview && ar.data.preview !== undefined) {
                preview = false
              }
              if(ar.data.max !== undefined && ar.data.max > 0) {
                max = ar.data.max
                show = true
              }
              if(ar.data.invitees !== undefined && ar.data.invitees.length > 0) {
                priv = true
                invited = ar.data.invitees
              }
              if(ar.data.clients !== undefined) {
                sel = ar.data.clients
              }
              if(ar.data.trainerId !== undefined) {
                tid = ar.data.trainerId
                tname = ar.data.trainerName
              }
              if(ar.data.link !== undefined) {
                link = ar.data.link
              }
              if(ar.data.desc !== undefined) {
                desc = ar.data.desc
              }
            }
          }
        }
      }

      var cdur = 75
      if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
        cdur = dur
        dur = 99
      }

      var tmpc = []
      var tmps = []
      if(item !== null) {
        if(item.data.availability) {
          title = lang.t('calendar:session.available')
        }
        
        for(var itemc of global.clients) {
          tmpc.push(itemc)
        }

        for(var items of global.userStaff) {
          if((items.data.role === 'assistant' || items.data.role === 'assistant2' || items.data.role === 'trainer') && items.data.status === 'active') {
            tmps.push(items)
          }
        }
      }

      global.linkCal = '/calendar/group-session/'+id
      this.setState({
        clients: tmpc,
        clientsList: tmpc,
        client: cl,
        groups: global.clientGroups,
        item: item,
        id: id,
        back: back,
        dur: dur,
        cdur: cdur,
        notes: notes,
        desc: desc,
        date: Moment(day, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        hour: hrs,
        mins: min,
        preview: preview,
        title: title,
        name: name,
        max: max,
        group: group,
        showMax: show,
        selected: sel,
        selectedNo: selNo,
        added: [],
        removed: [],
        private: priv,
        invited: invited,
        program: program,
        trainerId: tid,
        trainerName: tname,
        link: link,
        showBook: showBook,
        dateBook: Moment(dayBook, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        hourBook: hrsBook,
        minsBook: minBook,
        showBookCancel: showBookCancel,
        dateBookCancel: Moment(dayBookCancel, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        hourBookCancel: hrsBookCancel,
        minsBookCancel: minBookCancel,
        showMore: false,
        avail: avail,
        staff: tmps,
      })
    }
  }


  setPrograms() {
    var tmp = []
    for(var item1 of global.programs) {
      tmp.push(item1)
    }
    this.setState({
      programs: tmp,
      programsList: tmp,
    })
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        client = item2
      }
    }
    return client
  }


  getClientName(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }


  getGroupName(id) {
    var label = lang.t('common:label.deletedgroup')
    for(var item of this.state.groups) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  getMembershipName(id) {
    var label = lang.t('common:label.deletedproduct')
    for(var item of global.products) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }



  // Client update stuff ------------------------------------------------------------



  removeBooked(item) {
    var tmp = this.state.item.data.clients
    var tmp2 = []
    var id = ''
    var id2 = ''
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        id = tmp[i]
        tmp.splice(i, 1)
      }
    }
    if(this.state.item.data.waiting !== undefined) {
      tmp2 = this.state.item.data.waiting
      if(this.state.item.data.waiting.length > 0) {
        tmp.push(tmp2[0])
        id2 = tmp2[0]
        tmp2.splice(0, 1)
      }
    }
    var name = 'the group session'
    var name2 = 'Group session'
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
      name2 = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = 'the available PT session'
      name2 = 'Available PT session'
    }
    var bookings = []
    if(this.state.item.data.bookings !== undefined) {
      bookings = this.state.item.data.bookings
    }
    bookings.push(item+"||cancellation||trainer||"+Moment().format('X'))
    var tokens1 = []
    var tokens2 = []
    var text = 'You are no longer booked in for '+name+' '+Cal.getPushDate(this.state.item.data.date)
    var text2 = 'You are now booked in for '+name+' '+Cal.getPushDate(this.state.item.data.date)
    for(var client of global.clients) {
      if(client.data.pushToken !== undefined && client.data.pushToken !== '') {
        if(client.id === id) {
          tokens1.push(client.data.pushToken)
        }
        if(client.id === id2 && id2 !== '') {
          tokens2.push(client.data.pushToken)
        }
      }
      if(tokens1.length > 0) {
        Connector.sendPushNotification('', global.userName, text, 'session', this.state.item.id, tokens1)
      }
      if(tokens2.length > 0) {
        Connector.sendPushNotification('', global.userName, text2, 'session', tokens2)
      }
      // Email reminders
      if(client.data.email !== '' && global.spaceEmailReminder && (client.data.emailReminder || client.data.emailReminder === undefined)) {
        var base = 0
        if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
          base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
        }
        if(client.id === id) {
          var callFunction = Firebase.functions().httpsCallable('sendReminder');
          callFunction({type: 'canceled', email: client.data.email, name: global.userBusiness, id: global.uid, session: name2, time: Moment(this.state.item.data.timestamp, 'X').format('llll')}).then(function(result) {});
          base++
        }
        if(client.id === id2 && id2 !== '') {
          // Email reminder
          var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
          var callFunction2 = Firebase.functions().httpsCallable('sendReminder');
          callFunction2({type: 'booked', email: client.data.email, name: global.userBusiness, id: global.uid, session: name2, time: Moment(this.state.item.data.timestamp, 'X').format('llll'), scheduled: scheduled}).then(function(result) {});
          base++
        }
        Firebase.database().ref('/admin/emails/'+global.uid).update({
          [Moment().format('MM-YYYY')]: base
        })
      }
    }
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp,
      waiting: tmp2,
      bookings: bookings,
    })
    EventEmitter.dispatch('showMessage', 'Client successfully removed');
  }


  removeWaiting(item) {
    var tmp = this.state.item.data.waiting
    var id = ''
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    var name = 'the group session'
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = 'the available PT session'
    }
    var text = 'You are no longer on the waiting list for '+name+' '+Cal.getPushDate(this.state.item.data.date)
    for(var client of global.clients) {
      if(client.data.pushToken !== undefined && client.data.pushToken !== '') {
        if(client.id === id) {
          id = tmp[i]
          Connector.sendPushNotification(client.data.pushToken, global.userName, text, 'session', this.state.item.id)
        }
      }
    }
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      waiting: tmp
    })
    EventEmitter.dispatch('showMessage', 'Client successfully removed');
  }



  // Clients modal ------------------------------------------------------------



  showModalAttendance() {
    var sel = []
    if(this.state.item.data.noshows !== undefined) {
      sel = this.state.item.data.noshows
    }
    var cl = []
    if(this.state.item.data.clients !== undefined) {
      cl = this.state.item.data.clients
    }
    this.setState({
      selected: cl,
      selectedNo: sel,
      clientType: 'attendance',
    }, () => {
      this.showModalClients()
    })
  }


  showModalAttendanceStart() {
    var type = 'attendancestart'
    var sel = []
    if(this.state.item.data.noshows !== undefined) {
      sel = this.state.item.data.noshows
    }
    var cl = []
    if(this.state.item.data.clients !== undefined) {
      cl = this.state.item.data.clients
    }
    if(this.state.item.program.length === 0) {
      type = 'attendance'
    }
    this.setState({
      selected: cl,
      selectedNo: sel,
      clientType: type,
    }, () => {
      this.showModalClients()
    })
  }


  showModalAttendanceAdded() {
    var sel = []
    if(this.state.item.data.noshows !== undefined) {
      sel = this.state.item.data.noshows
    }
    var cl = []
    if(this.state.item.data.clients !== undefined) {
      cl = this.state.item.data.clients
    }
    this.setState({
      selected: cl,
      selectedNo: sel,
      added: [],
      removed: [],
      clientType: 'update',
    }, () => {
      this.showModalClients()
    })
  }


  showModalClients() {
    this.setState({
      showModalClient: true,
    })
    setTimeout(() => {
      this.setState({
        hiddenClient: '',
        search: '',
      });
    }, 100);
  }


  hideModalClients() {
    this.setState({
      hiddenClient: 'hidden',
    })
    setTimeout(() => {
      this.setState({showModalClient: false});
    }, 500);
  }


  getSelected(item) {
    var label = 'client-list'
    if(this.state.selected !== undefined) {
      for(var client of this.state.selected) {
        if(client === item && this.state.selectType === 'show') {
          label = 'client-list selected'
        }
      }
    }
    if(this.state.selectedNo !== undefined) {
      for(var client2 of this.state.selectedNo) {
        if(client2 === item && this.state.selectType === 'noshow') {
          label = 'client-list selected yellow'
        }
      }
    }
    
    return label
  }


  renderClientsContent() {
    if(this.state.clientsList.length === 0) {
      return (
        <p className="empty">No clients</p>
      )
    } else {
      var list = this.state.clientsList
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="scroll withbutton added">
          {list.map(item => (
            <ListClient key={item.id} client={item} type={this.getSelected(item.id)} clickElement={() => this.selectClient(item.id)}/>
          ))}
        </div>
      )
    }
  }


  renderModalButton() {
    if(this.state.clientType === 'invitees') {
      return (
        <button className="btn primary" onClick={() => this.updateInvitees()}>Update invited</button>
      )
    } else if(this.state.clientType === 'update') {
      return (
        <button className="btn primary" onClick={() => this.updateAttendanceAdded()}>Update attendees</button>
      )
    } else if(this.state.clientType === 'attendancestart') {
      return (
        <button className="btn primary" onClick={() => this.updateAttendance()}>Confirm & start session</button>
      )
    } else {
      var label = 'Confirm and mark as done'
      if(this.state.avail) {
        label = 'Confirm attendee'
      }
      return (
        <button className="btn primary" onClick={() => this.updateAttendance()}>{label}</button>
      )
    }
  }


  renderClientsTitle() {
    if(this.state.clientType === 'update') {
      return 'Manage attendance'
    } else if(this.state.clientType === 'attendancestart') {
      if(this.state.max !== 0) {
        return 'Attending'
      } else {
        return 'Confirm attendance'
      }
      //return 'Start session'
      
    } else {
      if(!this.state.avail) {
        //return 'Mark as done'
        return 'Confirm attendance'
      } else {
        return 'Select client'
      }
    }
  }


  renderClientsSubtitle() {
    var max = ''
    if(this.state.max !== 0 && !this.state.avail) {
      max = ' ('+this.state.max+' max)'
    }
    if(this.state.clientType === 'update') {
      return this.state.selected.length+' attendee'+(this.state.selected.length === 1 ? '' : 's')+max
    } else if(this.state.clientType === 'attendancestart') {
      if(this.state.selected !== undefined && this.state.selected !== 0) {
        return this.state.selected.length+' attendee'+(this.state.selected.length === 1 ? '' : 's')+max
      } else {
        return 'Select attendees'+max
      }
    } else {
      if(!this.state.avail) {
        if(this.state.selected === undefined) {
          return 'Select attendees'+max
        } else {
          if(this.state.selected.length === 0) {
            return 'Select attendees'+max
          } else {
            var label = this.state.selected.length+' attendee'+(this.state.selected.length === 1 ? '' : 's')
            return label
          }
        }
      } else {
        return ' '
      }
    }
  }


  renderSubtitle() {
    if(this.state.clientType === 'attendance' || this.state.clientType === 'attendancestart' || this.state.clientType === 'update') {
      return (
        <div style={{marginBottom: 5}}>
          <div className={this.state.selectType === 'show' ? 'tab active' : 'tab'} onClick={() => this.setState({selectType: 'show'})}>
            <p>Attended ({this.state.selected !== undefined ? this.state.selected.length : '0'})</p>
            <div className="bg"></div>
          </div>
          <div className={this.state.selectType === 'noshow' ? 'tab active' : 'tab'} onClick={() => this.setState({selectType: 'noshow'})}>
            <p>No show ({this.state.selectedNo !== undefined ? this.state.selectedNo.length : '0'})</p>
            <div className="bg"></div>
          </div>
          <h5 className={this.state.selectType === 'show' ? 'int active none' : 'int none'} style={{marginRight: '20px'}} onClick={() => this.setState({selectType: 'show'})}>Attended ({this.state.selected !== undefined ? this.state.selected.length : '0'})</h5>
          <h5 className={this.state.selectType === 'noshow' ? 'int active none' : 'int none'} onClick={() => this.setState({selectType: 'noshow'})}>No show ({this.state.selectedNo !== undefined ? this.state.selectedNo.length : '0'})</h5>
          <div className="clear"></div>
        </div>
      )
    } else {
      return (
        <h5 className="clear">{this.renderClientsSubtitle()}</h5>
      )
    }
  }


  renderModalClients() {
    if(this.state.showModalClient) {
      return (
        <div className={'overlay '+this.state.hiddenClient}>
          <div className="box clients">
            <h2 className="mb-10 lft">{this.renderClientsTitle()}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalClients()}>{lang.t('common:button.close')}</button>
            {this.renderSubtitle()}
            <div className="clear"></div>
            <input type="text" placeholder="Search by name" value={this.state.search} onChange={(val) => this.filterClients(val)}/>
            <div className="sv-20"></div>
            {this.renderClientsContent()}
            {this.renderModalButton()}
          </div>
        </div>
      )
    }
  }


  selectClient(item) {
    var tmp = []
    var tmpn = []
    var removed = this.state.removed

    if(this.state.selected !== undefined) {
      tmp = this.state.selected
    }
    if(this.state.selectedNo !== undefined) {
      tmpn = this.state.selectedNo
    }
    var add = true
    if(this.state.selectType === 'show') {
      if(tmp.length > 0) {
        for(var i=0; i<tmp.length; i++) {
          if(tmp[i] === item) {
            if(removed.indexOf(i) === -1) {
              removed.push(i)
            }
            tmp.splice(i, 1)
            add = false
          }
        }
      }
      if(tmpn.length > 0) {
        for(var i11=0; i11<tmpn.length; i11++) {
          if(tmpn[i11] === item) {
            tmpn.splice(i11, 1)
          }
        }
      }
    } else {
      if(tmpn.length > 0) {
        for(var i2=0; i2<tmpn.length; i2++) {
          if(tmpn[i2] === item) {
            if(removed.indexOf(i2) === -1) {
              removed.push(i2)
            }
            tmpn.splice(i2, 1)
            add = false
          }
        }
      }
      if(tmp.length > 0) {
        for(var i21=0; i21<tmp.length; i21++) {
          if(tmp[i21] === item) {
            tmp.splice(i21, 1)
          }
        }
      }
    }
    

    var added = this.state.added
    if(added.indexOf(item) === -1 && add) {
      added.push(item)
    }
    if(added.indexOf(item) !== -1 && !add) {
      for(var j=0; j<added.length; j++) {
        if(added[j] === item) {
          added.splice(j, 1)
        }
      }
    }
    
    
    if(add) {
      if(this.state.selectType === 'show') {
        if(this.state.clientType === 'attendance' || this.state.clientType === 'attendancestart') {
          if(this.state.max === 0) {
            tmp.push(item)
          } else {
            if(this.state.selected === undefined) {
              tmp.push(item)
            } else {
              if(this.state.selected.length < this.state.max) {
                tmp.push(item)
              }
            }
          }
          
        } else {
          tmp.push(item)
        }
      } else {
        tmpn.push(item)
      }
      
    }
    this.setState({
      selected: tmp,
      selectedNo: tmpn,
      added: added,
      removed: removed
    })
  }


  updateAttendance() {
    if((this.state.selected.length > 0 || this.state.selectedNo.length > 0 ) || (this.state.selected.length === 0 && (this.state.clientType === 'invitees' || this.state.clientType === 'update'))) {
      this.hideModalClients()
      var tmp = []
      var tmps = this.state.selected
      var tmpn = this.state.selectedNo
      if(this.state.item.data.confirmed !== undefined) {
        tmp = this.state.item.data.confirmed
      }
      // Update client credits
      if(this.state.selected.length > 0) {
        for(var i=tmps.length-1; i>=0; i--) {
          if(tmps[i] === undefined) {
            tmps.splice(i,1)
          }
        }
      }
      for(var item of tmps) {
        if(tmp.indexOf(item) === -1) {
          this.updateClientAttendance(item)
          tmp.push(item)
        }
      }
      for(var item2 of tmpn) {
        if(tmp.indexOf(item2) === -1) {
          if(global.spaceNoshow !== 'fee') {
            this.updateClientAttendance(item2)
          }
          this.updateClientFee(item2)
          tmp.push(item2)
        }
      }
      // Update attendance
      var parent = '/sessions/'
      if(this.state.back === ROUTES.CALARCHIVE) {
        parent = '/archive/'
      }
      global.badgeLocked = true
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'attendance');
      }, 1000);
      // Group session
      if(this.state.item.data.availability === undefined) {
        Firebase.database().ref(parent+global.uid+'/'+this.state.item.id).update({
          attendance: 3,
          clients: tmps,
          noshows: tmpn,
          confirmed: tmp
        }).then(() => {
          if(this.state.clientType === 'attendancestart') {
            setTimeout(() => {
              this.props.history.push('/calendar/run-session/'+this.state.item.id)
            }, 500);
          } else {
            EventEmitter.dispatch('showMessage', 'Session successfully updated');
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      } else {
        Firebase.database().ref(parent+global.uid+'/'+this.state.item.id+'/availability').remove()
        Firebase.database().ref(parent+global.uid+'/'+this.state.item.id).update({
          attendance: 3,
          confirmed: [],
          group: false,
          client: tmps[0],
          clients: [],
        }).then(() => {
          if(this.state.clientType === 'attendancestart') {
            setTimeout(() => {
              this.props.history.push('/calendar/run-session/'+this.state.item.id)
            }, 500);
          } else {
            this.props.history.push('/calendar/session/'+this.state.item.id)
            EventEmitter.dispatch('showMessage', 'Session successfully updated');
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    }
  }


  updateClientAttendance(item) {
    var group = true
    var linked = 0
    var family = false
    if(this.props.location.pathname.indexOf('availability') !== -1) {
      group = false
    }
    
    var memberships = []
    if(this.state.item.data.memberships !== undefined) {
      memberships = this.state.item.data.memberships
    }

    var client = null
    var cclient = null
    for(var cl of global.clients) {
      if(cl.id === item) {
        client = cl
        cclient = cl
        if(cl.data.linked !== undefined) {
          linked = cl.data.linked.length
          family = true
        }
      }
    }
    // Family accounts
    if(client !== null) {
      if(client.data.parent !== undefined && client.data.parent !== '') {
        for(var cl2 of global.clients) {
          if(cl2.id === client.data.parent) {
            client = cl2
            family = true
          }
        }
      }
    }
    
    if(client !== null && global.userChargeSessions) {
      var hasSub = false
      var credit = ''
      var credite = ''
      var datee = Moment('01/01/3000', 'DD/MM/YYYY')
      var amount = 1
      var total = 0
      var bl = 0
      for(var sub of client.subscriptions) {
        var valid = true
        if(memberships.length > 0) {
          valid = false
          if(memberships.indexOf(sub.id) !== -1) {
            valid = true
          }
        }
        if(valid) {
          if(sub.data.group === group && sub.data.status !== 'trialing' && (sub.data.sessions === 0 || sub.data.sessions === undefined) && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === item)) {
            hasSub = true
          }
          if(sub.data.is11 && !group && sub.data.status !== 'trialing' && (sub.data.sessions11 === 0 || sub.data.sessions11 === undefined) && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === item)) {
            hasSub = true
          }
        }
      }

      var avail = 0
      if(!hasSub) {
        avail = HelperBilling.getClientSessionDebit(cclient, group, cclient.id, false, memberships)
        if(avail === undefined) {
          avail = 0
        }
      }

      if(avail > 0 && !hasSub) {
        HelperBilling.chargeClientSessionDebit(client, group, item, memberships)
      }

      if(!hasSub && avail === 0) {
        // Family specific
        for(var cred of client.credits) {
          total = cred.data.sessionsTotal
          if(cred.data.group === group && cred.data.account === item) {
            credit = cred.id
            amount = total+1
            bl = cred.data.sessionsPaid-cred.data.sessionsTotal
          }
        }
        // Common and non-family
        for(var cred1 of client.credits) {
          total = cred1.data.sessionsTotal
          if(cred1.data.group === group && bl < 1 && (cred1.data.account === '' || cred1.data.account === undefined)) {
            if(cred1.data.sessionsPaid > cred1.data.sessionsTotal || !family) {
              credit = cred1.id
              amount = total+1
            }
          }
        }
        bl = 0
        // Family specific
        for(var crede of client.creditse) {
          var valid2 = true
          if(memberships.length > 0) {
            valid2 = false
            if(memberships.indexOf(crede.data.product) !== -1) {
              valid2 = true
            }
          }
          if(valid2) {
          total = crede.data.sessionsTotal
            if(crede.data.group === group && Moment(crede.data.expires, 'X') > Moment() && Moment(crede.data.expires, 'X') < datee && crede.data.sessionsPaid > crede.data.sessionsTotal) {
              if(crede.data.account === item) {
                credite = crede.id
                amount = total+1
                datee = Moment(crede.data.expires, 'X')
                bl = crede.data.sessionsPaid-crede.data.sessionsTotal
              }
            }
          }
        }
        // Common and non-family
        for(var crede1 of client.creditse) {
          var valid3 = true
          if(memberships.length > 0) {
            valid3 = false
            if(memberships.indexOf(crede1.data.product) !== -1) {
              valid3 = true
            }
          }
          if(valid3) {
            total = crede1.data.sessionsTotal
            if(crede1.data.group === group && Moment(crede1.data.expires, 'X') > Moment() && Moment(crede1.data.expires, 'X') < datee && crede1.data.sessionsPaid > crede1.data.sessionsTotal) {
              if(bl < 1 && (crede1.data.account === '' || crede1.data.account === undefined)) {
                if(crede1.data.sessionsPaid > crede1.data.sessionsTotal) {
                  credite = crede1.id
                  amount = total+1
                  datee = Moment(crede.data.expires, 'X')
                }
              }
            }
          }
        }

        if(credit === '' && credite === '') {
          console.log("new entry "+client.id)
          var acc = ''
          if(client.id !== item || linked > 0) {
            acc = client.id
          }
          Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/credits').push({
            group: group,
            sessionsPaid: 0,
            sessionsTotal: 1,
            type: 'sessions',
            account: acc
          })
        } else {
          if(credite === '') {
            Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/credits/'+credit).update({
              sessionsTotal: amount,
            })
              console.log("credit id "+credit+" "+client.id)
          } else {
            Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/credits/'+credite).update({
              sessionsTotal: amount,
            })
           console.log("expiry "+credite+" "+client.id)
          }
        }
      }
    }
  }


  updateClientFee(item) {
    // update fee
    if(global.spaceNoshow !== 'session' && global.userChargeSessions) {
      var name = lang.t('calendar:session.group')
      var add = ''
      if(this.state.item.data.client !== '') {
        name = this.state.item.data.client
      }
      var client = item
      for(var cl2 of global.clients) {
        if(cl2.id === item && cl2.data.parent !== undefined) {
          if(cl2.data.parent !== '' && cl2.data.parent !== undefined) {
            client = cl2.data.parent
            add = ' ('+cl2.data.name+')'
          }
        }
        if(cl2.data.linked !== undefined) {
          if(cl2.data.linked.length > 0) {
            add = ' ('+cl2.data.name+')'
          }
        }
      }
      Firebase.database().ref('/clients/'+global.uid+'/'+client+"/noshow").push({
        amount: global.spaceNoshowFee,
        session: name+' - '+Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('D MMM HH:mm')+' h'+add,
        id: this.state.item.id
      })
    }
  }


  getClientInfo(client, line) {
    var label = lang.t('calendar:nogroupmembership')
    var group = true
    if(this.state.avail) {
      group = false
      label = lang.t('calendar:nomembership')
    }

    var add = HelperBilling.getClientSessionDebit(client, group, client.id, false, [])
    if(add === undefined) {
      add = 0
    }
    
    if(line === 'line1') {
      label = lang.t('calendar:label.nogroupmembership')
      for(var sub of client.subscriptions) {
          label = lang.t('calendar:label.activemembership')
        if(sub.data.group === group && (sub.data.sessions === 0 || sub.data.sessions === undefined) && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === client.id)) {
          label = (group ? lang.t('calendar:label.activegroupmembership') : lang.t('calendar:label.activemembership'))
        }
        if(sub.data.is11 && !group && (sub.data.sessions11 === 0 || sub.data.sessions11 === undefined) && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === client.id)) {
          label = (group ? lang.t('calendar:label.activegroupmembership') : lang.t('calendar:label.adctivemembership'))
        }
      }
      if(client.data.parent !== undefined && client.data.parent !== '') {
        label = lang.t('calendar:label.familyaccount')
      }
    } else {
      label = lang.t('calendar:label.noupcomingsession')
      var dt = Moment().add(1, 'years').format('X')
      for(var session of global.sessions) {
        var sdt = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(sdt > Moment().format('X') && sdt < dt && session.data.clients !== undefined) {
          if(session.data.clients.indexOf(client.id) !== -1) {
            dt = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X')
            label = lang.t('calendar:label.next')+': '+Moment(session.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('lll')
          }
        }
      }
    }
    return label
  }


  filterClients = event => {
    this.setState({search: event.target.value});
    var tmp = this.state.clients
    if(event.target.value !== '') {
      tmp = []
      for(var item of this.state.clients) {
        if(item.data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) {
          tmp.push(item)
        }
      }
    }
    this.setState({clientsList: tmp})
  };


  updateInvitees() {
    this.setState({
      invited: this.state.selected,
      hiddenClient: 'hidden',
    })
    setTimeout(() => {
      this.hideModalClients();
    }, 500);
  }


  updateAttendanceAdded() {
    this.hideModalClients()
    var addStr = ''
    var eAddStr = ''

    var tmp = []
    if(this.state.item.data.confirmed !== undefined) {
      tmp = this.state.item.data.confirmed
    }
    // Update client credits
    for(var add of this.state.added) {
      eAddStr += '-0'
      if(tmp.indexOf(add) === -1) {
        if(this.state.item.selectedNo !== undefined) {
          if(this.state.item.selectedNo.indexOf(add) !== -1) {
            // Not attended
            if(global.spaceNoshow !== 'fee') {
              this.updateClientAttendance(add)
              this.updateClientFee(add)
            }
          } else {
            // Attended
            this.updateClientAttendance(add)
          }
        } else {
          this.updateClientAttendance(add)
        }
        addStr += '-0'
        tmp.push(add)
      }
    }

    var attendance = 3
    if(this.state.program !== null && this.state.program !== undefined) {
      var remove = this.state.removed.sort(function(a, b){return b-a})
      for(var block of this.state.program.blocks) {
        for(var i=0; i<block.data.exName.length; i++) {
          var arr1 = []
          var arr2 = []
          var astr1 = ''
          var astr2 = ''
          arr1 = block.data.exResRepsGroup[i].split('-')
          arr1.splice(0,1)
          arr2 = block.data.exResWeightGroup[i].split('-')
          arr2.splice(0,1)
          for(var item of remove) {
            arr1.splice(item, 1)
            arr2.splice(item, 1)
          }
          if(arr1.length > this.state.selected.length) {
            for(var ii=arr1.length-1; ii>=this.state.selected.length; ii--) {
              arr1.splice(ii, 1)
              arr2.splice(ii, 1)
            }
          }
          for(var j=0; j<arr1.length; j++) {
            astr1 += '-'+String(arr1[j])
            astr2 += '-'+String(arr2[j])
          }
          block.data.exResRepsGroup[i] = astr1+addStr
          block.data.exResWeightGroup[i] = astr2+addStr
        }
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update(
          block.data
        )
      }
    }

    if(this.state.selected.length === 0) {
      attendance = 2
    }

    // Update attendance
    var parent = '/sessions/'
    if(this.state.back === ROUTES.CALARCHIVE) {
      parent = '/archive/'
    }
    Firebase.database().ref(parent+global.uid+'/'+this.state.item.id).update({
      clients: this.state.selected,
      noshows: this.state.selectedNo,
      attendance: attendance,
      confirmed: tmp
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Attendance successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })

    // Energy scale
    if(this.state.item.data.energyStart !== undefined) {
      var tmp1 = this.state.item.data.energyStart.split('-')
      tmp1.splice(0,1)
      for(var item11 of this.state.removed) {
        tmp1.splice(item11, 1)
      }
      var tmp1Str = ''
      for(var i1=0; i1<tmp1.length; i1++) {
        tmp1Str += '-'+tmp1[i1]
      }
      tmp1Str += eAddStr
      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
        energyStart: tmp1Str
      })
    }

    if(this.state.item.data.energyEnd !== undefined) {
      var tmp2 = this.state.item.data.energyEnd.split('-')
      tmp2.splice(0,1)
      for(var item21 of this.state.removed) {
        tmp2.splice(item21, 1)
      }
      var tmp2Str = ''
      for(var i2=0; i2<tmp2.length; i2++) {
        tmp2Str += '-'+tmp2[i2]
      }
      tmp2Str += eAddStr
      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
        energyEnd: tmp2Str
      })
    }
  }


  addBooked(event) {
    var tmp = []
    if(this.state.item.data.clients !== undefined) {
      tmp = this.state.item.data.clients
    }
    tmp.push(event.target.value)
    var name = 'the group session'
    var name2 = 'Group session'
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
      name2 = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = 'the available PT session'
      name2 = 'Available PT session'
    }
    var bookings = []
    if(this.state.item.data.bookings !== undefined) {
      bookings = this.state.item.data.bookings
    }
    bookings.push(event.target.value+"||booking||trainer||"+Moment().format('X'))
    for(var item of global.clients) {
      if(item.id === event.target.value) {
        if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
          Connector.sendPushNotification(item.data.pushToken, "You're booked in", global.userName+' just booked you in for '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
        }
        if(item.data.email !== '' && global.spaceEmailReminder && (item.data.emailReminder || item.data.emailReminder === undefined)) {
          var base = 0
          if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
            base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
          }
          // Email reminder
          var scheduled = Moment(this.state.item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
          var callFunction = Firebase.functions().httpsCallable('sendReminder');
          callFunction({type: 'booked', email: item.data.email, name: global.userBusiness, id: global.uid, session: name2, time: Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('llll'), scheduled: scheduled}).then(function(result) {});
          Firebase.database().ref('/admin/emails/'+global.uid).update({
            [Moment().format('MM-YYYY')]: base+1
          })
        }
      }
    }
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp,
      bookings: bookings
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Client successfully booked in');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    this.setState({
      bookedState: 'button'
    })
  }



  // Comments ------------------------------------------------------------



  sendComment(id) {
    if(this.state.comment !== "") {
      if(id === '') {
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/comments').push({
          sender: global.uidUser,
          date: parseInt(Moment().format('X')),
          text: this.state.comment
        }).then((data)=>{
          this.setState({
            comment: '',
            writeComment: false
          })
          EventEmitter.dispatch('showMessage', 'Comment successfully sent');
          var sent = []
          var name = 'Group Session'
          if(this.state.item.data.client !== '') {
            name = this.state.item.data.client
          }
          for(var item of global.clients) {
            if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
              if(this.state.item.data.clients !== undefined) {
                if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.clients.indexOf(item.id) !== -1) {
                  sent.push(item.data.pushToken)
                }
              }
              if(this.state.item.data.waiting !== undefined) {
                if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.waiting.indexOf(item.id) !== -1) {
                  sent.push(item.data.pushToken)
                }
              }
            }
          }
          if(sent.length > 0) {
            Connector.sendPushNotification('', 'New session comment', global.userName+' commented on '+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id, sent)
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      } else {
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/comments/'+id).update({
          sender: global.uidUser,
          text: this.state.comment
        }).then((data)=>{
          this.setState({
            comment: '',
            editComment: ''
          })
          EventEmitter.dispatch('showMessage', 'Comment successfully update');
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    }
  }



  // Program ------------------------------------------------------------



  clickProgramEdit() {
    if(global.userSubStatus === 'active' && global.userSubPlan === 'spark') {
      this.setState({showModalSpark: true})
    } else {
      this.props.history.push('/calendar/group-session/'+this.state.item.id+'/edit-program/'+this.state.item.program[0].id)
    }
  }


  clickProgramCreate() {
    if(global.userSubStatus === 'active' && global.userSubPlan === 'spark') {
      this.setState({showModalSpark: true})
    } else {
      this.props.history.push('/calendar/group-session/'+this.state.item.id+'/new-program')
    }
  }


  clickProgramReplace() {
    this.hideModals()
    setTimeout(() => {
      this.setState({
        showModalPrograms: true
      });
    }, 500);
  }


  deleteProgram() {
    this.hideModals()
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout').remove()
  }



  // Modals ------------------------------------------------------------



  showModalBlock(item) {
    for(var bl of this.state.item.program[0].blocks) {
      if(bl.id === item) {
        this.setState({
          block: bl,
          showModalBlock: true
        })
      }
    }
  }


  showModalNotes(e, client) {
    e.stopPropagation()
    e.preventDefault()
    var list = []
    for(var cl of global.clients) {
      if(cl.id === client) {
        for(var hi of cl.history) {
          if(hi.data.important) {
            list.push(hi)
          }
        }
      }
    }
    this.setState({
      showModalNotes: true,
      hnotes: list,
    })
  }


  showModalHistory() {
    var typeact = ['waiting', 'waitingcancelled', 'booking', 'bookingcancelled']
    var tmp = []
    for(var item of global.activity) {
      if(typeact.indexOf(item.data.type) !== -1) {
        var arract = item.data.data.split(',')
        if(this.state.item.id === arract[1]) {
          var dateact = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
          var client = null
          var title = 'Client booked in'
          if(item.data.type === 'bookingcancelled') {
            title = 'Booking cancelled'
          }
          if(item.data.type === 'waiting') {
            title = 'Client entered the waiting list'
          }
          if(item.data.type === 'waitingcancelled') {
            title = 'Client left the waiting list'
          }
          for(var cl1 of global.clients) {
            if(cl1.data.uid === arract[0]) {
              client = cl1
            }
          }
          if(client !== null) {
            tmp.push({
              date: dateact,
              title: title,
              client: client
            })
          }
        }
      }
    }
    this.setState({
      showModalBookings: true,
      activity: tmp
    })
  }


  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalSpark: false,
      showModalPrograms: false,
      showModalReplace: false,
      showModalBlock: false,
      showModalNotes: false,
      showModalBookings: false,
      showModalReminder: false,
    })
  }


  clickSpark() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }


  selectProgram(item) {
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout').remove()
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout/'+item.id).update(
      item.data
    ).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Program successfully added');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    this.hideModals()
  }


  deleteSession() {
    this.hideModals()
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).remove()
    global.message = 'Session successfully deleted'
    var name = 'the group session'
    var name2 = 'Group session'
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
      name2 = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = 'the available PT session'
      name2 = 'Available PT session'
    }
    if(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm') > Moment()) {
      var sent = []
      var text = global.userName+' just cancelled '+name+' '+Cal.getPushDate(this.state.item.data.date)
      var base = 0
      if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
        base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
      }

      for(var item of global.clients) {
        if(item.data.pushToken !== undefined && item.data.pushToken !== '') {
          if(this.state.item.data.clients !== undefined) {
            if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.clients.indexOf(item.id) !== -1) {
              sent.push(item.data.pushToken)
            }
          }
          if(this.state.item.data.waiting !== undefined) {
            if(sent.indexOf(item.data.pushToken) === -1 && this.state.item.data.waiting.indexOf(item.id) !== -1) {
              sent.push(item.data.pushToken)
            }
          }
        }
        if(item.data.email !== '' && global.spaceEmailReminder && (item.data.emailReminder || item.data.emailReminder === undefined)) {
          if(this.state.item.data.clients !== undefined) {
            if(this.state.item.data.clients.indexOf(item.id) !== -1) {
              var callFunction = Firebase.functions().httpsCallable('sendReminder');
              callFunction({type: 'canceled', email: item.data.email, name: global.userBusiness, id: global.uid, session: name2, time: Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('llll')}).then(function(result) {});
              base++
            }
          }
        }
      }
      if(sent.length > 0) {
        Connector.sendPushNotification('', global.userName, text, 'session', this.state.item.id, sent)
      }

      // Update email usage
      if(global.spaceEmailReminder) {
        Firebase.database().ref('/admin/emails/'+global.uid).update({
          [Moment().format('MM-YYYY')]: base
        })
      }
    }
    this.props.history.push(this.state.back)
  }


  sendReminder() {
    var name = 'the group session'
    var name2 = 'Group session'
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
      name2 = this.state.item.data.client
    }
    if(this.state.item.data.availability) {
      name = 'the available PT session'
      name2 = 'Available PT session'
    }
    var text = 'You are now booked in for '+name+' '+Cal.getPushDate(this.state.item.data.date)
    var tokens = []
    for(var client of global.clients) {
      if(client.data.pushToken !== undefined && client.data.pushToken !== '') {
        if(this.state.item.data.clients.indexOf(client.id) !== -1) {
          tokens.push(client.data.pushToken)
        }
      }
      if(tokens.length > 0) {
        Connector.sendPushNotification('', 'Session Reminder', text, 'session', this.state.item.id, tokens)
      }
      // Email reminders
      if(client.data.email !== '' && global.spaceEmailReminder && (client.data.emailReminder || client.data.emailReminder === undefined) && this.state.item.data.clients.indexOf(client.id) !== -1) {
        var base = 0
        if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
          base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
        }
        var callFunction = Firebase.functions().httpsCallable('sendReminder');
        callFunction({type: 'booked', email: client.data.email, name: global.userBusiness, id: global.uid, session: name2, time: Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('llll')}).then(function(result) {});
        base++
        Firebase.database().ref('/admin/emails/'+global.uid).update({
          [Moment().format('MM-YYYY')]: base
        })
      }
    }
    EventEmitter.dispatch('showMessage', 'Session reminder successfully sent');
  }



  // Display stuff ------------------------------------------------------------



  renderRemove(type, item, list) {
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-30, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(type !== 'past' && now < date && HelperBusiness.getRoleIncl('trainer,admin')) {
      if(list === 'attendees') {
        return (
          <button className="btn tertiary rgt small close" style={{fontSize: 10, marginTop: 7}} onClick={() => this.removeBooked(item)}>{lang.t('common:button.remove')}</button>
        )
      } else {
        return (
          <button className="btn tertiary rgt small close" style={{fontSize: 10, marginTop: 7}} onClick={() => this.removeWaiting(item)}>{lang.t('common:button.remove')}</button>
        )
      }
    }
  }


  renderNoCredit(item) {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      var show = true
      var client = null

      if(!global.userChargeSessions) {
        show = false
      }

      for(var cl of global.clients) {
        if(cl.id === item) {
          client = cl
        }
      }
      // Family accounts
      if(client !== null) {
        if(client.data.parent !== undefined && client.data.parent !== '') {
          show = false
          for(var cl2 of global.clients) {
            if(cl2.id === client.data.parent) {
              client = cl2
            }
          }
        }
      }
      
      var hasSub = false
      if(client !== null) {
        
        var datee = Moment('01/01/3000', 'DD/MM/YYYY')
        var total = 0
        for(var sub of client.subscriptions) {
          if(sub.data.group) {
            hasSub = true
          }
        }
        if(!hasSub) {
          for(var cred of client.credits) {
            if(cred.data.group) {
              total = cred.data.sessionsTotal-cred.data.sessionsPaid
            }
          }
          for(var crede of client.creditse) {
            if(crede.data.group && Moment(crede.data.expires, 'X') > Moment() && Moment(crede.data.expires, 'X') < datee && crede.data.sessionsPaid > crede.data.sessionsTotal) {
              total += crede.data.sessionsTotal-crede.data.sessionsPaid
            }
          }
        }
      }
      if(total > 0 && !hasSub && show) {
        return <span title={lang.t('calendar:sessions.unpaid')}>*</span>
      }
    }
  }


  renderAttendeeNotes(client) {
    var show = false
    for(var item of global.clients) {
      if(item.id === client && item.history.length > 0) {
        for(var hi of item.history) {
          if(hi.data.important) {
            show = true
          }
        }
      }
    }
    if(show) {
      return (
        <div onClick={(e) => this.showModalNotes(e, client)} title={lang.t('calendar:view.notes')} className="icontoggle notes lft"></div>
      )
    }
  }


  renderAttendeesList(type) {
    var label = lang.t('calendar:label.noclientsbooked')
    if(this.state.item.data.availability) {
      label = lang.t('calendar:label.noclientsbooked')
    }
    if(this.state.item.data.unlocked !== undefined && this.state.item.data.unlocked !== '01/01/1900 00:00') {
      if(Moment() < Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm')) {
        label = lang.t('calendar:label.bookingsnotopen')
      }
    }
    if(type === 'past') {
      label = (this.state.avail ? lang.t('calendar:label.selectattendee') : lang.t('calendar:label.selectattendees'))
    }

    if(this.state.item.data.clients === undefined || this.state.item.data.clients.length === 0) {
      return (
        <p>{label}</p>
      )
    } else {
      return (
        <div>
        {this.state.item.data.clients.map(item => (
          <div className="client small" key={item}>
            {HelperClients.getImage(this.getClient(item))}
            <p><span className="lft">{this.getClientName(item)} {this.renderNoCredit(item)}</span>{this.renderAttendeeNotes(item)}</p>        
            {this.renderRemove(type, item, 'attendees')}
            <div className="clear"></div>
          </div>
        ))}
        </div>
      )
    }
  }


  renderWaitingList(type) {
    var label = lang.t('calendar:label.noclientsbooked')
    if(type === 'past') {
      label = lang.t('calendar:label.selectattendees')
    }
    if(this.state.item.data.clients === undefined || this.state.item.data.clients.length === 0) {
      return (
        <p>{label}</p>
      )
    } else {
      return (
        <div>
        {this.state.item.data.waiting.map(item => (
          <div className="client small" key={item}>
            {HelperClients.getImage(this.getClient(item))}
            <p><span className="lft">{this.getClientName(item)} {this.renderNoCredit(item)}</span>{this.renderAttendeeNotes(item)}</p>        
            {this.renderRemove(type, item, 'waiting')}
            <div className="clear"></div>
          </div>
        ))}
        </div>
      )
    }
  }


  renderNoshows() {
    if(this.state.item.data.noshows !== undefined) {
      if(this.state.item.data.noshows.length > 0) {
        return (
          <div className="attendance clientlist">
            <label>{lang.t('calendar:label.noshow')}</label>
            {this.state.item.data.noshows.map(item => (
              <div className="client small" key={item}>
                {HelperClients.getImage(this.getClient(item))}
                <p><span className="lft">{this.getClientName(item)} {this.renderNoCredit(item)}</span>{this.renderAttendeeNotes(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderAddBooked() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-30, 'minutes').format('X'))
      var now = parseInt(Moment().format('X'))
      var avail = this.state.item.data.availability
      if(this.state.item.data.availability === undefined) {
        avail = false
      }
      if(this.state.item.data.clients !== undefined) {
        if(this.state.item.data.clients.length < this.state.max || this.state.max === 0) {
          if(this.state.bookedState === 'form') {
            var list = []
            for(var client of this.state.clients) {
              if(this.state.item.data.clients.indexOf(client.id) === -1) {
                list.push(client)
              }
            }
            list.sort((a,b) => a.data.name.localeCompare(b.data.name))
            return (
              <div className="mt-10">
                <button className="btn tertiary small close width-12 mb-10" onClick={() => this.setState({bookedState: 'button'})}>{lang.t('common:button.cancel')}</button>
                <select onChange={event => this.addBooked(event)}>
                  <option>- {lang.t('calendar:form.selectclient')} -</option>
                  {list.map(item => (
                    <option value={item.id}>{item.data.name}</option>
                  ))}
                </select>
              </div>
            )
          } else {
            if(now > date && !avail) {
              // Nothing
            } else {
              return (
                <div className="mt-10">
                  <button className="btn tertiary small add width-12" onClick={() => this.setState({bookedState: 'form'})}>{lang.t('calendar:button.bookinclient')}</button>
                </div>
              )
            }
            
          }
        }
      } else {
        if(this.state.bookedState === 'form') {
          var list2 = this.state.clients
          list2.sort((a,b) => a.data.name.localeCompare(b.data.name))
          return (
            <div className="mt-10">
              <button className="btn tertiary small close width-12 mb-10" onClick={() => this.setState({bookedState: 'button'})}>{lang.t('common:button.cancel')}</button>
              <select onChange={event => this.addBooked(event)}>
                <option>- {lang.t('calendar:form.selectclient')} -</option>
                {list2.map(item => (
                  <option value={item.id}>{item.data.name}</option>
                ))}
              </select>
            </div>
          )
        } else {
          if(now > date && !avail) {
            // Nothing
          } else {
            return (
              <div className="mt-10">
                <button className="btn tertiary small add width-12" onClick={() => this.setState({bookedState: 'form'})}>{lang.t('calendar:button.bookinclient')}</button>
              </div>
            )
          }
        }
      }
    }
  }


  renderButtonHistory() {
    if(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm') > Moment().add(-7, 'days') && HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className="icontoggle history rgt" onClick={() => this.showModalHistory()}></div>
      )
    }
  }


  renderAttendance() {
    var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(this.state.item.data.duration, 'minutes').format('X'))
    var date2 = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date) {
      return (
        <div className="mb-20">
          <label className="lft">{now > date2 && this.state.item.data.attendance === 3 ? lang.t('calendar:label.attended') : lang.t('calendar:label.bookedin')} {this.state.max > 0 && !this.state.avail ? '('+this.state.max+' max)' : ''}</label>
          {this.renderButtonHistory()}
            <div className="clear"></div>
          {this.renderAttendeesList('past')}
        </div>
      )
    } else if(now < date) {
      if(this.state.item.data.availability) {
        return (
          <div className="mb-20">
            <label>{lang.t('calendar:label.clientbookedin')}</label>
            {this.renderAttendeesList('future')}
            {this.renderAddBooked()}
          </div>
        )
      } else {
        return (
          <div className="mb-20">
            <label className="lft">{this.state.item.data.attendance === 2 ? lang.t('calendar:label.clientsbookedin') : lang.t('calendar:label.clientsattending')} {this.state.max > 0 ? '('+this.state.max+' max)' : ''}</label>
            {this.renderButtonHistory()}
            <div className="clear"></div>
            {this.renderAttendeesList('future')}
            {this.renderAddBooked()}
          </div>
        )
      }
    }
  }


  renderWaiting() {
    var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now < date && this.state.item.data.waiting !== undefined) {
      if(this.state.item.data.waiting.length !== 0) {
        return (
          <div className="mb-20">
            <label>{lang.t('calendar:label.clientsinwaitinglist')}</label>
            {this.renderWaitingList('future')}
          </div>
        )
      }
    }
  }


  renderButtonDelete() {
    if(this.state.back !== ROUTES.CALARCHIVE) {
      if(HelperBusiness.getRoleIncl('trainer') && this.state.item.data.uid === global.uidUser) {
        return (
          <div className="clear">
            <div className="sv-20"></div>
            <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})} style={{width: '100%'}}>{lang.t('calendar:button.deletesession')}</button>
          </div>
        )
      } else if(HelperBusiness.getRole('trainer') && this.state.item.data.trainerId === global.uidUser) {
        return (
          <div className="clear">
            <div className="sv-20"></div>
            <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})} style={{width: '100%'}}>{lang.t('calendar:button.deletesession')}</button>
          </div>
        )
      }
    }
  }


  renderButtonVirtual() {
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var date1 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date1 && now < date2 && this.state.item.data.uid === global.uid) {
      if(this.state.item.data.link !== undefined && this.state.item.data.link !== '') {
        return (
          <div>
            <div className="sv-20"></div>
            <a href={this.state.item.data.link} target="_blank" className="btn secondary" rel="noopener noreferrer">{lang.t('calendar:button.openvirtuallink')}</a>
          </div>
        )
      }
    }
  }


  renderButtonAttendance() {
    var date1 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer,admin') && now > date1) {
      if(this.state.item.data.attendance === 3) {
        return (
          <div>
            <div className="sv-20"></div>
            <button className="btn secondary" onClick={() => this.showModalAttendanceAdded()}>{lang.t('calendar:button.manageattendance')}</button>
          </div>
        )
      } else {
        return (
          <div>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.showModalAttendanceStart()}>{lang.t('calendar:button.confirmattendance')}</button>
          </div>
        )
      }
    }
  }


  renderButtonStart() {
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var date1 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date1 && now < date2 && this.state.item.data.uid === global.uid && this.state.item.program.length !== 0) {
      return (
        <div className="pt-20">
          <button className="btn primary" onClick={() => this.showModalAttendanceStart()}>{lang.t('calendar:button.confirmattendancestart')}</button>
        </div>
      )
    } else if(now > date1 && now < date2 && this.state.item.data.uid === global.uid && this.state.item.program.length === 0) {
      return (
        <div className="pt-20">
          <button className="btn primary" onClick={() => this.showModalAttendanceStart()}>{lang.t('calendar:button.confirmattendance')}</button>
        </div>
      )
    }
  }


  renderButtonEdit() {
    if(Moment(this.state.item.data.timestamp, 'X') > Moment()) {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <div className="clear pt-20">
            <Link to={'/calendar/edit-group-session/'+this.state.item.id} className="btn primary">{lang.t('calendar:button.editsessiondetails')}</Link>
          </div>
        )
      } else if(HelperBusiness.getRole('trainer') && this.state.item.data.trainerId === global.uidUser) {
        return (
          <div className="clear pt-20">
            <Link to={'/calendar/edit-group--session/'+this.state.item.id} className="btn primary">{lang.t('calendar:button.editsessiondetails')}</Link>
          </div>
        )
      }
    }
  }


  renderMainButtons() {
    return (
      <div className="clear">
        {this.renderButtonEdit()}
        {this.renderButtonStart()}
        {this.renderButtonAttendance()}
        {this.renderButtonVirtual()}
        {this.renderButtonDelete()}
      </div>
    )
  }


  renderStatusInfo() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer,admin')) {
      var now = parseInt(Moment().format('X'))
      var date1 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-31, 'minutes').format('X'))
      var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
      var date3 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
      if(now > date1 && now < date2 && !this.state.avail && HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
        return (
          <div className="infobox primary mb-20">
            <p>{lang.t('calendar:message.30min.text')}<br/><Link to={'/calendar/check-in/'+this.state.item.id} className="btn tertiary small">{lang.t('calendar:message.30min.button')}</Link></p>
          </div>
        )
      } else if(now > date2 && now < date3 && !this.state.avail && HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
        // Program yes or no?
        return (
          <div className="infobox primary mb-20">
            <p>
              {this.state.item.program.length !== 0 ? lang.t('calendar:message.due1') : lang.t('calendar:message.due2')}<br/>
              <Link to={'/calendar/check-in/'+this.state.item.id} className="btn tertiary small">{lang.t('calendar:message.30min.button')}</Link>
            </p>
          </div>
        )
      } else if(now > date3 && this.state.item.data.attendance === 2) {
        return (
          <div className="infobox yellow mb-20">
            <p>
            {lang.t('calendar:message.confirm.text')}<br/>
              <button className="btn tertiary small" onClick={() => this.showModalAttendance()}>{lang.t('calendar:message.confirm.button')}</button>
            </p>
          </div>
        )
      } else if(now > date3 && this.state.item.data.attendance === 3) {
        return (
          <div className="infobox green mb-20">
            <p>{lang.t('calendar:message.attended')}</p>
          </div>
        )
      }
    }
  }


  renderGroups() {
    if(this.state.item.data.groups !== undefined) {
      if(this.state.item.data.groups.length > 0) {
        return (
          <div className="mb-20">
            <label>{lang.t('calendar:label.accesslimited')}</label>
            {this.state.item.data.groups.map(item => (
              <div className="client small" key={item}>
                <div className="icon tertiary">
                  <div className="inner group"></div>
                </div>
                <p>{this.getGroupName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderMemberships() {
    if(this.state.item.data.memberships !== undefined) {
      if(this.state.item.data.memberships.length > 0) {
        return (
          <div className="mb-20">
            <label>{lang.t('calendar:label.accesslimited2')}</label>
            {this.state.item.data.memberships.map(item => (
              <div className="client small" key={item}>
                <div className="icon secondary">
                  <div className="inner card"></div>
                </div>
                <p>{this.getMembershipName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderPreview() {
    var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-30, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(!this.state.item.data.preview && this.state.item.data.preview !== undefined && now < date) {
      return <InfoData label={lang.t('calendar:label.sessionpreview')} value={lang.t('calendar:label.sessionreview.cant')} small={true}/>
    }
  }


  renderNotes() {
    if(this.state.item.data.notes !== '') {
      return <InfoData label={lang.t('calendar:label.internalnotes')} value={this.state.item.data.notes} small={true}/>
    }
  }


  renderDesc() {
    if(this.state.item.data.desc !== undefined && this.state.item.data.desc !== '') {
      return <InfoData label={lang.t('calendar:label.description')} value={this.state.item.data.desc} small={true}/>
    }
  }


  renderLocation() {
    if(this.state.item.data.locationName !== undefined) {
      return (this.state.item.data.locationName === '' ? '' : ' - '+this.state.item.data.locationName)
    }
  }


  renderTrainerInfo() {
    if(HelperBusiness.getRole('assistant,assistant2')) {
      if(this.state.item.data.trainerName !== undefined && this.state.item.data.trainerName !== global.userName) {
        return <InfoData label={lang.t('calendar:label.trainer')} value={this.state.item.data.trainerName} small={true}/>
      } else if(this.state.item.data.trainerName !== undefined && this.state.item.data.trainerName === global.userName) {
        return <InfoData label={lang.t('calendar:label.trainer')} value={lang.t('calendar:label.assignedtoyou')} small={true}/>
      }
    } else {
      if(this.state.item.data.trainerName !== undefined && this.state.item.data.trainerName !== global.userName && this.state.item.data.trainerName !== '') {
        return <InfoData label={lang.t('calendar:label.trainer')} value={this.state.item.data.trainerName} small={true}/>
      }
    }
  }


  renderBookingClose() {
    if(this.state.item.data.locked !== undefined && Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes') > Moment()) {
      if(Moment(this.state.item.data.locked, 'X') > Moment() && Moment(this.state.item.data.locked, 'X') < Moment(this.state.item.data.timestamp, 'X')) {
        return <p className="mb-20"><span className="pill quarternary">{lang.t('calendar:label.bookingsclose')} {Moment(this.state.item.data.locked, 'X').locale(lang.language).format('llll')}</span></p>
      } else if(Moment(this.state.item.data.locked, 'X') < Moment()) {
        return <p className="mb-20"><span className="pill tertiary">{lang.t('calendar:label.bookingsclosed')}</span></p>
      } else {
        if(this.state.item.data.unlocked !== undefined) {
          if(Moment() < Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm')) {
            return <p className="mb-20"><span className="pill">{lang.t('calendar:label.bookingsopen')} {Moment(this.state.item.data.unlocked, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')}</span></p>
          }
        }
      }
    }
  }


  renderVirtual() {
    if(this.state.item.data.link !== undefined && this.state.item.data.link !== '') {
      return <span className="pill primary" style={{marginLeft: 5, position: 'relative', top: '-2px'}}>Virtual</span>
    }
  }


  renderPrivateInfo() {
    if(this.state.item.data.invitees !== undefined) {
      if(this.state.item.data.invitees.length > 0) {
        return (
          <label>{lang.t('calendar:label.privatesession')}</label>
        )
      }
    }
  }


  renderReminder() {
    if(Moment(this.state.item.data.timestamp, 'X') > Moment() && this.state.item.data.clients !== undefined && global.spaceEmailReminder) {
      return (
        <div>
          <div className="sv-30 clear"></div>
          <button className="btn tertiary width-12 small" onClick={() => this.setState({showModalReminder: true})}>{lang.t('calendar:button.sendreminder')}</button>
        </div>
      )
    }
  }


  renderContent() {
    if(this.state.item !== null) {
      return (
        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <div className="box">
              {this.renderStatusInfo()}
              {this.renderPrivateInfo()}
              <h4 style={{marginBottom: 5}}>{Cal.getSpecialDate(this.state.item, true)}{this.renderVirtual()}</h4>
              <h3 className="mb-20">{Cal.getDuration(this.state.item.data.duration)} {this.renderLocation()}</h3>
              {this.renderBookingClose()}
              <div className="clear"></div>
              {this.renderGroups()}
              {this.renderMemberships()}
              {this.renderAttendance()}
              {this.renderWaiting()}
              {this.renderNoshows()}
              <div className="sv-10"></div>
              <WidgetProgram session={this.state.item} clickList={() => this.setState({showModalPrograms: true})} clickNew={() => this.clickProgramCreate()} clickEdit={() => this.clickProgramEdit()} clickReplace={() => this.setState({showModalReplace: true})} clickResults={() => this.props.history.push('/calendar/results/'+this.state.item.id)} clickElement={(event) => this.showModalBlock(event)}/>
              {this.renderTrainerInfo()}
              {this.renderDesc()}
              {this.renderNotes()}
              {this.renderPreview()}
              {this.renderMainButtons()}
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
            <WidgetComments session={this.state.item} onChange={(event) => this.setState({comment: event})} sendComment={(event) => this.sendComment(event)}/>
          </div>
          {this.renderReminder()}
          <div className="sv-40 clear"></div>
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={'theme-'+global.spaceTheme}>
            {this.renderContent()}
            <div className="sv-20"></div>
            <Header title={this.state.title} link={this.state.back}/>
            <Navigation active='calendar' />
            {this.renderModalClients()}
            <ModalMessageSimple type='deletesession' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteSession()}/>
            <ModalMessageSimple type='sparksession' show={this.state.showModalSpark} onHide={() => this.hideModals()} clickMainButton={() => this.clickSpark()}/>
            <ModalItems show={this.state.showModalPrograms} type={'programs'} items={global.programs} onHide={() => this.hideModals()} clickElement={(event) => this.selectProgram(event)}/>
            <ModalOptions show={this.state.showModalReplace} onHide={() => this.hideModals()} clickButton1={() => this.clickProgramCreate()}  clickButton2={() => this.clickProgramReplace()} clickButton3={() => this.deleteProgram()}/>
            <ModalBlock show={this.state.showModalBlock} block={this.state.block} onHide={() => this.hideModals()}/>
            <ModalItems show={this.state.showModalNotes} type={'notes'} title='Important notes' empty='No important notes' items={this.state.hnotes} onHide={() => this.hideModals()}/>
            <ModalBookings show={this.state.showModalBookings} activity={this.state.activity} onHide={() => this.hideModals()}/>
            <ModalMessageSimple type='sessionreminder' show={this.state.showModalReminder} onHide={() => this.hideModals()} clickMainButton={() => this.sendReminder()}/>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common'])(withRouter(withAuthorization(condition)(GroupPage)));