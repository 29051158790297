import React, { Component } from 'react';
import Firebase from 'firebase';
import Connector from '../../data/Connector';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import ModalClients from '../../components/Modals/clients';
import ModalStats from '../../components/Modals/stats';
import ModalMessageSimple from '../../components/Modals/message-simple';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class DocumentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      clients: global.clients,
      itemStats: null,
      showModalDelete: false,
      showModalResponse: false,
      showModalSend: false,
      showModalStats: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Forms & Documents - PT Mate', ROUTES.ADMINDOC)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('formsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var form = null
      var arr = this.props.location.pathname.split('/')
      for(var item of global.forms) {
        if(item.id === arr[arr.length-1]) {
          form = item
        }
      }
      this.setState({
        item: form
      })
      this.setClients('')
    }
  }


  setClients(type) {
    var tmp = []
    if(type !== 'response' || this.state.item.data.pre) {
      tmp = global.clients
    } else if(type === 'response' && !this.state.item.data.pre) {
      for(var item of global.clients) {
        for(var form of item.forms) {
          if(form.id === this.state.item.id) {
            tmp.push(item)
          }
        }
      }
    }
    this.setState({
      clients: tmp
    })
  }


  duplicateForm() {
    var obj = Firebase.database().ref('/forms/').push()
    Firebase.database().ref('/forms/'+global.uid+'/'+obj.key).update(
      this.state.item.data
    ).then((data)=>{
      Firebase.database().ref('/forms/'+global.uid+'/'+obj.key).update({
        name: this.state.item.data.name+' (Copy)',
        pre: false
      })
      EventEmitter.dispatch('showMessage', 'Form successfully duplicated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Modals ------------------------------------------------------------



  showResponses() {
    this.setClients('response')
    this.setState({
      showModalResponse: true
    })
  }


  showSend() {
    this.setClients('')
    this.setState({
      showModalSend: true
    })
  }


  showStats(item) {
    this.setState({
      itemStats: item,
      showModalStats: true
    })
  }


  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalResponse: false,
      showModalStats: false,
      showModalSend: false
    })
  }


  selectClient(item) {
    this.hideModals()
    this.props.history.push('/clients/'+item.id+'/form/'+this.state.item.id)
  }


  sendForm(list) {
    if(list.length > 0) {
      for(var item of list) {
        Firebase.database().ref('/clients/'+global.uid+'/'+item+'/forms/'+this.state.item.id).update(
          this.state.item.data
        ).then((data)=>{
          //
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        var tokens = []
        for(var item2 of global.clients) {
          if(item === item2.id && item2.data.pushToken !== undefined && item2.data.pushToken !== '') {
            tokens.push(item2.data.pushToken)
          }
        }
        if(tokens.length > 0) {
          Connector.sendPushNotification('',"New form", global.userName+" just sent you a new form", 'form', this.state.item.id, tokens)
        }
      }
      this.hideModals()
      EventEmitter.dispatch('showMessage', 'Form successfully sent');
    }
  }


  deleteForm() {
    this.hideModals()
    if(this.state.item.data.pre) {
      Firebase.database().ref('/spaces/'+global.uid).update({
        preExercise: ''
      })
    }
    Firebase.database().ref('/forms/'+global.uid+'/'+this.state.item.id).remove()
    .then((data)=>{
      global.message = 'Form successfully deleted'
      this.props.history.push(ROUTES.ADMINDOC)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderLock() {
    if(this.state.item.data.lock !== undefined) {
      if(this.state.item.data.lock) {
        return (
          <span>Note: Your clients cannot edit their response once they filled out the form.</span>
        )
      }
    }
  }


  renderTop() {
    if(this.state.item.data.pre) {
      return (
        <div className="infobox green mb-30 large" style={{marginBottom: 20}}>
          <p className="mb-10">This is your pre-exercise questionnaire. When new clients connect they'll be asked to fill it out on their Member app or Member web portal.</p>
          <button className="btn tertiary" onClick={() => this.showResponses()}>View responses</button>
          <div className="clear"></div>
        </div>
      )
    } else {
      return (
        <div className="infobox mb-30 large">
          <p className="mb-10">You can view your clients' complete responses and edit them if needed. {this.renderLock()}</p>
          <button className="btn tertiary" onClick={() => this.showResponses()}>View responses</button>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderQuestion(item) {
    if(item.data.type === 'header') {
      return (
        <div className="listheader">
          <h4>{item.data.label}</h4>
          <div className="clear"></div>
        </div>
      )
    } else if(item.data.type === 'paragraph') {
      return (
        <div className="box mb-20">
          <p>{item.data.label}</p>
        </div>
      )
    } else if(item.data.type === 'text') {
      return (
        <div className="box mb-20">
          <p className="lft" style={{width: 'calc(100% - 34px)'}}>{item.data.label}</p>
          <div className="icontoggle stats rgt" onClick={() => this.showStats(item)}></div>
          <div className="clear mb-10"></div>
          <span className="pill primary">Free text</span>
        </div>
      )
    } else if(item.data.type === 'yesno') {
      return (
        <div className="box mb-20">
          <p className="lft" style={{width: 'calc(100% - 34px)'}}>{item.data.label}</p>
          <div className="icontoggle stats rgt" onClick={() => this.showStats(item)}></div>
          <div className="clear mb-10"></div>
          <span className="pill primary">{item.data.answer1 ? 'Yes > Provide details' : 'Yes'}</span>&nbsp;
          <span className="pill primary">{item.data.answer2 ? 'No > Provide details' : 'No'}</span>
        </div>
      )
    } else if(item.data.type === 'selection') {
      return (
        <div>
          <div className="box mb-20">
            <p className="lft" style={{width: 'calc(100% - 34px)'}}>{item.data.label}</p>
            <div className="icontoggle stats rgt" onClick={() => this.showStats(item)}></div>
            <div className="clear mb-10"></div>
            <span className="pill primary">{item.data.multiple ? 'Multiple selection' : 'Single selection'}</span>
            <div className="sv-10"></div>
            <ul className="basic">
              {item.data.options.map((option) => (
                <li>{option}</li>
              ))}
            </ul>
          </div>
          <div className="clear space-20 hide768"></div>
        </div>
      )
    } else if(item.data.type === 'rating') {
      return (
        <div>
          <div className="box mb-20">
            <p className="lft" style={{width: 'calc(100% - 34px)'}}>{item.data.label}</p>
            <div className="icontoggle stats rgt" onClick={() => this.showStats(item)}></div>
            <div className="clear mb-10"></div>
            <span className="pill primary">Rating from 1 to {item.data.num}</span>
          </div>
        </div>
      )
    }
  }


  renderButtons() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(this.state.item.data.pre) {
        return (
          <div>
            <div className="col-4">
              <button className="btn secondary" onClick={() => this.duplicateForm()}>Duplicate</button>
              <div className="sv-20"></div>
            </div>
            <div className="col-4">
              <Link to={'/admin/documents/form/'+this.state.item.id+'/edit'} className="btn secondary">Edit</Link>
              <div className="sv-20"></div>
            </div>
            <div className="col-4">
              <button className="btn secondary" onClick={() => this.setState({showModalDelete: true})}>Delete</button>
            </div>
            <div className="clear"></div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="col-3">
              <button className="btn primary" onClick={() => this.showSend()}>Send to my clients</button>
            </div>
            <div className="col-3">
              <button className="btn secondary" onClick={() => this.duplicateForm()}>Duplicate</button>
            </div>
            <div className="col-3">
              <Link to={'/admin/documents/form/'+this.state.item.id+'/edit'} className="btn secondary">Edit</Link>
            </div>
            <div className="col-3">
              <button className="btn secondary" onClick={() => this.setState({showModalDelete: true})}>Delete</button>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(this.state.item === null) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content"></div>
            <Header title='Form' link={ROUTES.ADMINDOC}/>
            <Navigation active='admin' />
          </div>
        )
      } else {
        var list = this.state.item.sections
        list.sort((a, b) => a.data.seq - b.data.seq)
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar">
              {this.renderTop()}
              {list.map((item) => (
                <div className="col-12">
                  {this.renderQuestion(item)}
                </div>
              ))}
              <div className="clear sv-30"></div>
              {this.renderButtons()}
              <div className="clear sv-40"></div>
            </div>
            <Header title={this.state.item.data.name} link={ROUTES.ADMINDOC}/>
            <Navigation active='admin' />
            <ModalClients clients={this.state.clients} title='View responses' object={this.state.item} data='response' show={this.state.showModalResponse} onHide={() => this.hideModals()} clickElement={(event) => this.selectClient(event)}/>
            <ModalClients clients={this.state.clients} title='Send form' multiple={true} object={this.state.item} data='sendform' show={this.state.showModalSend} onHide={() => this.hideModals()} clickMainButton={(event) => this.sendForm(event)}/>
            <ModalStats id={this.state.item.id} item={this.state.itemStats} show={this.state.showModalStats} onHide={() => this.hideModals()}/>
            <ModalMessageSimple type={this.state.item.data.pre ? 'deleteformpre' : 'deleteform'} show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteForm()}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='forms-locked'/>
          </div>
          <Header title='Form' link='/overview'/>
          <Navigation active='admin' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(DocumentPage));