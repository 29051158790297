import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



const DataActions = [
  {
    id: 'newclient',
    title: 'addclient',
  },
  {
    id: 'importclient',
    title: 'importclientlist',
  },
  {
    id: 'clientdetails',
    title: 'viewdetails',
  },
  {
    id: 'emailinvites',
    title: 'sendemailinvites',
  },
  {
    id: '11session',
    title: 'create11session',
  },
  {
    id: 'report',
    title: 'createreport',
  },
  {
    id: 'newclientgroup',
    title: 'createnewgroup',
  },
  {
    id: 'newprogram',
    title: 'createprogram',
  },
  {
    id: 'newbenchmark',
    title: 'createbenchmark',
  },
  {
    id: 'newmovement',
    title: 'createmovement',
  },
  {
    id: 'newplan',
    title: 'createtrainingplan',
  },
  {
    id: 'newnutrition',
    title: 'newnutritionplan',
  },
  {
    id: 'nutbilling',
    title: "viewbilling",
  },
  {
    id: 'newhabit',
    title: "newhabit",
  },
  {
    id: 'newpayment',
    title: 'processpayment',
  },
  {
    id: 'newdebit',
    title: "setupmembership",
  },
  {
    id: 'newproduct',
    title: "createproduct",
  },
  {
    id: 'newinvoice',
    title: "createinvoice",
  },
  {
    id: 'newexpense',
    title: "logexpense",
  },
  {
    id: 'newstaff',
    title: 'addstaff',
  },
  {
    id: 'staffinvite',
    title: 'sendinvite',
  },
  {
    id: 'newform',
    title: 'createform',
  },
  {
    id: 'uploaddoc',
    title: 'uploaddocument',
  },
  {
    id: 'downloaddoc',
    title: 'downloadtemplates',
  },
  {
    id: 'cal11',
    title: 'new11session',
  },
  {
    id: 'calgroup',
    title: 'newgroupsession',
  },
  {
    id: 'calevent',
    title: 'newevent',
  },
  {
    id: 'rec11',
    title: 'new11template',
  },
  {
    id: 'recgroup',
    title: 'newgrouptemplate',
  },
  {
    id: 'recevent',
    title: 'neweventtemplate',
  },
  {
    id: 'rec11client',
    title: 'newrecurringsession',
  },
  {
    id: 'sendprogram',
    title: 'sendprogram',
  },
  {
    id: 'sendplan',
    title: 'sendtrainingplan',
  },
  {
    id: 'trial',
    title: 'setuptrial',
  },
  {
    id: 'newemail',
    title: 'createnewemail',
  },
  {
    id: 'emailblacklist',
    title: "managenosendlist",
  },
  {
    id: 'emailusage',
    title: "viewmonthbilling",
  },
  {
    id: 'newlocation',
    title: "addlocation",
  },
]



class WidgetActions extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      item1: props.item1 ?? {id: ''},
      item2: props.item2 ?? {id: ''},
      item3: props.item3 ?? {id: ''},
      ext: props.ext ?? false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      item1: props.item1 ?? {id: ''},
      item2: props.item2 ?? {id: ''},
      item3: props.item3 ?? {id: ''},
      ext: props.ext ?? false,
    }
  }


  renderItem1() {
    var data = null
    var link = ''
    for(var item of DataActions) {
      if(item.id === this.state.item1.id) {
        data = item
        link = this.state.item1.link
      }
    }
    if(data !== null) {
      if(link !== '') {
        return (
          <Link to={link} className='action'>{lang.t('actions:'+data.title)}</Link>
        )
      } else {
        return (
          <div className='action' onClick={() => this.props.clickItem1()}>{lang.t('actions:'+data.title)}</div>
        )
      }
    }
  }


  renderItem2() {
    var data = null
    var link = ''
    for(var item of DataActions) {
      if(item.id === this.state.item2.id) {
        data = item
        link = this.state.item2.link
      }
    }
    if(data !== null) {
      if(link !== '') {
        return (
          <Link to={link} className='action'>{lang.t('actions:'+data.title)}</Link>
        )
      } else {
        return (
          <div className='action' onClick={() => this.props.clickItem2()}>{lang.t('actions:'+data.title)}</div>
        )
      }
    }
  }


  renderItem3() {
    var data = null
    var link = ''
    for(var item of DataActions) {
      if(item.id === this.state.item3.id) {
        data = item
        link = this.state.item3.link
      }
    }
    if(data !== null) {
      if(link !== '') {
        return (
          <Link to={link} className='action'>{lang.t('actions:'+data.title)}</Link>
        )
      } else {
        return (
          <div className='action' onClick={() => this.props.clickItem3()}>{lang.t('actions:'+data.title)}</div>
        )
      }
    }
  }


  render() {
    return (
      <div className="col-3">
        {this.renderItem1()}
        {this.renderItem2()}
        {this.renderItem3()}
      </div>
    )
  }
}


export default withTranslation(['actions'])(WidgetActions);