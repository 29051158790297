import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import HelperCal from '../../helper/Calendar';
import HelperPro from '../../helper/Programming';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import InputToggle from '../../components/Form/toggle';
import ListEmpty from '../../components/List/empty';
import ModalForm from '../../components/Modals/form';
import ModalMessageDouble from '../../components/Modals/message-double';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewProgramPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exercises: global.exercises,
      session: '',
      groupSession: false,
      values: ['', '', ''],
      blocks: [{
        preset: '',
        type: 4,
        name: '',
        cat: 0,
        rounds: 1,
        emom: false,
        notes: '',
        exercises: [],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        simple: false,
      }],
      mins: [],
      secs: [],
      rnds: [],
      error: false,
      error2: false,
      error3: false,
      // Add exercises
      search: '',
      showOverlay: false,
      overlayMode: 'list',
      hidden: 'hidden',
      margin: 0,
      currentBlock: 0,
      ex: 'cat',
      plan: '',
      back: ROUTES.PROGRAMMING,
      // New exercise
      newListEx: [],
      newEx: 'new',
      newExName: '',
      newName: '',
      newTool: 0,
      newVideo: '',
      newUnit: 'reps',
      newError: [false, false, false],
      addList: 99999,
      variations: [],
      placeholders: ['Start typing to add a movement'],
      custImages: [],
      tools: '',
      toolList: 0,
      started: false,
      benchmark: false,
      rearrange: false,
      showOverlayOrder: false,
      hiddenOrder: 'hidden',
      exNotes: '',
      exNote1: 0,
      exNote2: 0,
      unsaved: true,
      showOverlayTemplates: false,
      hiddenTemplates: 'hidden',
      replace: 99999,
      tags: '',
      class: 'hidden',
      tour: 'hidden none',
      info: '',
      showModalSave: false,
      showModalNotes: false,
      showModalBlockNotes: false,
      editing: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }


  elementRefs = [];


  componentDidMount() {
    HelperPage.initializeForm('New Program - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.updateExercises())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('plansLoaded', (event) => this.configureData())
    this.configureData()

    setTimeout(() => {
      this.setState({
        started: true,
      })
    }, 10000);

    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });

    if(this.props.location.pathname.indexOf('/edit-program/') !== -1) {
      //this.configureEditing()
    } else {
      setTimeout(() => {
        this.showOverlayTemplates()
      }, 10);
      setTimeout(() => {
        this.setState({class: ''})
      }, 100);
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(!this.state.started && this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var tmp3 = []
      var tmpr = []
      for(var i=0; i<60; i++) {
        tmp1.push(i+' min')
      }
      for(var i1=0; i1<12; i1++) {
        tmp2.push((i1*5)+' sec')
      }
      for(var i2=0; i2<51; i2++) {
        tmpr.push(i2)
      }

      var session = ''
      var group = false
      var plan = ''
      var arr = this.props.location.pathname.split('/')
      if(this.props.location.pathname.indexOf('/session/') !== -1) {
        session = arr[arr.length-2]
      }
      if(this.props.location.pathname.indexOf('/group-session/') !== -1) {
        session = arr[arr.length-2]
        group = true
      }
      if(this.props.location.pathname.indexOf('/plan/') !== -1) {
        plan = arr[arr.length-2]
      }

      var back = global.currentPage
      if(global.linkCal !== '') {
        back = global.linkCal
      }
      if(global.linkPro !== '') {
        back = global.linkPro
      }

      var benchmark = false
      if(global.benchmark) {
        global.benchmark = false
        benchmark = true
      }

      for(var item of global.exercises) {
        if(item.data.user === global.uidUser) {
          tmp3.push({id: item.id, name: item.data.name})
        }
      }

      this.setState({
        mins: tmp1,
        secs: tmp2,
        rnds: tmpr,
        exercises: global.exercises,
        session: session,
        groupSession: group,
        plan: plan,
        back: back,
        newListEx: tmp3,
        benchmark: benchmark
      })

      if(this.props.location.pathname.indexOf('/edit-program/') !== -1) {
        this.configureEditing()
      }
    }
  }


  configureEditing() {
    document.title = 'Edit Program - PT Mate'
    var tmp1 = []
    var tmp2 = []
    var tmp3 = []
    var tmpr = []
    var tblk = []
    var tph = []
    for(var i0=0; i0<60; i0++) {
      tmp1.push(i0+' min')
    }
    for(var i1=0; i1<12; i1++) {
      tmp2.push((i1*5)+' sec')
    }
    for(var i2=0; i2<51; i2++) {
      tmpr.push(i2)
    }

    var values = ['', '', '']

    var id = ''
    var session = ''
    var group = false
    var plan = ''
    var locked = false
    var arr = this.props.location.pathname.split('/')
    id = arr[arr.length-1]
    if(arr.length > 5) {
      if(arr.indexOf('session') !== -1) {
        session = arr[arr.length-3]
      } else if(arr.indexOf('group-session') !== -1) {
        session = arr[arr.length-3]
        group = true
      } else {
        plan = arr[arr.length-3]
        if(arr.indexOf('plan-program') !== -1) {
          locked = true
          plan = arr[arr.length-2]
        }
      }
    }

    var benchmark = false
    var program = null
    var list = global.programs
    global.backLink = '/programming/program/'+arr[arr.length-1]
    if(session !== '') {
      global.backLink = '/calendar/session/'+session
      if(group) {
        global.backLink = '/calendar/group-session/'+session
      }
      for(var sess of global.sessions) {
        if(sess.id === session) {
          list = sess.program
        }
      }
      if(global.linkCli !== '') {
        locked = true
      }
    }
    if(plan !== '') {
      global.backLink = '/programming/edit-plan/'+plan
      for(var pln of global.plans) {
        if(pln.id === plan) {
          list = pln.programs 
        }
      }
    }

    for(var iteme of global.exercises) {
      if(iteme.data.user === global.uidUser) {
        tmp3.push({id: iteme.id, name: iteme.data.name})
      }
    }

    for(var item of list) {
      if(item.id === id) {
        program = item
        var video = ''
        if(item.data.video !== undefined) {
          video = item.data.video
        }
        values = [item.data.name, item.data.desc, video]

        if(item.data.benchmark !== undefined) {
          benchmark = item.data.benchmark
        }

        for(var block of item.blocks) {
          var min = parseInt(block.data.rounds/60)
          var sec = block.data.rounds-(min*60)
          var min2 = 0
          var sec2 = 0
          var logResults = true
          var show = false
          var simple = false
          if(block.data.type === 1 || block.data.type === 2) {
            min = parseInt(block.data.exWork[0]/60)
            sec = parseInt(block.data.exWork[0])-(min*60)
            min2 = parseInt(block.data.exRest[0]/60)
            sec2 = parseInt(block.data.exRest[0])-(min2*60)
          }
          if(block.data.type === 4) {
            min = parseInt(block.data.exWork[0]/60)
            sec = parseInt(block.data.exWork[0])-(min*60)
          }
          
          var ex = []
          if(block.data.simple !== undefined) {
            simple = block.data.simple
          }
          if(!simple) {
            for(var i=0; i<block.data.exName.length; i++) {
              var min1 = parseInt(block.data.exWork[i]/60)
              min2 = parseInt(block.data.exRest[i]/60)
              //var show = false
              var weightType = 'per'
              if(block.data.exWeightType !== undefined) {
                weightType = block.data.exWeightType[i]
              }
              if(block.data.notes !== '') {
                show = true
              }
              if(block.data.logResults !== undefined) {
                logResults = block.data.logResults
              }
              var wgt = block.data.exWeight[i]
              if(weightType === 'kg') {
                wgt = block.data.exWeight[i]
              }
              if(block.data.exWeightRounds !== undefined && block.data.exWeightRounds.length > i) {
                wgt = block.data.exWeightRounds[i]
                if(weightType === 'kg') {
                  wgt = block.data.exWeightRounds[i]
                }
              }
              var rps = block.data.exReps[i]
              if(block.data.exRepsRounds !== undefined && block.data.exRepsRounds.length > i) {
                rps = block.data.exRepsRounds[i]
              }
              var unit = ''
              if(block.data.exUnits !== undefined && block.data.exUnits.length > i) {
                unit = block.data.exUnits[i]
              }
              var notes = ''
              if(block.data.exNotes !== undefined && block.data.exNotes.length > i) {
                notes = block.data.exNotes[i]
              }
              ex.push({
                id: block.data.exId[i],
                name: block.data.exName[i],
                tool: block.data.exTool[i],
                image: block.data.exImage[i],
                cat: block.data.exCat[i],
                type: block.data.exType[i],
                reps: rps,
                weight: wgt,
                wmin: min1,
                wsec: block.data.exWork[i]-(min1*60),
                rmin: min2,
                rsec: block.data.exRest[i]-(min2*60),
                weighttype: weightType,
                unit: unit,
                notes: notes
              })
            }
          }
          var rounds = 1
          if(block.data.type !== 5) {
            rounds = block.data.rounds
          } else {
            if(block.data.cycles !== undefined) {
              rounds = block.data.cycles
            }
          }
          //var show = false
          if(block.data.type === 1 || block.data.type === 4 || block.data.type === 5) {
            if(rounds > 1) {
              show = true
            }
          }
          var name = ''
          if(block.data.name !== undefined) {
            name = block.data.name
          }
          var preset = ''
          if(block.data.preset !== undefined) {
            preset = block.data.preset
          }
          var bcat = block.data.cat
          if(block.data.name !== '' && block.data.name !== undefined) {
            bcat = 99
          }
          tblk.push({
            type: block.data.type,
            cat: bcat,
            rounds: rounds,
            emom: block.data.emom,
            notes: block.data.notes,
            exercises: ex,
            min: min,
            sec: sec,
            min2: min2,
            sec2: sec2,
            show: show,
            logResults: logResults,
            showInfo: show,
            name: name,
            preset: preset,
            link: global.backLink,
            simple: simple
          })
          tph.push('Start typing to add a movement')
        }
      }
    }

    this.setState({
      id: id,
      session: session,
      plan: plan,
      mins: tmp1,
      secs: tmp2,
      rnds: tmpr,
      exercises: global.exercises,
      program: program,
      blocks: tblk,
      values: values,
      locked: locked,
      placeholders: tph,
      newListEx: tmp3,
      benchmark: benchmark,
      editing: true,
      back: global.backLink
    })
  }


  updateExercises() {
    this.setState({
      exercises: global.exercises
    })
  }


  getImg(image) {
    var label = ''
    for(var item of this.state.custImages) {
      if(item.image === image) {
        label = item.url
      }
    }
    if(label === '') {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        var tmp = this.state.custImages
        tmp.push({
          image: image,
          url: url
        })
        this.setState({
          custImages: tmp
        })
      }).catch((error) => {})
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  addBlock() {
    var tmp = this.state.blocks
    var tmp2 = this.state.placeholders
    tmp2.push('Start typing to add a movement')
    tmp.push({
      type: 4,
      name: '',
      cat: 0,
      rounds: 1,
      emom: false,
      notes: '',
      exercises: [],
      min: 0,
      sec: 0,
      min2: 0,
      sec2: 0,
      show: false,
      logResults: true,
      showInfo: false,
      preset: '',
      simple: false,
    })
    this.setState({
      blocks: tmp,
      placeholders: tmp2
    })
  }


  deleteBlock(index) {
    var tmp = this.state.blocks
    var tmp2 = this.state.placeholders
    tmp.splice(index, 1)
    tmp2.splice(index, 1)
    this.setState({
      blocks: tmp,
      placeholders: tmp2
    })
  }


  setType(value, index) {
    var tmp = this.state.blocks
    tmp[index].type = value
    tmp[index].preset = ''
    for(var ex of tmp[index].exercises) {
      var ar1 = String(ex.reps).split('-')
      if(ar1.length > 0) {
        ex.reps = ar1[0]
      }
      var ar2 = String(ex.weight).split('-')
      if(ar2.length > 0) {
        ex.weight = ar2[0]
      }
    }
    if(value === 0 || value === 5) {
      tmp[index].min = 10
      tmp[index].sec = 0
      tmp[index].rounds = 1
    }
    if(value === 1) {
      tmp[index].min = 1
      tmp[index].sec = 0
      tmp[index].emom = false
      tmp[index].rounds = 1
      for(var item of tmp[index].exercises) {
        item.wmin = 1
        item.wsec = 0
        item.rmin = 0
        item.rsec = 0
      }
    }
    if(value === 2) {
      tmp[index].rounds = 1
      tmp[index].emom = false
      tmp[index].min = 0
      tmp[index].sec = 45
      tmp[index].min2 = 0
      tmp[index].sec2 = 15
      for(var item2 of tmp[index].exercises) {
        item2.wmin = 0
        item2.wsec = 45
        item2.rmin = 0
        item2.rsec = 15
      }
    }
    if(value === 3) {
      tmp[index].rounds = 1
      for(var item3 of tmp[index].exercises) {
        item3.wmin = 0
        item3.wsec = 20
        item3.rmin = 0
        item3.rsec = 10
      }
    }
    if(value === 4) {
      tmp[index].rounds = 1
      tmp[index].min = 1
      tmp[index].sec = 0
    }
    if((value === 1 || value === 2) && tmp[index].simple) {
      tmp[index].emom = true
    }
    tmp[index].showInfo = false
    this.setState({
      blocks: tmp
    })
  }


  setCat(event, index) {
    var tmp = this.state.blocks
    tmp[index].cat = parseInt(event.target.value)
    this.setState({
      blocks: tmp
    })
  }


  setRounds(value, index) {
    var tmp = this.state.blocks
    var val = parseInt(value.target.value)
    tmp[index].rounds = val
    if(value.target.value === '1') {
      for(var ex of tmp[index].exercises) {
        var ar1 = String(ex.reps).split('-')
        if(ar1.length > 0) {
          ex.reps = ar1[0]
        }
        var ar2 = String(ex.weight).split('-')
        if(ar2.length > 0) {
          ex.weight = ar2[0]
        }
      }
    }
    
    var show = false
    if(val > 1 && (tmp[index].type === 1 || tmp[index].type === 4 || tmp[index].type === 5)) {
      show = true
    }
    tmp[index].showInfo = show
    this.setState({
      blocks: tmp
    })
  }


  setPreset(event, index) {
    var tmp = this.state.blocks
    tmp[index].preset = event.target.value
    if(event.target.value !== '') {
      tmp[index] = HelperPro.setPreset(tmp[index], event.target.value)
    }
    this.setState({
      blocks: tmp
    })
  }


  onChangeReps(event, num, index) {
    var tmp = this.state.blocks
    var tmp2 = event.target.value
    var val1 = tmp2.replace(/[^0-9-]/g, "")
    var val = val1.replace("--", "-")
    if(tmp[num].type !== 1 && tmp[num].type !== 4 && tmp[num].type !== 5) {
      val = val.replaceAll("-", "")
    } else {
      if(tmp[num].rounds === 1) {
        val = val.replaceAll("-", "")
      }
    }
    tmp[num].exercises[index].reps = val
    this.setState({
      blocks: tmp
    })
  }


  onChangeWeight(event, num, index) {
    var tmp = this.state.blocks
    var tmp2 = event.target.value
    //var val1 = tmp2.replace(/[^0-9-]/g, "")
    var val1 = tmp2.replace(/[^0-9-.]/g, "").replace("..", ".")
    var val = val1.replace("--", "-")
    var count = [...val].filter(x => x === '.').length
    if(tmp[num].type !== 1 && tmp[num].type !== 4 && tmp[num].type !== 5) {
      if(count > 1) {
        val = val.substring(0, val.length-1)
      }
      val = val.replaceAll("-", "")
    } else {
      if(tmp[num].rounds === 1) {
        if(count > 1) {
          val = val.substring(0, val.length-1)
        }
        val = val.replaceAll("-", "")
      }
    }
    tmp[num].exercises[index].weight = val
    this.setState({
      blocks: tmp
    })
  }


  setTimeMin(event, index, type) {
    var tmp = this.state.blocks
    if(type === 'rest') {
      tmp[index].min2 = event.target.value
    } else {
      tmp[index].min = event.target.value
    }
    if(tmp[index].type === 1) {
      for(var item of tmp[index].exercises) {
        item.wmin = event.target.value
      }
    }
    this.setState({
      blocks: tmp
    })
  }


  setTimeSec(event, index, type) {
    var tmp = this.state.blocks
    if(type === 'rest') {
      tmp[index].sec2 = event.target.value
    } else {
      tmp[index].sec = event.target.value
    }
    if(tmp[index].type === 1) {
      for(var item of tmp[index].exercises) {
        item.wsec = event.target.value
      }
    }
    this.setState({
      blocks: tmp
    })
  }


  setTimeMinEx(event, num, index, type) {
    var tmp = this.state.blocks
    if(type === 'work') {
      tmp[num].exercises[index].wmin = event.target.value
    } else {
      tmp[num].exercises[index].rmin = event.target.value
    } 
    this.setState({
      blocks: tmp
    })
  }


  setTimeSecEx(event, num, index, type) {
    var tmp = this.state.blocks
    if(type === 'work') {
      tmp[num].exercises[index].wsec = event.target.value
    } else {
      tmp[num].exercises[index].rsec = event.target.value
    } 
    this.setState({
      blocks: tmp
    })
  }


  setEmom(event, index) {
    var tmp = this.state.blocks
    if(event.target.value === 'true') {
      tmp[index].emom = true
    } else {
      tmp[index].emom = false
    }
    this.setState({
      blocks: tmp
    })
  }


  onChangeBlockName(event, index) {
    var tmp = this.state.blocks
    tmp[index].name = event.target.value
    this.setState({
      blocks: tmp
    })
  }


  deleteExercise(index, num) {
    var tmp = this.state.blocks
    tmp[index].exercises.splice(num, 1)
    this.setState({
      blocks: tmp
    })
  }


  toggleNotes(index) {
    var tmp = this.state.blocks
    tmp[index].show = !tmp[index].show
    this.setState({
      blocks: tmp
    })
  }


  setNotes(event, index) {
    var tmp = this.state.blocks
    tmp[index].notes = event.target.value
    this.setState({
      blocks: tmp
    })
  }


  toggleWmode(block, ex, type) {
    var tmp = this.state.blocks
    tmp[block].exercises[ex].weighttype = type
    if(type === 'per' && tmp[block].exercises[ex].weight > 100) {
      tmp[block].exercises[ex].weight = 100
    }
    this.setState({
      blocks: tmp
    })
  }


  toggleResults(block, value) {
    var tmp = this.state.blocks
    tmp[block].logResults = value
    this.setState({
      blocks: tmp
    })
  }


  setShowList(num) {
    var tmp = this.state.placeholders
    if(num === 99999) {
      tmp[this.state.currentBlock] ='Start typing to add a movement'
      if(this.state.variations.length === 0) {
        this.setState({
          addList: num,
          //currentBlock: num,
          variations: [],
          search: '',
          placeholders: tmp
        })
      } else {
        setTimeout(() => {
          this.setState({
            addList: num,
            //currentBlock: num,
            variations: [],
            search: '',
          })
        }, 300);
      }
    } else {
      tmp[num] ='E.g. "Deadlift" or "Push up"'
      this.setState({
        addList: num,
        currentBlock: num,
        variations: [],
        search: '',
        placeholders: tmp,
        tools: '',
        toolList: 0,
        tags: '',
      })
    }
  }


  showAddEx(index) {
    this.setState({
      showOverlay: true,
      currentBlock: index,
      overlayMode: 'form',
      newEx: 'new',
      newExName: '',
      newName: '',
      newTool: 0,
      newVideo: '',
      newUnit: 'reps',
      newError: [false, false, false]
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
        search: '',
        ex: 'cat',
        variations: [],
      });
    }, 100);
  }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    var tmp = this.state.blocks
    if(this.state.rearrange) {
      const res = Array.from(tmp);
      const [removed] = res.splice(result.source.index, 1);
      res.splice(result.destination.index, 0, removed);
      tmp = res
    } else {
      let ar = result.destination.droppableId.split('droppable')
      let num = parseInt(ar[1])
      //var moved = this.state.blocks[num].exercises[result.source.index]
      const res = Array.from(tmp[num].exercises);
      const [removed] = res.splice(result.source.index, 1);
      res.splice(result.destination.index, 0, removed);
      tmp[num].exercises = res
    }

    this.setState({
      blocks: tmp
    })
  }



  // Create exercises ------------------------------------------------------------



  checkCreateProgram() {
    var passed = true
    var amrap = true
    var time = true
    var placeholder = true
    for(var item of this.state.blocks) {
      if(!item.simple) {
        if(item.exercises.length === 0) {
          passed = false
        }
        for(var ex of item.exercises) {
          if(ex.reps === 0 && item.type === 0) {
            amrap = false
          }
          if(ex.reps === 0 && item.type === 5 && item.rounds > 1) {
            time = false
          }
          if(ex.id === 'placeholder') {
            placeholder = false
          }
        }
      }
    }
    if(passed && amrap && time && placeholder) {
      this.createProgram()
    } else {
      this.showError(!passed, !amrap, !time, !placeholder)
    }
  }


  createProgram() {
    global.badgeLocked = true
    global.programLocked = true
    var name = this.state.values[0]
    var time = 0
    var ex = 0

    this.setState({
      unsaved: false
    })

    if(this.state.values[0] === '') {
      name = 'Program '+Moment().format('DD MMM YYYY')
    }
    for(let block of this.state.blocks) {
      var rounds2 = block.rounds
      block.cycles = 0
      if(block.type === 0 || block.type === 5) {
        rounds2 = parseInt(block.min)*60+parseInt(block.sec)
      }
      if(block.type === 5) {
        block.cycles = block.rounds
      }
      ex += block.exercises.length
      var tt = 0
      var tt2 = 0
      if(block.type === 4) {
        tt = parseInt(block.min)*60+parseInt(block.sec)
      }
      if(block.type === 2 && block.emom) {
        tt = parseInt(block.min)*60+parseInt(block.sec)
        var tm = 0
        var ts = 0
        if(block.min2 !== undefined) {tm = parseInt(block.min2)*60}
        if(block.sec2 !== undefined) {ts = parseInt(block.sec2)}
        tt2 = tm+ts
      }
      time += HelperCal.calcBlockTime(block.exercises, block.type, rounds2, block.emom, tt, tt2, block)
    }

    var ref = '/workouts/'+global.uid
    var toload = 'program'
    if(this.state.session !== '') {
      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.session+'/workout').remove()
      ref = '/sessions/'+global.uid+'/'+this.state.session+'/workout'
      toload = 'session'
    }
    if(this.state.plan !== '') {
      ref = '/plans/'+global.uid+'/'+this.state.plan+'/workouts'
    }

    var obj = Firebase.database().ref(ref).push()

    // Plan Stuff
    if(this.state.plan !== '') {
      var d1 = ''
      var d2 = ''
      var d3 = ''
      var d4 = ''
      var d5 = ''
      var d6 = ''
      var d7 = ''
      for(var pln of global.plans) {
        if(pln.id === this.state.plan) {
          for(var wk of pln.weeks) { 
            if(wk.id === global.currentWeekPlan) {
              d1 = wk.data.day1
              d2 = wk.data.day2
              d3 = wk.data.day3
              d4 = wk.data.day4
              d5 = wk.data.day5
              d6 = wk.data.day6
              d7 = wk.data.day7
              if(global.currentDay === 'day1') {
                Firebase.database().ref('/plans/'+global.uid+'/'+this.state.plan+'/weeks/'+global.currentWeekPlan).update({day1: d1+','+obj.key})
              } else if(global.currentDay === 'day2') {
                Firebase.database().ref('/plans/'+global.uid+'/'+this.state.plan+'/weeks/'+global.currentWeekPlan).update({day2: d2+','+obj.key})
              } else if(global.currentDay === 'day3') {
                Firebase.database().ref('/plans/'+global.uid+'/'+this.state.plan+'/weeks/'+global.currentWeekPlan).update({day3: d3+','+obj.key})
              } else if(global.currentDay === 'day4') {
                Firebase.database().ref('/plans/'+global.uid+'/'+this.state.plan+'/weeks/'+global.currentWeekPlan).update({day4: d4+','+obj.key})
              } else if(global.currentDay === 'day5') {
                Firebase.database().ref('/plans/'+global.uid+'/'+this.state.plan+'/weeks/'+global.currentWeekPlan).update({day5: d5+','+obj.key})
              } else if(global.currentDay === 'day6') {
                Firebase.database().ref('/plans/'+global.uid+'/'+this.state.plan+'/weeks/'+global.currentWeekPlan).update({day6: d6+','+obj.key})
              } else if(global.currentDay === 'day7') {
                Firebase.database().ref('/plans/'+global.uid+'/'+this.state.plan+'/weeks/'+global.currentWeekPlan).update({day7: d7+','+obj.key})
              }

            }
            
          }
        }
      }
    }

    var assembled = {
      name: name,
      desc: this.state.values[1],
      video: this.state.values[2],
      session: '',
      time: Math.ceil(time/60),
      exercises: ex,
      uid: global.uid,
      benchmark: this.state.benchmark,
      blocks: [],
    }
    
    for(let block of this.state.blocks) {
      var rounds = block.rounds
      if(block.type === 0 || block.type === 5) {
        rounds = parseInt(block.min)*60+parseInt(block.sec)
      }
      var t1 = 0
      var t2 = 0
      if(block.type === 2 && block.emom || block.type === 1 && block.simple) {
        t1 = parseInt(block.min)*60+parseInt(block.sec)
        if(block.min2 !== undefined && block.sec2 !== undefined) {
          t2 = parseInt(block.min2)*60+parseInt(block.sec2)
        }
      }
      if(block.type === 4) {
        t1 = parseInt(block.min)*60+parseInt(block.sec)
      }
      var exIds = []
      var exCats = []
      var exNames = []
      var exImages = []
      var exReps = []
      var exRepsRounds = []
      var exResReps = []
      var exResRepsGroup = []
      var exResWeight = []
      var exResWeightGroup = []
      var exRest = []
      var exTool = []
      var exType = []
      var exWeight = []
      var exWeightRounds = []
      var exWork = []
      var exWeightType = []
      var exUnits = []
      var exNotes = []

      if(block.simple) {
        exIds.push("-LtiRnFRcGesQkLZKSFq")
        exCats.push(0)
        exNames.push("NOTES")
        exImages.push("")
        exRepsRounds.push("0")
        exResReps.push(0)
        exResRepsGroup.push('')
        exResWeight.push(0)
        exResWeightGroup.push('')
        exTool.push(0)
        exType.push(0)
        exNotes.push("")
        exWeightType.push("per")
        exReps.push(0)
        exWeight.push(0)
        exWeightRounds.push("0")
        if(block.type === 2) {
          exWork.push(t1)
          exRest.push(t2)
        } else if(block.type === 1) {
          exWork.push(parseInt(t1))
          exRest.push(parseInt(0))
        } else {
          exWork.push(0)
          exRest.push(0)
        }
        exUnits.push('reps')
      } else {
        for(var i=0; i<block.exercises.length; i++) {
          exIds.push(block.exercises[i].id)
          exCats.push(parseInt(block.exercises[i].cat))
          exNames.push(block.exercises[i].name)
          exImages.push(block.exercises[i].image)

          var tmpr = String(block.exercises[i].reps)
          if(tmpr[0] === '-') {
            tmpr = tmpr.substring(1, tmpr.length)
          }
          if(tmpr[tmpr.length-1] === '-') {
            tmpr = tmpr.substring(0, tmpr.length-1)
          }

          if(block.exercises[i].reps !== '') {
            //var ar1 = String(block.exercises[i].reps).split('-')
            var ar1 = tmpr.split('-')
            exReps.push(parseInt(ar1[0]))
          } else {
            exReps.push(0)
          }
          if(block.exercises[i].unit !== '' && block.exercises[i].unit !== undefined) {
            exUnits.push(block.exercises[i].unit)
          } else {
            exUnits.push('reps')
          }
          exRepsRounds.push(tmpr)
          exResReps.push(0)
          exResRepsGroup.push('')

          exResWeight.push(0)
          exResWeightGroup.push('')
          exTool.push(parseInt(block.exercises[i].tool))
          exType.push(parseInt(block.exercises[i].type))
          exNotes.push(block.exercises[i].notes)

          var tmpw = String(block.exercises[i].weight)
          if(tmpw[0] === '-') {
            tmpw = tmpw.substring(1, tmpw.length)
          }
          if(tmpw[tmpw.length-1] === '-') {
            tmpw = tmpw.substring(0, tmpw.length-1)
          }

          if(block.exercises[i].weight !== '') {
            //var ar2 = String(block.exercises[i].weight).split('-')
            var ar2 = tmpw.split('-')
            if(block.exercises[i].weighttype === 'kg') {
              exWeight.push(parseFloat(ar2[0]))
            } else {
              exWeight.push(parseFloat(ar2[0]))
            }
          } else {
            exWeight.push(0)
          }
          if(block.exercises[i].weighttype === 'kg') {
            exWeightRounds.push(tmpw)
          } else {
            exWeightRounds.push(tmpw)
          }
          exWeightType.push(block.exercises[i].weighttype)
          if(block.type === 2 && block.emom) {
            exWork.push(t1)
            exRest.push(t2)
          } else {
            exWork.push(parseInt(block.exercises[i].wmin)*60+parseInt(block.exercises[i].wsec))
            exRest.push(parseInt(block.exercises[i].rmin)*60+parseInt(block.exercises[i].rsec))
          }
          if(block.type === 4) {
            exWork[exWork.length-1] = t1
          }
        }
      }

      var bname = ''
      if(block.name !== undefined && block.name !== null) {
        bname = block.name
      }

      var bcat = block.cat
      if(bcat === 99) {
        bcat = 0
      }

      var simple = null
      if(block.simple) {
        simple = true
      }

      var rf = obj.child('blocks').push()
      assembled.blocks[rf.key] = {
        cat: bcat,
        name: bname,
        emom: block.emom,
        notes: block.notes,
        results: false,
        notesRes: '',
        rounds: rounds,
        cycles: block.cycles,
        type: block.type,
        logResults: block.logResults,
        exId: exIds,
        exCat: exCats,
        exName: exNames,
        exImage: exImages,
        exReps: exReps,
        exRepsRounds: exRepsRounds,
        exResReps: exResReps,
        exResRepsGroup: exResRepsGroup,
        exResWeight: exResWeight,
        exResWeightGroup: exResWeightGroup,
        exRest: exRest,
        exTool: exTool,
        exType: exType,
        exWeight: exWeight,
        exWeightRounds: exWeightRounds,
        exWork: exWork,
        exWeightType: exWeightType,
        exUnits: exUnits,
        exNotes: exNotes,
        preset: block.preset,
        simple: simple
      }
    }

    obj.set(
      assembled
    ).then((data)=>{
      global.message = 'Program successfully created'
      global.programLocked = false
      setTimeout(() => {
        if(toload === 'program') {
          Connector.loadPrograms((result) => {})
        } else {
          Connector.loadSessions((result) => {})
        }
      }, 500);
      if(this.state.session !== '') {
        if(this.state.groupSession) {
          this.props.history.push('/calendar/group-session/'+this.state.session)
        } else {
          this.props.history.push('/calendar/session/'+this.state.session)
        }
      } else if(this.state.plan !== '') {
        this.props.history.push('/programming/edit-plan/'+this.state.plan)
      } else {
        EventEmitter.dispatch('showMessage', 'Program successfully created');
        setTimeout(() => {
          this.props.history.push('/programming/program/'+obj.key)
          global.message = ''
        }, 1000);
      }
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'program');
      }, 1000);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })

  }


  showError(passed, amrap, time, placeholder) {
    this.setState({
      error: passed,
      error2: amrap,
      error3: time,
      error4: placeholder,
    })
  }



  // Update program ------------------------------------------------------------



  checkUpdateProgram() {
    var passed = true
    var amrap = true
    var time = true
    for(var item of this.state.blocks) {
      if(!item.simple) {
        if(item.exercises.length === 0) {
          passed = false
        }
        for(var ex of item.exercises) {
          if(ex.reps === 0 && item.type === 0) {
            amrap = false
          }
          if(ex.reps === 0 && item.type === 5 && item.rounds > 1) {
            time = false
          }
        }
      }
    }
    if(passed && amrap && time) {
      this.setState({
        editing: false,
        updating: true,
        unsaved: false
      }, () => {
        this.updateProgram('')
      })
    } else {
      this.showError(!passed, !amrap, !time)
    }
  }


  updateProgram(type) {
    var name = this.state.values[0]
    var time = 0
    var ex = 0
    var ref = '/workouts/'+global.uid+'/'+this.state.id
    var message = 'Program successfully updated'
    var toload = 'program'
    global.programLocked = true
    if(this.state.session !== '') {
      ref = '/sessions/'+global.uid+'/'+this.state.session+'/workout/'+this.state.id
      toload = 'session'
    }
    if(this.state.plan !== '') {
      ref = '/plans/'+global.uid+'/'+this.state.plan+'/workouts/'+this.state.id
    }
    //Benchmark
    if(this.state.benchmark) {
      var obj = Firebase.database().ref('/workouts/'+global.uid).push()
      Firebase.database().ref('/workouts/'+global.uid+'/'+this.state.id).remove()
      ref = '/workouts/'+global.uid+'/'+obj.key
    }

    if(type === 'savetolist') {
      var ref2 = Firebase.database().ref('/workouts/'+global.uid).push()
      ref = 'workouts/'+global.uid+'/'+ref2.key
      message = 'Program successfully saved'
    }
    if(this.state.values[0] === '') {
      name = 'Program '+Moment().format('DD MMM YYYY')
    }
    for(let block of this.state.blocks) {
      var rounds2 = block.rounds
      block.cycles = 0
      if(block.type === 0 || block.type === 5) {
        rounds2 = parseInt(block.min)*60+parseInt(block.sec)
      }
      if(block.type === 5) {
        block.cycles = block.rounds
      }
      ex += block.exercises.length
      var tt = 0
      var tt2 = 0
      if(block.type === 4) {
        tt = parseInt(block.min)*60+parseInt(block.sec)
      }
      if(block.type === 2 && block.emom) {
        tt = parseInt(block.min)*60+parseInt(block.sec)
        if(block.min2 !== undefined && block.sec2 !== undefined) {
          tt2 = parseInt(block.min2)*60+parseInt(block.sec2)
        }
      }
      time += HelperCal.calcBlockTime(block.exercises, block.type, rounds2, block.emom, tt, tt2)
    }

    var assembled = {
      name: name,
      desc: this.state.values[1],
      video: this.state.values[2],
      session: '',
      time: Math.ceil(time/60),
      exercises: ex,
      uid: global.uid,
      benchmark: this.state.benchmark,
      blocks: []
    }

    for(let block of this.state.blocks) {
      var rounds = block.rounds
      if(block.type === 0 || block.type === 5) {
        rounds = block.min*60+block.sec
      }
      block.cycles = 0
      if(block.type === 5) {
        block.cycles = block.rounds
      }
      var cat = 0
      if(block.cat !== 99) {
        cat = block.cat
      }

      var t1 = 0
      var t2 = 0
      if((block.type === 2 && block.emom) || (block.type === 1 && block.simple)) {
        t1 = parseInt(block.min)*60+parseInt(block.sec)
        var tm = 0
        var ts = 0
        if(block.min2 !== undefined) {tm = parseInt(block.min2)*60}
        if(block.sec2 !== undefined) {ts = parseInt(block.sec2)}
        t2 = tm+ts
      }
      if(block.type === 4) {
        t1 = parseInt(block.min)*60+parseInt(block.sec)
      }
      var exIds = []
      var exCats = []
      var exNames = []
      var exImages = []
      var exReps = []
      var exRepsRounds = []
      var exResReps = []
      var exResRepsGroup = []
      var exResWeight = []
      var exResWeightGroup = []
      var exRest = []
      var exTool = []
      var exType = []
      var exWeight = []
      var exWeightRounds = []
      var exWork = []
      var exWeightType = []
      var exUnits = []
      var exNotes = []

      if(block.simple) {
        exIds.push("-LtiRnFRcGesQkLZKSFq")
        exCats.push(0)
        exNames.push("NOTES")
        exImages.push("")
        exRepsRounds.push("0")
        exResReps.push(0)
        exResRepsGroup.push('')
        exResWeight.push(0)
        exResWeightGroup.push('')
        exTool.push(0)
        exType.push(0)
        exNotes.push("")
        exWeightType.push("per")
        exReps.push(0)
        exWeight.push(0)
        exWeightRounds.push("0")
        if(block.type === 2) {
          exWork.push(t1)
          exRest.push(t2)
        } else if(block.type === 1) {
          exWork.push(parseInt(t1))
          exRest.push(parseInt(0))
        } else {
          exWork.push(0)
          exRest.push(0)
        }
        exUnits.push('reps')
      } else {
        for(var i=0; i<block.exercises.length; i++) {
          exIds.push(block.exercises[i].id)
          exCats.push(parseInt(block.exercises[i].cat))
          exNames.push(block.exercises[i].name)
          exImages.push(block.exercises[i].image)

          var tmpr = String(block.exercises[i].reps)
          if(tmpr[0] === '-') {
            tmpr = tmpr.substring(1, tmpr.length)
          }
          if(tmpr[tmpr.length-1] === '-') {
            tmpr = tmpr.substring(0, tmpr.length-1)
          }
          
          if(block.exercises[i].reps !== '') {
            var ar1 = tmpr.split('-')
            exReps.push(parseInt(ar1[0]))
          } else {
            exReps.push(0)
          }
          exRepsRounds.push(tmpr)

          exResReps.push(0)
          exResRepsGroup.push('')
          exResWeight.push(0)
          exResWeightGroup.push('')
          exTool.push(parseInt(block.exercises[i].tool))
          exType.push(parseInt(block.exercises[i].type))
          exNotes.push(block.exercises[i].notes)

          var tmpw = String(block.exercises[i].weight)
          if(tmpw[0] === '-') {
            tmpw = tmpw.substring(1, tmpw.length)
          }
          if(tmpw[tmpw.length-1] === '-') {
            tmpw = tmpw.substring(0, tmpw.length-1)
          }

          if(block.exercises[i].weight !== '') {
            var ar2 = tmpw.split('-')
            if(block.exercises[i].weighttype === 'kg') {
              exWeight.push(parseFloat(ar2[0]))
            } else {
              exWeight.push(parseFloat(ar2[0]))
            }
          } else {
            exWeight.push(0)
          }
          
          if(block.exercises[i].unit !== '' && block.exercises[i].unit !== undefined) {
            exUnits.push(block.exercises[i].unit)
          } else {
            if(block.exercises[i].tool === 6 || block.exercises[i].tool === 7 || block.exercises[i].tool === 25) {
              exUnits.push('dist')
            } else if(block.exercises[i].tool === 27) {
              exUnits.push('cal')
            } else if(block.exercises[i].tool === 28) {
              exUnits.push('s')
            } else {
              exUnits.push('reps')
            }
          }
          if(block.exercises[i].weighttype === 'kg') {
            exWeightRounds.push(tmpw)
          } else {
            exWeightRounds.push(tmpw)
          }
          exWeightType.push(block.exercises[i].weighttype)
          if(block.type === 2 && block.emom) {
            exWork.push(t1)
            exRest.push(t2)
          } else {
            exWork.push(parseInt(block.exercises[i].wmin)*60+parseInt(block.exercises[i].wsec))
            exRest.push(parseInt(block.exercises[i].rmin)*60+parseInt(block.exercises[i].rsec))
          }
          if(block.type === 4) {
            exWork[exWork.length-1] = t1
          }
        }
      }

      var bname = ''
      if(block.name !== undefined && block.name !== null) {
        bname = block.name
      }

      var simple = null
      if(block.simple) {
        simple = true
      }

      var rf = Firebase.database().ref(ref+'/blocks').push()
      assembled.blocks[rf.key] = {
        cat: cat,
        emom: block.emom,
        notes: block.notes,
        results: false,
        notesRes: '',
        rounds: rounds,
        cycles: block.cycles,
        type: block.type,
        logResults: block.logResults,
        exId: exIds,
        exCat: exCats,
        exName: exNames,
        exImage: exImages,
        exReps: exReps,
        exRepsRounds: exRepsRounds,
        exResReps: exResReps,
        exResRepsGroup: exResRepsGroup,
        exResWeight: exResWeight,
        exResWeightGroup: exResWeightGroup,
        exRest: exRest,
        exTool: exTool,
        exType: exType,
        exWeight: exWeight,
        exWeightRounds: exWeightRounds,
        exWork: exWork,
        exWeightType: exWeightType,
        name: bname,
        exUnits: exUnits,
        exNotes: exNotes,
        simple: simple
      }
    }

    Firebase.database().ref(ref).set(
      assembled
    ).then((data)=>{
      EventEmitter.dispatch('showMessage', message);
      setTimeout(() => {
        if(this.state.benchmark) {
          this.props.history.push('/programming/program/'+obj.key)
        } else {
          this.configureData()
          //this.props.history.push(this.state.back)
        }
        
      }, 500);
      setTimeout(() => {
        this.setState({
          //editing: false,
          //updating: false,
          unsaved: false
        })
        this.configureData()
        global.programLocked = false
        if(toload === 'program') {
          Connector.loadPrograms((result) => {})
        } else {
          if(type === 'savetolist') {
            Connector.loadPrograms((result) => {})
          } else {
            Connector.loadSessions((result) => {})
          }
        }
        this.props.history.push(this.state.back)
      }, 1010);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  // Exercises overlay ------------------------------------------------------------



  showOverlayEx(index, position) {
    this.setState({
      showOverlay: true,
      currentBlock: index,
      overlayMode: 'list',
      newEx: 'new',
      newExName: '',
      newName: '',
      newTool: 0,
      newVideo: '',
      newUnit: 'reps',
      newError: [false, false, false],
      replace: position,
    })
    setTimeout(() => {
      this.filterExercises('', 'tool')
      this.setState({
        hidden: '',
        search: '',
        ex: 'cat',
        //variations: []
      });
    }, 100);
  }


  hideOverlayEx() {
    this.setState({
      hidden: 'hidden',
      replace: 99999,
    })
    setTimeout(() => {
      this.setState({
        showOverlay: false,
        //tools: [],
        //toolList: 0,
      });
    }, 500);
  }


  renderAddEx(num) {
    if(global.maxPrograms !== 2) {
      if(this.state.addList === 99999) {
        return (
          <button className="btn tertiary add width-12" onMouseDown={() => this.showAddEx(num)}>Create a new movement</button>
        )
      } else {
        return (
          <button className="btn tertiary add width-12" onMouseDown={() => this.showAddEx(num)}>Create a new movement</button>
        )
      }
    }
  }


  renderNewError(num, text) {
    if(this.state.newError[num]) {
      return (
        <p className="error">{text}</p>
      )
    }
  }


  renderNumber(id) {
    var label = ''
    var num = 0;
    for(var ex of this.state.blocks[this.state.currentBlock].exercises) {
      if(ex.id === id) {
        num++
      }
    }
    if(num > 0) {
      label = num
      return <span className="pill primary">{label}</span>
    }
  }


  renderToolExp() {
    var co = false
    for(var item of global.exToolsWeight) {
      if(String(item) === String(this.state.newTool)) {co = true}
    }
    if(co) {
      return (
        <div>
          <div style={{height: 5}}></div>
          <label>Also tracks weight</label>
        </div>
      )
    }
  }


  renderExContent() {
    // Existing exercises
    if(this.state.overlayMode === 'list') {
      var list = this.state.variations
      list.sort((a,b) => a.name.localeCompare(b.name))
      return (
        <div className="box clients">
          <h2 className="mb-20 lft">Select movement</h2>
          <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayEx()}>Close</button>
          <div className="clear"></div>
          <input type="text" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterExercises(val, 'field')}/>
          <div className="mb-10"></div>
          <div className="lft" style={{width: '49%'}}>
            <select value={this.state.tags} onChange={(event) => this.selectTag(event.target.value)}>
              <option value=''>Any type</option>
              <option value='6,'>Accessory</option>
              <option value='1,'>Activation</option>
              <option value='3,'>Cardiovascular</option>
              <option value='8,'>Metabolic Conditioning</option>
              <option value='0,'>Mobility</option>
              <option value='4,'>Skill</option>
              <option value='7,'>Stability</option>
              <option value='2,'>Strength</option>
              <option value='9,'>Stretch</option>
              <option value='5,'>Warm up</option>
            </select>
          </div>
          <div className="rgt" style={{width: '49%'}}>
            <select value={this.state.tools} onChange={(event) => this.selectTool(event.target.value)}>
              <option value={''}>Any equipment</option>
              <option value={',19,20,21,'}>Accessories</option>
              <option value={',3,'}>Barbell</option>
              <option value={',22,'}>Battle ropes</option>
              <option value={',0,28,'}>Body weight</option>
              <option value={',4,5,'}>Dumbbells</option>
              <option value={',6,7,'}>Cardio</option>
              <option value={',12,13,14,'}>Exercise ball</option>
              <option value={',1,2,'}>Kettlebells</option>
              <option value={',18,'}>Rings</option>
              <option value={',8,'}>Pin loaded cables</option>
              <option value={',23,'}>Plyo box</option>
              <option value={',16,'}>Resistance band</option>
              <option value={',17,'}>Rig</option>
              <option value={',9,'}>Skipping Rope</option>
              <option value={',24,25,'}>Strongman</option>
              <option value={',15,'}>Suspension trainer</option>
              <option value={',10,'}>Weight plate</option>
              <option value={',11,'}>Other</option>
            </select>
          </div>
          <div className="clear mb-20"></div>
          <div className="scroll withbutton">
            {list.map(item => (
              <div className="list bare" onClick={() => this.addExercise(item, this.state.currentBlock, 'overlay')} key={item.id}>
                <p><strong>{item.name}</strong>{this.renderNumber(item.id)}<br/>{global.exTools[item.tool]}</p>
              </div>
            ))}
          </div>
        </div> 
      )
    // Add exercise
    } else {
      return (
        <div className="box clients">
          <h2 className="mb-20 lft">Create a new movement</h2>
          <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayEx()}>Close</button>
          <div className="clear"></div>
          <div className="clear">
          <label>Movement name*</label>
          <input type="text" value={this.state.newName} onChange={(val) => this.onChangeName(val)} className={this.state.newError[2] ? 'error': ''} placeholder="E.g. Goblet squat"/>
          {this.renderNewError(2, 'Enter a name for your movement')}
          <div className="sv-20"></div>
            <label>Equipment*</label>
            <select value={this.state.newTool} onChange={event => this.setTool(event)}>
              {global.exTools.map((item, index) => (
                <option key={index} value={index}>{item}</option>
              ))}
            </select>
          </div>
          <div>
            <div className="sv-20"></div>
            <label>Tracks</label>
            <div className="radios">
              <button className={this.state.newUnit === 'reps' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({newUnit: 'reps'})}>Reps</button>
              <button className={this.state.newUnit === 'dist' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({newUnit: 'dist'})}>Distance</button>
              <button className={this.state.newUnit === 'cals' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({newUnit: 'cals'})}>Calories</button>
              <button className={this.state.newUnit === 'time' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({newUnit: 'time'})}>Time</button>
            </div>
            {this.renderToolExp()}
          </div>
          <div className="clear sv-20"></div>
          <label>Video (optional)</label>
          <input type="text" value={this.state.newVideo} onChange={(val) => this.onChangeVideo(val)}/>
          <div className="sv-30"></div>
          <button className="btn primary mb-10" onClick={() => this.checkCreateNewExercise()}>Create movement</button>
          <p className="small" style={{fontSize: 11}}>Note: You can update this movement from your movement list if you want to add a description or update any of the details.</p>
        </div>
      )
    }
  }


  renderOverlayEx() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}>
          
            {this.renderExContent()}
            <div className="clear"></div>
          </div>
      )
    }
  }


  selectTag(item) {
    this.setState({
      tags: item,
    }, () => {
      this.filterExercises(this.state.search, 'tag')
    })
  }


  selectTool(items) {
    this.setState({
      tools: items
    }, () => {
      this.filterExercises(this.state.search, 'tool')
    })
  }


  selectExercise(id) {
    var tmp = []
    for(var item of this.state.exercises) {
      if(item.id === id) {
        for(var i=0; i<item.data.subTitles.length; i++) {
          var unit = 'reps'
          if(item.data.subUnits !== undefined) {
            unit = item.data.subUnits[i]
          }
          tmp.push({
            id: item.data.subIds[i],
            name: item.data.subTitles[i],
            tool: item.data.subTools[i],
            image: item.data.subImages[i],
            cat: item.data.cat,
            type: item.data.type,
            reps: 0,
            weight: 0,
            wmin: 0,
            wsec: 0,
            rmin: 0,
            rsec: 0,
            weighttype: (global.spaceLbs ? 'lb' : 'kg'),
            unit: unit,
            notes: "",
          })
        }
      }
    }
    this.setState({
      variations: tmp,
      ex: 'exercise'
    })
  }


  filterExercises(val, type) {
    var tmp = []
    var value = ''
    var tags = []
    if(val !== '' && type === 'field') {
      value = val.target.value
    }
    if(val !== '' && type !== 'field') {
      value = val
    }
    if(value === '') {
      for(var item of this.state.exercises) {
        tags = []
        if(item.data.subTags !== undefined) {
          tags = item.data.subTags
        }
        for(var i=0; i<item.data.subTitles.length; i++) {
          var t1 = ''
          if(tags.length > i) {
            t1 = tags[i]
          }
          if((this.state.tools.indexOf(','+item.data.subTools[i]+',') !== -1 || this.state.tools === '') && (t1.indexOf(this.state.tags) !== -1 || this.state.tags === '')) {
            var unit = 'reps'
            if(item.data.subUnits !== undefined) {
              unit = item.data.subUnits[i]
            }
            tmp.push({
              id: item.data.subIds[i],
              name: item.data.subTitles[i],
              tool: item.data.subTools[i],
              image: item.data.subImages[i],
              cat: item.data.cat,
              type: item.data.type,
              reps: 0,
              weight: 0,
              wmin: 0,
              wsec: 0,
              rmin: 0,
              rsec: 0,
              weighttype: (global.spaceLbs ? 'lb' : 'kg'),
              unit: unit,
              notes: "",
            })
          }
        }
      }
      this.setState({
        variations: tmp,
        search: ''
      })
    } else {
      var str = value.toLowerCase()
      for(var item2 of this.state.exercises) {
        tags = []
        if(item2.data.subTags !== undefined) {
          tags = item2.data.subTags
        }
        for(var i2=0; i2<item2.data.subTitles.length; i2++) {
          var t2 = ''
          if(tags.length > i2) {
            t2 = tags[i2]
          }
          if(item2.data.subTitles[i2].toLowerCase().indexOf(str) !== -1) {
            if((this.state.tools.indexOf(item2.data.subTools[i2]) !== -1 || this.state.tools.length === 0) && (t2.indexOf(this.state.tags) !== -1 || this.state.tags === '')) {
              var unit2 = 'reps'
              if(item2.data.subUnits !== undefined) {
                unit2 = item2.data.subUnits[i2]
              }
              tmp.push({
                id: item2.data.subIds[i2],
                name: item2.data.subTitles[i2],
                tool: item2.data.subTools[i2],
                image: item2.data.subImages[i2],
                cat: item2.data.cat,
                type: item2.data.type,
                reps: 0,
                weight: 0,
                wmin: 0,
                wsec: 0,
                rmin: 0,
                rsec: 0,
                weighttype: (global.spaceLbs ? 'lb' : 'kg'),
                unit: unit2,
                notes: "",
              })
            }
          }
        }
      }
      this.setState({
        variations: tmp,
        //search: val.target.value
        search: value
      })
    }
  }


  addExercise(item, index, type) {
    var tmp = this.state.blocks
    var tmp2 = this.state.placeholders
    if(tmp[this.state.currentBlock].type === 1) {
      item.wmin = tmp[this.state.currentBlock].min
      item.wsec = tmp[this.state.currentBlock].sec
    }
    if(tmp[this.state.currentBlock].type === 2) {
      item.wsec = 45
      item.rsec = 15
    }
    if(tmp[this.state.currentBlock].type === 3) {
      item.wsec = 20
      item.rsec = 10
    }
    if(tmp[this.state.currentBlock].preset !== '') {
      item.reps = HelperPro.setPresetMovement(tmp[this.state.currentBlock].preset)
      if(tmp[this.state.currentBlock].type === 2) {
        item = HelperPro.setPresetInterval(item, tmp[this.state.currentBlock].preset)
      }
    }
    if(this.state.replace === 99999) {
      tmp[this.state.currentBlock].exercises.push(item)
      if(type === 'inline') {
        this.setState({
          blocks: tmp,
          search: '',
          addList: 999999
        })
        tmp2[index] = 'E.g. "Deadlift" or "Push up"'
        setTimeout(() => {
          this.elementRefs[index].focus()
          this.setState({
            addList: index,
            currentBlock: index,
            placeholders: tmp2
          })
        }, 310);
      } else {
        this.setState({
          blocks: tmp,
          addList: 999999
        })
      }
    } else {
      this.hideOverlayEx()
      var tmpe = []
      for(var i=0; i<tmp[this.state.currentBlock].exercises.length; i++) {
        if(i !== this.state.replace) {
          tmpe.push(tmp[this.state.currentBlock].exercises[i])
        } else {
          tmpe.push(item)
        }
      }
      tmp[this.state.currentBlock].exercises = tmpe
      this.setState({
        blocks: tmp,
        addList: 999999
      })
    }
  }


  duplicateExercise(item, pos, index) {
    var tmp2 = []
    var unit = 'reps'
    if(item.unit !== '') {
      unit = item.unit
    }
    tmp2.push({
      id: item.id,
      name: item.name,
      tool: item.tool,
      image: item.image,
      cat: item.cat,
      type: item.type,
      weighttype: item.weighttype,
      reps: item.reps,
      weight: item.weight,
      wmin: item.wmin,
      wsec: item.wsec,
      rmin: item.rmin,
      rsec: item.rsec,
      unit: unit,
      notes: item.notes,
    })
    var tmp = this.state.blocks
    const newData = tmp[index].exercises.slice(0);
    newData.splice(pos+1, 0, tmp2[0])
    tmp[index].exercises = newData
    this.setState({
      blocks: tmp
    })
  }



  // Create new exercise ------------------------------------------------------------



  onChangeName(event) {
    this.setState({
      newName: event.target.value,
      newExName: event.target.value,
    })
  }


  setTool(event) {
    this.setState({
      newTool: event.target.value
    })
  }


  onChangeVideo(event) {
    this.setState({
      newVideo: event.target.value
    })
  }


  cancelCreateEx() {
    this.setState({
      overlayMode: 'list',
      newEx: '',
      newExName: '',
      newName: '',
      newTool: 0,
      newVideo: '',
      newUnit: 'reps',
      newError: [false, false, false]
    })
  }


  checkCreateNewExercise() {
    var locked = false
    var tmp = [false, false, false]

    this.setState({
      newError: [false, false, false]
    })
    if(this.state.newEx === '') {
      tmp[0] = true
      locked = true
    }
    if(this.state.newEx === 'new' && this.state.newExName === '') {
      tmp[1] = true
      locked = true
    }
    if(this.state.newName === '') {
      tmp[2] = true
      locked = true
    }
    if(!locked) {
      this.createNewExercise()
    } else {
      this.setState({
        newError: tmp
      })
    }
  }


  createNewExercise() {
    global.badgeLocked = true
    var cat = 0
    var type = 0
    var id = Moment().format('DDMMYYYYHHmmssSSS')
    if(this.state.newEx === 'new') {
      Firebase.database().ref('/exercises/').push({
        name: this.state.newExName,
        desc: '-',
        muscles: [0],
        cat: 0,
        type: 0,
        user: global.uidUser,
        subIds: [id],
        subTitles: [this.state.newName],
        subDescs: [''],
        subTools: [parseInt(this.state.newTool)],
        subImages: [''],
        subVideos: [this.state.newVideo],
        subUnits: [this.state.newUnit],
      }).then((data)=>{
        EventEmitter.dispatch('showMessage', 'Movement successfully created');
        this.hideOverlayEx()
        /*setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'exercise');
        }, 1000);*/
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      var tmp1 = []
      var tmp2 = []
      var tmp3 = []
      var tmp4 = []
      var tmp5 = []
      var tmp6 = []
      var tmp7 = []
      for(var item of global.exercises) {
        if(item.id === this.state.newEx) {
          tmp1 = item.data.subIds
          tmp2 = item.data.subTitles
          tmp3 = item.data.subDescs
          tmp4 = item.data.subTools
          tmp5 = item.data.subImages
          tmp6 = item.data.subVideos
          if(item.data.subUnits !== undefined) {
            tmp7 = item.data.subUnits
          } else {
            for(var su of item.data.subIds) {
              console.log(su)
              tmp7.push('reps')
            }
          }
        }
      }
      tmp1.push(id)
      tmp2.push(this.state.newName)
      tmp3.push('')
      tmp4.push(parseInt(this.state.newTool))
      tmp5.push('')
      tmp6.push(this.state.newVideo)
      tmp7.push(this.state.newUnit)
      Firebase.database().ref('/exercises/'+this.state.newEx).update({
        subIds: tmp1,
        subTitles: tmp2,
        subDescs: tmp3,
        subTools: tmp4,
        subImages: tmp5,
        subVideos: tmp6,
        subUnits: tmp7
      }).then((data)=>{
        EventEmitter.dispatch('showMessage', 'Movement successfully created');
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'exercise');
        }, 1000);
        this.hideOverlayEx()
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    var tmp = this.state.variations
    tmp.push({
      id: id,
      name: this.state.newName,
      tool: parseInt(this.state.newTool),
      image: '',
      cat: cat,
      type: type,
      reps: 0,
      weight: 0,
      weighttype: (global.spaceLbs ? 'lb' : 'kg'),
      wmin: 0,
      wsec: 0,
      rmin: 0,
      rsec: 0,
      unit: this.state.newUnit,
      notes: "",
    })
    this.addExercise(tmp[0], this.state.currentBlock, 'inline')
    this.setState({
      variations: tmp
    })
  }



  // Rearrange overlay ------------------------------------------------------------



  showOverlayOrder(index) {
    this.setState({
      showOverlayOrder: true,
      rearrange: true
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenOrder: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayOrder() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenOrder: 'hidden',
      rearrange: false,
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showOverlayOrder: false,
      });
    }, 500);
  }


  renderBlocksOrder() {
    return (
      <div>
        {this.state.blocks.map((item, index) => (
          <div key={index}>
            <Draggable key={item.id+'-'+index} draggableId={item.id+'-'+index} index={index}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <div className="list bare">
                    <div className="main">
                      <h4>{item.name === '' ? 'Block '+(index+1) : item.name}</h4>
                      <p>{global.proTypes[item.type]} {HelperPro.getBlockType(item)}<br/>{global.exCats[item.cat]}</p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
              )}
            </Draggable>
          </div>
        ))}
      </div>
    )
  }


  renderOverlayOrder() {
    if(this.state.showOverlayOrder) {
      return (
        <div className={'overlay '+this.state.hiddenOrder}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Re-order blocks</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayOrder()}>Close</button>
            <h4 className="mb-20 clear">Drag the blocks to re-order them</h4>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId={'droppable'}>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {this.renderBlocksOrder()}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.hideOverlayOrder()}>Done</button>
          </div>
        </div>
      )
    }
  }



  // Modals ------------------------------------------------------------



  showModalNotes(block, num) {
    this.setState({
      showModalNotes: true,
      exNotes: this.state.blocks[block].exercises[num].notes,
      exNote1: block,
      exNote2: num,
    })
  }


  showModalBlockNotes(block) {
    this.setState({
      showModalBlockNotes: true,
      blockNotes: this.state.blocks[block].notes,
      exNote1: block,
    })
  }


  hideModals() {
    this.setState({
      showModalNotes: false,
      showModalBlockNotes: false,
      showModalSave: false,
    })
  }


  updateNotes() {
    this.hideModals()
    var tmp = this.state.blocks
    tmp[this.state.exNote1].exercises[this.state.exNote2].notes = this.state.exNotes
    this.setState({
      blocks: tmp
    })
  }


  updateBlockNotes() {
    this.hideModals()
    var tmp = this.state.blocks
    tmp[this.state.exNote1].notes = this.state.blockNotes
    this.setState({
      blocks: tmp
    })
  }


  updateNote(index, event) {
    var tmp = this.state.blocks
    tmp[index].notes = event.target.value
    this.setState({
      blocks: tmp
    })
  }


  updateSimple(index, value) {
    var tmp = this.state.blocks
    tmp[index].simple = value
    if(tmp[index].simple) {
      tmp[index].emom = true
    }
    this.setState({
      blocks: tmp
    })
  }


  attemptSave() {
    this.hideModals()
    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });
    this.checkCreateProgram()
  }


  cancelSave() {
    this.hideModals()
    this.props.history.push(this.state.back)
  }


  closeSave() {
    this.hideModals()
    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });
  }



  // Overlay templates ------------------------------------------------------------



  showOverlayTemplates() {
    this.setState({
      showOverlayTemplates: true,
    })
    setTimeout(() => {
      this.setState({
        hiddenTemplates: '',
      });
    }, 100);
  }


  hideOverlayTemplates() {
    this.setState({
      hiddenTemplates: 'hidden',
    })
    setTimeout(() => {
      this.setState({showOverlayTemplates: false});
    }, 500);
  }


  renderOverlayTemplates() {
    if(this.state.showOverlayTemplates) {
      return (
        <div className={'overlay white '+this.state.hiddenTemplates}>
          <div className="pr-download">
            <a className="btn tertiary" href="/pdf/programs.zip" target="_blank" rel="noreferrer noopener">Download printable templates (PDF)</a>
          </div>
          <div className="pr-templates" style={{overflowX: 'auto'}}>
            <h2>Create a new program</h2>
            <h4 className="subtitle"><span>Start blank</span><span>Select a template →</span></h4>
            <div className={'track '+this.state.class}>
              <div className="template pr-empty" onClick={() => this.hideOverlayTemplates()}>
                <div className="image">
                  <img src="/img/template-empty.svg" alt="Start from scratch"/>
                </div>
                <div className="clear"></div>
                <div className="tag">Empty</div>
                <h4>Start from scratch</h4>
                <p>Create a new program</p>
              </div>
              <div className="template blue" onClick={() => this.selectTemplate('functional1')}>
                <div className="image">
                  <img src="/img/template-func.svg" alt="Functional training" className="i1"/>
                  <img src="/img/template-func-w.svg" alt="Functional training" className="i2"/>
                </div>
                <div className="clear"></div>
                <div className="tag">Functional</div>
                <h4>Strength</h4>
                <p>Mobility / Stretch<br/>Activation / Warm Up<br/>Compound Strength<br/>Metabolic Conditioning</p>
              </div>
              <div className="template blue" onClick={() => this.selectTemplate('functional2')}>
                <div className="image">
                  <img src="/img/template-func.svg" alt="Functional training" className="i1"/>
                  <img src="/img/template-func-w.svg" alt="Functional training" className="i2"/>
                </div>
                <div className="clear"></div>
                <div className="tag">Functional</div>
                <h4>Strength Stamina</h4>
                <p>Activation / Warm Up<br/>Strength Stamina<br/>Metabolic Conditioning</p>
              </div>
              <div className="template blue" onClick={() => this.selectTemplate('functional3')}>
                <div className="image">
                  <img src="/img/template-func.svg" alt="Functional training" className="i1"/>
                  <img src="/img/template-func-w.svg" alt="Functional training" className="i2"/>
                </div>
                <div className="clear"></div>
                <div className="tag">Functional</div>
                <h4>Strength & Fitness</h4>
                <p>Activation / Warm Up<br/>Compound Strength<br/>Strength Stamina<br/>Metabolic Conditioning</p>
              </div>
              <div className="template green" onClick={() => this.selectTemplate('hiit1')}>
                <div className="image">
                  <img src="/img/template-hiit.svg" alt="HIIT" className="i1"/>
                  <img src="/img/template-hiit-w.svg" alt="HIIT" className="i2"/>
                </div>
                <div className="tag">HIIT</div>
                <h4>Tabata HIIT</h4>
                <p>Activation / Warm Up<br/>3x Strength Stamina (2x tabata)<br/>Tabata Cool Down</p>
              </div>
              <div className="template green" onClick={() => this.selectTemplate('hiit2')}>
                <div className="image">
                  <img src="/img/template-hiit.svg" alt="HIIT" className="i1"/>
                  <img src="/img/template-hiit-w.svg" alt="HIIT" className="i2"/>
                </div>
                <div className="tag">HIIT</div>
                <h4>Stamina Builder</h4>
                <p>Activation / Warm Up<br/>2x Strength Stamina (2x tabata)<br/>Cool Down</p>
              </div>
              <div className="template green" onClick={() => this.selectTemplate('hiit3')}>
                <div className="image">
                  <img src="/img/template-hiit.svg" alt="HIIT" className="i1"/>
                  <img src="/img/template-hiit-w.svg" alt="HIIT" className="i2"/>
                </div>
                <div className="tag">HIIT</div>
                <h4>Stamina Strength & Endurance</h4>
                <p>Activation / Warm Up (3 rounds)<br/>Strength Stamina (4 rounds)<br/>Strength Stamina (4 rounds)<br/>Cool Down (2 rounds)</p>
              </div>
              <div className="template yellow" onClick={() => this.selectTemplate('weight1')}>
                <div className="image">
                  <img src="/img/template-weight.svg" alt="Weightlifting" className="i1"/>
                  <img src="/img/template-weight-w.svg" alt="Weightlifting" className="i2"/>
                </div>
                <div className="tag">Weightlifting</div>
                <h4>Strength</h4>
                <p>2x Warm Up<br/>Priority Movement<br/>2x Accessory Movement <br/>Strength Compound<br/>Mobility / Stretch</p>
              </div>
              <div className="template yellow" onClick={() => this.selectTemplate('weight2')}>
                <div className="image">
                  <img src="/img/template-weight.svg" alt="Weightlifting" className="i1"/>
                  <img src="/img/template-weight-w.svg" alt="Weightlifting" className="i2"/>
                </div>
                <div className="tag">Weightlifting</div>
                <h4>Strength Endurance</h4>
                <p>Mobility / Stretch<br/>2x Warm Up<br/>Priority Movement<br/>3x Accessory Movement<br/>Strength Compound<br/>Mobility / Stretch</p>
              </div>
              <div className="template yellow" onClick={() => this.selectTemplate('weight3')}>
                <div className="image">
                  <img src="/img/template-weight.svg" alt="Weightlifting" className="i1"/>
                  <img src="/img/template-weight-w.svg" alt="Weightlifting" className="i2"/>
                </div>
                <div className="tag">Weightlifting</div>
                <h4>Strength Stamina</h4>
                <p>Mobility / Stretch<br/>2x Warm Up<br/>Priority Movement<br/>Accessory Movement<br/>Strength Compound<br/>Mobility / Stretch</p>
              </div>
              <div className="template orange" onClick={() => this.selectTemplate('body1')}>
                <div className="image">
                  <img src="/img/template-body.svg" alt="Bodybuilding" className="i1"/>
                  <img src="/img/template-body-w.svg" alt="Bodybuilding" className="i2"/>
                </div>
                <div className="tag">Bodybuilding</div>
                <h4>Strength Training</h4>
                <p>Activation / Warm Up<br/>3x Strength Compound<br/>2x Accessory<br/>Cool Down<br/>Strength / Mobility</p>
              </div>
              <div className="template orange" onClick={() => this.selectTemplate('body2')}>
                <div className="image">
                  <img src="/img/template-body.svg" alt="Bodybuilding" className="i1"/>
                  <img src="/img/template-body-w.svg" alt="Bodybuilding" className="i2"/>
                </div>
                <div className="tag">Bodybuilding</div>
                <h4>Hypertrophy Training</h4>
                <p>Activation / Warm Up<br/>2x Strength Compound<br/>2x Accessory<br/>Cool Down<br/>Strength / Mobility</p>
              </div>
              <div className="template orange" onClick={() => this.selectTemplate('body3')}>
                <div className="image">
                  <img src="/img/template-body.svg" alt="Bodybuilding" className="i1"/>
                  <img src="/img/template-body-w.svg" alt="Bodybuilding" className="i2"/>
                </div>
                <div className="tag">Bodybuilding</div>
                <h4>Strength Stamina</h4>
                <p>Activation / Warm up<br/>Strength Compound<br/>Stamina Strength<br/>Strength Accessory<br/>Mobility / Stretch</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }


  selectTemplate(type) {
    this.hideOverlayTemplates()
    var blocks = HelperPro.createTemplate(type)
    var tmp = []
    for(var item of blocks) {
      tmp.push('Start typing to add a movement')
    }
    this.setState({
      blocks: blocks,
      placeholders: tmp,
      tour: 'hidden',
      info: type,
    })
    setTimeout(() => {
      this.setState({tour: ''});
    }, 700);
  }


  renderTour() {
    return (
      <div className={'tour '+this.state.tour}>
        <h4>About this template</h4>
        <h3>{HelperPro.getTemplateInfo(this.state.info)[0]}</h3>
        <p>{HelperPro.getTemplateInfo(this.state.info)[1]}</p>
        <button className="btn primary" onClick={() => this.hideTour()}>Got it</button>
      </div>
    )
  }


  hideTour() {
    this.setState({
      tour: 'hidden'
    })
    setTimeout(() => {
      this.setState({tour: 'hidden none'});
    }, 700);
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    if(this.state.error || this.state.error2 || this.state.error3 || this.state.error4) {
      var label1 = ''
      var label2 = ''
      var label3 = ''
      var label4 = ''
      if(this.state.error) {
        label1 = <span>Please select at least one movement per block<br/></span>
      }
      if(this.state.error2) {
        label2 = <span>Please set reps for all AMRAP movements<br/></span>
      }
      if(this.state.error3) {
        label3 = <span>Please set reps for all movements in "For Time" blocks where target rounds are more than 1</span>
      }
      if(this.state.error4) {
        label4 = <span>Please select a movement for each placeholder</span>
      }
      return (
        <div className="inline-message red">
          <strong>{label1}{label2}{label3}{label4}</strong>
        </div>
      )
    }
  }


  renderSetsName(index, capitalize) {
    var label = 'sets'
    var label2 = 'Sets'  
    if(this.state.blocks[index].exercises.length > 1) {
      var prev = ''
      for(var ex of this.state.blocks[index].exercises) {
        if(ex.id !== prev && prev !== '') {
          label = 'supersets'
          label2 = 'Supersets'
        }
        if(ex.id === prev && prev !== '') {
          label = 'dropsets'
          label2 = 'Dropsets'
        }
        prev = ex.id
      }
    }
    if(capitalize) {
      label = label2
    }
    return label
  }


  renderBlockType(index) {
    if(this.state.blocks[index].type === 0) {
      return (
        <div className="col-10">
          <div className="col-2">
            <label>Start time</label>
            <select value={this.state.blocks[index].min} onChange={event => this.setTimeMin(event, index)}>
              {this.state.mins.map((item, index) => (
                <option key={index} value={index}>{item}</option>
              ))}
            </select>
          </div>
          <div className="col-2">
            <label>&nbsp;</label>
            <select value={this.state.blocks[index].sec} onChange={event => this.setTimeSec(event, index)}>
              {this.state.secs.map((item, index) => (
                <option key={index} value={index*5}>{item}</option>
              ))}
            </select>
          </div>
          <div className="clear"></div>
        </div>
      )
    } else if(this.state.blocks[index].type === 1) {
      return (
        <div className="col-10">
          <div className="col-2">
            <label>Rounds</label>
            <select value={this.state.blocks[index].rounds} onChange={event => this.setRounds(event, index)}>
              {this.state.rnds.map((item, index) => (
                <option key={index} value={index}>{item}</option>
              ))}
            </select>
          </div>
          <div className={this.state.blocks[index].simple ? 'none' : 'col-4'}>
            <label>Mode</label>
            <select value={this.state.blocks[index].emom} onChange={event => this.setEmom(event, index)}>
              <option value={false}>EMOM per movement</option>
              <option value={true}>EMOM per round</option>
            </select>
          </div>
          <div className="col-4">
            <label>{this.state.blocks[index].emom ? 'Time per round' : 'Time per movement'}</label>
            <div>
              <div className="col-6" style={{paddingLeft: 0}}>
                <select value={this.state.blocks[index].min} onChange={event => this.setTimeMin(event, index)}>
                  {this.state.mins.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-6" style={{paddingRight: 0}}>
                <select value={this.state.blocks[index].sec} onChange={event => this.setTimeSec(event, index)}>
                  {this.state.secs.map((item, index) => (
                    <option key={index} value={index*5}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          
        </div>
      )
    } else if(this.state.blocks[index].type === 2) {
      return (
        <div className="col-10">
          <div className="col-2">
            <label>Rounds</label>
            <select value={this.state.blocks[index].rounds} onChange={event => this.setRounds(event, index)}>
              {this.state.rnds.map((item, index) => (
                <option key={index} value={index}>{item}</option>
              ))}
            </select>
          </div>
          <div className={this.state.blocks[index].simple ? 'none' : 'col-4'}>
            <label>Mode</label>
            <select value={this.state.blocks[index].emom} onChange={event => this.setEmom(event, index)}>
              <option value={false}>Separate interval for each movement</option>
              <option value={true}>One interval per round</option>
            </select>
          </div>
          {this.renderBlockEmom(index)}
          <div className="clear"></div>
        </div>
      )
    } else if(this.state.blocks[index].type === 3) {
      return (
        <div className="col-10">
          <div className="col-2">
            <label>Rounds</label>
            <select value={this.state.blocks[index].rounds} onChange={event => this.setRounds(event, index)}>
              {this.state.rnds.map((item, index) => (
                <option key={index} value={index}>{item}</option>
              ))}
            </select>
          </div>
        </div>
      )
    } else if(this.state.blocks[index].type === 4) {
      return (
        <div className="col-10">
          <div className="col-2">
            <label>{this.renderSetsName(index, true)}</label>
            <select value={this.state.blocks[index].rounds} onChange={event => this.setRounds(event, index)}>
              {this.state.rnds.map((item, index) => (
                <option key={index} value={index}>{item}</option>
              ))}
            </select>
          </div>
          <div className="col-4">
            <label>Rest between {this.renderSetsName(index, false)}</label>
            <div className="col-6" style={{paddingLeft: 0}}>
              <select value={this.state.blocks[index].min} onChange={event => this.setTimeMin(event, index)}>
                {this.state.mins.map((item, index) => (
                  <option key={index} value={index}>{item}</option>
                ))}
              </select>
            </div>
            <div className="col-6" style={{paddingRight: 0}}>
              <select value={this.state.blocks[index].sec} onChange={event => this.setTimeSec(event, index)}>
                {this.state.secs.map((item, index) => (
                  <option key={index} value={index*5}>{item}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )
    } else if(this.state.blocks[index].type === 5) {
      return (
        <div className="col-10">
          <div className="col-4">
            <label>Cut off time</label>
            <div className="col-6" style={{paddingLeft: 0}}>
              <select value={this.state.blocks[index].min} onChange={event => this.setTimeMin(event, index)}>
                {this.state.mins.map((item, index) => (
                  <option key={index} value={index}>{item}</option>
                ))}
              </select>
            </div>
            <div className="col-6" style={{paddingRight: 0}}>
              <select value={this.state.blocks[index].sec} onChange={event => this.setTimeSec(event, index)}>
                {this.state.secs.map((item, index) => (
                  <option key={index} value={index*5}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <label>Target rounds</label>
            <select value={this.state.blocks[index].rounds} onChange={event => this.setRounds(event, index)}>
              {this.state.rnds.map((item, index) => (
                <option key={index} value={index}>{item}</option>
              ))}
            </select>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBlockEmom(index) {
    if(this.state.blocks[index].type === 2 && this.state.blocks[index].emom) {
      return (
        <div className="col-6">
          <div className="col-6">
            <label>Work per round</label>
            <div>
              <div className="col-6" style={{paddingLeft: 0}}>
                <select value={this.state.blocks[index].min} onChange={event => this.setTimeMin(event, index, 'work')}>
                  {this.state.mins.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-6" style={{paddingRight: 0}}>
                <select value={this.state.blocks[index].sec} onChange={event => this.setTimeSec(event, index, 'work')}>
                  {this.state.secs.map((item, index) => (
                    <option key={index} value={index*5}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="col-6">
            <label>Rest per round</label>
            <div>
              <div className="col-6" style={{paddingLeft: 0}}>
                <select value={this.state.blocks[index].min2} onChange={event => this.setTimeMin(event, index, 'rest')}>
                  {this.state.mins.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-6" style={{paddingRight: 0}}>
                <select value={this.state.blocks[index].sec2} onChange={event => this.setTimeSec(event, index, 'rest')}>
                  {this.state.secs.map((item, index) => (
                    <option key={index} value={index*5}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderReps(item) {
    var label = 'Reps'
    if(item.unit === 'dist') {
      label = 'Distance (m)'
    }
    if(item.unit === 'cals') {
      label = 'Calories (cal)'
    }
    if(item.unit === 'time') {
      label = 'Time (sec)'
    }
    return label
  }


  renderWeight(item, num, index) {
    if(global.exToolsWeight.indexOf(item.tool) !== -1) {
      return (
        <div className="col-3 program-results">
          <label>Weight <span className={item.weighttype === 'kg' ? 'wmode on' : 'wmode'} onClick={() => this.toggleWmode(num, index, 'kg')}>kg</span> <span className={item.weighttype === 'lb' ? 'wmode on' : 'wmode'} onClick={() => this.toggleWmode(num, index, 'lb')}>lb</span> <span className={item.weighttype === 'per' ? 'wmode on' : 'wmode'} onClick={() => this.toggleWmode(num, index, 'per')}>%</span></label>
          <input type="text" className="light" value={item.weight === 0 ? '' : item.weight} onChange={(val) => this.onChangeWeight(val, num, index)} placeholder={this.state.blocks[num].showInfo ? 'E.g. "20" or "50-60-70"' : 'E.g. "50"'}/>
        </div>
      )
    } else {
      return (
        <div className="col-3">&nbsp;</div>
      )
    }
  }


  renderInterval(num, index, item) {
    if(this.state.blocks[num].type === 2 && !this.state.blocks[num].emom) {
      return (
        <div className="col-6">
          <div className="col-6 program-results">
            <label>Work time</label>
            <select className="light col-5" value={item.wmin} onChange={event => this.setTimeMinEx(event, num, index, 'work')}>
              {this.state.mins.map((item, pos) => (
                <option key={pos} value={pos}>{item}</option>
              ))}
            </select>
            <select className="light col-5" value={item.wsec} onChange={event => this.setTimeSecEx(event, num, index, 'work')}>
              {this.state.secs.map((item, pos) => (
                <option key={pos} value={pos*5}>{item}</option>
              ))}
            </select>
          </div>
          <div className="col-6 program-results">
            <label>Rest time</label>
            <select className="light col-5" value={item.rmin} onChange={event => this.setTimeMinEx(event, num, index, 'rest')}>
              {this.state.mins.map((item, pos) => (
                <option key={pos} value={pos}>{item}</option>
              ))}
            </select>
            <select className="light col-5" value={item.rsec} onChange={event => this.setTimeSecEx(event, num, index, 'rest')}>
              {this.state.secs.map((item, pos) => (
                <option key={pos} value={pos*5}>{item}</option>
              ))}
            </select>
          </div>
        </div>
      )
    }
  }


  renderExInner(num, item, index) {
    if(item.id === 'placeholder') {
      return (
        <div>
          <p className="lft mb-10"><strong>Placeholder</strong><br/><span>{item.notes}</span></p>
          <div className="prog-actions">
            <div className="rgt ml-10" title="Delete" onClick={() => this.deleteExercise(num, index)}>{HelperEmpty.renderImage('action-delete')}</div>
            <div className="rgt ml-10" title="Duplicate" onClick={() => this.duplicateExercise(item, index, num)}>{HelperEmpty.renderImage('action-duplicate')}</div>
          </div>
          <div className="clear"></div>
          <button className="btn tertiary grey small" onClick={() => this.showOverlayEx(num, index)}>Select a movement</button>
        </div>
      )
    } else {
      return (
        <div>
          <p className="lft mb-10"><strong>{item.name}</strong></p>
          <div className="prog-actions">
            <div className="rgt ml-10" title="Delete" onClick={() => this.deleteExercise(num, index)}>{HelperEmpty.renderImage('action-delete')}</div>
            <div className="rgt ml-10" title="Duplicate" onClick={() => this.duplicateExercise(item, index, num)}>{HelperEmpty.renderImage('action-duplicate')}</div>
            <div className="rgt ml-10" title="Swap" onClick={() => this.showOverlayEx(num, index)}>{HelperEmpty.renderImage('action-swap')}</div>
            <div className="rgt ml-10" title="Notes" onClick={() => this.showModalNotes(num, index)}>{(item.notes === '' ? HelperEmpty.renderImage('action-notes') : HelperEmpty.renderImage('action-notes2'))}</div>
          </div>
          <div className="col-3 program-results clear">
            <label>{this.renderReps(item)}</label>
            <input type="text" className="light" value={item.reps === 0 ? '' : item.reps} onChange={(val) => this.onChangeReps(val, num, index)} placeholder={this.state.blocks[num].showInfo ? 'E.g. "10" or "21-15-9"' : 'E.g. "10"'}/>
          </div>
          {this.renderWeight(item, num, index)}
          {this.renderInterval(num, index, item)}
          <div className="clear"></div>
          <p className="ex-note none interactive" onClick={() => this.showModalNotes(num, index)}>Notes: {item.notes === '' ? '-' : item.notes}</p>
        </div>
      )
    }
  }


  renderExercises(num) {
    return (
      <div style={{padding: '0 7px'}}>
        {this.state.blocks[num].exercises.map((item, index) => (
          <Draggable key={item.id+'-'+index} draggableId={item.id+'-'+index} index={index}>
            {(provided, snapshot) => (
              <div className={item.id === 'placeholder' ? 'prog-placeholder mb-10' : 'box prog-movement list simple mb-10'} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                {this.renderExInner(num, item, index)}
                <div className="clear"></div>
              </div>
            )}
          </Draggable>
        ))}
      </div>
    )
  }


  renderExSearch(num) {
    if(this.state.addList === num) {
      var list1 = this.state.variations
      list1.sort((a,b) => a.name.localeCompare(b.name))
      if(list1.length === 0 && this.state.search !== '') {
        return (
          <div className="add-movement" onClick={() => this.setShowList(num)}>
            <p className="empty">No movements found</p>
            {this.renderAddEx(num)}
          </div>
        )
      } else {
        return (
          <div className="add-movement">
            {list1.map(item => (
              <div className="item" key={item.id} onClick={() => this.addExercise(item, num, 'inline')}>{item.name}</div>
            ))}
          </div>
        )
      }
    }
  }


  renderBlockInfo(index) {
    if(this.state.blocks[index].showInfo) {
      return (
        <div style={{fontSize:12, margin: '0 20px 10px 20px', opacity: '0.4'}}>Note: You can set increasing or decreasing sets for reps and weights by separating the values in each movement with a dash, e.g. 21-15-9</div>
      )
    }
  }


  renderInstructions(item) {
    if(item.instructions !== undefined) {
      return (
        <div className="prog-instructions mb-20"><strong>Suggestion:</strong> <pre>{item.instructions}</pre></div>
      )
    }
  }


  renderPreset(index) {
    if(this.state.blocks[index].type === 0) { // AMRAP
      return (
        <div className="light col-2 pt-20 rgt">
          <select className="light" value={this.state.blocks[index].preset} onChange={event => this.setPreset(event, index)}>
            <option value={''}>No preset</option>
            <option value={'amrap1'}>Ascending ladder: 1-2-3-4-5 ... reps (12 min)</option>
            <option value={'amrap2'}>Ascending ladder: 2-4-6-8-10 ... reps (15 min)</option>
            <option value={'amrap3'}>Ascending ladder: 3-6-9-12-15 ... reps (20 min)</option>
            <option value={'amrap4'}>Descending ladder: 10-9-8-7-6 ... reps (12 min)</option>
            <option value={'amrap5'}>Descending ladder: 20-18-16-14-12 ... reps (15 min)</option>
            <option value={'amrap6'}>Descending ladder: 30-27-24-21-18 ... reps (20 min)</option>
          </select>
        </div>
      )
    } else if(this.state.blocks[index].type === 2) { // Intervals
      return (
        <div className="light col-2 pt-20 rgt">
          <select className="light" value={this.state.blocks[index].preset} onChange={event => this.setPreset(event, index)}>
            <option value={''}>No preset</option>
            <option value={'intervals1'}>45 sec work / 30 sec rest</option>
            <option value={'intervals2'}>30 sec work / 30 sec rest</option>
            <option value={'intervals3'}>60 sec work / 30 sec rest</option>
            <option value={'intervals4'}>75 sec work / 30 sec rest</option>
            <option value={'intervals5'}>90 sec work / 30 sec rest</option>
            <option value={'intervals6'}>2 min work / 30 sec rest</option>
            <option value={'intervals7'}>3 min work / 1 min rest</option>
            <option value={'intervals8'}>5 min work / 1 min rest</option>
          </select>
        </div>
      )
    } else if(this.state.blocks[index].type === 4) { // No time
      return (
        <div className="light col-2 pt-20 rgt">
          <select className="light" value={this.state.blocks[index].preset} onChange={event => this.setPreset(event, index)}>
            <option value={''}>No preset</option>
            <option value={'notime1'}>1 x 1 rep</option>
            <option value={'notime2'}>1 x 2 reps</option>
            <option value={'notime3'}>1 x 3 reps</option>
            <option value={'notime4'}>1 x 4 reps</option>
            <option value={'notime5'}>1 x 5 reps</option>
            <option value={'notime6'}>2 x 1 rep</option>
            <option value={'notime7'}>2 x 2 reps</option>
            <option value={'notime8'}>2 x 3 reps</option>
            <option value={'notime9'}>2 x 4 reps</option>
            <option value={'notime10'}>2 x 5 reps</option>
            <option value={'notime11'}>2 x 6 reps</option>
            <option value={'notime12'}>3 x 1 rep</option>
            <option value={'notime13'}>3 x 3 reps</option>
            <option value={'notime14'}>3 x 4 reps</option>
            <option value={'notime15'}>3 x 5 reps</option>
            <option value={'notime16'}>3 x 6 reps</option>
            <option value={'notime17'}>3 x 8 reps</option>
            <option value={'notime18'}>3 x 10 reps</option>
            <option value={'notime19'}>3 x 12 reps</option>
            <option value={'notime20'}>4 x 3 reps</option>
            <option value={'notime21'}>4 x 4 reps</option>
            <option value={'notime22'}>4 x 5 reps</option>
            <option value={'notime23'}>4 x 6 reps</option>
            <option value={'notime24'}>4 x 8 reps</option>
            <option value={'notime25'}>4 x 9 reps</option>
            <option value={'notime26'}>4 x 10 reps</option>
            <option value={'notime27'}>4 x 12 reps</option>
            <option value={'notime28'}>5 x 3 reps</option>
            <option value={'notime29'}>5 x 4 reps</option>
            <option value={'notime30'}>5 x 5 reps</option>
            <option value={'notime31'}>5 x 6 reps</option>
            <option value={'notime32'}>5 x 7 reps</option>
            <option value={'notime33'}>5 x 8 reps</option>
            <option value={'notime34'}>5 x 10 reps</option>
            <option value={'notime35'}>5 x 12 reps</option>
            <option value={'notime36'}>6 x 4 reps</option>
            <option value={'notime37'}>6 x 6 reps</option>
            <option value={'notime38'}>7 x 5 reps</option>
            <option value={'notime39'}>8 x 4 reps</option>
            <option value={'notime40'}>10 x 3 reps</option>
          </select>
        </div>
      )
    } else if(this.state.blocks[index].type === 5) { // For time
      return (
        <div className="light col-2 pt-20 rgt">
          <select className="light" value={this.state.blocks[index].preset} onChange={event => this.setPreset(event, index)}>
            <option value={''}>No preset</option>
            <option value={'fortime1'}>21-18-15-12-9-6-3 reps (15 min)</option>
            <option value={'fortime2'}>21-15-9 reps (10 min)</option>
            <option value={'fortime3'}>Ascending ladder: 1-2-3-4-5 ... 10 reps (12 min)</option>
            <option value={'fortime4'}>Ascending ladder: 2-4-6-8-10 ...20  reps (15 min)</option>
            <option value={'fortime5'}>Ascending ladder: 3-6-9-12-15 ... 30 reps (20 min)</option>
            <option value={'fortime6'}>Ascending ladder: 5-10-15 reps (6 min)</option>
            <option value={'fortime7'}>Ascending ladder: 5-10-15-20 reps (8 min)</option>
            <option value={'fortime8'}>Ascending ladder: 5-10-15-20-25 reps (11 min)</option>
            <option value={'fortime9'}>Ascending ladder: 5-10-15-20-25-30 reps (16 min)</option>
            <option value={'fortime10'}>Descending ladder: 10-9-8-7-6 ... 1 rep (12 min)</option>
            <option value={'fortime11'}>Descending ladder: 20-18-16-14-12 ... 1 rep (15 min)</option>
            <option value={'fortime12'}>Descending ladder: 30-27-24-21-18 ... 1 rep (20 min)</option>
            <option value={'fortime13'}>Descending ladder: 15-10-5 reps (6 min)</option>
            <option value={'fortime14'}>Descending ladder: 20-15-10-5 reps (8 min)</option>
            <option value={'fortime15'}>Descending ladder: 25-20-15-10-5 reps (11 min)</option>
            <option value={'fortime16'}>Descending ladder: 30-25-20-15-10-5 reps (16 min)</option>
          </select>
        </div>
      )
    }
  }


  renderBlockActions(index) {
    if(this.state.blocks.length > 1) {
      return (
        <div className="col-5 pt-20">
          <button className="btn tertiary small close rgt" onClick={() => this.deleteBlock(index)}>Delete</button>
          <span className={this.state.blocks[index].simple ? ' none' : ''}>
            <button className="btn tertiary rgt edit small mr-10" onClick={() => this.showModalBlockNotes(index)}>Notes</button>
          </span>
        </div>
      )
    } else {
      return (
        <div className={'col-5 pt-20'+(this.state.blocks[index].simple ? ' none' : '')}>
          <button className="btn tertiary rgt edit small" onClick={() => this.showModalBlockNotes(index)}>Notes</button>
        </div>
      )
    }
  }


  renderCustomName(index) {
    if(this.state.blocks[index].cat === 99) {
      return (
        <input type="text" className="mb-20" placeholder={'Block '+(index+1)}  value={this.state.blocks[index].name} onChange={(val) => this.onChangeBlockName(val, index)} title='Add a description to this block'/>
      )
    }
  }


  renderBlockMain(item, index) {
    if(this.state.blocks[index].simple) {
      return (
        <div>
          <div className="col-12">
            <textarea value={this.state.blocks[index].notes} onChange={(event) => this.updateNote(index, event)} placeholder="Write your workout/movements and block notes here" className="borderonly big"></textarea>
            <p className="small mt-10">Note: Individual weights & results won't be tracked in the simplified editor</p>
          </div>
        <div className="clear"></div>
        </div>
      )
    } else {
      return (
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={'droppable'+index}>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.renderExercises(index)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {this.renderBlockInfo(index)}
          <div className="col-12 mb-10">
            <input type="text" className="add" placeholder={this.state.placeholders[index]} ref={el => this.elementRefs[index] = el}  onFocus={() => this.setShowList(index)} onBlur={() => this.setShowList(99999)} value={this.state.addList === index ? this.state.search : ''} onChange={(val) => this.filterExercises(val, 'field')}/>
            {this.renderExSearch(index)}
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBlocks() {
    return (
      <div>
        {this.state.blocks.map((item, index) => (
          <div key={index} className="mb-50">
            {this.renderInstructions(item)}
            <div className="col-7">
              <select className="x-large mb-10" value={this.state.blocks[index].cat} onChange={event => this.setCat(event, index)}>
                {global.exCats.map((item, index) => (
                  <option key={index} value={index}>{item}</option>
                ))}
                <option value='99'>Custom name</option>
              </select>
            </div>
            
            {this.renderBlockActions(index)}
            <div className="clear"></div> 
            {this.renderCustomName(index)}
            
            <div className="col-9" style={{marginTop: 5}}>
              <div className={this.state.blocks[index].type === 4 ? 'tab active' : 'tab'} onClick={() => this.setType(4, index)}>
                <p>No time</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.blocks[index].type === 2 ? 'tab active' : 'tab'} onClick={() => this.setType(2, index)}>
                <p>Intervals</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.blocks[index].type === 3 ? 'tab active' : 'tab'} onClick={() => this.setType(3, index)}>
                <p>Tabata</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.blocks[index].type === 0 ? 'tab active' : 'tab'} onClick={() => this.setType(0, index)}>
                <p>AMRAP</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.blocks[index].type === 1 ? 'tab active' : 'tab'} onClick={() => this.setType(1, index)}>
                <p>EMOM</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.blocks[index].type === 5 ? 'tab active' : 'tab'} onClick={() => this.setType(5, index)}>
                <p>For time</p>
                <div className="bg"></div>
              </div>
            </div>
            <div className="col-3 rgt">
              <InputToggle label='' align='right' value={item.logResults} onChange={() => this.toggleResults(index, !item.logResults)} text="Log results"/>
            </div>
            <div className="sv-20"></div>
            {this.renderBlockType(index)}
            {this.renderPreset(index)}
            <div className="clear mb-20"></div>
            <div className="radios simple col-12">
              <button className={this.state.blocks[index].simple ? 'radio col-6' : 'radio col-6 active'} onClick={() => this.updateSimple(index, false)}>Default editor</button>
              <button className={!this.state.blocks[index].simple ? 'radio col-6' : 'radio col-6 active'} onClick={() => this.updateSimple(index, true)}>Simplified editor</button>
            </div>
            <div className="sv-20 clear"></div>
            {this.renderBlockMain(item,index)}
          </div>
        ))}
      </div>
    )
  }


  renderBenchmark() {
    if(this.state.benchmark) {
      return (
        <div className="infobox green large mb-30">
          <p>Use this program to monitor your clients' progress by attaching it to multiple sessions over time. You'll be able to see their progress and compare results in their details.</p>
        </div>
      )
    }
  }


  renderButtonRearrange() {
    if(this.state.blocks.length > 1) {
      return (
        <div>
          <div className="sv-30"></div>
          <button className="btn tertiary width-12" onClick={() => this.showOverlayOrder()}>Re-order blocks</button>
        </div>
      )
    }
  }


  renderMainButton() {
    if(this.state.editing) {
      return (
        <div>
          <button className="btn primary mb-20" onClick={() => this.checkUpdateProgram()}>Update program</button>
          <button className="btn tertiary close width-12" onClick={() => this.props.history.push(this.state.back)}>Cancel</button>
        </div>
      )
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateProgram()}>Create program</button>
    }
  }


  renderHeader() {
    var edit = 'New '
    if(this.state.editing) {
      edit = 'Edit '
    }
    var path = this.props.location.pathname
    if(path.indexOf('/programming/') !== -1 || path.indexOf('/programming/plans/') !== -1) {
      if(this.state.benchmark) {
        return (
          <Header title={edit+'Benchmark Program'} link={this.state.back}/>
        )
      } else {
        return (
          <Header title={edit+'Program'} link={this.state.back}/>
        )
      }
      
    } else {
      return (
        <Header title={global.linkCal === '' ? edit+'Training Plan Program' : edit+'Session Program'} link={this.state.back}/>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar mt-20">
            <div className="col-12">
              {this.renderBenchmark()}
              <div className="form-item">
                <label>Name</label>
                <input type="text" value={this.state.values[0]} name="0" onChange={this.onChange}/>
              </div>
              <div className="form-item">
                <label>Description</label>
                <input type="text" value={this.state.values[1]} name="1" onChange={this.onChange}/>
              </div>
              <div className="form-item">
                <label>Video (Youtube or Vimeo)</label>
                <input type="text" value={this.state.values[2]} name="2" onChange={this.onChange}/>
              </div>
              <div className="sv-50"></div>

              {this.renderBlocks()}
              <button className="btn tertiary add width-12" onClick={() => this.addBlock()}>Add block</button>
              {this.renderButtonRearrange()}
              <div className="sv-60"></div>
              {this.renderError()}
              <div className="width-6">
                {this.renderMainButton()}
              </div>
              <div className="sv-40"></div>
            </div>
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '320655')}>Show me around</div>
          {this.renderHeader()}
          <Navigation active='programming' />
          {this.renderTour()}
          {this.renderOverlayEx()}
          {this.renderOverlayOrder()}
          <ModalForm type={'exnotes'} show={this.state.showModalNotes} onHide={() => this.hideModals()} value1={this.state.exNotes} updateValue1={(event) => this.setState({exNotes: event})} clickMainButton={() => this.updateNotes()}/>
          <ModalForm type={'blocknotes'} show={this.state.showModalBlockNotes} onHide={() => this.hideModals()} value1={this.state.blockNotes} updateValue1={(event) => this.setState({blockNotes: event})} clickMainButton={() => this.updateBlockNotes()}/>
          <ModalMessageDouble type='saveform' show={this.state.showModalSave} onHide={() => this.closeSave()} clickMainButton={() => this.attemptSave()} clickSecondaryButton={() => this.cancelSave()}/>
          {this.renderOverlayTemplates()}
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='programming-locked' type='simple'/>
          </div>
          <Header title='New Program' link={this.state.back}/>
          <Navigation active='programming' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(NewProgramPage));