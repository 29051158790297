//import Var from '../helper/Calendar';
import React from 'react';



class HelperEmpty {


  renderImage(type) {
    var colors = ['#1DC5C9', '#0C82AC', '#218AFF', '#81DB24', '#378D19', '#18D971', '#FAB54A', '#FB691F', '#DE1053', '#8A15E9']
    var themes = ['blue', 'darkblue', 'vividblue', 'green', 'darkgreen', 'vividgreen', 'yellow', 'orange', 'red', 'purple']
    var color = colors[0]
    for(var i=0; i<themes.length; i++) {
      if(global.spaceTheme === themes[i]) {
        color = colors[i]
      }
    }
    if(type === 'calendar') {
      return this.renderCal(color)
    } else if(type === 'inbox') {
      return this.renderInbox(color)
    } else if(type === 'party') {
      return this.renderParty(color)
    } else if(type === 'notes') {
      return this.renderNotes(color)
    } else if(type === 'forms') {
      return this.renderForms(color)
    } else if(type === 'card') {
      return this.renderCard(color)
    } else if(type === 'comments') {
      return this.renderComments(color)
    } else if(type === 'graph') {
      return this.renderGraph(color)
    } else if(type === 'health') {
      return this.renderHealth(color)
    } else if(type === 'documents') {
      return this.renderDocuments(color)
    } else if(type === 'locations') {
      return this.renderLocations(color)
    } else if(type === 'action-delete') {
      return this.renderActionDelete(color)
    } else if(type === 'action-duplicate') {
      return this.renderActionDuplicate(color)
    } else if(type === 'action-swap') {
      return this.renderActionSwap(color)
    } else if(type === 'action-notes') {
      return this.renderActionNotes(color)
    } else if(type === 'action-notes2') {
      return this.renderActionNotes2(color)
    }
  }


  // Calendar

  renderCal(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No sessions</title>
          <g id="empty-calendar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M121,38 C126.522847,38 131,42.4771525 131,48 L131,116 C131,121.522847 126.522847,126 121,126 L38,126 C32.4771525,126 28,121.522847 28,116 L28,48 C28,42.4771525 32.4771525,38 38,38 L121,38 Z M121,40 L38,40 C33.6650857,40 30.1354571,43.4478378 30.0038068,47.7508207 L30,48 L30,116 C30,120.334914 33.4478378,123.864543 37.7508207,123.996193 L38,124 L121,124 C125.334914,124 128.864543,120.552162 128.996193,116.249179 L129,116 L129,48 C129,43.6650857 125.552162,40.1354571 121.249179,40.0038068 L121,40 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M49.5,30.5 C50.0128358,30.5 50.4355072,30.8860402 50.4932723,31.3833789 L50.5,31.5 L50.5,47.5 C50.5,48.0522847 50.0522847,48.5 49.5,48.5 C48.9871642,48.5 48.5644928,48.1139598 48.5067277,47.6166211 L48.5,47.5 L48.5,31.5 C48.5,30.9477153 48.9477153,30.5 49.5,30.5 Z" id="Line-6" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M109.5,30.5 C110.012836,30.5 110.435507,30.8860402 110.493272,31.3833789 L110.5,31.5 L110.5,47.5 C110.5,48.0522847 110.052285,48.5 109.5,48.5 C108.987164,48.5 108.564493,48.1139598 108.506728,47.6166211 L108.5,47.5 L108.5,31.5 C108.5,30.9477153 108.947715,30.5 109.5,30.5 Z" id="Line-6" fill="#A2AEB0" fillRule="nonzero"></path>
            <polygon id="Line-10" fill="#A2AEB0" fillRule="nonzero" points="130.5 60.5 130.5 62.5 28.5 62.5 28.5 60.5"></polygon>
            <path d="M44,71 C45.1045695,71 46,71.8954305 46,73 L46,79 C46,80.1045695 45.1045695,81 44,81 L38,81 C36.8954305,81 36,80.1045695 36,79 L36,73 C36,71.8954305 36.8954305,71 38,71 L44,71 Z M44,73 L38,73 L38,79 L44,79 L44,73 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M57,71 C58.1045695,71 59,71.8954305 59,73 L59,79 C59,80.1045695 58.1045695,81 57,81 L51,81 C49.8954305,81 49,80.1045695 49,79 L49,73 C49,71.8954305 49.8954305,71 51,71 L57,71 Z M57,73 L51,73 L51,79 L57,79 L57,73 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M70,71 C71.1045695,71 72,71.8954305 72,73 L72,79 C72,80.1045695 71.1045695,81 70,81 L64,81 C62.8954305,81 62,80.1045695 62,79 L62,73 C62,71.8954305 62.8954305,71 64,71 L70,71 Z M70,73 L64,73 L64,79 L70,79 L70,73 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M83,71 C84.1045695,71 85,71.8954305 85,73 L85,79 C85,80.1045695 84.1045695,81 83,81 L77,81 C75.8954305,81 75,80.1045695 75,79 L75,73 C75,71.8954305 75.8954305,71 77,71 L83,71 Z M83,73 L77,73 L77,79 L83,79 L83,73 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M96,71 C97.1045695,71 98,71.8954305 98,73 L98,79 C98,80.1045695 97.1045695,81 96,81 L90,81 C88.8954305,81 88,80.1045695 88,79 L88,73 C88,71.8954305 88.8954305,71 90,71 L96,71 Z M96,73 L90,73 L90,79 L96,79 L96,73 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M109,71 C110.104569,71 111,71.8954305 111,73 L111,79 C111,80.1045695 110.104569,81 109,81 L103,81 C101.895431,81 101,80.1045695 101,79 L101,73 C101,71.8954305 101.895431,71 103,71 L109,71 Z M109,73 L103,73 L103,79 L109,79 L109,73 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M122,71 C123.104569,71 124,71.8954305 124,73 L124,79 C124,80.1045695 123.104569,81 122,81 L116,81 C114.895431,81 114,80.1045695 114,79 L114,73 C114,71.8954305 114.895431,71 116,71 L122,71 Z M122,73 L116,73 L116,79 L122,79 L122,73 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M44,86 C45.1045695,86 46,86.8954305 46,88 L46,94 C46,95.1045695 45.1045695,96 44,96 L38,96 C36.8954305,96 36,95.1045695 36,94 L36,88 C36,86.8954305 36.8954305,86 38,86 L44,86 Z M44,88 L38,88 L38,94 L44,94 L44,88 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M57,86 C58.1045695,86 59,86.8954305 59,88 L59,94 C59,95.1045695 58.1045695,96 57,96 L51,96 C49.8954305,96 49,95.1045695 49,94 L49,88 C49,86.8954305 49.8954305,86 51,86 L57,86 Z M57,88 L51,88 L51,94 L57,94 L57,88 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M70,86 C71.1045695,86 72,86.8954305 72,88 L72,94 C72,95.1045695 71.1045695,96 70,96 L64,96 C62.8954305,96 62,95.1045695 62,94 L62,88 C62,86.8954305 62.8954305,86 64,86 L70,86 Z M70,88 L64,88 L64,94 L70,94 L70,88 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M83,86 C84.1045695,86 85,86.8954305 85,88 L85,94 C85,95.1045695 84.1045695,96 83,96 L77,96 C75.8954305,96 75,95.1045695 75,94 L75,88 C75,86.8954305 75.8954305,86 77,86 L83,86 Z M83,88 L77,88 L77,94 L83,94 L83,88 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <rect id="Rectangle" fill={color} x="88" y="86" width="10" height="10" rx="2"></rect>
            <path d="M109,86 C110.104569,86 111,86.8954305 111,88 L111,94 C111,95.1045695 110.104569,96 109,96 L103,96 C101.895431,96 101,95.1045695 101,94 L101,88 C101,86.8954305 101.895431,86 103,86 L109,86 Z M109,88 L103,88 L103,94 L109,94 L109,88 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M122,86 C123.104569,86 124,86.8954305 124,88 L124,94 C124,95.1045695 123.104569,96 122,96 L116,96 C114.895431,96 114,95.1045695 114,94 L114,88 C114,86.8954305 114.895431,86 116,86 L122,86 Z M122,88 L116,88 L116,94 L122,94 L122,88 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M44,101 C45.1045695,101 46,101.895431 46,103 L46,109 C46,110.104569 45.1045695,111 44,111 L38,111 C36.8954305,111 36,110.104569 36,109 L36,103 C36,101.895431 36.8954305,101 38,101 L44,101 Z M44,103 L38,103 L38,109 L44,109 L44,103 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M57,101 C58.1045695,101 59,101.895431 59,103 L59,109 C59,110.104569 58.1045695,111 57,111 L51,111 C49.8954305,111 49,110.104569 49,109 L49,103 C49,101.895431 49.8954305,101 51,101 L57,101 Z M57,103 L51,103 L51,109 L57,109 L57,103 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M70,101 C71.1045695,101 72,101.895431 72,103 L72,109 C72,110.104569 71.1045695,111 70,111 L64,111 C62.8954305,111 62,110.104569 62,109 L62,103 C62,101.895431 62.8954305,101 64,101 L70,101 Z M70,103 L64,103 L64,109 L70,109 L70,103 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M83,101 C84.1045695,101 85,101.895431 85,103 L85,109 C85,110.104569 84.1045695,111 83,111 L77,111 C75.8954305,111 75,110.104569 75,109 L75,103 C75,101.895431 75.8954305,101 77,101 L83,101 Z M83,103 L77,103 L77,109 L83,109 L83,103 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M96,101 C97.1045695,101 98,101.895431 98,103 L98,109 C98,110.104569 97.1045695,111 96,111 L90,111 C88.8954305,111 88,110.104569 88,109 L88,103 C88,101.895431 88.8954305,101 90,101 L96,101 Z M96,103 L90,103 L90,109 L96,109 L96,103 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M109,101 C110.104569,101 111,101.895431 111,103 L111,109 C111,110.104569 110.104569,111 109,111 L103,111 C101.895431,111 101,110.104569 101,109 L101,103 C101,101.895431 101.895431,101 103,101 L109,101 Z M109,103 L103,103 L103,109 L109,109 L109,103 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M122,101 C123.104569,101 124,101.895431 124,103 L124,109 C124,110.104569 123.104569,111 122,111 L116,111 C114.895431,111 114,110.104569 114,109 L114,103 C114,101.895431 114.895431,101 116,101 L122,101 Z M122,103 L116,103 L116,109 L122,109 L122,103 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No sessions</title>
          <g id="empty-calendar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M121,38 C126.522847,38 131,42.4771525 131,48 L131,116 C131,121.522847 126.522847,126 121,126 L38,126 C32.4771525,126 28,121.522847 28,116 L28,48 C28,42.4771525 32.4771525,38 38,38 L121,38 Z M121,40 L38,40 C33.6650857,40 30.1354571,43.4478378 30.0038068,47.7508207 L30,48 L30,116 C30,120.334914 33.4478378,123.864543 37.7508207,123.996193 L38,124 L121,124 C125.334914,124 128.864543,120.552162 128.996193,116.249179 L129,116 L129,48 C129,43.6650857 125.552162,40.1354571 121.249179,40.0038068 L121,40 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M49.5,30.5 C50.0128358,30.5 50.4355072,30.8860402 50.4932723,31.3833789 L50.5,31.5 L50.5,47.5 C50.5,48.0522847 50.0522847,48.5 49.5,48.5 C48.9871642,48.5 48.5644928,48.1139598 48.5067277,47.6166211 L48.5,47.5 L48.5,31.5 C48.5,30.9477153 48.9477153,30.5 49.5,30.5 Z" id="Line-6" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M109.5,30.5 C110.012836,30.5 110.435507,30.8860402 110.493272,31.3833789 L110.5,31.5 L110.5,47.5 C110.5,48.0522847 110.052285,48.5 109.5,48.5 C108.987164,48.5 108.564493,48.1139598 108.506728,47.6166211 L108.5,47.5 L108.5,31.5 C108.5,30.9477153 108.947715,30.5 109.5,30.5 Z" id="Line-6" fill="#6B6F70" fillRule="nonzero"></path>
            <polygon id="Line-10" fill="#6B6F70" fillRule="nonzero" points="130.5 60.5 130.5 62.5 28.5 62.5 28.5 60.5"></polygon>
            <path d="M44,71 C45.1045695,71 46,71.8954305 46,73 L46,79 C46,80.1045695 45.1045695,81 44,81 L38,81 C36.8954305,81 36,80.1045695 36,79 L36,73 C36,71.8954305 36.8954305,71 38,71 L44,71 Z M44,73 L38,73 L38,79 L44,79 L44,73 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M57,71 C58.1045695,71 59,71.8954305 59,73 L59,79 C59,80.1045695 58.1045695,81 57,81 L51,81 C49.8954305,81 49,80.1045695 49,79 L49,73 C49,71.8954305 49.8954305,71 51,71 L57,71 Z M57,73 L51,73 L51,79 L57,79 L57,73 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M70,71 C71.1045695,71 72,71.8954305 72,73 L72,79 C72,80.1045695 71.1045695,81 70,81 L64,81 C62.8954305,81 62,80.1045695 62,79 L62,73 C62,71.8954305 62.8954305,71 64,71 L70,71 Z M70,73 L64,73 L64,79 L70,79 L70,73 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M83,71 C84.1045695,71 85,71.8954305 85,73 L85,79 C85,80.1045695 84.1045695,81 83,81 L77,81 C75.8954305,81 75,80.1045695 75,79 L75,73 C75,71.8954305 75.8954305,71 77,71 L83,71 Z M83,73 L77,73 L77,79 L83,79 L83,73 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M96,71 C97.1045695,71 98,71.8954305 98,73 L98,79 C98,80.1045695 97.1045695,81 96,81 L90,81 C88.8954305,81 88,80.1045695 88,79 L88,73 C88,71.8954305 88.8954305,71 90,71 L96,71 Z M96,73 L90,73 L90,79 L96,79 L96,73 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M109,71 C110.104569,71 111,71.8954305 111,73 L111,79 C111,80.1045695 110.104569,81 109,81 L103,81 C101.895431,81 101,80.1045695 101,79 L101,73 C101,71.8954305 101.895431,71 103,71 L109,71 Z M109,73 L103,73 L103,79 L109,79 L109,73 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M122,71 C123.104569,71 124,71.8954305 124,73 L124,79 C124,80.1045695 123.104569,81 122,81 L116,81 C114.895431,81 114,80.1045695 114,79 L114,73 C114,71.8954305 114.895431,71 116,71 L122,71 Z M122,73 L116,73 L116,79 L122,79 L122,73 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M44,86 C45.1045695,86 46,86.8954305 46,88 L46,94 C46,95.1045695 45.1045695,96 44,96 L38,96 C36.8954305,96 36,95.1045695 36,94 L36,88 C36,86.8954305 36.8954305,86 38,86 L44,86 Z M44,88 L38,88 L38,94 L44,94 L44,88 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M57,86 C58.1045695,86 59,86.8954305 59,88 L59,94 C59,95.1045695 58.1045695,96 57,96 L51,96 C49.8954305,96 49,95.1045695 49,94 L49,88 C49,86.8954305 49.8954305,86 51,86 L57,86 Z M57,88 L51,88 L51,94 L57,94 L57,88 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M70,86 C71.1045695,86 72,86.8954305 72,88 L72,94 C72,95.1045695 71.1045695,96 70,96 L64,96 C62.8954305,96 62,95.1045695 62,94 L62,88 C62,86.8954305 62.8954305,86 64,86 L70,86 Z M70,88 L64,88 L64,94 L70,94 L70,88 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M83,86 C84.1045695,86 85,86.8954305 85,88 L85,94 C85,95.1045695 84.1045695,96 83,96 L77,96 C75.8954305,96 75,95.1045695 75,94 L75,88 C75,86.8954305 75.8954305,86 77,86 L83,86 Z M83,88 L77,88 L77,94 L83,94 L83,88 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <rect id="Rectangle" fill={color} x="88" y="86" width="10" height="10" rx="2"></rect>
            <path d="M109,86 C110.104569,86 111,86.8954305 111,88 L111,94 C111,95.1045695 110.104569,96 109,96 L103,96 C101.895431,96 101,95.1045695 101,94 L101,88 C101,86.8954305 101.895431,86 103,86 L109,86 Z M109,88 L103,88 L103,94 L109,94 L109,88 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M122,86 C123.104569,86 124,86.8954305 124,88 L124,94 C124,95.1045695 123.104569,96 122,96 L116,96 C114.895431,96 114,95.1045695 114,94 L114,88 C114,86.8954305 114.895431,86 116,86 L122,86 Z M122,88 L116,88 L116,94 L122,94 L122,88 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M44,101 C45.1045695,101 46,101.895431 46,103 L46,109 C46,110.104569 45.1045695,111 44,111 L38,111 C36.8954305,111 36,110.104569 36,109 L36,103 C36,101.895431 36.8954305,101 38,101 L44,101 Z M44,103 L38,103 L38,109 L44,109 L44,103 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M57,101 C58.1045695,101 59,101.895431 59,103 L59,109 C59,110.104569 58.1045695,111 57,111 L51,111 C49.8954305,111 49,110.104569 49,109 L49,103 C49,101.895431 49.8954305,101 51,101 L57,101 Z M57,103 L51,103 L51,109 L57,109 L57,103 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M70,101 C71.1045695,101 72,101.895431 72,103 L72,109 C72,110.104569 71.1045695,111 70,111 L64,111 C62.8954305,111 62,110.104569 62,109 L62,103 C62,101.895431 62.8954305,101 64,101 L70,101 Z M70,103 L64,103 L64,109 L70,109 L70,103 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M83,101 C84.1045695,101 85,101.895431 85,103 L85,109 C85,110.104569 84.1045695,111 83,111 L77,111 C75.8954305,111 75,110.104569 75,109 L75,103 C75,101.895431 75.8954305,101 77,101 L83,101 Z M83,103 L77,103 L77,109 L83,109 L83,103 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M96,101 C97.1045695,101 98,101.895431 98,103 L98,109 C98,110.104569 97.1045695,111 96,111 L90,111 C88.8954305,111 88,110.104569 88,109 L88,103 C88,101.895431 88.8954305,101 90,101 L96,101 Z M96,103 L90,103 L90,109 L96,109 L96,103 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M109,101 C110.104569,101 111,101.895431 111,103 L111,109 C111,110.104569 110.104569,111 109,111 L103,111 C101.895431,111 101,110.104569 101,109 L101,103 C101,101.895431 101.895431,101 103,101 L109,101 Z M109,103 L103,103 L103,109 L109,109 L109,103 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M122,101 C123.104569,101 124,101.895431 124,103 L124,109 C124,110.104569 123.104569,111 122,111 L116,111 C114.895431,111 114,110.104569 114,109 L114,103 C114,101.895431 114.895431,101 116,101 L122,101 Z M122,103 L116,103 L116,109 L122,109 L122,103 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
          </g>
        </svg>
      </div>
    )
  }


  // Inbox

  renderInbox(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No requests</title>
          <g id="empty-inbox-small" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path d="M110.075304,37 C112.397502,37 114.41405,38.5988399 114.943576,40.8598587 L126.811881,91.5361582 L126.811881,118 C126.811881,120.761424 124.573305,123 121.811881,123 L39,123 C36.2385763,123 34,120.761424 34,118 L34,92.0777431 L45.8747271,40.870495 C46.4002363,38.6043476 48.4191973,37 50.7454784,37 L110.075304,37 Z M110.075304,39 L50.7454784,39 C49.4103953,39 48.2440188,39.8807232 47.8691776,41.1472447 L47.8230276,41.322297 L36,92.305 L36,118 C36,119.597681 37.24892,120.903661 38.8237272,120.994907 L39,121 L121.811881,121 C123.409562,121 124.715542,119.75108 124.806788,118.176273 L124.811881,118 L124.811881,91.766 L112.996267,41.3159152 C112.692365,40.0182871 111.572147,39.0841688 110.255943,39.0053941 L110.075304,39 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
              <path d="M126.811881,90.5361582 L126.811881,92.5361582 L93.706,92.536 L93.703468,92.6096378 C93.3337905,100.553861 88.7812433,104.719872 80.3544796,104.838957 L79.980198,104.841584 L79.980198,102.841584 C87.8117902,102.841584 91.6102286,99.2912493 91.72525,91.8860991 L91.7279452,91.5361582 L91.7279452,90.5361582 L126.811881,90.5361582 Z" id="Path-50" fill="#979797" fillRule="nonzero"></path>
              <path d="M80.8316832,90.5361582 L80.8316832,92.5361582 L47.726,92.536 L47.7232699,92.6096378 C47.3535924,100.553861 42.8010453,104.719872 34.3742816,104.838957 L34,104.841584 L34,102.841584 C41.8315922,102.841584 45.6300306,99.2912493 45.745052,91.8860991 L45.7477471,91.5361582 L45.7477471,90.5361582 L80.8316832,90.5361582 Z" id="Path-50" fill="#979797" fillRule="nonzero" transform="translate(57.415842, 97.688871) scale(-1, 1) translate(-57.415842, -97.688871) "></path>
              <path d="M103.227723,43.8118812 C105.436862,43.8118812 107.227723,45.6027422 107.227723,47.8118812 L107.227723,56.5841584 L52.7326733,56.5841584 L52.7326733,47.8118812 C52.7326733,45.6027422 54.5235343,43.8118812 56.7326733,43.8118812 L103.227723,43.8118812 Z M103.227723,45.8118812 L56.7326733,45.8118812 C55.6783115,45.8118812 54.8145081,46.627759 54.738159,47.6626188 L54.7326733,47.8118812 L54.732,54.584 L105.227,54.584 L105.227723,47.8118812 C105.227723,46.7575194 104.411845,45.8937161 103.376985,45.8173669 L103.227723,45.8118812 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
              <path d="M106.633663,54.8811881 C108.842802,54.8811881 110.633663,56.6720491 110.633663,58.8811881 L110.633663,67.6534653 L49.3267327,67.6534653 L49.3267327,58.8811881 C49.3267327,56.6720491 51.1175937,54.8811881 53.3267327,54.8811881 L106.633663,54.8811881 Z M106.633663,56.8811881 L53.3267327,56.8811881 C52.2723709,56.8811881 51.4085676,57.6970659 51.3322184,58.7319258 L51.3267327,58.8811881 L51.326,65.653 L108.633,65.653 L108.633663,58.8811881 C108.633663,57.8268263 107.817786,56.963023 106.782926,56.8866739 L106.633663,56.8811881 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
              <path d="M109.300904,65.8019802 C113.140921,65.8019802 115.152228,67.3460799 114.612657,70.2594459 L114.595,70.342 L114.595351,85.75678 C114.595351,86.2696159 114.209311,86.6922872 113.711972,86.7500523 L113.595351,86.75678 C113.082515,86.75678 112.659844,86.3707398 112.602079,85.8734012 L112.595351,85.75678 L112.595351,70.2449419 L112.618795,70.0296795 C112.949568,68.5290976 112.161782,67.8440023 109.551025,67.8038593 L109.300904,67.8019802 L58.8725755,67.8019802 C58.3202908,67.8019802 57.8725755,67.3542649 57.8725755,66.8019802 C57.8725755,66.2891444 58.2586157,65.866473 58.7559544,65.8087079 L58.8725755,65.8019802 L109.300904,65.8019802 Z" id="Path-51" fill={color} fillRule="nonzero"></path>
              <path d="M63.320706,65.8019802 C67.1607229,65.8019802 69.1720304,67.3460799 68.6324593,70.2594459 L68.615,70.342 L68.6151528,85.75678 C68.6151528,86.2696159 68.2291126,86.6922872 67.7317739,86.7500523 L67.6151528,86.75678 C67.102317,86.75678 66.6796457,86.3707398 66.6218806,85.8734012 L66.6151528,85.75678 L66.6151528,70.2449419 L66.6385966,70.0296795 C66.96937,68.5290976 66.181584,67.8440023 63.5708267,67.8038593 L63.320706,67.8019802 L46.9517834,67.8019802 C46.3994987,67.8019802 45.9517834,67.3542649 45.9517834,66.8019802 C45.9517834,66.2891444 46.3378236,65.866473 46.8351623,65.8087079 L46.9517834,65.8019802 L63.320706,65.8019802 Z" id="Path-51" fill={color} fillRule="nonzero" transform="translate(57.335358, 76.279380) scale(-1, 1) translate(-57.335358, -76.279380) "></path>
              <path d="M54.152581,73.6426098 L78.8106367,90.6808561 C79.5978729,91.1577525 80.3508694,91.3876433 81.0815055,91.3876433 C81.7264442,91.3876433 82.3724152,91.2085407 82.9918091,90.8647223 L83.222782,90.7280974 L106.638624,73.6572858 L107.816822,75.2734073 L84.3462196,92.3814288 C83.2938843,93.0466633 82.2004963,93.3876433 81.0815055,93.3876433 C80.0604207,93.3876433 79.0457008,93.1038262 78.0077578,92.5242908 L77.7240845,92.3588949 L53.0157358,75.2880833 L54.152581,73.6426098 Z" id="Path-52" fill={color} fillRule="nonzero"></path>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No requests</title>
          <g id="empty-inbox-small" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path d="M110.075304,37 C112.397502,37 114.41405,38.5988399 114.943576,40.8598587 L126.811881,91.5361582 L126.811881,118 C126.811881,120.761424 124.573305,123 121.811881,123 L39,123 C36.2385763,123 34,120.761424 34,118 L34,92.0777431 L45.8747271,40.870495 C46.4002363,38.6043476 48.4191973,37 50.7454784,37 L110.075304,37 Z M110.075304,39 L50.7454784,39 C49.4103953,39 48.2440188,39.8807232 47.8691776,41.1472447 L47.8230276,41.322297 L36,92.305 L36,118 C36,119.597681 37.24892,120.903661 38.8237272,120.994907 L39,121 L121.811881,121 C123.409562,121 124.715542,119.75108 124.806788,118.176273 L124.811881,118 L124.811881,91.766 L112.996267,41.3159152 C112.692365,40.0182871 111.572147,39.0841688 110.255943,39.0053941 L110.075304,39 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
              <path d="M126.811881,90.5361582 L126.811881,92.5361582 L93.706,92.536 L93.703468,92.6096378 C93.3337905,100.553861 88.7812433,104.719872 80.3544796,104.838957 L79.980198,104.841584 L79.980198,102.841584 C87.8117902,102.841584 91.6102286,99.2912493 91.72525,91.8860991 L91.7279452,91.5361582 L91.7279452,90.5361582 L126.811881,90.5361582 Z" id="Path-50" fill="#6B6F70" fillRule="nonzero"></path>
              <path d="M80.8316832,90.5361582 L80.8316832,92.5361582 L47.726,92.536 L47.7232699,92.6096378 C47.3535924,100.553861 42.8010453,104.719872 34.3742816,104.838957 L34,104.841584 L34,102.841584 C41.8315922,102.841584 45.6300306,99.2912493 45.745052,91.8860991 L45.7477471,91.5361582 L45.7477471,90.5361582 L80.8316832,90.5361582 Z" id="Path-50" fill="#6B6F70" fillRule="nonzero" transform="translate(57.415842, 97.688871) scale(-1, 1) translate(-57.415842, -97.688871) "></path>
              <path d="M103.227723,43.8118812 C105.436862,43.8118812 107.227723,45.6027422 107.227723,47.8118812 L107.227723,56.5841584 L52.7326733,56.5841584 L52.7326733,47.8118812 C52.7326733,45.6027422 54.5235343,43.8118812 56.7326733,43.8118812 L103.227723,43.8118812 Z M103.227723,45.8118812 L56.7326733,45.8118812 C55.6783115,45.8118812 54.8145081,46.627759 54.738159,47.6626188 L54.7326733,47.8118812 L54.732,54.584 L105.227,54.584 L105.227723,47.8118812 C105.227723,46.7575194 104.411845,45.8937161 103.376985,45.8173669 L103.227723,45.8118812 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
              <path d="M106.633663,54.8811881 C108.842802,54.8811881 110.633663,56.6720491 110.633663,58.8811881 L110.633663,67.6534653 L49.3267327,67.6534653 L49.3267327,58.8811881 C49.3267327,56.6720491 51.1175937,54.8811881 53.3267327,54.8811881 L106.633663,54.8811881 Z M106.633663,56.8811881 L53.3267327,56.8811881 C52.2723709,56.8811881 51.4085676,57.6970659 51.3322184,58.7319258 L51.3267327,58.8811881 L51.326,65.653 L108.633,65.653 L108.633663,58.8811881 C108.633663,57.8268263 107.817786,56.963023 106.782926,56.8866739 L106.633663,56.8811881 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
              <path d="M109.300904,65.8019802 C113.140921,65.8019802 115.152228,67.3460799 114.612657,70.2594459 L114.595,70.342 L114.595351,85.75678 C114.595351,86.2696159 114.209311,86.6922872 113.711972,86.7500523 L113.595351,86.75678 C113.082515,86.75678 112.659844,86.3707398 112.602079,85.8734012 L112.595351,85.75678 L112.595351,70.2449419 L112.618795,70.0296795 C112.949568,68.5290976 112.161782,67.8440023 109.551025,67.8038593 L109.300904,67.8019802 L58.8725755,67.8019802 C58.3202908,67.8019802 57.8725755,67.3542649 57.8725755,66.8019802 C57.8725755,66.2891444 58.2586157,65.866473 58.7559544,65.8087079 L58.8725755,65.8019802 L109.300904,65.8019802 Z" id="Path-51" fill={color} fillRule="nonzero"></path>
              <path d="M63.320706,65.8019802 C67.1607229,65.8019802 69.1720304,67.3460799 68.6324593,70.2594459 L68.615,70.342 L68.6151528,85.75678 C68.6151528,86.2696159 68.2291126,86.6922872 67.7317739,86.7500523 L67.6151528,86.75678 C67.102317,86.75678 66.6796457,86.3707398 66.6218806,85.8734012 L66.6151528,85.75678 L66.6151528,70.2449419 L66.6385966,70.0296795 C66.96937,68.5290976 66.181584,67.8440023 63.5708267,67.8038593 L63.320706,67.8019802 L46.9517834,67.8019802 C46.3994987,67.8019802 45.9517834,67.3542649 45.9517834,66.8019802 C45.9517834,66.2891444 46.3378236,65.866473 46.8351623,65.8087079 L46.9517834,65.8019802 L63.320706,65.8019802 Z" id="Path-51" fill={color} fillRule="nonzero" transform="translate(57.335358, 76.279380) scale(-1, 1) translate(-57.335358, -76.279380) "></path>
              <path d="M54.152581,73.6426098 L78.8106367,90.6808561 C79.5978729,91.1577525 80.3508694,91.3876433 81.0815055,91.3876433 C81.7264442,91.3876433 82.3724152,91.2085407 82.9918091,90.8647223 L83.222782,90.7280974 L106.638624,73.6572858 L107.816822,75.2734073 L84.3462196,92.3814288 C83.2938843,93.0466633 82.2004963,93.3876433 81.0815055,93.3876433 C80.0604207,93.3876433 79.0457008,93.1038262 78.0077578,92.5242908 L77.7240845,92.3588949 L53.0157358,75.2880833 L54.152581,73.6426098 Z" id="Path-52" fill={color} fillRule="nonzero"></path>
          </g>
        </svg>
      </div>
    )
  }


  // Comments

  renderComments(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No comments</title>
          <g id="empty-comments" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M123,52 L66,52 C59.9248678,52 55,56.9248678 55,63 L55,99 L55.0037074,99.2884075 C55.1566348,105.230236 60.0212984,110 66,110 L110.86,110 L125.999842,132.246564 L126,109.584 L126.034861,109.575978 C130.688943,108.245013 134,103.96296 134,99 L134,63 C134,56.9248678 129.075132,52 123,52 Z M66,54 L123,54 C127.970563,54 132,58.0294373 132,63 L132,99 C132,103.29918 128.962227,106.974844 124.80195,107.819771 L124.00102,107.982435 L124,125.754 L111.918057,108 L66,108 C61.0294373,108 57,103.970563 57,99 L57,63 C57,58.0294373 61.0294373,54 66,54 Z" id="Combined-Shape" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M120.536364,66.5 C121.068565,66.5 121.5,66.9477153 121.5,67.5 C121.5,68.0128358 121.127998,68.4355072 120.648744,68.4932723 L120.536364,68.5 L69.4636364,68.5 C68.9314347,68.5 68.5,68.0522847 68.5,67.5 C68.5,66.9871642 68.8720024,66.5644928 69.351256,66.5067277 L69.4636364,66.5 L120.536364,66.5 Z" id="Line-14" fill={color} fillRule="nonzero"></path>
            <path d="M120.536364,79.5 C121.068565,79.5 121.5,79.9477153 121.5,80.5 C121.5,81.0128358 121.127998,81.4355072 120.648744,81.4932723 L120.536364,81.5 L69.4636364,81.5 C68.9314347,81.5 68.5,81.0522847 68.5,80.5 C68.5,79.9871642 68.8720024,79.5644928 69.351256,79.5067277 L69.4636364,79.5 L120.536364,79.5 Z" id="Line-14" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M120.536364,92.5 C121.068565,92.5 121.5,92.9477153 121.5,93.5 C121.5,94.0128358 121.127998,94.4355072 120.648744,94.4932723 L120.536364,94.5 L69.4636364,94.5 C68.9314347,94.5 68.5,94.0522847 68.5,93.5 C68.5,92.9871642 68.8720024,92.5644928 69.351256,92.5067277 L69.4636364,92.5 L120.536364,92.5 Z" id="Line-14" fill="#A2AEB0" fillRule="nonzero"></path>
            <path d="M84,32 C90.0751322,32 95,36.9248678 95,43 L95,43 L95,48 L93,48 L93,43 C93,38.1181973 89.1131863,34.1442086 84.2653623,34.0038371 L84,34 L35,34 C30.0294373,34 26,38.0294373 26,43 L26,43 L26,70 C26,74.1631349 28.8506243,77.755144 32.836115,78.7384106 L32.836115,78.7384106 L33.5966018,78.9260315 L33.596,95.779 L47.860594,79 L50,79 L50,81 L48.784,81 L31.5961344,101.220347 L31.596,80.462 L31.2463366,80.3430574 C27.0520221,78.8233115 24.1241321,74.8626097 24.0038498,70.2927729 L24.0038498,70.2927729 L24,70 L24,43 C24,36.9248678 28.9248678,32 35,32 L35,32 Z" id="Combined-Shape" fill="#A2AEB0" fillRule="nonzero"></path>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No comments</title>
          <g id="empty-comments" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M123,52 L66,52 C59.9248678,52 55,56.9248678 55,63 L55,99 L55.0037074,99.2884075 C55.1566348,105.230236 60.0212984,110 66,110 L110.86,110 L125.999842,132.246564 L126,109.584 L126.034861,109.575978 C130.688943,108.245013 134,103.96296 134,99 L134,63 C134,56.9248678 129.075132,52 123,52 Z M66,54 L123,54 C127.970563,54 132,58.0294373 132,63 L132,99 C132,103.29918 128.962227,106.974844 124.80195,107.819771 L124.00102,107.982435 L124,125.754 L111.918057,108 L66,108 C61.0294373,108 57,103.970563 57,99 L57,63 C57,58.0294373 61.0294373,54 66,54 Z" id="Combined-Shape" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M120.536364,66.5 C121.068565,66.5 121.5,66.9477153 121.5,67.5 C121.5,68.0128358 121.127998,68.4355072 120.648744,68.4932723 L120.536364,68.5 L69.4636364,68.5 C68.9314347,68.5 68.5,68.0522847 68.5,67.5 C68.5,66.9871642 68.8720024,66.5644928 69.351256,66.5067277 L69.4636364,66.5 L120.536364,66.5 Z" id="Line-14" fill={color} fillRule="nonzero"></path>
            <path d="M120.536364,79.5 C121.068565,79.5 121.5,79.9477153 121.5,80.5 C121.5,81.0128358 121.127998,81.4355072 120.648744,81.4932723 L120.536364,81.5 L69.4636364,81.5 C68.9314347,81.5 68.5,81.0522847 68.5,80.5 C68.5,79.9871642 68.8720024,79.5644928 69.351256,79.5067277 L69.4636364,79.5 L120.536364,79.5 Z" id="Line-14" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M120.536364,92.5 C121.068565,92.5 121.5,92.9477153 121.5,93.5 C121.5,94.0128358 121.127998,94.4355072 120.648744,94.4932723 L120.536364,94.5 L69.4636364,94.5 C68.9314347,94.5 68.5,94.0522847 68.5,93.5 C68.5,92.9871642 68.8720024,92.5644928 69.351256,92.5067277 L69.4636364,92.5 L120.536364,92.5 Z" id="Line-14" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M84,32 C90.0751322,32 95,36.9248678 95,43 L95,43 L95,48 L93,48 L93,43 C93,38.1181973 89.1131863,34.1442086 84.2653623,34.0038371 L84,34 L35,34 C30.0294373,34 26,38.0294373 26,43 L26,43 L26,70 C26,74.1631349 28.8506243,77.755144 32.836115,78.7384106 L32.836115,78.7384106 L33.5966018,78.9260315 L33.596,95.779 L47.860594,79 L50,79 L50,81 L48.784,81 L31.5961344,101.220347 L31.596,80.462 L31.2463366,80.3430574 C27.0520221,78.8233115 24.1241321,74.8626097 24.0038498,70.2927729 L24.0038498,70.2927729 L24,70 L24,43 C24,36.9248678 28.9248678,32 35,32 L35,32 Z" id="Combined-Shape" fill="#6B6F70" fillRule="nonzero"></path>
          </g>
        </svg>
      </div>
    )
  }


  // Party

  renderParty(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No birthdays</title>
          <g id="empty-party" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="party-hat" transform="translate(31.000000, 31.000000)" fillRule="nonzero">
              <path d="M28.6030121,9.38143679 C26.0590362,7.98712318 23.9801205,5.90410316 22.5897591,3.35631203 L21.0825302,0.594846809 C20.6500001,-0.19828227 19.35,-0.19828227 18.9174699,0.594846809 L17.410241,3.35631203 C16.0198796,5.90349967 13.9409639,7.9871233 11.3975903,9.38143679 L8.64156623,10.8916414 C8.24578307,11.1083335 8,11.5242132 8,11.9763087 C8,12.4284043 8.2457832,12.844284 8.64156623,13.060976 L11.3975903,14.5711807 C13.9403615,15.9648907 16.0192771,18.0479107 17.410241,20.5957019 L18.9174699,23.3571671 C19.133735,23.7537317 19.5487953,24 20,24 C20.4512048,24 20.8662651,23.7537316 21.0825302,23.3571671 L22.5897591,20.5957019 C23.980723,18.0479107 26.0596387,15.9642872 28.6030121,14.5711807 L31.3584338,13.060976 C31.7542169,12.844284 32,12.4284043 32,11.9763087 C32,11.5242132 31.7542168,11.1083335 31.3584338,10.8916414 L28.6030121,9.38143679 Z M27.4186748,12.401846 C24.4608435,14.0225093 22.0427711,16.4459591 20.4246989,19.4090263 L20.0000001,20.1876689 L19.5753012,19.4090263 C17.957229,16.445959 15.5391566,14.0231128 12.5819277,12.401846 L11.8048193,11.9763087 L12.5819277,11.5507714 C15.539759,9.92890106 17.9584339,7.50545126 19.5753012,4.54298753 L20.0000001,3.76434489 L20.4246989,4.54298753 C22.0415664,7.50545126 24.4602411,9.92890106 27.4186748,11.5507714 L28.1951808,11.9763087 L27.4186748,12.401846 Z" id="Shape" fill="#A2AEB0"></path>
              <path d="M97.3664486,12.4054054 L94.2230815,10.683136 C91.2903034,9.07647918 88.892326,6.67424098 87.2885188,3.736252 L85.569304,0.587299874 C85.1421773,-0.195766625 83.8584176,-0.195766625 83.4312909,0.587299874 L81.7120762,3.736252 C80.1082689,6.6742411 77.7102914,9.07647918 74.7775134,10.683136 L71.6335514,12.4054054 C71.2433075,12.6193483 71,13.0299517 71,13.4763114 C71,13.9226711 71.2433075,14.3332745 71.6335514,14.5472173 L74.7775134,16.2694868 C77.7102916,17.8761436 80.1082689,20.2783818 81.7120762,23.2157748 L83.4312909,26.3653229 C83.6448543,26.7562602 84.0547294,27 84.5002975,27 C84.9458655,27 85.3557406,26.7562602 85.569304,26.3653229 L87.2885188,23.2157748 C88.892326,20.2783817 91.2903035,17.8761436 94.2230815,16.2694868 L97.3664486,14.5472173 C97.7572874,14.3332745 98,13.9226711 98,13.4763114 C98,13.0299517 97.7572874,12.6193483 97.3664486,12.4054054 L97.3664486,12.4054054 Z M93.0535396,14.1276749 C89.7114813,15.9590015 86.9785842,18.6961585 85.1505057,22.0441549 L84.5002975,23.2354409 L83.8500892,22.0441549 C82.0220107,18.6961584 79.2891136,15.9590015 75.9470553,14.1276749 L74.7578822,13.4763114 L75.9470553,12.8249479 C79.2891136,10.9936212 82.0220107,8.25586842 83.8500892,4.90787193 L84.5002975,3.71658593 L85.1505057,4.90787193 C86.9785842,8.25586842 89.7114813,10.9936212 93.0535396,12.8249479 L94.2427127,13.4763114 L93.0535396,14.1276749 Z" id="Shape" fill="#A2AEB0"></path>
              <path d="M93,72.5 C93,68.915987 90.0843548,66 86.5,66 C82.9156452,66 80,68.915987 80,72.5 C80,76.084013 82.9156452,79 86.5,79 C90.0843548,79 93,76.084013 93,72.5 Z M82.4175066,72.5 C82.4175066,70.2461482 84.2489331,68.4129056 86.5,68.4129056 C88.7510669,68.4129056 90.5824934,70.2461482 90.5824934,72.5 C90.5824934,74.7538518 88.7510669,76.5870944 86.5,76.5870944 C84.2489331,76.5870944 82.4175066,74.7538518 82.4175066,72.5 Z" id="Shape" fill="#A2AEB0"></path>
              <path d="M59,6.5 C59,2.91598697 56.0843548,0 52.5,0 C48.9156452,0 46,2.91598697 46,6.5 C46,10.084013 48.9156452,13 52.5,13 C56.0843548,13 59,10.084013 59,6.5 Z M52.5,10.5870944 C50.2489331,10.5870944 48.4175066,8.75385178 48.4175066,6.5 C48.4175066,4.24614822 50.2489331,2.41290557 52.5,2.41290557 C54.7510669,2.41290557 56.5824934,4.24614822 56.5824934,6.5 C56.5824934,8.75385178 54.7510669,10.5870944 52.5,10.5870944 Z" id="Shape" fill="#A2AEB0"></path>
              <path d="M80.5002951,51 C84.0844872,51 87,48.0838809 87,44.4997054 C87,40.91553 84.0844872,38 80.5002951,38 C76.916103,38 74,40.91553 74,44.4997054 C74,48.0838809 76.9161029,51 80.5002951,51 L80.5002951,51 Z M80.5002951,40.4130149 C82.7512598,40.4130149 84.5826032,42.2463406 84.5826032,44.4997054 C84.5826032,46.7536594 82.7512598,48.5869851 80.5002951,48.5869851 C78.2487402,48.5869851 76.4173968,46.7536594 76.4173968,44.4997054 C76.4173968,42.2463406 78.2487402,40.4130149 80.5002951,40.4130149 L80.5002951,40.4130149 Z" id="Shape" fill={color}></path>
              <path d="M1.23178239,98 C1.39354779,98 1.55651583,97.9681988 1.71166616,97.9027962 L75.2481486,66.8810044 C75.6252001,66.7219983 75.8982168,66.3865853 75.9769948,65.98517 C76.0557726,65.5843545 75.9294875,65.1709386 75.6402342,64.8811276 L33.1230089,22.361694 C32.832553,22.0718829 32.416413,21.943478 32.0147059,22.023281 C31.6123972,22.101884 31.2750354,22.3754946 31.1156754,22.7529091 L0.0964181622,96.2947345 C-0.0978204728,96.7555522 0.00681580257,97.2877727 0.361617077,97.6405862 C0.597349541,97.8757953 0.911859871,98 1.23178239,98 Z M19.7652654,55.9988363 L41.682666,78.3725709 L28.8550196,83.7840288 L14.3089172,68.9350333 L19.7652654,55.9988363 Z M26.2585806,40.6041199 L56.9485189,71.9324485 L44.120572,77.3440564 L20.8022324,53.5402419 L26.2585806,40.6041199 Z M32.6743957,25.3930105 L72.6063822,65.3269447 L59.3864249,70.903934 L27.2955475,38.1455254 L32.6743957,25.3930105 Z M13.2719502,71.3935528 L26.4171136,84.8125433 L3.54159914,94.4628641 L13.2719502,71.3935528 Z" id="Shape" fill="#A2AEB0"></path>
              <path d="M57.2757365,34 C57.6683888,34 58.0554318,33.8281981 58.3053581,33.5041443 C58.7086059,32.9810373 68.144727,20.4714923 65.5488588,1.05965955 C65.459733,0.394371716 64.8202706,-0.070085723 64.1215987,0.0087061563 C63.4223035,0.0934222225 62.927437,0.700652952 63.0159396,1.36534846 C65.4778074,19.7748062 56.3377337,31.9508187 56.2448683,32.0716724 C55.830402,32.6125521 55.9544302,33.3714424 56.5234644,33.7665867 C56.7503303,33.9241702 57.0145916,34 57.2757365,34 L57.2757365,34 Z" id="Path" fill="#A2AEB0"></path>
              <path d="M64.2384382,41 C64.5092397,41 64.7818545,40.9130518 65.0097388,40.7337957 C65.1481619,40.6242171 79.0315729,29.9468625 96.3030243,36.7389441 C96.9395287,36.9884734 97.6582407,36.6835592 97.9115125,36.0582471 C98.1647844,35.4329349 97.8559015,34.7230566 97.220606,34.4735273 C78.6265795,27.1597568 64.0764409,38.3474852 63.4659286,38.8268912 C62.9315793,39.2473393 62.8439315,40.0143888 63.2700811,40.5408421 C63.5148905,40.8427786 63.8751531,41 64.2384382,41 L64.2384382,41 Z" id="Path" fill="#A2AEB0"></path>
              <path d="M41.0243363,24.9689571 C41.1167895,24.9898513 41.2092426,25 41.3004952,25 C41.8600169,25 42.3655076,24.6179326 42.4969833,24.0525923 C42.6140505,23.545756 45.2795837,11.5577947 37.0446474,4.30687209 C36.536155,3.85973379 35.7587079,3.90689534 35.3090492,4.41134362 C34.8587903,4.91698591 34.9062175,5.68947849 35.4141096,6.13721366 C42.5438101,12.4144614 40.1256193,23.3947201 40.1004048,23.5045644 C39.9497183,24.1624366 40.3627558,24.817921 41.0243363,24.9689571 Z" id="Path" fill={color}></path>
              <path d="M97.7752561,61.1026323 C90.993071,51.6662495 76.4741578,55.9379669 75.8599715,56.1245355 C75.2172736,56.3193179 74.8567213,56.9916689 75.0539261,57.6258849 C75.2517251,58.2606875 75.9324383,58.6173974 76.5739482,58.4220284 C76.7075961,58.3797865 90.0147683,54.4559785 95.7913273,62.49427 C96.0283296,62.823992 96.4037317,63 96.7844796,63 C97.0280158,63 97.273928,62.9278367 97.4877645,62.7782298 C98.035424,62.3939454 98.1643199,61.6441506 97.7752561,61.1026323 Z" id="Path" fill="#A2AEB0"></path>
            </g>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No birthdays</title>
          <g id="empty-party" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="party-hat" transform="translate(31.000000, 31.000000)" fillRule="nonzero">
              <path d="M28.6030121,9.38143679 C26.0590362,7.98712318 23.9801205,5.90410316 22.5897591,3.35631203 L21.0825302,0.594846809 C20.6500001,-0.19828227 19.35,-0.19828227 18.9174699,0.594846809 L17.410241,3.35631203 C16.0198796,5.90349967 13.9409639,7.9871233 11.3975903,9.38143679 L8.64156623,10.8916414 C8.24578307,11.1083335 8,11.5242132 8,11.9763087 C8,12.4284043 8.2457832,12.844284 8.64156623,13.060976 L11.3975903,14.5711807 C13.9403615,15.9648907 16.0192771,18.0479107 17.410241,20.5957019 L18.9174699,23.3571671 C19.133735,23.7537317 19.5487953,24 20,24 C20.4512048,24 20.8662651,23.7537316 21.0825302,23.3571671 L22.5897591,20.5957019 C23.980723,18.0479107 26.0596387,15.9642872 28.6030121,14.5711807 L31.3584338,13.060976 C31.7542169,12.844284 32,12.4284043 32,11.9763087 C32,11.5242132 31.7542168,11.1083335 31.3584338,10.8916414 L28.6030121,9.38143679 Z M27.4186748,12.401846 C24.4608435,14.0225093 22.0427711,16.4459591 20.4246989,19.4090263 L20.0000001,20.1876689 L19.5753012,19.4090263 C17.957229,16.445959 15.5391566,14.0231128 12.5819277,12.401846 L11.8048193,11.9763087 L12.5819277,11.5507714 C15.539759,9.92890106 17.9584339,7.50545126 19.5753012,4.54298753 L20.0000001,3.76434489 L20.4246989,4.54298753 C22.0415664,7.50545126 24.4602411,9.92890106 27.4186748,11.5507714 L28.1951808,11.9763087 L27.4186748,12.401846 Z" id="Shape" fill="#6B6F70"></path>
              <path d="M97.3664486,12.4054054 L94.2230815,10.683136 C91.2903034,9.07647918 88.892326,6.67424098 87.2885188,3.736252 L85.569304,0.587299874 C85.1421773,-0.195766625 83.8584176,-0.195766625 83.4312909,0.587299874 L81.7120762,3.736252 C80.1082689,6.6742411 77.7102914,9.07647918 74.7775134,10.683136 L71.6335514,12.4054054 C71.2433075,12.6193483 71,13.0299517 71,13.4763114 C71,13.9226711 71.2433075,14.3332745 71.6335514,14.5472173 L74.7775134,16.2694868 C77.7102916,17.8761436 80.1082689,20.2783818 81.7120762,23.2157748 L83.4312909,26.3653229 C83.6448543,26.7562602 84.0547294,27 84.5002975,27 C84.9458655,27 85.3557406,26.7562602 85.569304,26.3653229 L87.2885188,23.2157748 C88.892326,20.2783817 91.2903035,17.8761436 94.2230815,16.2694868 L97.3664486,14.5472173 C97.7572874,14.3332745 98,13.9226711 98,13.4763114 C98,13.0299517 97.7572874,12.6193483 97.3664486,12.4054054 L97.3664486,12.4054054 Z M93.0535396,14.1276749 C89.7114813,15.9590015 86.9785842,18.6961585 85.1505057,22.0441549 L84.5002975,23.2354409 L83.8500892,22.0441549 C82.0220107,18.6961584 79.2891136,15.9590015 75.9470553,14.1276749 L74.7578822,13.4763114 L75.9470553,12.8249479 C79.2891136,10.9936212 82.0220107,8.25586842 83.8500892,4.90787193 L84.5002975,3.71658593 L85.1505057,4.90787193 C86.9785842,8.25586842 89.7114813,10.9936212 93.0535396,12.8249479 L94.2427127,13.4763114 L93.0535396,14.1276749 Z" id="Shape" fill="#6B6F70"></path>
              <path d="M93,72.5 C93,68.915987 90.0843548,66 86.5,66 C82.9156452,66 80,68.915987 80,72.5 C80,76.084013 82.9156452,79 86.5,79 C90.0843548,79 93,76.084013 93,72.5 Z M82.4175066,72.5 C82.4175066,70.2461482 84.2489331,68.4129056 86.5,68.4129056 C88.7510669,68.4129056 90.5824934,70.2461482 90.5824934,72.5 C90.5824934,74.7538518 88.7510669,76.5870944 86.5,76.5870944 C84.2489331,76.5870944 82.4175066,74.7538518 82.4175066,72.5 Z" id="Shape" fill="#6B6F70"></path>
              <path d="M59,6.5 C59,2.91598697 56.0843548,0 52.5,0 C48.9156452,0 46,2.91598697 46,6.5 C46,10.084013 48.9156452,13 52.5,13 C56.0843548,13 59,10.084013 59,6.5 Z M52.5,10.5870944 C50.2489331,10.5870944 48.4175066,8.75385178 48.4175066,6.5 C48.4175066,4.24614822 50.2489331,2.41290557 52.5,2.41290557 C54.7510669,2.41290557 56.5824934,4.24614822 56.5824934,6.5 C56.5824934,8.75385178 54.7510669,10.5870944 52.5,10.5870944 Z" id="Shape" fill="#6B6F70"></path>
              <path d="M80.5002951,51 C84.0844872,51 87,48.0838809 87,44.4997054 C87,40.91553 84.0844872,38 80.5002951,38 C76.916103,38 74,40.91553 74,44.4997054 C74,48.0838809 76.9161029,51 80.5002951,51 L80.5002951,51 Z M80.5002951,40.4130149 C82.7512598,40.4130149 84.5826032,42.2463406 84.5826032,44.4997054 C84.5826032,46.7536594 82.7512598,48.5869851 80.5002951,48.5869851 C78.2487402,48.5869851 76.4173968,46.7536594 76.4173968,44.4997054 C76.4173968,42.2463406 78.2487402,40.4130149 80.5002951,40.4130149 L80.5002951,40.4130149 Z" id="Shape" fill={color}></path>
              <path d="M1.23178239,98 C1.39354779,98 1.55651583,97.9681988 1.71166616,97.9027962 L75.2481486,66.8810044 C75.6252001,66.7219983 75.8982168,66.3865853 75.9769948,65.98517 C76.0557726,65.5843545 75.9294875,65.1709386 75.6402342,64.8811276 L33.1230089,22.361694 C32.832553,22.0718829 32.416413,21.943478 32.0147059,22.023281 C31.6123972,22.101884 31.2750354,22.3754946 31.1156754,22.7529091 L0.0964181622,96.2947345 C-0.0978204728,96.7555522 0.00681580257,97.2877727 0.361617077,97.6405862 C0.597349541,97.8757953 0.911859871,98 1.23178239,98 Z M19.7652654,55.9988363 L41.682666,78.3725709 L28.8550196,83.7840288 L14.3089172,68.9350333 L19.7652654,55.9988363 Z M26.2585806,40.6041199 L56.9485189,71.9324485 L44.120572,77.3440564 L20.8022324,53.5402419 L26.2585806,40.6041199 Z M32.6743957,25.3930105 L72.6063822,65.3269447 L59.3864249,70.903934 L27.2955475,38.1455254 L32.6743957,25.3930105 Z M13.2719502,71.3935528 L26.4171136,84.8125433 L3.54159914,94.4628641 L13.2719502,71.3935528 Z" id="Shape" fill="#6B6F70"></path>
              <path d="M57.2757365,34 C57.6683888,34 58.0554318,33.8281981 58.3053581,33.5041443 C58.7086059,32.9810373 68.144727,20.4714923 65.5488588,1.05965955 C65.459733,0.394371716 64.8202706,-0.070085723 64.1215987,0.0087061563 C63.4223035,0.0934222225 62.927437,0.700652952 63.0159396,1.36534846 C65.4778074,19.7748062 56.3377337,31.9508187 56.2448683,32.0716724 C55.830402,32.6125521 55.9544302,33.3714424 56.5234644,33.7665867 C56.7503303,33.9241702 57.0145916,34 57.2757365,34 L57.2757365,34 Z" id="Path" fill="#6B6F70"></path>
              <path d="M64.2384382,41 C64.5092397,41 64.7818545,40.9130518 65.0097388,40.7337957 C65.1481619,40.6242171 79.0315729,29.9468625 96.3030243,36.7389441 C96.9395287,36.9884734 97.6582407,36.6835592 97.9115125,36.0582471 C98.1647844,35.4329349 97.8559015,34.7230566 97.220606,34.4735273 C78.6265795,27.1597568 64.0764409,38.3474852 63.4659286,38.8268912 C62.9315793,39.2473393 62.8439315,40.0143888 63.2700811,40.5408421 C63.5148905,40.8427786 63.8751531,41 64.2384382,41 L64.2384382,41 Z" id="Path" fill="#6B6F70"></path>
              <path d="M41.0243363,24.9689571 C41.1167895,24.9898513 41.2092426,25 41.3004952,25 C41.8600169,25 42.3655076,24.6179326 42.4969833,24.0525923 C42.6140505,23.545756 45.2795837,11.5577947 37.0446474,4.30687209 C36.536155,3.85973379 35.7587079,3.90689534 35.3090492,4.41134362 C34.8587903,4.91698591 34.9062175,5.68947849 35.4141096,6.13721366 C42.5438101,12.4144614 40.1256193,23.3947201 40.1004048,23.5045644 C39.9497183,24.1624366 40.3627558,24.817921 41.0243363,24.9689571 Z" id="Path" fill={color}></path>
              <path d="M97.7752561,61.1026323 C90.993071,51.6662495 76.4741578,55.9379669 75.8599715,56.1245355 C75.2172736,56.3193179 74.8567213,56.9916689 75.0539261,57.6258849 C75.2517251,58.2606875 75.9324383,58.6173974 76.5739482,58.4220284 C76.7075961,58.3797865 90.0147683,54.4559785 95.7913273,62.49427 C96.0283296,62.823992 96.4037317,63 96.7844796,63 C97.0280158,63 97.273928,62.9278367 97.4877645,62.7782298 C98.035424,62.3939454 98.1643199,61.6441506 97.7752561,61.1026323 Z" id="Path" fill="#6B6F70"></path>
            </g>
          </g>
        </svg>
      </div>
    )
  }


  // Notes

  renderNotes(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No notes</title>
          <g id="empty-notes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M102.5,31 C104.571068,31 106.446068,31.8394661 107.803301,33.1966991 C109.160534,34.5539322 110,36.4289322 110,38.5 C110,40.1773438 109.449572,41.7261971 108.519039,42.9753809 C107.565323,44.2556869 106.213032,45.2218731 104.645447,45.6889183 C104.429215,45.7533423 104.211214,45.8075756 103.992179,45.8519807 C103.983256,46.076427 103.965332,46.3018552 103.938143,46.5278318 C103.79015,47.7578652 103.379373,48.9070077 102.763339,49.9173712 L102.763339,49.9173712 L113.784509,52.87078 C115.384908,53.2996054 116.659981,54.3365236 117.427748,55.6663349 C118.195515,56.9961463 118.455976,58.6188509 118.027074,60.2195319 L118.027074,60.2195319 L116.879445,64.5075634 C117.58514,65.4911946 118,66.6971891 118,68 L118,68 L118,123 C118,124.656854 117.328427,126.156854 116.242641,127.242641 C115.156854,128.328427 113.656854,129 112,129 L112,129 L60,129 C58.3431458,129 56.8431458,128.328427 55.7573593,127.242641 C54.6715729,126.156854 54,124.656854 54,122.999979 L54,122.999979 L53.9996515,106.214644 L46.2154905,104.12922 C44.6150922,103.700395 43.3400193,102.663476 42.5722524,101.333665 C41.8044854,100.003854 41.5440244,98.3811491 41.9728498,96.7807508 L41.9728498,96.7807508 L56.2078973,43.6548303 C56.6367227,42.054432 57.6736409,40.7793591 59.0034523,40.0115922 C60.3332636,39.2438252 61.9559682,38.9833642 63.5563654,39.4121893 L63.5563654,39.4121893 L87.0023256,45.6945451 C86.9477107,43.2790879 87.9019236,41.086501 89.4719589,39.5072774 C90.7841307,38.1874263 92.5267621,37.2956592 94.471922,37.0614405 C94.6979709,37.0342218 94.9234708,37.0162786 95.1478483,37.0072672 C95.1920504,36.7882732 95.2462895,36.5703826 95.3107083,36.3542629 C95.7779233,34.7867975 96.7442374,33.4345505 98.0246456,32.4808615 C99.2738582,31.550408 100.822686,31 102.5,31 Z" id="Combined-Shape" stroke="#A2AEB0" strokeWidth="2"></path>
            <path d="M102.5,31 C104.571068,31 106.446068,31.8394661 107.803301,33.1966991 C109.160534,34.5539322 110,36.4289322 110,38.5 C110,40.1773438 109.449572,41.7261971 108.519039,42.9753809 C107.565323,44.2556869 106.213032,45.2218731 104.645447,45.6889183 C104.429215,45.7533423 104.211214,45.8075756 103.99217,45.8518737 C103.983257,46.0763532 103.965336,46.3018167 103.938143,46.5278318 C103.683406,48.6450594 102.649684,50.5222603 101.132813,51.8657031 C99.6331952,53.1938658 97.6607174,54 95.5,54 C93.1527898,54 91.0277898,53.0486051 89.4895924,51.5104076 C87.9513949,49.9722102 87,47.8472102 87,45.5 C87,43.3393106 87.8061132,41.3668571 89.1342017,39.8671976 C90.4776226,38.350225 92.3547906,37.3163664 94.471922,37.0614405 C94.6979269,37.0342271 94.9233824,37.0162855 95.1478483,37.0072672 C95.1920504,36.7882732 95.2462895,36.5703826 95.3107083,36.3542629 C95.7779233,34.7867975 96.7442374,33.4345505 98.0246456,32.4808615 C99.2738582,31.550408 100.822686,31 102.5,31 Z" id="Combined-Shape" stroke={color} strokeWidth="2"></path>
            <circle id="Oval" stroke={color} strokeWidth="2" cx="102.5" cy="38.5" r="7.5"></circle>
            <polyline id="Path-63" stroke="#A2AEB0" strokeWidth="2" points="116.392639 63.8295593 104.974915 107.280716 89.5812988 115.949791 54.741333 106.280716"></polyline>
            <polyline id="Path-64" stroke="#A2AEB0" strokeWidth="2" points="104.974915 107.280716 92.2767944 103.582581 88.5924072 115"></polyline>
            <line x1="89.5924072" y1="52.544693" x2="86.5669861" y2="56" id="Path-65" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No notes</title>
          <g id="empty-notes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M102.5,31 C104.571068,31 106.446068,31.8394661 107.803301,33.1966991 C109.160534,34.5539322 110,36.4289322 110,38.5 C110,40.1773438 109.449572,41.7261971 108.519039,42.9753809 C107.565323,44.2556869 106.213032,45.2218731 104.645447,45.6889183 C104.429215,45.7533423 104.211214,45.8075756 103.992179,45.8519807 C103.983256,46.076427 103.965332,46.3018552 103.938143,46.5278318 C103.79015,47.7578652 103.379373,48.9070077 102.763339,49.9173712 L102.763339,49.9173712 L113.784509,52.87078 C115.384908,53.2996054 116.659981,54.3365236 117.427748,55.6663349 C118.195515,56.9961463 118.455976,58.6188509 118.027074,60.2195319 L118.027074,60.2195319 L116.879445,64.5075634 C117.58514,65.4911946 118,66.6971891 118,68 L118,68 L118,123 C118,124.656854 117.328427,126.156854 116.242641,127.242641 C115.156854,128.328427 113.656854,129 112,129 L112,129 L60,129 C58.3431458,129 56.8431458,128.328427 55.7573593,127.242641 C54.6715729,126.156854 54,124.656854 54,122.999979 L54,122.999979 L53.9996515,106.214644 L46.2154905,104.12922 C44.6150922,103.700395 43.3400193,102.663476 42.5722524,101.333665 C41.8044854,100.003854 41.5440244,98.3811491 41.9728498,96.7807508 L41.9728498,96.7807508 L56.2078973,43.6548303 C56.6367227,42.054432 57.6736409,40.7793591 59.0034523,40.0115922 C60.3332636,39.2438252 61.9559682,38.9833642 63.5563654,39.4121893 L63.5563654,39.4121893 L87.0023256,45.6945451 C86.9477107,43.2790879 87.9019236,41.086501 89.4719589,39.5072774 C90.7841307,38.1874263 92.5267621,37.2956592 94.471922,37.0614405 C94.6979709,37.0342218 94.9234708,37.0162786 95.1478483,37.0072672 C95.1920504,36.7882732 95.2462895,36.5703826 95.3107083,36.3542629 C95.7779233,34.7867975 96.7442374,33.4345505 98.0246456,32.4808615 C99.2738582,31.550408 100.822686,31 102.5,31 Z" id="Combined-Shape" stroke="#6B6F70" strokeWidth="2"></path>
            <path d="M102.5,31 C104.571068,31 106.446068,31.8394661 107.803301,33.1966991 C109.160534,34.5539322 110,36.4289322 110,38.5 C110,40.1773438 109.449572,41.7261971 108.519039,42.9753809 C107.565323,44.2556869 106.213032,45.2218731 104.645447,45.6889183 C104.429215,45.7533423 104.211214,45.8075756 103.99217,45.8518737 C103.983257,46.0763532 103.965336,46.3018167 103.938143,46.5278318 C103.683406,48.6450594 102.649684,50.5222603 101.132813,51.8657031 C99.6331952,53.1938658 97.6607174,54 95.5,54 C93.1527898,54 91.0277898,53.0486051 89.4895924,51.5104076 C87.9513949,49.9722102 87,47.8472102 87,45.5 C87,43.3393106 87.8061132,41.3668571 89.1342017,39.8671976 C90.4776226,38.350225 92.3547906,37.3163664 94.471922,37.0614405 C94.6979269,37.0342271 94.9233824,37.0162855 95.1478483,37.0072672 C95.1920504,36.7882732 95.2462895,36.5703826 95.3107083,36.3542629 C95.7779233,34.7867975 96.7442374,33.4345505 98.0246456,32.4808615 C99.2738582,31.550408 100.822686,31 102.5,31 Z" id="Combined-Shape" stroke={color} strokeWidth="2"></path>
            <circle id="Oval" stroke={color} strokeWidth="2" cx="102.5" cy="38.5" r="7.5"></circle>
            <polyline id="Path-63" stroke="#6B6F70" strokeWidth="2" points="116.392639 63.8295593 104.974915 107.280716 89.5812988 115.949791 54.741333 106.280716"></polyline>
            <polyline id="Path-64" stroke="#6B6F70" strokeWidth="2" points="104.974915 107.280716 92.2767944 103.582581 88.5924072 115"></polyline>
            <line x1="89.5924072" y1="52.544693" x2="86.5669861" y2="56" id="Path-65" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
          </g>
        </svg>
      </div>
    )
  }


  // Forms

  renderForms(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No forms</title>
          <g id="empty-forms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <line x1="52.5" y1="62.5" x2="96.5" y2="62.5" id="Line-16" stroke="#A2AEB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="52.5" y1="76.5" x2="96.5" y2="76.5" id="Line-16" stroke="#A2AEB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="52.5" y1="89.5" x2="96.5" y2="89.5" id="Line-16" stroke="#A2AEB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
            <path d="M111.1203,78 L111.1203,47.5 C111.049154,45.6610967 110.188029,44.071267 108.536926,42.7305109 C106.885823,41.3897549 104.873515,40.6462512 102.5,40.5 L93.0589131,40.5 L93.0589131,36.2060072 L85.0556641,36.2060072 C85.0556641,30.7353357 82.0371094,28 76,28 C69.9628906,28 67.1832479,30.7353357 67.6610718,36.2060072 L60,36.5 L60,40.5 L47.5436401,40.5 C44.9395768,40.941133 42.8366716,41.7744664 41.2349243,43 C39.6331771,44.2255336 38.554869,45.8262198 38,47.8020585 L38,108.76048 L41.2349243,113.949791 L47.5436401,117.713697 L79.3167725,117.713697" id="Path-68" stroke="#A2AEB0" strokeWidth="2"></path>
            <path d="M110.584831,68.5586554 C111.689401,68.5586554 112.689401,69.0063706 113.413258,69.7302282 C114.137116,70.4540859 114.584831,71.4540859 114.584831,72.5586554 L114.584831,72.5586554 L114.584831,127.146868 C114.584831,127.756629 114.445426,128.358319 114.177272,128.905951 L114.177272,128.905951 L111.686721,133.992213 C111.402511,134.572634 110.909945,134.982907 110.343326,135.177023 C109.776707,135.371139 109.136036,135.349097 108.555615,135.064887 C108.088878,134.836343 107.711485,134.45895 107.482941,133.992213 L107.482941,133.992213 L104.992391,128.905951 C104.724236,128.358319 104.584831,127.756629 104.584831,127.146868 L104.584831,127.146868 L104.584831,72.5586554 C104.584831,71.4540859 105.032546,70.4540859 105.756404,69.7302282 C106.480262,69.0063706 107.480262,68.5586554 108.584831,68.5586554 L108.584831,68.5586554 Z" id="Rectangle" stroke={color} strokeWidth="2" transform="translate(109.584831, 104.058655) rotate(45.000000) translate(-109.584831, -104.058655) "></path>
            <polyline id="Path-69" stroke="#A2AEB0" strokeWidth="2" points="93.0589131 40.5 93.0589131 46.7305109 59.9727173 46.7305109 59.9727173 40.5"></polyline>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No forms</title>
          <g id="empty-forms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <line x1="52.5" y1="62.5" x2="96.5" y2="62.5" id="Line-16" stroke="#6B6F70" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="52.5" y1="76.5" x2="96.5" y2="76.5" id="Line-16" stroke="#6B6F70" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="52.5" y1="89.5" x2="96.5" y2="89.5" id="Line-16" stroke="#6B6F70" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
            <path d="M111.1203,78 L111.1203,47.5 C111.049154,45.6610967 110.188029,44.071267 108.536926,42.7305109 C106.885823,41.3897549 104.873515,40.6462512 102.5,40.5 L93.0589131,40.5 L93.0589131,36.2060072 L85.0556641,36.2060072 C85.0556641,30.7353357 82.0371094,28 76,28 C69.9628906,28 67.1832479,30.7353357 67.6610718,36.2060072 L60,36.5 L60,40.5 L47.5436401,40.5 C44.9395768,40.941133 42.8366716,41.7744664 41.2349243,43 C39.6331771,44.2255336 38.554869,45.8262198 38,47.8020585 L38,108.76048 L41.2349243,113.949791 L47.5436401,117.713697 L79.3167725,117.713697" id="Path-68" stroke="#6B6F70" strokeWidth="2"></path>
            <path d="M110.584831,68.5586554 C111.689401,68.5586554 112.689401,69.0063706 113.413258,69.7302282 C114.137116,70.4540859 114.584831,71.4540859 114.584831,72.5586554 L114.584831,72.5586554 L114.584831,127.146868 C114.584831,127.756629 114.445426,128.358319 114.177272,128.905951 L114.177272,128.905951 L111.686721,133.992213 C111.402511,134.572634 110.909945,134.982907 110.343326,135.177023 C109.776707,135.371139 109.136036,135.349097 108.555615,135.064887 C108.088878,134.836343 107.711485,134.45895 107.482941,133.992213 L107.482941,133.992213 L104.992391,128.905951 C104.724236,128.358319 104.584831,127.756629 104.584831,127.146868 L104.584831,127.146868 L104.584831,72.5586554 C104.584831,71.4540859 105.032546,70.4540859 105.756404,69.7302282 C106.480262,69.0063706 107.480262,68.5586554 108.584831,68.5586554 L108.584831,68.5586554 Z" id="Rectangle" stroke={color} strokeWidth="2" transform="translate(109.584831, 104.058655) rotate(45.000000) translate(-109.584831, -104.058655) "></path>
            <polyline id="Path-69" stroke="#6B6F70" strokeWidth="2" points="93.0589131 40.5 93.0589131 46.7305109 59.9727173 46.7305109 59.9727173 40.5"></polyline>
          </g>
        </svg>
      </div>
    )
  }


  // Card

  renderCard(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No card</title>
          <g id="empty-card" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M127,42 C132.522847,42 137,46.4771525 137,52 L137,108 C137,113.522847 132.522847,118 127,118 L34,118 C28.4771525,118 24,113.522847 24,108 L24,52 C24,46.4771525 28.4771525,42 34,42 L127,42 Z M127,44 L34,44 C29.6650857,44 26.1354571,47.4478378 26.0038068,51.7508207 L26,52 L26,108 C26,112.334914 29.4478378,115.864543 33.7508207,115.996193 L34,116 L127,116 C131.334914,116 134.864543,112.552162 134.996193,108.249179 L135,108 L135,52 C135,47.6650857 131.552162,44.1354571 127.249179,44.0038068 L127,44 Z" id="Rectangle" fill="#A2AEB0" fillRule="nonzero"></path>
            <polygon id="Line-11" fill="#A2AEB0" fillRule="nonzero" points="70.5 57.5 70.5 59.5 33.5 59.5 33.5 57.5"></polygon>
            <rect id="Rectangle" fill={color} x="111" y="55" width="15" height="15" rx="2"></rect>
            <polygon id="Line-12" fill="#A2AEB0" fillRule="nonzero" points="53.5 90.5 53.5 92.5 33.5 92.5 33.5 90.5"></polygon>
            <polygon id="Line-12" fill="#A2AEB0" fillRule="nonzero" points="47.5 102.5 47.5 104.5 33.5 104.5 33.5 102.5"></polygon>
            <polygon id="Line-12" fill="#A2AEB0" fillRule="nonzero" points="127.5 102.5 127.5 104.5 113.5 104.5 113.5 102.5"></polygon>
            <polygon id="Line-12" fill="#A2AEB0" fillRule="nonzero" points="78.5 90.5 78.5 92.5 58.5 92.5 58.5 90.5"></polygon>
            <polygon id="Line-12" fill="#A2AEB0" fillRule="nonzero" points="103.5 90.5 103.5 92.5 83.5 92.5 83.5 90.5"></polygon>
            <polygon id="Line-12" fill="#A2AEB0" fillRule="nonzero" points="128.5 90.5 128.5 92.5 108.5 92.5 108.5 90.5"></polygon>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No card</title>
          <g id="empty-card" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M127,42 C132.522847,42 137,46.4771525 137,52 L137,108 C137,113.522847 132.522847,118 127,118 L34,118 C28.4771525,118 24,113.522847 24,108 L24,52 C24,46.4771525 28.4771525,42 34,42 L127,42 Z M127,44 L34,44 C29.6650857,44 26.1354571,47.4478378 26.0038068,51.7508207 L26,52 L26,108 C26,112.334914 29.4478378,115.864543 33.7508207,115.996193 L34,116 L127,116 C131.334914,116 134.864543,112.552162 134.996193,108.249179 L135,108 L135,52 C135,47.6650857 131.552162,44.1354571 127.249179,44.0038068 L127,44 Z" id="Rectangle" fill="#6B6F70" fillRule="nonzero"></path>
            <polygon id="Line-11" fill="#6B6F70" fillRule="nonzero" points="70.5 57.5 70.5 59.5 33.5 59.5 33.5 57.5"></polygon>
            <rect id="Rectangle" fill={color} x="111" y="55" width="15" height="15" rx="2"></rect>
            <polygon id="Line-12" fill="#6B6F70" fillRule="nonzero" points="53.5 90.5 53.5 92.5 33.5 92.5 33.5 90.5"></polygon>
            <polygon id="Line-12" fill="#6B6F70" fillRule="nonzero" points="47.5 102.5 47.5 104.5 33.5 104.5 33.5 102.5"></polygon>
            <polygon id="Line-12" fill="#6B6F70" fillRule="nonzero" points="127.5 102.5 127.5 104.5 113.5 104.5 113.5 102.5"></polygon>
            <polygon id="Line-12" fill="#6B6F70" fillRule="nonzero" points="78.5 90.5 78.5 92.5 58.5 92.5 58.5 90.5"></polygon>
            <polygon id="Line-12" fill="#6B6F70" fillRule="nonzero" points="103.5 90.5 103.5 92.5 83.5 92.5 83.5 90.5"></polygon>
            <polygon id="Line-12" fill="#6B6F70" fillRule="nonzero" points="128.5 90.5 128.5 92.5 108.5 92.5 108.5 90.5"></polygon>
          </g>
        </svg>
      </div>
    )
  }


  // Graph

  renderGraph(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No data</title>
          <g id="empty-graph" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect id="Rectangle" stroke="#979797" strokeWidth="2" x="28" y="99" width="20" height="36" rx="3"></rect>
            <rect id="Rectangle" stroke="#979797" strokeWidth="2" x="56" y="70" width="20" height="65" rx="3"></rect>
            <rect id="Rectangle" stroke="#979797" strokeWidth="2" x="84" y="90" width="20" height="45" rx="3"></rect>
            <rect id="Rectangle" stroke="#979797" strokeWidth="2" x="112" y="62" width="20" height="73" rx="3"></rect>
            <path d="M38,58 C42.418278,58 46,61.581722 46,66 C46,70.418278 42.418278,74 38,74 C33.581722,74 30,70.418278 30,66 C30,61.581722 33.581722,58 38,58 Z M38,60 C34.6862915,60 32,62.6862915 32,66 C32,69.3137085 34.6862915,72 38,72 C41.3137085,72 44,69.3137085 44,66 C44,62.6862915 41.3137085,60 38,60 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <path d="M66,30 C70.418278,30 74,33.581722 74,38 C74,42.418278 70.418278,46 66,46 C61.581722,46 58,42.418278 58,38 C58,33.581722 61.581722,30 66,30 Z M66,32 C62.6862915,32 60,34.6862915 60,38 C60,41.3137085 62.6862915,44 66,44 C69.3137085,44 72,41.3137085 72,38 C72,34.6862915 69.3137085,32 66,32 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <path d="M94,50 C98.418278,50 102,53.581722 102,58 C102,62.418278 98.418278,66 94,66 C89.581722,66 86,62.418278 86,58 C86,53.581722 89.581722,50 94,50 Z M94,52 C90.6862915,52 88,54.6862915 88,58 C88,61.3137085 90.6862915,64 94,64 C97.3137085,64 100,61.3137085 100,58 C100,54.6862915 97.3137085,52 94,52 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <path d="M122,22 C126.418278,22 130,25.581722 130,30 C130,34.418278 126.418278,38 122,38 C117.581722,38 114,34.418278 114,30 C114,25.581722 117.581722,22 122,22 Z M122,24 C118.686292,24 116,26.6862915 116,30 C116,33.3137085 118.686292,36 122,36 C125.313708,36 128,33.3137085 128,30 C128,26.6862915 125.313708,24 122,24 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <polygon id="Line-13" fill={color} fillRule="nonzero" points="117.45961 34.0863633 118.913637 35.4596104 100.54039 54.9136367 99.0863633 53.5403896"></polygon>
            <polygon id="Path-36" fill={color} fillRule="nonzero" points="60.4940683 42.3245852 61.9689444 43.6754148 44.1090201 63.1754148 42.634144 61.8245852"></polygon>
            <polygon id="Path-37" fill={color} fillRule="nonzero" points="71.9861432 41.1811256 88.3929425 52.6811256 87.244997 54.3188744 70.8381977 42.8188744"></polygon>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No data</title>
          <g id="empty-graph" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect id="Rectangle" stroke="#6B6F70" strokeWidth="2" x="28" y="99" width="20" height="36" rx="3"></rect>
            <rect id="Rectangle" stroke="#6B6F70" strokeWidth="2" x="56" y="70" width="20" height="65" rx="3"></rect>
            <rect id="Rectangle" stroke="#6B6F70" strokeWidth="2" x="84" y="90" width="20" height="45" rx="3"></rect>
            <rect id="Rectangle" stroke="#6B6F70" strokeWidth="2" x="112" y="62" width="20" height="73" rx="3"></rect>
            <path d="M38,58 C42.418278,58 46,61.581722 46,66 C46,70.418278 42.418278,74 38,74 C33.581722,74 30,70.418278 30,66 C30,61.581722 33.581722,58 38,58 Z M38,60 C34.6862915,60 32,62.6862915 32,66 C32,69.3137085 34.6862915,72 38,72 C41.3137085,72 44,69.3137085 44,66 C44,62.6862915 41.3137085,60 38,60 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <path d="M66,30 C70.418278,30 74,33.581722 74,38 C74,42.418278 70.418278,46 66,46 C61.581722,46 58,42.418278 58,38 C58,33.581722 61.581722,30 66,30 Z M66,32 C62.6862915,32 60,34.6862915 60,38 C60,41.3137085 62.6862915,44 66,44 C69.3137085,44 72,41.3137085 72,38 C72,34.6862915 69.3137085,32 66,32 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <path d="M94,50 C98.418278,50 102,53.581722 102,58 C102,62.418278 98.418278,66 94,66 C89.581722,66 86,62.418278 86,58 C86,53.581722 89.581722,50 94,50 Z M94,52 C90.6862915,52 88,54.6862915 88,58 C88,61.3137085 90.6862915,64 94,64 C97.3137085,64 100,61.3137085 100,58 C100,54.6862915 97.3137085,52 94,52 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <path d="M122,22 C126.418278,22 130,25.581722 130,30 C130,34.418278 126.418278,38 122,38 C117.581722,38 114,34.418278 114,30 C114,25.581722 117.581722,22 122,22 Z M122,24 C118.686292,24 116,26.6862915 116,30 C116,33.3137085 118.686292,36 122,36 C125.313708,36 128,33.3137085 128,30 C128,26.6862915 125.313708,24 122,24 Z" id="Oval" fill={color} fillRule="nonzero"></path>
            <polygon id="Line-13" fill={color} fillRule="nonzero" points="117.45961 34.0863633 118.913637 35.4596104 100.54039 54.9136367 99.0863633 53.5403896"></polygon>
            <polygon id="Path-36" fill={color} fillRule="nonzero" points="60.4940683 42.3245852 61.9689444 43.6754148 44.1090201 63.1754148 42.634144 61.8245852"></polygon>
            <polygon id="Path-37" fill={color} fillRule="nonzero" points="71.9861432 41.1811256 88.3929425 52.6811256 87.244997 54.3188744 70.8381977 42.8188744"></polygon>
          </g>
        </svg>
      </div>
    )
  }


  // Health

  renderHealth(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No health log</title>
          <g id="empty-health" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M83.2978544,119.946907 L82.195817,120.794022 L82.0360385,120.585861 L81.8763271,120.794022 L80.7742898,119.946907 C65.6472791,108.2716 54.4183676,97.6258892 47.1197357,87.9999821 L49.6435401,88.0001373 C56.8022299,97.1114147 67.5817519,107.239573 82.0117615,118.375587 L82.0360385,118.393861 L82.0603827,118.375587 C96.4903444,107.23961 107.269843,97.1114819 114.428533,88.0002281 L116.952408,87.9999821 C109.653777,97.6258892 98.4248651,108.2716 83.2978544,119.946907 Z M124.528302,53.7597098 C128.041423,60.2552045 127.939555,67.665772 124.250607,75.9999044 L122.04622,75.9997867 C125.828933,67.8953513 126.060243,60.7962079 122.769121,54.711172 C114.597441,39.602354 101.41419,37.6647305 82.6817214,49.0037736 L82.1123717,49.351445 L82.0360385,49.2268609 L81.9597725,49.351445 L81.3904228,49.0037736 C62.6579545,37.6647305 49.4747034,39.602354 41.3030235,54.711172 C38.0118711,60.7962637 38.2432154,67.8954816 42.0260287,76.0000098 L39.8215373,75.9999044 C36.1325893,67.665772 36.030721,60.2552045 39.5438419,53.7597098 C48.2613794,37.6416424 62.6011899,35.4422999 82.0358916,47.0582236 C101.471446,35.4423753 115.810914,37.6419187 124.528302,53.7597098 Z" id="Combined-Shape" fill="#979797" fillRule="nonzero"></path>
            <path d="M91.1303549,57.72692 C91.3916301,56.8065139 92.6477052,56.7578454 93.0071666,57.5940599 L93.0504489,57.7135752 L98.958,77.476 L103.055066,72.3739023 C103.45567,71.8749886 104.204122,71.8814183 104.600835,72.3568184 L104.67528,72.4581406 L110.182,80.9999988 L135,80.9999988 C135.512836,80.9999988 135.935507,81.3860402 135.993272,81.8833789 L136,81.9999988 C136,82.5128358 135.61396,82.9355072 135.116621,82.9932723 L135,82.9999988 L109.637207,82.9999988 C109.339883,82.9999988 109.060361,82.8678911 108.871778,82.6435408 L108.796738,82.5418594 L103.746,74.707 L99.325521,80.2130828 C98.847534,80.8083697 97.9226354,80.6638407 97.630346,79.9904946 L97.5876737,79.8734099 L92.117,61.574 L81.9834265,97.27308 C81.7306585,98.1635174 80.5297168,98.2481613 80.1323903,97.4593969 L80.083355,97.3464181 L68.662,66.42 L60.7310145,82.4436431 C60.5810793,82.7465273 60.2899962,82.9505413 59.9601292,82.9921385 L59.834811,82.9999988 L29.0227661,82.9999988 C28.4704814,82.9999988 28.0227661,82.5522847 28.0227661,81.9999988 C28.0227661,81.4871642 28.4088063,81.0644928 28.906145,81.0067277 L29.0227661,80.9999988 L59.213,80.9999988 L67.9004884,63.4524608 C68.2712311,62.7035234 69.3238381,62.7227167 69.6858252,63.437293 L69.7347721,63.5496858 L80.896,93.776 L91.1303549,57.72692 Z" id="Path-23" fill={color} fillRule="nonzero"></path>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No health log</title>
          <g id="empty-health" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M83.2978544,119.946907 L82.195817,120.794022 L82.0360385,120.585861 L81.8763271,120.794022 L80.7742898,119.946907 C65.6472791,108.2716 54.4183676,97.6258892 47.1197357,87.9999821 L49.6435401,88.0001373 C56.8022299,97.1114147 67.5817519,107.239573 82.0117615,118.375587 L82.0360385,118.393861 L82.0603827,118.375587 C96.4903444,107.23961 107.269843,97.1114819 114.428533,88.0002281 L116.952408,87.9999821 C109.653777,97.6258892 98.4248651,108.2716 83.2978544,119.946907 Z M124.528302,53.7597098 C128.041423,60.2552045 127.939555,67.665772 124.250607,75.9999044 L122.04622,75.9997867 C125.828933,67.8953513 126.060243,60.7962079 122.769121,54.711172 C114.597441,39.602354 101.41419,37.6647305 82.6817214,49.0037736 L82.1123717,49.351445 L82.0360385,49.2268609 L81.9597725,49.351445 L81.3904228,49.0037736 C62.6579545,37.6647305 49.4747034,39.602354 41.3030235,54.711172 C38.0118711,60.7962637 38.2432154,67.8954816 42.0260287,76.0000098 L39.8215373,75.9999044 C36.1325893,67.665772 36.030721,60.2552045 39.5438419,53.7597098 C48.2613794,37.6416424 62.6011899,35.4422999 82.0358916,47.0582236 C101.471446,35.4423753 115.810914,37.6419187 124.528302,53.7597098 Z" id="Combined-Shape" fill="#6B6F70" fillRule="nonzero"></path>
            <path d="M91.1303549,57.72692 C91.3916301,56.8065139 92.6477052,56.7578454 93.0071666,57.5940599 L93.0504489,57.7135752 L98.958,77.476 L103.055066,72.3739023 C103.45567,71.8749886 104.204122,71.8814183 104.600835,72.3568184 L104.67528,72.4581406 L110.182,80.9999988 L135,80.9999988 C135.512836,80.9999988 135.935507,81.3860402 135.993272,81.8833789 L136,81.9999988 C136,82.5128358 135.61396,82.9355072 135.116621,82.9932723 L135,82.9999988 L109.637207,82.9999988 C109.339883,82.9999988 109.060361,82.8678911 108.871778,82.6435408 L108.796738,82.5418594 L103.746,74.707 L99.325521,80.2130828 C98.847534,80.8083697 97.9226354,80.6638407 97.630346,79.9904946 L97.5876737,79.8734099 L92.117,61.574 L81.9834265,97.27308 C81.7306585,98.1635174 80.5297168,98.2481613 80.1323903,97.4593969 L80.083355,97.3464181 L68.662,66.42 L60.7310145,82.4436431 C60.5810793,82.7465273 60.2899962,82.9505413 59.9601292,82.9921385 L59.834811,82.9999988 L29.0227661,82.9999988 C28.4704814,82.9999988 28.0227661,82.5522847 28.0227661,81.9999988 C28.0227661,81.4871642 28.4088063,81.0644928 28.906145,81.0067277 L29.0227661,80.9999988 L59.213,80.9999988 L67.9004884,63.4524608 C68.2712311,62.7035234 69.3238381,62.7227167 69.6858252,63.437293 L69.7347721,63.5496858 L80.896,93.776 L91.1303549,57.72692 Z" id="Path-23" fill={color} fillRule="nonzero"></path>
          </g>
        </svg>
      </div>
    )
  }


  // Documents

  renderDocuments(color) {
    return (
      <div>
        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-light'>
          <title>No documents</title>
          <g id="empty-documents" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="paper" transform="translate(31.000000, 32.000000)" strokeWidth="2">
              <g id="g151" transform="translate(49.500000, 49.000000) scale(-1, 1) rotate(-180.000000) translate(-49.500000, -49.000000) ">
                <g id="g157" stroke="#979797">
                  <path d="M10.9511127,39.518098 L0.0583239532,69.7970914 C-0.128720313,70.3247374 0.149156952,70.9089525 0.676824962,71.1017654 C6.44073645,73.1721137 12.4544387,75.1972466 18.5249116,77.2391112 M76.2956945,18.0596966 L41.4958996,5.55215556 L26.2234066,0.0613703417 C25.6959377,-0.131641734 25.1114991,0.146423135 24.9186782,0.673869963 L13.2430524,33.1481019" id="path159"></path>
                </g>
                <g id="g161" transform="translate(17.431790, 10.286303)" stroke="#979797">
                  <path d="M65.7455599,8.95323711 L29.6069743,2.75892387 L13.6082157,0.0135312616 C13.0580386,-0.0770987637 12.5247931,0.297173363 12.4281835,0.847526681 L6.52244501,35.306855 L0.0151371328,73.2435876 C-0.0812733304,73.799319 0.293213593,74.326965 0.843390657,74.4233714 L19.0773183,77.5488126" id="path163"></path>
                </g>
                <g id="g165" transform="translate(36.509068, 19.239520)" stroke="#979797">
                  <path d="M0,37.539554 L0,77.6998097 C0,78.2840247 0.476674046,78.7604797 1.06091349,78.7604797 L48.4950606,78.7604797 L49.2892517,78.7604797 C49.6125455,78.7604797 49.7033785,78.6300123 50.2537547,78.1081427 L61.8895415,67.0130358 C62.3604389,66.5535117 62.4909117,66.5025198 62.4909117,66.1961704 L62.4909117,65.5382562 L62.4909117,18.2024928 L62.4909117,1.06644649 C62.4909117,0.482231409 62.0144369,0 61.4301974,0 L17.9956902,0 L1.06091349,0 C0.476674046,0 0,0.482231409 0,1.06644649 L0,30.6080502" id="path167"></path>
                </g>
                <g id="g169" transform="translate(85.004149, 85.269489)" stroke="#979797">
                  <path d="M0,12.7304711 L0.0227082496,2.20822556 C0.0227082496,-0.145764851 2.17859759,0.00183261838 3.21660363,0.00183261838 L13.9958512,0.00183261838" id="path171"></path>
                </g>
                <g id="g173" transform="translate(46.698200, 76.197942)" stroke={color}>
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path175"></line>
                </g>
                <g id="g177" transform="translate(46.698200, 65.585026)" stroke="#979797">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path179"></line>
                </g>
                <g id="g181" transform="translate(46.698200, 54.972091)" stroke="#979797">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path183"></line>
                </g>
                <g id="g185" transform="translate(46.698200, 44.359175)" stroke="#979797">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path187"></line>
                </g>
                <g id="g189" transform="translate(46.698200, 33.746141)" stroke="#979797">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path191"></line>
                </g>
              </g>
            </g>
          </g>
        </svg>

        <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: 80, height: 80}} className='show-dark'>
          <title>No documents</title>
          <g id="empty-documents" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="paper" transform="translate(31.000000, 32.000000)" strokeWidth="2">
              <g id="g151" transform="translate(49.500000, 49.000000) scale(-1, 1) rotate(-180.000000) translate(-49.500000, -49.000000) ">
                <g id="g157" stroke="#6B6F70">
                  <path d="M10.9511127,39.518098 L0.0583239532,69.7970914 C-0.128720313,70.3247374 0.149156952,70.9089525 0.676824962,71.1017654 C6.44073645,73.1721137 12.4544387,75.1972466 18.5249116,77.2391112 M76.2956945,18.0596966 L41.4958996,5.55215556 L26.2234066,0.0613703417 C25.6959377,-0.131641734 25.1114991,0.146423135 24.9186782,0.673869963 L13.2430524,33.1481019" id="path159"></path>
                </g>
                <g id="g161" transform="translate(17.431790, 10.286303)" stroke="#6B6F70">
                  <path d="M65.7455599,8.95323711 L29.6069743,2.75892387 L13.6082157,0.0135312616 C13.0580386,-0.0770987637 12.5247931,0.297173363 12.4281835,0.847526681 L6.52244501,35.306855 L0.0151371328,73.2435876 C-0.0812733304,73.799319 0.293213593,74.326965 0.843390657,74.4233714 L19.0773183,77.5488126" id="path163"></path>
                </g>
                <g id="g165" transform="translate(36.509068, 19.239520)" stroke="#6B6F70">
                  <path d="M0,37.539554 L0,77.6998097 C0,78.2840247 0.476674046,78.7604797 1.06091349,78.7604797 L48.4950606,78.7604797 L49.2892517,78.7604797 C49.6125455,78.7604797 49.7033785,78.6300123 50.2537547,78.1081427 L61.8895415,67.0130358 C62.3604389,66.5535117 62.4909117,66.5025198 62.4909117,66.1961704 L62.4909117,65.5382562 L62.4909117,18.2024928 L62.4909117,1.06644649 C62.4909117,0.482231409 62.0144369,0 61.4301974,0 L17.9956902,0 L1.06091349,0 C0.476674046,0 0,0.482231409 0,1.06644649 L0,30.6080502" id="path167"></path>
                </g>
                <g id="g169" transform="translate(85.004149, 85.269489)" stroke="#6B6F70">
                  <path d="M0,12.7304711 L0.0227082496,2.20822556 C0.0227082496,-0.145764851 2.17859759,0.00183261838 3.21660363,0.00183261838 L13.9958512,0.00183261838" id="path171"></path>
                </g>
                <g id="g173" transform="translate(46.698200, 76.197942)" stroke={color}>
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path175"></line>
                </g>
                <g id="g177" transform="translate(46.698200, 65.585026)" stroke="#6B6F70">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path179"></line>
                </g>
                <g id="g181" transform="translate(46.698200, 54.972091)" stroke="#6B6F70">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path183"></line>
                </g>
                <g id="g185" transform="translate(46.698200, 44.359175)" stroke="#6B6F70">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path187"></line>
                </g>
                <g id="g189" transform="translate(46.698200, 33.746141)" stroke="#6B6F70">
                  <line x1="42.7140183" y1="0.52688172" x2="0" y2="0.52688172" id="path191"></line>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }


  // Locations

  renderLocations(color) {
    return (
      <div>
        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: 80, height: 80}} className='show-light'>
          <g id="empty-locations">
          <g id="Subtract">
          <mask id="path-1-outside-1_1639_43" maskUnits="userSpaceOnUse" x="48" y="99.5176" width="63" height="31" fill="black">
          <rect fill="white" x="48" y="99.5176" width="63" height="31"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M71.121 102.518L80 115.333L88.7831 102.656C100.467 104.35 108.889 109.137 108.889 114.778C108.889 121.835 95.7062 127.555 79.4444 127.555C63.1827 127.555 50 121.835 50 114.778C50 108.975 58.9126 104.076 71.121 102.518Z"/>
          </mask>
          <path d="M80 115.333L78.356 116.472L80 118.845L81.644 116.472L80 115.333ZM71.121 102.518L72.765 101.379L72.0731 100.38L70.8678 100.534L71.121 102.518ZM88.7831 102.656L89.0701 100.677L87.8444 100.499L87.1391 101.517L88.7831 102.656ZM81.644 114.194L72.765 101.379L69.4771 103.657L78.356 116.472L81.644 114.194ZM87.1391 101.517L78.356 114.194L81.644 116.472L90.4271 103.795L87.1391 101.517ZM88.496 104.635C94.1694 105.458 98.9237 107.017 102.198 108.958C105.557 110.949 106.889 113.03 106.889 114.778H110.889C110.889 110.885 108.01 107.753 104.237 105.517C100.38 103.23 95.081 101.548 89.0701 100.677L88.496 104.635ZM106.889 114.778C106.889 115.874 106.385 117.081 105.143 118.355C103.891 119.639 101.983 120.887 99.4686 121.978C94.4462 124.158 87.3681 125.555 79.4444 125.555V129.555C87.7825 129.555 95.4266 128.093 101.061 125.648C103.875 124.426 106.279 122.921 108.007 121.147C109.745 119.365 110.889 117.209 110.889 114.778H106.889ZM79.4444 125.555C71.5208 125.555 64.4427 124.158 59.4203 121.978C56.9059 120.887 54.9975 119.639 53.7461 118.355C52.5038 117.081 52 115.874 52 114.778H48C48 117.209 49.1441 119.365 50.8816 121.147C52.6102 122.921 55.0139 124.426 57.8279 125.648C63.4623 128.093 71.1063 129.555 79.4444 129.555V125.555ZM52 114.778C52 112.978 53.4163 110.826 56.9745 108.793C60.4349 106.816 65.4421 105.259 71.3743 104.501L70.8678 100.534C64.5915 101.335 59.0382 103.007 54.9902 105.32C51.0399 107.576 48 110.774 48 114.778H52Z" fill={color} mask="url(#path-1-outside-1_1639_43)"/>
          </g>
          <g id="Union">
          <mask id="path-3-inside-2_1639_43" fill="white">
          <path fillRule="evenodd" clipRule="evenodd" d="M105.486 77.8333C108.347 73.2378 110 67.8119 110 62C110 45.4315 96.5685 32 80 32C63.4315 32 50 45.4315 50 62C50 67.8119 51.6527 73.2378 54.5137 77.8333H54.0192L80 115.333L105.981 77.8333H105.486Z"/>
          </mask>
          <path d="M105.486 77.8333L103.788 76.7763L101.885 79.8333H105.486V77.8333ZM54.5137 77.8333V79.8333H58.1148L56.2116 76.7763L54.5137 77.8333ZM54.0192 77.8333V75.8333H50.2005L52.3752 78.9723L54.0192 77.8333ZM80 115.333L78.356 116.472L80 118.845L81.644 116.472L80 115.333ZM105.981 77.8333L107.625 78.9723L109.8 75.8333H105.981V77.8333ZM108 62C108 67.4275 106.458 72.4887 103.788 76.7763L107.184 78.8904C110.237 73.9868 112 68.1962 112 62H108ZM80 34C95.464 34 108 46.536 108 62H112C112 44.3269 97.6731 30 80 30V34ZM52 62C52 46.536 64.536 34 80 34V30C62.3269 30 48 44.3269 48 62H52ZM56.2116 76.7763C53.5423 72.4887 52 67.4275 52 62H48C48 68.1962 49.7631 73.9868 52.8159 78.8904L56.2116 76.7763ZM54.0192 79.8333H54.5137V75.8333H54.0192V79.8333ZM81.644 114.194L55.6632 76.6943L52.3752 78.9723L78.356 116.472L81.644 114.194ZM104.337 76.6943L78.356 114.194L81.644 116.472L107.625 78.9723L104.337 76.6943ZM105.486 79.8333H105.981V75.8333H105.486V79.8333Z" fill="#979797" mask="url(#path-3-inside-2_1639_43)"/>
          </g>
          <circle id="Ellipse 3" cx="80.5" cy="61.5" r="12.5" stroke="#979797" strokeWidth="2"/>
          </g>
        </svg>

        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: 80, height: 80}} className='show-dark'>
          <g id="empty-locations">
          <g id="Subtract">
          <mask id="path-1-outside-1_1639_43" maskUnits="userSpaceOnUse" x="48" y="99.5176" width="63" height="31" fill="black">
          <rect fill="white" x="48" y="99.5176" width="63" height="31"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M71.121 102.518L80 115.333L88.7831 102.656C100.467 104.35 108.889 109.137 108.889 114.778C108.889 121.835 95.7062 127.555 79.4444 127.555C63.1827 127.555 50 121.835 50 114.778C50 108.975 58.9126 104.076 71.121 102.518Z"/>
          </mask>
          <path d="M80 115.333L78.356 116.472L80 118.845L81.644 116.472L80 115.333ZM71.121 102.518L72.765 101.379L72.0731 100.38L70.8678 100.534L71.121 102.518ZM88.7831 102.656L89.0701 100.677L87.8444 100.499L87.1391 101.517L88.7831 102.656ZM81.644 114.194L72.765 101.379L69.4771 103.657L78.356 116.472L81.644 114.194ZM87.1391 101.517L78.356 114.194L81.644 116.472L90.4271 103.795L87.1391 101.517ZM88.496 104.635C94.1694 105.458 98.9237 107.017 102.198 108.958C105.557 110.949 106.889 113.03 106.889 114.778H110.889C110.889 110.885 108.01 107.753 104.237 105.517C100.38 103.23 95.081 101.548 89.0701 100.677L88.496 104.635ZM106.889 114.778C106.889 115.874 106.385 117.081 105.143 118.355C103.891 119.639 101.983 120.887 99.4686 121.978C94.4462 124.158 87.3681 125.555 79.4444 125.555V129.555C87.7825 129.555 95.4266 128.093 101.061 125.648C103.875 124.426 106.279 122.921 108.007 121.147C109.745 119.365 110.889 117.209 110.889 114.778H106.889ZM79.4444 125.555C71.5208 125.555 64.4427 124.158 59.4203 121.978C56.9059 120.887 54.9975 119.639 53.7461 118.355C52.5038 117.081 52 115.874 52 114.778H48C48 117.209 49.1441 119.365 50.8816 121.147C52.6102 122.921 55.0139 124.426 57.8279 125.648C63.4623 128.093 71.1063 129.555 79.4444 129.555V125.555ZM52 114.778C52 112.978 53.4163 110.826 56.9745 108.793C60.4349 106.816 65.4421 105.259 71.3743 104.501L70.8678 100.534C64.5915 101.335 59.0382 103.007 54.9902 105.32C51.0399 107.576 48 110.774 48 114.778H52Z" fill={color} mask="url(#path-1-outside-1_1639_43)"/>
          </g>
          <g id="Union">
          <mask id="path-3-inside-2_1639_43" fill="white">
          <path fillRule="evenodd" clipRule="evenodd" d="M105.486 77.8333C108.347 73.2378 110 67.8119 110 62C110 45.4315 96.5685 32 80 32C63.4315 32 50 45.4315 50 62C50 67.8119 51.6527 73.2378 54.5137 77.8333H54.0192L80 115.333L105.981 77.8333H105.486Z"/>
          </mask>
          <path d="M105.486 77.8333L103.788 76.7763L101.885 79.8333H105.486V77.8333ZM54.5137 77.8333V79.8333H58.1148L56.2116 76.7763L54.5137 77.8333ZM54.0192 77.8333V75.8333H50.2005L52.3752 78.9723L54.0192 77.8333ZM80 115.333L78.356 116.472L80 118.845L81.644 116.472L80 115.333ZM105.981 77.8333L107.625 78.9723L109.8 75.8333H105.981V77.8333ZM108 62C108 67.4275 106.458 72.4887 103.788 76.7763L107.184 78.8904C110.237 73.9868 112 68.1962 112 62H108ZM80 34C95.464 34 108 46.536 108 62H112C112 44.3269 97.6731 30 80 30V34ZM52 62C52 46.536 64.536 34 80 34V30C62.3269 30 48 44.3269 48 62H52ZM56.2116 76.7763C53.5423 72.4887 52 67.4275 52 62H48C48 68.1962 49.7631 73.9868 52.8159 78.8904L56.2116 76.7763ZM54.0192 79.8333H54.5137V75.8333H54.0192V79.8333ZM81.644 114.194L55.6632 76.6943L52.3752 78.9723L78.356 116.472L81.644 114.194ZM104.337 76.6943L78.356 114.194L81.644 116.472L107.625 78.9723L104.337 76.6943ZM105.486 79.8333H105.981V75.8333H105.486V79.8333Z" fill="#6B6F70" mask="url(#path-3-inside-2_1639_43)"/>
          </g>
          <circle id="Ellipse 3" cx="80.5" cy="61.5" r="12.5" stroke="#6B6F70" strokeWidth="2"/>
          </g>
        </svg>
      </div>
    )
  }


  // Action delete

  renderActionDelete(color) {
    return (
      <div>
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <title>Delete</title>
          <g id="action-delete" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M15.3615745,6.5 L14.4855924,16.1358036 C14.4504733,16.522113 14.2709646,16.8631621 14.00326,17.1076338 C13.7355554,17.3521056 13.3796549,17.5 12.9917525,17.5 L12.9917525,17.5 L6.82642928,17.5 C6.43852687,17.5 6.08262644,17.3521056 5.81492184,17.1076338 C5.54721725,16.8631621 5.3677085,16.522113 5.33258947,16.1358036 L5.33258947,16.1358036 L4.45660732,6.5 L15.3615745,6.5 Z" id="Rectangle" stroke={color}></path>
            <path d="M12.1679902,2.5 L13.2744713,4.5 L6.72552869,4.5 L7.3647659,2.82200234 L12.1679902,2.5 Z" id="Rectangle" stroke={color}></path>
            <line x1="7.5" y1="8.33333333" x2="7.5" y2="15.6666667" id="Line-8"  stroke={color} strokeLinecap="square"></line>
            <line x1="12.5" y1="8.33333333" x2="12.5" y2="15.6666667" id="Line-8" stroke={color} strokeLinecap="square"></line>
            <line x1="10" y1="8.33333333" x2="10" y2="15.6666667" id="Line-8" stroke={color} strokeLinecap="square"></line>
          </g>
        </svg>
      </div>
    )
  }


  // Action duplicate

  renderActionDuplicate(color) {
    return (
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Duplicate</title>
        <g id="action-duplicate" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect id="Rectangle" stroke={color} x="8.5" y="7.5" width="8" height="10" rx="2"></rect>
          <path d="M10,2.5 C10.4142136,2.5 10.7892136,2.66789322 11.0606602,2.93933983 C11.3321068,3.21078644 11.5,3.58578644 11.5,4 L11.5,4 L11.5,7.5 L10,7.5 C9.58578644,7.5 9.21078644,7.66789322 8.93933983,7.93933983 C8.66789322,8.21078644 8.5,8.58578644 8.5,9 L8.5,9 L8.5,12.5 L5,12.5 C4.58578644,12.5 4.21078644,12.3321068 3.93933983,12.0606602 C3.66789322,11.7892136 3.5,11.4142136 3.5,11 L3.5,11 L3.5,4 C3.5,3.58578644 3.66789322,3.21078644 3.93933983,2.93933983 C4.21078644,2.66789322 4.58578644,2.5 5,2.5 L5,2.5 Z" id="Rectangle" stroke={color}></path>
          <path d="M12.5,11 L12.5,14 M11,12.5 L14,12.5" id="Combined-Shape" stroke={color} strokeLinecap="square"></path>
        </g>
      </svg>
    )
  }


  // Action swap

  renderActionSwap(color) {
    return (
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Swap</title>
        <g id="action-swap" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect id="Rectangle" stroke={color} x="4.86363636" y="3.5" width="10.4545455" height="13" rx="2"></rect>
          <line x1="8.5" y1="8" x2="12.5" y2="8" id="Line-9" stroke={color} strokeLinecap="square"></line>
          <polyline id="Path-17" stroke={color} transform="translate(11.039514, 8.039514) rotate(-135.000000) translate(-11.039514, -8.039514) " points="9.65520579 6.53951365 9.65520579 9.53951365 12.4238215 9.53951365"></polyline>
          <line x1="7.5" y1="12.5" x2="11.5" y2="12.5" id="Line-9" stroke={color} strokeLinecap="square"></line>
          <polyline id="Path-17" stroke={color} transform="translate(9.039514, 12.500000) rotate(45.000000) translate(-9.039514, -12.500000) " points="7.65520579 11 7.65520579 14 10.4238215 14"></polyline>
        </g>
      </svg>
    )
  }


  // Action notes

  renderActionNotes(color) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4.5" y="3.5" width="11" height="14" rx="1.5" stroke={color}/>
        <line x1="7" y1="6.5" x2="13" y2="6.5" stroke={color}/>
        <line x1="7" y1="8.5" x2="13" y2="8.5" stroke={color}/>
        <line x1="7" y1="10.5" x2="13" y2="10.5" stroke={color}/>
      </svg>
    )
  }


  renderActionNotes2(color) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6 3C4.89543 3 4 3.89543 4 5V16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V5C16 3.89543 15.1046 3 14 3H6ZM7 7H13V6H7V7ZM7 9H13V8H7V9ZM13 11H7V10H13V11Z" fill={color}/>
      </svg>
    )
  }


}



const b = new HelperEmpty();
export default b;