import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



const DataListHeader  = [
  {
    id: 'activeclients',
    title: 'header:title.clients',
    single: 'header:title.clients1',
    empty: 'header:title.clientsno',
  },
  {
    id: 'inactiveclients',
    title: 'header:title.inactiveclients',
    single: 'header:title.inactiveclients1',
    empty: 'header:title.inactiveclientsno',
  },
  {
    id: 'clientgroups',
    title: 'header:title.clientgroups',
    single: 'header:title.clientgroups1',
    empty: 'header:title.clientgroupsno',
  },
  {
    id: 'rectemplates',
    title: 'header:title.recurring',
    single: 'header:title.recurring1',
    empty: 'header:title.recurringno',
  },
  {
    id: 'recexptemplates',
    title: 'header:title.expiredrecurring',
    single: 'header:title.expiredrecurring1',
    empty: 'header:title.expiredrecurringno',
  },
  {
    id: 'programs',
    title: 'header:title.programs',
    single: 'header:title.programs1',
    empty: 'header:title.programsno',
  },
  {
    id: 'benchmark',
    title: 'header:title.benchmark',
    single: 'header:title.benchmark1',
    empty: 'header:title.benchmarkno',
  },
  {
    id: 'plans',
    title: 'header:title.plans',
    single: 'header:title.plans1',
    empty: 'header:title.plansno',
  },
  {
    id: 'nutrition',
    title: 'header:title.nutrition',
    single: 'header:title.nutrition1',
    empty: 'header:title.nutritionno',
  },
  {
    id: 'nutritioninactive',
    title: 'header:title.inactivenutrition',
    single: 'header:title.inactivenutrition1',
    empty: 'header:title.inactivenutritionno',
  },
  {
    id: 'habits',
    title: 'header:title.habits',
    single: 'header:title.habits1',
    empty: 'header:title.habitsno',
  },
  {
    id: 'payments',
    title: 'header:title.payments',
    single: 'header:title.payments1',
    empty: 'header:title.paymentsno',
  },
  {
    id: 'debits',
    title: 'header:title.memberships',
    single: 'header:title.memberships1',
    empty: 'header:title.membershipsno',
  },
  {
    id: 'products',
    title: 'header:title.products',
    single: 'header:title.products1',
    empty: 'header:title.productsno',
  },
  {
    id: 'invoices',
    title: 'header:title.invoices',
    single: 'header:title.invoices1',
    empty: 'header:title.invoicesno',
  },
  {
    id: 'expenses',
    title: 'header:title.expenses',
    single: 'header:title.expenses1',
    empty: 'header:title.expensesno',
  },
  {
    id: 'exppacks',
    title: 'header:title.packs',
    single: 'header:title.packs1',
    empty: 'header:title.packsno',
  },
  {
    id: 'unpaidinvoices',
    title: 'header:title.unpaidinvoices',
    single: 'header:title.unpaidinvoices1',
    empty: 'header:title.unpaidinvoicesno',
  },
  {
    id: 'planssent',
    title: 'header:title.planssent',
    single: 'header:title.planssent1',
    empty: 'header:title.planssentno',
  },
  {
    id: 'programssent',
    title: 'header:title.programssent',
    single: 'header:title.programssent1',
    empty: 'header:title.programssentno',
  },
  {
    id: 'staff',
    title: 'header:title.staff',
    single: 'header:title.staff1',
    empty: 'header:title.staffno',
  },
  {
    id: 'leads',
    title: 'header:title.leads',
    single: 'header:title.leads1',
    empty: 'header:title.leadsno',
  },
  {
    id: 'emails',
    title: 'header:title.emails',
    single: 'header:title.emails1',
    empty: 'header:title.emailsno',
  },
  {
    id: 'forms',
    title: 'header:title.forms',
    single: 'header:title.forms1',
    empty: 'header:title.formsno',
  },
  {
    id: 'documents',
    title: 'header:title.documents',
    single: 'header:title.documents1',
    empty: 'header:title.documentsno',
  },
  {
    id: 'locations',
    title: 'header:title.locations',
    single: 'header:title.locations1',
    empty: 'header:title.locationsno',
  },
]



class ListHeader extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      number: props.number ?? 0
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      number: props.number ?? 0
    }
  }


  render() {
    var data = null
    for(var item of DataListHeader) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      if(this.state.number === 0) {
        return <h3>{lang.t(data.empty)}</h3>
      } else if(this.state.number === 1) {
        return <h3>{lang.t(data.single)}</h3>
      } else {
        return <h3>{this.state.number} {lang.t(data.title)}</h3>
      }
    }
  }
}


export default withTranslation(['header'])(ListHeader);