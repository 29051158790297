import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



const DataOverview = [
  {
    id: 'activeclients',
    title: 'overview:activeclients',
  },
  {
    id: 'sessions',
    title: 'overview:sessions',
  },
  {
    id: 'group',
    title: 'overview:group',
  },
  {
    id: 'training',
    title: 'overview:training',
  },
  {
    id: 'clientgroups',
    title: 'overview:clientgroups',
  },
  {
    id: 'progtime',
    title: 'overview:progtime',
  },
  {
    id: 'progblocks',
    title: 'overview:progblocks',
  },
  {
    id: 'progtotal',
    title: 'overview:progtotal',
  },
  {
    id: 'plans',
    title: 'overview:plans',
  },
  {
    id: 'planweeks',
    title: 'overview:planweeks',
  },
  {
    id: 'planclients',
    title: 'overview:planclients',
  },
  {
    id: 'nutactive',
    title: 'overview:nutactive',
  },
  {
    id: 'nutinactive',
    title: 'overview:nutinactive',
  },
  {
    id: 'nutdays',
    title: 'overview:nutdays',
  },
  {
    id: 'habtotal',
    title: 'overview:habtotal',
  },
  {
    id: 'habactive',
    title: 'overview:habactive',
  },
  {
    id: 'habaverage',
    title: 'overview:habaverage',
  },
  {
    id: 'income',
    title: 'overview:income',
  },
  {
    id: 'payments',
    title: 'overview:payments',
  },
  {
    id: 'payclients',
    title: 'overview:payclients',
  },
  {
    id: 'memberships',
    title: 'overview:memberships',
  },
  {
    id: 'prodtotal',
    title: 'overview:prodtotal',
  },
  {
    id: 'prodpacks',
    title: 'overview:prodpacks',
  },
  {
    id: 'invtotal',
    title: 'overview:invtotal',
  },
  {
    id: 'invopen',
    title: 'overview:invopen',
  },
  {
    id: 'invpaid',
    title: 'overview:invpaid',
  },
  {
    id: 'invoverdue',
    title: 'overview:invoverdue',
  },
  {
    id: 'stafftotal',
    title: 'overview:stafftotal',
  },
  {
    id: 'staffactive',
    title: 'overview:staffactive',
  },
  {
    id: 'staffinactive',
    title: 'overview:staffinactive',
  },
  {
    id: 'forms',
    title: 'overview:forms',
  },
  {
    id: 'formdocs',
    title: 'overview:formdocs',
  },
  {
    id: 'formclients',
    title: 'overview:formclients',
  },
  {
    id: 'leads',
    title: 'overview:leads',
  },
  {
    id: 'leadsnew',
    title: 'overview:leadsnew',
  },
  {
    id: 'leadsclients',
    title: 'overview:leadsclients',
  },
  {
    id: 'cal11',
    title: 'overview:cal11',
  },
  {
    id: 'calgroup',
    title: 'overview:calgroup',
  },
  {
    id: 'caltotal',
    title: 'overview:caltotal',
  },
  {
    id: 'rec11',
    title: 'overview:rec11',
  },
  {
    id: 'recgroup',
    title: 'overview:recgroup',
  },
  {
    id: 'recsessions',
    title: 'overview:recsessions',
  },
  {
    id: 'recclients',
    title: 'overview:recclients',
  },
  {
    id: 'archive',
    title: 'overview:archive',
  },
  {
    id: 'planssent',
    title: 'overview:planssent',
  },
  {
    id: 'plansavail',
    title: 'overview:plansavail',
  },
  {
    id: 'programssent',
    title: 'overview:programssent',
  },
  {
    id: 'programsavail',
    title: 'overview:programsavail',
  },
  {
    id: 'emailstotal',
    title: 'overview:emailstotal',
  },
  {
    id: 'emailssent',
    title: 'overview:emailssent',
  },
  {
    id: 'emailsclients',
    title: 'overview:emailsclients',
  },
  {
    id: 'unpaid',
    title: 'overview:unpaid',
  },
  {
    id: 'locationstotal',
    title: 'overview:locationstotal',
  },
  {
    id: 'locationsavg',
    title: 'overview:locationsavg',
  },
]



class WidgetOverview extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      item1: props.item1,
      item2: props.item2,
      item3: props.item3,
      item4: props.item4,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      item1: props.item1,
      item2: props.item2,
      item3: props.item3,
      item4: props.item4,
    }
  }


  getClass1() {
    var label = 'info large col-3'
    if(this.state.item2.id === '') {
      label = 'info large col-6'
    }
    return label
  }


  renderCursymPrev(val) {
    if(this.state.item1.id === 'income' && this.state.item1.value !== 'N/A' && val !== '-') {
      return global.curSym
    }
  }


  renderCursym() {
    if(this.state.item1.id === 'income' && this.state.item1.value !== 'N/A') {
      return global.curSym
    }
  }


  renderItem1Prev() {
    if(this.state.item1.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item1.value-this.state.item1.prev
      var badge = ''
      var val = this.state.item1.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      if(this.state.item1.id === 'income' && this.state.item1.value !== 'N/A' && val !== '-') {
        diff = diff.toFixed(2)
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title={lang.t('overview:changecompared')}>{diff > 0 ? '+' : ''}{this.renderCursymPrev(val)}{diff}</span>
      )
    }
  }


  renderItem1() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item1.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className={this.getClass1()}>
          <label>{lang.t(data.title)}</label>
          <p>{this.renderCursym()}{this.state.item1.value}</p>
          {this.renderItem1Prev()}
        </div>
      )
    }
  }


  renderItem2Prev() { 
    if(this.state.item2.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item2.value-this.state.item2.prev
      var badge = ''
      var val = this.state.item2.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title={lang.t('overview:changecompared')}>{diff > 0 ? '+' : ''}{diff}</span>
      )
    }
  }


  renderItem2() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item2.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className='info large col-3'>
          <label>{lang.t(data.title)}</label>
          <p>{this.state.item2.value}</p>
          {this.renderItem2Prev()}
        </div>
      )
    }
  }


  renderItem3Prev() {
    if(this.state.item3.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item3.value-this.state.item3.prev
      var badge = ''
      var val = this.state.item3.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title={lang.t('overview:changecompared')}>{diff > 0 ? '+' : ''}{diff}</span>
      )
    }
  }


  renderItem3() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item3.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className='info large col-3'>
          <label>{lang.t(data.title)}</label>
          <p>{this.state.item3.value}</p>
          {this.renderItem3Prev()}
        </div>
      )
    }
  }


  renderItem4Prev() {
    if(this.state.item4.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item4.value-this.state.item4.prev
      var badge = ''
      var val = this.state.item4.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title={lang.t('overview:changecompared')}>{diff > 0 ? '+' : ''}{diff}</span>
      )
    }
  }


  renderItem4Button(data) {
    if(data.id === 'unpaid') {
      return <button className="btn tertiary small lft" onClick={() => this.props.clickButton()}>{lang.t('common:button.viewdetails')}</button>
    }
  }


  renderItem4() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item4.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className='info large col-3'>
          <label>{lang.t(data.title)}</label>
          <p>{this.state.item4.value}</p>
          {this.renderItem4Prev()}
          {this.renderItem4Button(data)}
        </div>
      )
    }
  }


  render() {
    return (
      <div className="pt-20">
        {this.renderItem1()}
        {this.renderItem2()}
        {this.renderItem3()}
        {this.renderItem4()}
        <div className="clear"></div>
      </div>
    )
  }
}


export default withTranslation(['overview','common'])(WidgetOverview);