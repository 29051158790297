import React, { Component } from 'react';
import Moment from 'moment';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class WidgetProgram extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      item: props.session,
      editComment: '',
      comment: props.comment,
      writeComment: false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      item: props.session,
      comment: props.comment,
    }
  }


  calculateRating(num) {
    var width = 0
    var number = 0
    if(this.state.item.data.rating !== undefined) {
      for(var item of this.state.item.data.rating) {
        var arr = item.split(',')
        number += parseInt(arr[1])
      }
      var total = number/(this.state.item.data.rating.length*5)*100
      if(total >= 20*num) {
        width = 20
      } else {
        var tmp = (20*num)-(total+20)
        width = (tmp/100)*20
      }
    }
    return width
  }


  onChange = event => {
    this.props.onChange(event.target.value)
  }


  sendComment(value) {
    this.props.sendComment(value)
    this.setState({
      editComment: '',
      writeComment: false,
    })
  }


  getRating() {
    var label = ''
    var number = 0
    if(this.state.item.data.rating !== undefined) {
      for(var item of this.state.item.data.rating) {
        var arr = item.split(',')
        number += parseInt(arr[1])
      }
      var total = number/(this.state.item.data.rating.length*5)*100
      label = (total/20).toFixed(1)+" ("+this.state.item.data.rating.length+")"
    }
    return label
  }


  getCommenter(id) {
    var label = lang.t('widgets.comments.label.trainer')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    if(id === global.uidUser) {
      label = lang.t('common:label.you')
    }
    return label
  }


  getCommentImage(id) {
    var client = null;
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    if(client !== null) {
      if(client.data.image === '') {
        var inits = ''
        let arr = client.data.name.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        if(client.data.avatar !== undefined && client.data.avatar !== '') {
          return (
            <div className={'avatar '+client.data.avatar}></div>
          )
        } else {
          return (
            <div className="avatar">
              <p>{inits}</p>
            </div>
          )
        }
      } else {
        var img = ''
        for(var item2 of global.clientsImages) {
          if(item2.id === client.id) {
            img = item2.image
          }
        }
        return (
          <div className="avatar" style={{backgroundImage: 'url('+img+')'}}> </div>
        )
      }
    } else {
      if(id === global.uid) {
        if(global.userImage === '' && global.userName !== null) {
          var inits2 = ''
          let arr = global.userName.split(' ')
          if(arr.length > 1) {
            inits2 = arr[0].charAt(0)+arr[1].charAt(0)
          } else {
            inits2 = arr[0].charAt(0)
          }
          return (
            <div className="avatar">
              <p>{inits2}</p>
            </div>
          )
        } else {
          return (
            <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
          )
        }
      }
    }
  }


  renderRating() {
    if(global.userComments && this.state.item.data.attendance === 3) {
      return (
        <div className="mb-50">
          <h3 className="mb-20">{lang.t('widgets:comments.title.rating')}</h3>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(1)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(2)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(3)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(4)}}></div>
          </div>
          <div className="star lft">
            <div className="inner" style={{width: this.calculateRating(5)}}></div>
          </div>
          <p className="star-rate">{this.getRating()}</p>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderCommentsButton() {
    if(this.state.editComment === '') {
      if(this.state.writeComment) {
        return (
          <div>
            <h3 className="mb-20">{lang.t('widgets:comments.title.writeacomment')}</h3>
            <textarea value={this.state.comment} onChange={this.onChange}></textarea>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.sendComment('')}>{lang.t('widgets:comments.button.send')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary close width-12" onClick={() => this.setState({writeComment: false})}>{lang.t('common:button.cancel')}</button>
            <div className="sv-20"></div>
          </div>
        )
      } else {
        return (
          <button className="btn tertiary add width-12" onClick={() => this.setState({writeComment: true, comment: ''})}>{lang.t('widgets:comments.button.write')}</button>
        )
      }
    }
  }


  renderCommentButtonEdit(item) {
    if(item.data.sender === global.uidUser && !this.state.writeComment) {
      if(this.state.editComment === '') {
        return (
          <button className="btn tertiary small edit rgt" onClick={() => this.setState({editComment: item.id, comment: item.data.text})}>{lang.t('common:button.edit')}</button>
        )
      }
    }
  }


  renderCommentEdit(item) {
    if(item.data.sender === global.uidUser && !this.state.writeComment) {
      if(this.state.editComment === '') {
        // nothing
      } else if(this.state.editComment === item.id) {
        return (
          <div className="pt-20 clear">
            <textarea value={this.state.comment} onChange={this.onChange}></textarea>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.sendComment(item.id)}>{lang.t('widgets:comments.button.update')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary close width-12 fixed" onClick={() => this.setState({editComment: ''})}>{lang.t('common:button.cancel')}</button>
            <div className="sv-20"></div>
          </div>
        )
      }
    }
  }


  renderButtonFeedback() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <Link to={ROUTES.ADMINSETTINGS} className="btn tertiary width-12 add">{lang.t('widgets:button.enable')}</Link>
      )
    }
  }


  renderComments() {
    if(global.userComments) {
      if(this.state.item.comments === undefined || this.state.item.comments.length === 0) {
        return (
          <div>
            <div className="empty small">
              {HelperEmpty.renderImage('comments')}
              <h4>{lang.t('widgets:comments.title.nocommentsyet')}</h4>
            </div>
            <div className="sv-40"></div>
            {this.renderCommentsButton()}
          </div>
        )
      } else {
        var list = this.state.item.comments;
        list.sort((a,b) => Moment(b.data.date, 'X') - Moment(a.data.date, 'X'));
        return (
          <div>
            {list.map(item => (
              <div className="list bare" key={item.id}>
                {this.getCommentImage(item.data.sender)}
                <div className="main">
                  {this.renderCommentButtonEdit(item)}
                  <h4>{this.getCommenter(item.data.sender)}</h4>
                  <h6 style={{marginBottom: 5}}>{Cal.getPushDate(Moment(item.data.date, 'X'))}</h6>
                  <p>{item.data.text}</p>
                  {this.renderCommentEdit(item)}
                </div>
                
                <div className="clear"></div>
              </div>
            ))}
            <div className="sv-20"></div>
            {this.renderCommentsButton()}
          </div>
        )
      }
    } else {
      return (
        <div className="empty-help clear">
          <h3 className="mb-20">{lang.t('widgets:comments.title.commentsdisabled')}</h3>
          <p className="mb-20">{lang.t('widgets:comments.label.commentsdisabled')}</p>
          {this.renderButtonFeedback()}
        </div>
      )
    }
  }


  render() {
    return (
      <div>
        {this.renderRating()}
        <h3 className="mb-20">{lang.t('widgets:comments.title.comments')}</h3>
        {this.renderComments()}
      </div>
    )
  }
}


export default withTranslation(['widgets','common'])(WidgetProgram);