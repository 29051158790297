import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class Loader extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label ?? lang.t('widgets:form.loader'),
      show: props.show,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label ?? lang.t('widgets:form.loader'),
      show: props.show,
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p style={{whiteSpace: 'pre-wrap'}}>{this.state.label}</p>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['widgets'])(Loader);