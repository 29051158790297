import React, { Component } from 'react';
import Firebase from 'firebase';
import Connector from '../../data/Connector';

import Cal from '../../helper/Calendar';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalClients from '../../components/Modals/clients';
import ModalVideo from '../../components/Modals/video';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class PlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      clients: global.clients,
      programs: global.programs,
      programsList: global.programs,
      showModalClients: false,
      showModalDelete: false,
      showModalVideo: false,
      showModalMessage: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Training Plan - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    global.currentDay = 'day1'
    global.planLocked = false;
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('groupsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('plansLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClients() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients,
      }, () => {
        this.configureData()
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      var tmp1 = null
      var tmp2 = ''
      for(var item of global.plans) {
        if(item.id === id) {
          tmp1 = item
          tmp2 = item.id
        }
      }

      global.linkPro = '/programming/plan/'+id;

      var tmp = global.programs

      var tmp3 = []
      for(var client of global.clients) {
        if(client.data.uid !== '') {
          tmp3.push(client)
        }
      }

      this.setState({
        id: tmp2,
        item: tmp1,
        programs: tmp,
        clients: tmp3,
      })
    }
  }


  duplicatePlan() {
    var obj = Firebase.database().ref('/plans/'+global.uid).push()
    obj.update({
      name: this.state.item.data.name+' (Copy)',
      description: this.state.item.data.description,
      video: this.state.item.data.video,
      sessions: this.state.item.data.sessions,
      uid: global.uidUser
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Plan successfully duplicated')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    for(var week of this.state.item.weeks) {
      Firebase.database().ref('/plans/'+global.uid+'/'+obj.key+'/weeks/'+week.id).update(
        week.data
      )
    }
    for(var prog of this.state.item.programs) {
      Firebase.database().ref('/plans/'+global.uid+'/'+obj.key+'/workouts/'+prog.id).update(
        prog.data
      )
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false,
      showModalDelete: false,
      showModalVideo: false,
      showModalMessage: false,
    })
  }


  deletePlan() {
    this.hideModals()
    Firebase.database().ref('/plans/'+global.uid+'/'+this.state.id).remove()
    .then((data)=>{
      global.message = 'Plan successfully deleted'
      this.props.history.push(ROUTES.PLANS)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  prepSend(list) {
    this.setState({
      selected: list
    }, () => {
      this.sendPlan()
    })
  }


  sendPlan() {
    if(this.state.selected.length > 0) {
      this.hideModals()
      var tokens = []
      for(var item of this.state.selected) {
        for(var client of global.clients) {
          if(client.id === item) {
            var obj = Firebase.database().ref('/plans/'+client.data.uid).push()
            //Firebase.database().ref('/clients/'+global.uid+'/'+item).update({plan: obj.key})
            this.updateClientData(client, obj.key)
            obj.update({
              name: this.state.item.data.name,
              description: this.state.item.data.description,
              video: this.state.item.data.video,
              sessions: this.state.item.data.sessions,
              uid: global.uidUser
            }).then((data)=>{
              EventEmitter.dispatch('showMessage', 'Plan successfully sent to selected clients')
            }).catch((error)=>{
              EventEmitter.dispatch('showMessageError', error.message);
            })
            for(var week of this.state.item.weeks) {
              Firebase.database().ref('/plans/'+client.data.uid+'/'+obj.key+'/weeks/'+week.id).update(
                week.data
              )
            }
            for(var prog of this.state.item.programs) {
              Firebase.database().ref('/plans/'+client.data.uid+'/'+obj.key+'/workouts/'+prog.id).update(
                prog.data
              )
            }
            var num = 1
            if(this.state.item.data.clients !== null && this.state.item.data.clients !== undefined) {
              num = this.state.item.data.clients+1
            }
            Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id).update({
              clients: num
            })
            if(client.data.pushToken !== undefined && client.data.pushToken !== '') {
              tokens.push(client.data.pushToken)
            }
          }
        }
      }
      if(tokens.length > 0) {
        Connector.sendPushNotification('',"New training plan", global.userName+" just sent you a new training plan", 'plan', this.state.item.id, tokens)
      }
    } 
  }


  updateClientData(item, id) {
    if(this.state.item.data.sessions > 0) {
      var plans = []
      if(item.data.plans !== undefined && item.data.plans !== null) {
        plans = item.data.plans
      }
      plans.push(id)
      Firebase.database().ref('/clients/'+global.uid+'/'+item.id).update({plans: plans})
      var hasCredit = false
      if(global.userChargeSessions) {
        if(item.credits.length > 0) {
          for(var cred of item.credits) {
            if(!cred.data.group) {
              hasCredit = true
              var paid = cred.data.sessionsPaid+this.state.item.data.sessions
              Firebase.database().ref('/clients/'+global.uid+'/'+item.id+'/credits/'+cred.id).update({sessionsPaid: paid})
            }
          }
        }
        if(!hasCredit) {
          Firebase.database().ref('/clients/'+global.uid+'/'+item.id+'/credits').push({
            sessionsPaid: this.state.item.data.sessions,
            sessionsTotal: 0,
            group: false,
            type: 'sessions'
          })
        }
      }
    }
  }



  // Display stuff ------------------------------------------------------------



  renderProgramColor(id) {
    var label = ''
    for(var item of this.state.item.programs) {
      if(item.id === id) {
        label = Cal.getColor(item.data.time)
      }
    }
    return label
  }


  renderProgramData(id) {
    var mins = ''
    var exercises = ''
    var name = ''
    var time = 0
    for(var item of this.state.item.programs) {
      if(item.id === id) {
        name = item.data.name
        exercises = item.data.exercises+' movements'
        mins = Cal.getDuration(item.data.time)
        time = item.data.time
        if(item.data.exercises === 1) {
          exercises = item.data.exercises+' movement'
        }
      }
    }
    return [name, exercises, mins, time]
  }


  renderWeekday(id, day) {
    var programs = []
    for(var item of this.state.item.weeks) {
      if(item.id === id) {
        if(day === 1) {
          programs = item.data.day1.split(',')
        } else if(day === 2) {
          programs = item.data.day2.split(',')
        } else if(day === 3) {
          programs = item.data.day3.split(',')
        } else if(day === 4) {
          programs = item.data.day4.split(',')
        } else if(day === 5) {
          programs = item.data.day5.split(',')
        } else if(day === 6) {
          programs = item.data.day6.split(',')
        } else if(day === 7) {
          programs = item.data.day7.split(',')
        }
      }
    }
    programs.splice(0,1)
    if(programs.length > 0) {
      return (
        <div>
          {programs.map((item) => (
            <div className="cal-session">
              <Link to={'/programming/plan/'+this.state.item.id+'/program/'+item} className={'plan-program '+this.renderProgramColor(item)} key={item}>
                <div className={'icon clear '+this.renderProgramColor(item)}>
                  <p>{this.renderProgramData(item)[3]}'</p>
                </div>
                <p className="lft"><strong>{this.renderProgramData(item)[0]}</strong><br/>{this.renderProgramData(item)[2]}</p>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className="empty mt-30 small"><h4>Rest Day</h4></div>
      )
    }
  }


  renderWeeks() {
    return (
      <div>
        {this.state.item.weeks.map((item, index) => (
          <div key={item.id}>
            <div className="box mb-20">
              <h4 className="mb-20">Week {index+1}</h4>
              <div className="calmain">
                <div style={{minWidth: '1200px'}}>
                  <div className="planweek clear calendar">
                    <div style={{minWidth: '1000px'}}>
                      <div className="weekday cal-top lft">
                        <h3 className="mb-10">Day 1</h3>
                        {this.renderWeekday(item.id, 1)}
                      </div>
                      <div className="weekday cal-top lft">
                        <h3 className="mb-10">Day 2</h3>
                        {this.renderWeekday(item.id, 2)}
                      </div>
                      <div className="weekday cal-top lft">
                        <h3 className="mb-10">Day 3</h3>
                        {this.renderWeekday(item.id, 3)}
                      </div>
                      <div className="weekday cal-top lft">
                        <h3 className="mb-10">Day 4</h3>
                        {this.renderWeekday(item.id, 4)}
                      </div>
                      <div className="weekday cal-top lft">
                        <h3 className="mb-10">Day 5</h3>
                        {this.renderWeekday(item.id, 5)}
                      </div>
                      <div className="weekday cal-top lft">
                        <h3 className="mb-10">Day 6</h3>
                        {this.renderWeekday(item.id, 6)}
                      </div>
                      <div className="weekday cal-top lft">
                        <h3 className="mb-10">Day 7</h3>
                        {this.renderWeekday(item.id, 7)}
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderSessions() {
    if(this.state.item.data.sessions !== 0) {
      return (
        <p className="mb-10"><span className="pill primary">{this.state.item.data.sessions} session{this.state.item.data.sessions === 1 ? '' : 's'} included</span></p>
      )
    }
  }


  renderVideo() {
    if(this.state.item.data.video !== undefined && this.state.item.data.video !== '') {
      if(this.state.item.data.video.indexOf('folder') !== -1 || this.state.item.data.video.indexOf('playlist') !== -1) {
        return (
          <a className="btn tertiary" href={this.state.item.data.video} target="_blank" rel="noopener noreferrer">Watch video</a>
        )
      } else {
        return (
          <button className="btn tertiary" onClick={() => this.setState({showModalVideo: true})}>Watch video</button>
        )
      }
    }
  }


  renderContent() {
    if(this.state.item !== null) {
      return (
        <div className="mt-20">
          <div className="program-header mb-60">
            <div className="icon large primary lft mr-20">
              <p>{this.state.item.weeks.length}</p>
            </div>
            <div className="main">
              <h2>{this.state.item.data.name}</h2>
              <p className="mb-10">{this.state.item.data.desc}</p>
              {this.renderSessions()}
              {this.renderVideo()}
            </div>
            <div className="clear"></div>
          </div>

          {this.renderWeeks()}
          <div className="sv-30"></div>
  
          <div className="lft col-4">
            <Link to={'/programming/edit-plan/'+this.state.item.id} className="btn primary">Edit plan</Link>
            <div className="sv-30"></div>
          </div>
          <div className="lft col-4">
            <button className="btn secondary" onClick={() => this.setState({showModalClients: true})}>Send to my clients</button>
            <div className="sv-30"></div>
          </div>
          <div className="lft col-4">
            <button className="btn secondary" onClick={() => this.duplicatePlan()}>Duplicate this plan</button>
            <div className="sv-30"></div>
          </div>
          <div className="clear"></div>
          <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>Delete this training plan</button>
          
          <div className="clear sv-40"></div>
        </div>
      )
    }
    
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content">
          {this.renderContent()}
        </div>
        <Header title='Training Plan' link={ROUTES.PLANS}/>
        <Navigation active='programming' />
        <ModalMessageSimple type='deleteplan' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deletePlan()}/>
        <ModalMessageSimple type='sendplan' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
        <ModalVideo type='deleteplanclient' show={this.state.showModalVideo} url={this.state.item === null ? '' : this.state.item.data.video} onHide={() => this.hideModals()}/>
        <ModalClients title='Send to clients' clients={this.state.clients} multiple={true} show={this.state.showModalClients} selected={this.state.selected} onHide={() => this.hideModals()} clickMainButton={(event) => this.prepSend(event)}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(PlanPage));