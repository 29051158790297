import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import 'firebase/functions';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class InvoicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      item: null,
      id: '',
      emails: '',
      back: global.currentPage,
      showModalDelete: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Invoice - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = null
      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      for(var item of global.invoices) {
        if(item.id === id) {
          tmp = item
        }
      }
      var back = global.currentPage
      if(global.linkCli !== '') {
        back = global.linkCli
      }
      this.setState({
        clients: global.clients,
        item: tmp,
        id: id,
        back: back
      })
    }
  }


  renderClientName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }


  getClient() {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === this.state.item.data.client) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === this.state.item.data.client) {
        label = item2.data.name
      }
    }
    return label
  }


  getClientInfo() {
    var label = ''
    for(var item of global.clients) {
      if(item.id === this.state.item.data.client) {
        label = item.data.phone
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === this.state.item.data.client) {
        label = item2.data.phone
      }
    }
    return label
  }


  getProductInfo(type) {
    var label = ''
    for(var item of global.products) {
      if(item.id === this.state.item.data.product) {
        label = item.data.name
        if(type === 'price') {
          label = item.data.price.toFixed(2)
        }
      }
    }
    return label
  }


  onChange = event => {
    this.setState({
      emails: event.target.value
    });
  };


  updateStatus(status) {
    Firebase.database().ref('/invoices/'+global.uid+'/'+this.state.item.id).update({
      status: status,
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Status successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  sendInvoice() {
    if(this.state.emails !== '') {
      var arr = this.state.emails.split(',')
      for(var ar of arr) {
        var email = ar.replace(' ', '')
        var arr2 = []
        var address = ''
        if(global.spaceAddress !== undefined) {
          arr2 = global.spaceAddress.split('||')
          if(arr2.length > 3) {
            address = arr2[0]+'\n'+arr2[1]+'\n'+arr2[2]+', '+arr2[3]
          }
        }
        if(global.spaceInvoice1 !== undefined && global.spaceInvoice1 !== '') {
          address = global.spaceInvoice1
        }

        var gststr1 = ''
        var gststr2 = ''
        if(this.state.item.data.gst !== undefined) {
          gststr1 = global.curSym+(this.state.item.data.gst).toFixed(2)
          gststr2 = 'GST included'
        }

        var callFunction = Firebase.functions().httpsCallable('sendInvoice');
        callFunction({name: global.userBusiness, email: email, address: address, number: this.state.item.data.number, date: Moment(this.state.item.data.date, 'X').format('D MMM YYYY'), client: this.getClient(), phone: this.getClientInfo(), product: this.getProductInfo('name'), price: global.curSym+this.getProductInfo('price'), link: 'https://ptmate.app/member/'+global.uid+'/admin/pay-invoice/'+this.state.item.id, footer: global.spaceInvoice2, due: Moment(this.state.item.data.due, 'X').format('D MMM YYYY'), gst1: gststr1, gst2: gststr2}).then(function(result) {});
      }
      EventEmitter.dispatch('showMessage', 'Emails successfully sent');
      this.setState({
        emails: ''
      })
    }
  }



  // Delete overlay ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
    })
  }


  deleteInvoice() {
    this.hideModals()
    Firebase.database().ref('/invoices/'+global.uid+'/'+this.state.item.id).remove()
    global.message = 'Invoice successfully deleted'
    this.props.history.push(this.state.back)
  }



  // Display stuff ------------------------------------------------------------



  renderLogo() {
    if(global.spaceImage !== '') {
      return (
        <img src={global.spaceImage} alt={global.userBusiness}/>
      )
    } else {
      return (
        <img src="/img/logo.svg" alt="PT Mate"/>
      )
    }
  }


  renderAddress() {
    if(global.spaceAddress !== undefined) {
      var arr2 = global.spaceAddress.split('||')
      if(arr2.length > 3) {
        var address = arr2[0]+'\n'+arr2[1]+'\n'+arr2[2]+', '+arr2[3]
        return address
      }
    }
  }


  renderGST(type) {
    if(this.state.item.data.gst !== undefined) {
      if(type === 'label') {
        return <span>{lang.t('billing:label.gstincluded')}<br/></span>
      } else {
        return <span>{global.curSym+this.state.item.data.gst.toFixed(2)}<br/></span>
      }
    }
  }


  renderInvoice() {
    return (
      <div>
        <div className="print-header">
          {this.renderLogo()}
          <h2>{global.userBusiness}</h2>
        </div>
        <div className="col-12 print-data">
          <h2>{lang.t('billing:label.invoice')}</h2>
          <div className="print-info mt-30">
            <p>{global.spaceInvoice1 === '' ? this.renderAddress() : global.spaceInvoice1}</p>
            <p>{lang.t('billing:label.invoice')} {this.state.item.data.number}<br/>{Moment(this.state.item.data.date, 'X').locale(lang.language).format('D MMM YYYY')}<br/>{lang.t('billing:label.due')} {Moment(this.state.item.data.due, 'X').locale(lang.language).format('D MMM YYYY')}</p>
            <p><strong>{lang.t('billing:label.billto')}</strong><br/>{this.getClient()}<br/>{this.getClientInfo()}</p>
          </div>
          <p className="big lft"><span>{lang.t('billing:label.item')}</span><br/>{this.getProductInfo('name')}<br/>{this.renderGST('label')}<strong>{lang.t('billing:label.totaldue')}</strong></p>
          <p className="big rgt mb-30" style={{textAlign: 'right'}}><span>{lang.t('billing:form.price')}</span><br/>{global.curSym}{this.getProductInfo('price')}<br/>{this.renderGST('amount')}<strong>{global.curSym}{this.getProductInfo('price')}</strong></p>
          <div className="print-disclaimer left clear mt-30">
            <p className="small"><strong>{lang.t('billing:label.payment')}</strong><br/>{lang.t('billing:label.payment.text')}: https://ptmate.app/member/{global.uid}/admin/pay-invoice/{this.state.item.id}<br/>&nbsp;<br/>{global.spaceInvoice2}</p>
          </div>
        </div>
      </div>
    )
  }


  renderStatus() {
    if(this.state.item.data.status === 'paid') {
      return <p>{lang.t('billing:label.paid')}</p>
    } else if(this.state.item.data.status === 'void') {
      return <p>{lang.t('billing:label.void')}</p>
    } else if(this.state.item.data.status === 'open' && Moment(this.state.item.data.due, 'X') < Moment().add(-1, 'day')) {
      return <p style={{color: '#ff0000'}}>{lang.t('billing:label.overdue')}</p>
    } else {
      return <p>{lang.t('billing:label.open')}</p>
    }
  }


  renderPay() {
    if(this.state.item.data.status === 'open') {
      return (
        <Link to={'/billing/pay-invoice/'+this.state.item.id} className="btn tertiary small rgt" style={{marginRight: 15}}>{lang.t('billing:button.paynow')}</Link>
      )
    }
  }


  renderContent() {
    if(this.state.item !== null) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <div className="col-12 noprint">
              <div className="box">
                <button className="btn tertiary small close rgt" onClick={() => this.setState({showModalDelete: true})}>{lang.t('common:button.delete')}</button>
                {this.renderPay()}
                <div className="info col-3 clear">
                  <label>{lang.t('billing:label.status')}</label>
                  {this.renderStatus()}
                </div>
                <div className="info col-9">
                  <label>{lang.t('billing:label.client')}</label>
                  <p>{this.getClient()}</p>
                </div>
                <div className="col-3 mb-20">
                  <label>{lang.t('billing:form.invoicepaid')}</label>
                  <div className="radios">
                    <button className={this.state.item.data.status === 'open' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.updateStatus('open')}>{lang.t('billing:form.invoicepaid.notpaid')}</button>
                    <button className={this.state.item.data.status === 'paid' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.updateStatus('paid')}>{lang.t('billing:label.paid')}</button>
                    <button className={this.state.item.data.status === 'void' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.updateStatus('void')}>{lang.t('billing:label.void')}</button>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="col-3">
                  <label>{lang.t('billing:form.resendinvoiceviaemail')}</label>
                  <input type="text" value={this.state.emails} onChange={this.onChange}/>
                </div>
                <div className="col-2 pt-20">
                  <button className="btn primary" onClick={() => this.sendInvoice()}>{lang.t('billing:button.resendinvoice')}</button>
                </div>
                <div className="col-2">&nbsp;</div>
                <div className="col-2 pt-20">
                  <button className="btn secondary" onClick={() => window.print()}>{lang.t('billing:button.print')}</button>
                </div>
                <div className="clear"></div>
              </div>
              <div className="sv-40"></div>
            </div>
            {this.renderInvoice()}
            <div className="clear sv-30"></div>
          </div>
          <div className="noprint">
            <Header title={lang.t('billing:label.invoice')+' '+this.state.item.data.number} link={this.state.back}/>
            <Navigation active='billing' />
          </div>
          <ModalMessageSimple type='deleteinvoice' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteInvoice()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="noprint">
            <Header title={lang.t('billing:label.invoice')} link={this.state.back}/>
            <Navigation active='billing' />
          </div>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(!global.showHabits) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-container">
              <ListEmpty id='invoices-locked2' type='simple'/>
            </div>
            <Header title={lang.t('billing:label.invoice')} link={this.state.back}/>
            <Navigation active='billing' />
          </div>
        )
      } else {
        return (
          this.renderContent()
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-container">
            <ListEmpty id='billing-locked' type='simple'/>
          </div>
          <Header title={lang.t('billing:label.invoice')} link={this.state.back}/>
          <Navigation active='billing' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header'])(withRouter(withAuthorization(condition)(InvoicePage)));