import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ReceiptPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      id: '',
      emails: '',
      showOverlayDelete: false,
      hiddenDelete: 'hidden',
      margin: 0,
      back: global.currentPage
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Receipt - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var tmp = null
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    for(var item of global.payments) {
      if(item.id === id) {
        tmp = item
      }
    }
    for(var item2 of global.paymentsArchive) {
      if(item2.id === id) {
        tmp = item2
      }
    }
    this.setState({
      item: tmp,
      id: id
    })
  }


  getClient(type) {
    var label = '-'
    for(var item of global.clients) {
      if(item.id === this.state.item.data.client) {
        label = item.data[type]
      }
    }
    return label
  }


  getName() {
    var label = this.state.item.data.name
    if(label.indexOf('Invoice') !== -1 || label.indexOf('Subscription') !== -1) {
      label = lang.t('billing:label.membership')
    }
    return label
  }


  getEmail() {
    var label = '-'
    for(var item of global.clients) {
      if(item.id === this.state.item.data.client) {
        label = item.data.email
      }
    }
    return label
  }


  sendEmail() {
    var arr = []
    var address = ''
    if(global.spaceAddress !== undefined) {
      arr = global.spaceAddress.split('||')
      if(arr.length > 3) {
        address = arr[0]+'\n'+arr[1]+'\n'+arr[2]+', '+arr[3]
      }
    }
    if(global.spaceInvoice1 !== undefined && global.spaceInvoice1 !== '') {
      address = global.spaceInvoice1
    }
    var gst1 = ''
    var gst2 = ''
    if(global.spaceGST !== 0) {
      var mul = (this.state.item.data.amount/100)/11
      var num = mul/10*global.spaceGST
      gst1 = 'GST included'
      gst2 = global.curSym+num.toFixed(2)
    }
    
    var callFunction = Firebase.functions().httpsCallable('sendReceipt');
    callFunction({name: global.userBusiness, email: this.getEmail(), address: address, number: this.state.item.id, date: Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY - HH:mm'), businessemail: global.spaceEmail, phone: global.spacePhone, product: this.state.item.data.name, price: global.curSym+((this.state.item.data.amount/100).toFixed(2)), method: this.renderMethod(), gst1: gst1, gst2: gst2}).then(function(result) {});
  }



  // Display stuff ------------------------------------------------------------



  renderAddress() {
    if(global.spaceAddress !== undefined) {
      var arr2 = global.spaceAddress.split('||')
      if(arr2.length > 3) {
        var address = arr2[0]+'\n'+arr2[1]+'\n'+arr2[2]+', '+arr2[3]
        return address
      }
    }
  }


  renderDate() {
    var label = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
    if(this.state.item.data.timestamp !== undefined) {
      label = Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('D MMM YYYY')
    }
    return label
  }


  renderMethod() {
    var label = this.state.item.data.type+' - '+this.state.item.data.last4
    if(this.state.item.data.type === 'Cash') {
      label = lang.t('billing:label.otherpaymentmethod')
    }
    return label
  }


  renderGST(type) {
    if(global.spaceGST !== 0 && this.state.item !== null) {
      if(type === 'amount') {
        var label = '-'
        var mul = (this.state.item.data.amount/100)/11
        var num = mul/10*global.spaceGST
        label = global.curSym+num.toFixed(2)
        return <span>{label}<br/></span>
      } else {
        return <span>{lang.t('billing:label.gstincluded')}<br/></span>
      }
    }
  }


  renderLogo() {
    if(global.spaceImage !== '') {
      return (
        <img src={global.spaceImage} alt={global.userBusiness}/>
      )
    } else {
      return (
        <img src="/img/logo.svg" alt="PT Mate"/>
      )
    }
  }


  renderContent() {
    return (
      <div>
        <div className="col-12 print-box short none">
          <h1>{global.userBusiness}</h1>
          <p style={{fontSize: 24, fontWeight: 400}}>{lang.t('billing:label.receipt')} {this.state.item.id}</p>
        </div>
        <div className="print-header mb-30">
          {this.renderLogo()}
          <h2>{global.userBusiness}</h2>
        </div>
        <div className="col-12 print-data">
          <h4 className="print-title">{lang.t('billing:label.receipt')} {this.state.item.id}</h4>
          <div className="print-info mt-30 mb-30">
            <p>{global.spaceInvoice1 === '' ? this.renderAddress() : global.spaceInvoice1}</p>
            <p><strong>{lang.t('billing:label.billto')}:</strong><br/>{this.getClient('name')}<br/>{this.getClient('email')}</p>
            <p>{lang.t('billing:label.amountpaid')}: {global.curSym}{(this.state.item.data.amount/100).toFixed(2)}<br/>{lang.t('billing:label.paid')}: {this.renderDate()}<br/>{lang.t('billing:label.paymentmethod')}: {this.renderMethod()}</p>
          </div>
          <p className="big lft"><span>{lang.t('billing:label.item')}</span><br/>{this.getName()}<br/>{this.renderGST('label')}<strong>{lang.t('billing:label.amountcharged')}</strong></p>
          <p className="big rgt mb-30" style={{textAlign: 'right'}}><span>{lang.t('billing:form.price')}</span><br/>{global.curSym}{(this.state.item.data.amount/100).toFixed(2)}<br/>{this.renderGST('amount')}<strong>{global.curSym}{(this.state.item.data.amount/100).toFixed(2)}</strong></p>
          <div className="print-disclaimer clear">
            <p className="small">{lang.t('billing:label.questions1')} {global.spaceEmail} {lang.t('billing:label.questions2')} {global.spacePhone}.</p>
          </div>
        </div>
        <div className="sv-60 clear"></div>
          <button className="btn tertiary width-12" onClick={() => this.sendEmail()}>{lang.t('billing:button.resendviaemail')}</button>
        <div className="sv-30"></div>
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(global.showHabits && this.state.item !== null) {
        return (
          <div className="content" style={{paddingTop: 20}}>
            {this.renderContent()}
          </div>
        )
      } else {
        return (
          <div className="content" style={{paddingTop: 20}}>
            <h2>Loading</h2>
          </div>
        )
      }
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withTranslation(['billing','common','header'])(withRouter(withAuthorization(condition)(ReceiptPage)));

