import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperClients from '../../helper/Clients';
import HelperEmptyImg from '../../helper/EmptyLarge';
import HelperBusiness from '../../helper/Business';
import ListEmpty from '../../components/List/empty';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import WidgetChartPie from '../../components/Widgets/chart-pie';
import WidgetChartBar from '../../components/Widgets/chart-bar-big';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class BillingInsightsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      clientsall: global.clients,
      payments: global.payments,
      paymentsArchive: global.paymentsArchive,
      products: global.products,
      expenses: global.expenses,
      sessions: global.sessions,
      archive: global.archive,
      ids: [],
      total: [],
      date1: Moment().add(-30, 'days').format('YYYY-MM-DD'),
      date2: Moment().format('YYYY-MM-DD'),
      inactive: [],
      income: 0,
      amounte: 0,
      transactions: 0,
      net: 0,
      active: 0,
      spent: 0,
      selected: '',
      m1: 100,
      m2: 100,
      m3: 100,
      m3a: 0,
      dataTop1: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataTop2: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataTop3: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataTop: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataClients: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataExp: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataCat: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataTypes: [
        {
          "id": "japan",
          "label": "java",
          "value": 47,
          "color": "#1DC5C9"
        },
      ],
      dataActive: [
        {
          "id": "japan",
          "label": "java",
          "value": 47,
          "color": "#1DC5C9"
        },
      ],
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Financial Insights - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';
    global.currentPage = ROUTES.BILINSIGHTS;
    global.linkCli = '';
    global.linkCal = '';
    global.linkPro = '';
    global.linkBil = '';
    global.linkAdm = '';

    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsArchiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('expensesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var tmp = []
    var tmp6 = []
    var ids = []
    var sel = ''

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    if(global.paymentsArchive.length === 0 && global.uidUser !== '') {
      Connector.loadPaymentsArchive((result) => {})
    }
    if(global.archive.length === 0 && global.uidUser !== '') {
      Connector.loadArchive((result) => {})
    }
    for(var item of global.clients) {
      if(list.indexOf(item.id) !== -1) {
        tmp6.push(item)
        tmp.push({
          id: item.id,
          name: item.data.name,
          income: 0,
          payments: 0,
          image: item.data.image
        })
        ids.push(item.id)
      }
    }
    if(tmp.length > 0) {
      sel = tmp6[0].id
    }
    var tmp1 = []
    var tmp2 = []
    var tmp3 = []
    var tmp4 = []
    var tmp5 = []
    for(var item1 of global.payments) {
      if(list.indexOf(item1.data.client) !== -1) {
        tmp1.push(item1)
      }
    }
    for(var item2 of global.paymentsArchive) {
      if(list.indexOf(item2.data.client) !== -1) {
        tmp2.push(item2)
      }
    }
    for(var item3 of global.expenses) {
      if(item3.data.location === global.loc || global.loc === '') {
        tmp3.push(item3)
      }
    }
    for(var item4 of global.sessions) {
      if(item4.data.location === global.loc || global.loc === '') {
        tmp4.push(item4)
      }
    }
    for(var item5 of global.archive) {
      if(item5.data.location === global.loc || global.loc === '') {
        tmp5.push(item5)
      }
    }
    this.setState({
      clients: tmp,
      clientsall: tmp6,
      payments: tmp1,
      paymentsArchive: tmp2,
      products: global.products,
      expenses: tmp3,
      sessions: tmp4,
      archive: tmp5,
      ids: ids,
      selected: sel,
    }, () => {
      this.getSessions()
      this.getTopGraph()
      this.getTopGraphs()
    });
  }


  getTemplates(group) {
    var count = 0
    for(var item of this.state.recurring) {
      if(item.data.group === group) {
        count++
      }
    }
    return count
  }


  // Get sessions per user

  getSessions() {
    var tmp = this.state.clients
    for(var tp of tmp) {
      tp.income = 0
      tp.payments = 0
    }

    for(var item of this.state.payments) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
      if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client of tmp) {
          if(client.id === item.data.client) {
            client.income += item.data.amount/100
            client.payments += 1
          }
        }
      }
    }
    for(var item2 of this.state.paymentsArchive) {
      var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
      if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client2 of tmp) {
          if(client2.id === item2.data.client) {
            client2.income += item2.data.amount/100
            client2.payments += 1
          }
        }
      }
    }

    this.setState({
      clients: tmp
    })
  }


  // Get graph data

  getTopGraphs() {
    var tmp1 = []
    var tmp2 = []
    var tmp3 = []
    var m1 = 0
    var m2 = 0
    var m3 = 0
    var m3a = 0
    var start = Moment(this.state.date1, 'YYYY-MM-DD').startOf('month').format('X')
    var end = Moment(this.state.date2, 'YYYY-MM-DD').startOf('month').format('X')

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var i=0; i<1000; i++) {
      var ms = Moment(start, 'X').add(i, 'months').format('X')
      if(Moment(ms, 'X') < Moment(end, 'X')) {
        tmp1.push({
          date: Moment(ms, 'X').locale(lang.language).format('MMM YYYY'),
          payments: 0,
        })
        tmp2.push({
          date: Moment(ms, 'X').locale(lang.language).format('MMM YYYY'),
          expenses: 0,
        })
        tmp3.push({
          date: Moment(ms, 'X').locale(lang.language).format('MMM YYYY'),
          profit: 0,
        })
      }
    }
    for(var item1 of global.payments) {
      if(list.indexOf(item1.data.client) !== -1) {
        var dt = Moment(item1.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('MMM YYYY')
        for(var t1 of tmp1) {
          if(t1.payments > m1) {
            m1 = t1.payments
          }
          if(t1.date === dt) {
            t1.payments += (item1.data.amount/100)
          }
        }
      }
    }
    for(var item1a of global.paymentsArchive) {
      if(list.indexOf(item1a.data.client) !== -1) {
        var dt = Moment(item1a.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('MMM YYYY')
        for(var t1a of tmp1) {
          if(t1a.payments > m1) {
            m1 = t1a.payments
          }
          if(t1a.date === dt) {
            t1a.payments += (item1a.data.amount/100)
          }
        }
      }
    }
    for(var item2 of global.expenses) {
      if(global.loc === '' || item2.data.location === global.loc) {
        var dt2 = Moment(item2.data.date, 'X').locale(lang.language).format('MMM YYYY')
        for(var t2 of tmp2) {
          if(t2.expenses > m2) {
            m2 = t2.expenses
          }
          if(t2.date === dt2) {
            t2.expenses += item2.data.amount
          }
        }
      }
    }
    for(var i=0; i<tmp1.length; i++) {
      var val = tmp1[i].payments-tmp2[i].expenses
      tmp3[i].profit = val
      if(val > m3) {
        m3 = val
      }
      if(val < m3a) {
        m3a = val
      }
    }
    var tmp11 = []
    for(var t11 of tmp1) {
      tmp11.push({
        "date": t11.date,
        "Payments": t11.payments.toFixed(2),
      })
    }
    var tmp21 = []
    for(var t21 of tmp2) {
      tmp21.push({
        "date": t21.date,
        "Expenses": t21.expenses.toFixed(2),
      })
    }
    var tmp31 = []
    for(var t31 of tmp3) {
      tmp31.push({
        "date": t31.date,
        "Profit": t31.profit.toFixed(2),
      })
    }
    setTimeout(() => {
      this.setState({
        dataTop1: tmp11,
        dataTop2: tmp21,
        dataTop3: tmp31,
        m1: m1,
        m2: m2,
        m3: m3,
        m3a: m3a,
      });
    }, 100);
  }


  getTopGraph() {
    var tmp = []
    var tmpd = []
    var tmpp = []
    var tmpc = []
    var tmpc1 = []
    var tmpc2 = []
    var tmpe = []
    var inactive = []

    var totald = 0
    var totalp = 0
    var amount = 0
    var net = 0
    var amounte = 0
    var tmpExp = 0

    var cls = []

    var start = Moment(this.state.date1, 'YYYY-MM-DD').startOf('week')
    var end = Moment(this.state.date2, 'YYYY-MM-DD').add(1, 'week').startOf('week')
    var weeks = end.diff(start, 'weeks')

    var tmp6 = []
    var ii = 0
    while(ii < global.expensesCat.length) {
      tmp6.push(0)
      ii++
    }
    var nume = []

    for(var i=0; i<parseInt(weeks); i++) {
      var num = 0
      var numd = 0
      var nump = 0
      
      var numc = []
      var numc1 = []
      var numc2 = []

      for(var item of this.state.payments) {
        var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(item.data.timestamp !== undefined) {
          date = Moment(item.data.timestamp, 'X').format('X')
        }
        if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date >= Moment(start).add(7*i, 'days').format('X') && date < Moment(start).add(7*i+7, 'days').format('X')) {
          num += parseInt(item.data.amount/100)
          amount += item.data.amount/100
          if(item.data.fee === undefined || item.data.fee !== 0) {
            net += (item.data.amount/100)*0.9775-0.30
          } else {
            net += item.data.amount/100
          }
          
          if(item.data.name.indexOf('Invoice') !== -1 || item.data.name.indexOf('Subscription') !== -1) {
            numd += parseInt(item.data.amount/100)
            totald++
            if(numc.indexOf(item.data.client) === -1) {
              numc.push(item.data.client)
              numc1.push(item.data.client)
              cls.push(item.data.client)
            }
          } else {
            nump += parseInt(item.data.amount/100)
            totalp++
            if(numc.indexOf(item.data.client) === -1) {
              numc.push(item.data.client)
              numc2.push(item.data.client)
              cls.push(item.data.client)
            }
          }
        }
      }
      for(var item2 of this.state.paymentsArchive) {
        var date2 = Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(item2.data.timestamp !== undefined) {
          date2 = Moment(item2.data.timestamp, 'X').format('X')
        }
        if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 >= Moment(start).add(7*i, 'days').format('X') && date2 < Moment(start).add(7*i+7, 'days').format('X')) {
          num += parseInt(item2.data.amount/100)
          amount += item2.data.amount/100
          if(item2.data.fee === undefined || item2.data.fee !== 0) {
            net += (item2.data.amount/100)*0.9775-0.30
          } else {
            net += item2.data.amount/100
          }
          
          if(item2.data.name.indexOf('Invoice') !== -1 || item2.data.name.indexOf('Subscription') !== -1) {
            numd += parseInt(item2.data.amount/100)
            totald++
            if(numc.indexOf(item2.data.client) === -1) {
              numc.push(item2.data.client)
              numc1.push(item2.data.client)
              cls.push(item2.data.client)
            }
          } else {
            nump += parseInt(item2.data.amount/100)
            totalp++
            if(numc.indexOf(item2.data.client) === -1) {
              numc.push(item2.data.client)
              numc2.push(item2.data.client)
              cls.push(item2.data.client)
            }
          }
        }
      }

      for(var item3 of this.state.expenses) {
        var date3 = parseInt(Moment(item3.data.date, 'X').format('X'))
        if(date3 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date3 >= Moment(start).add(7*i, 'days').format('X') && date3 < Moment(start).add(7*i+7, 'days').format('X')) {
          amounte += item3.data.amount
          tmpExp++
          nume.push(item3.data.cat)
          for(var i1=0; i1<global.expensesCat.length; i1++) {
            if(global.expensesCat[i1].id === item3.data.cat) {
              var asd = parseInt(tmp6[i1])
              asd++
              tmp6[i1] = asd
            }
          }
        }
      }

      tmp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": num,
      })
      tmpd.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numd,
      })
      tmpp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": nump,
      })
      tmpc.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc.length,
      })
      tmpc1.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc1.length,
      })
      tmpc2.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc2.length,
      })
      tmpe.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": nume.length,
      })

    }

    for(var client of this.state.clientsall) {
      var add = true
      for(var n of cls) {
        if(n === client.id) {
          add = false
        }
      }
      if(add) {
        inactive.push(client)
      }
    }

    var tmpData = [{
      "id": lang.t('billing:label.total'),
      "color": global.themeColors[2],
      data: tmp
    },
    {
      "id": lang.t('billing:label.membership'),
      "color": global.themeColors[1],
      data: tmpd
    },
    {
      "id": lang.t('billing:label.pack'),
      "color": global.themeColors[0],
      data: tmpp
    }]

    var tmpData2 = [{
      "id": lang.t('billing:label.total'),
      "color": global.themeColors[2],
      data: tmpc
    },
    {
      "id": lang.t('billing:label.membership'),
      "color": global.themeColors[1],
      data: tmpc1
    },
    {
      "id": lang.t('billing:label.pack'),
      "color": global.themeColors[0],
      data: tmpc2
    }]

    var tmpData3 = [
      {
        "id": lang.t('billing:label.memberships'),
        "label": lang.t('billing:label.memberships'),
        "value": totald,
        "color": global.themeColors[0]
      },
      {
        "id": lang.t('billing:label.packsofsessions'),
        "label":lang.t('billing:label.packs'),
        "value": totalp,
        "color": global.themeColors[1]
      },
    ]

    var tmpData4 = [
      {
        "id": lang.t('billing:label.paymentmade'),
        "label": lang.t('billing:label.paymentmade'),
        "value": this.state.clientsall.length-inactive.length,
        "color": global.themeColors[0]
      },
      {
        "id": lang.t('billing:label.nopayments'),
        "label": lang.t('billing:label.nopayments'),
        "value": inactive.length,
        "color": global.themeColors[1]
      },
    ]

    var tmpData5 = [{
      "id": lang.t('billing:label.total'),
      "color": global.themeColors[0],
      data: tmpe
    }]

    var tmpData6 = [{
      "id": "",
      "label": "",
      "value": 0,
      "color": global.themeColors[0]
    }]
    for(var i2=0; i2<global.expensesCat.length; i2++) {
      if(tmp6[i2] !== 0) {
        tmpData6.push({
          "id": global.expensesCat[i2].data.name,
          "label": global.expensesCat[i2].data.name,
          "value": tmp6[i2]
        })
      }
    }

    this.setState({
      dataTop: tmpData,
      dataClients: tmpData2,
      dataTypes: tmpData3,
      dataActive: tmpData4,
      dataExp: tmpData5,
      dataCat: tmpData6,
      inactive: inactive,
      transactions: totald+totalp,
      income: amount,
      net: net,
      amounte: amounte,
      spent: tmpExp,
      active: this.state.clientsall.length-inactive.length,
    })
  }


  setDateStart(event) {
    var dt = Moment(event.target.value+' 23:59', 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
    this.setState({
        date1: dt
    })
  }


  setDateEnd(event) {
    var dt = Moment(event.target.value+' 23:59', 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
    this.setState({
        date2: dt
    })
  }


  setDates() {
    this.getSessions()
    this.getTopGraph()
    this.getTopGraphs()
  }



  // Customer lifetime ------------------------------------------------------------



  getLifetime() {
    var number = 0
    for(var item1 of this.state.payments) {
      if(this.state.ids.indexOf(item1.data.client) !== -1) {
        number += item1.data.amount
      }
    }
    for(var item2 of this.state.paymentsArchive) {
      if(this.state.ids.indexOf(item2.data.client) !== -1) {
        number += item2.data.amount
      }
    }
    if(number === 0) {
      return (
        <p>-</p>
      )
    } else {
      number = number/this.state.clientsall.length
      return (
        <p>{global.curSym}{(number/100).toFixed(2)}</p>
      )
    }
  }


  getLifetimeClient() {
    var number = 0
    for(var item1 of this.state.payments) {
      if(item1.data.client === this.state.selected) {
        number += item1.data.amount
      }
    }
    for(var item2 of this.state.paymentsArchive) {
      if(item2.data.client === this.state.selected) {
        number += item2.data.amount
      }
    }
    if(number === 0) {
      return (
        <p>-</p>
      )
    } else {
      return (
        <p>{global.curSym}{(number/100).toFixed(2)}</p>
      )
    }
  }


  getLifeName() {
    var label = 'Client'
    for(var item of this.state.clientsall) {
      if(item.id === this.state.selected) {
        label = item.data.name
      }
    }
    return label
  }


  getRetention(type) {
    var label = '-'
    var min = Moment()
    var max = Moment('01/01/2000', 'DD/MM/YYYY')
    var check = false
    for(var item1 of this.state.sessions) {
      check = false
      if(item1.data.client === this.state.selected && !item1.data.group) {
        check = true
      } else if(item1.data.group && item1.data.clients !== undefined) {
        if(item1.data.clients.indexOf(this.state.selected) !== -1) {
          check = true
        }
      }
      if(check) {
        if(Moment(item1.data.date, 'DD/MM/YYYY HH:mm').isAfter(max)) {
          max = Moment(item1.data.date, 'DD/MM/YYYY HH:mm')
        }
        if(Moment(item1.data.date, 'DD/MM/YYYY HH:mm').isBefore(min)) {
          min = Moment(item1.data.date, 'DD/MM/YYYY HH:mm')
        }
      }
    }
    for(var item2 of this.state.archive) {
      check = false
      if(item2.data.client === this.state.selected && !item2.data.group) {
        check = true
      } else if(item2.data.group && item2.data.clients !== undefined) {
        if(item2.data.clients.indexOf(this.state.selected) !== -1) {
          check = true
        }
      }
      if(check) {
        if(Moment(item2.data.date, 'DD/MM/YYYY HH:mm').isAfter(max)) {
          max = Moment(item2.data.date, 'DD/MM/YYYY HH:mm')
        }
        if(Moment(item2.data.date, 'DD/MM/YYYY HH:mm').isBefore(min)) {
          min = Moment(item2.data.date, 'DD/MM/YYYY HH:mm')
        }
      }
    }
    label = Moment(min).format('MMM YYYY')+' - '+Moment(max).locale(lang.language).format('MMM YYYY')
    if(type === 'total') {
      label = max.diff(min, 'months')
      if(label > 0) {
        label++
      }
    }
    if(min.isAfter(max)) {
      label = '-'
    }
    return label
  }


  getRetAll() {
    var label = '-'
    var total = 0
    for(var cl of this.state.clientsall) {
      var p1 = 0
      var min = Moment()
      var max = Moment('01/01/2000', 'DD/MM/YYYY')
      var check = false
      for(var item1 of this.state.sessions) {
        check = false
        if(item1.data.client === cl.id && !item1.data.group) {
          check = true
        } else if(item1.data.group && item1.data.clients !== undefined) {
          if(item1.data.clients.indexOf(cl.id) !== -1) {
            check = true
          }
        }
        if(check) {
          if(Moment(item1.data.date, 'DD/MM/YYYY HH:mm').isAfter(max)) {
            max = Moment(item1.data.date, 'DD/MM/YYYY HH:mm')
          }
          if(Moment(item1.data.date, 'DD/MM/YYYY HH:mm').isBefore(min)) {
            min = Moment(item1.data.date, 'DD/MM/YYYY HH:mm')
          }
        }
      }
      for(var item2 of this.state.archive) {
        check = false
        if(item2.data.client === cl.id && !item2.data.group) {
          check = true
        } else if(item2.data.group && item2.data.clients !== undefined) {
          if(item2.data.clients.indexOf(cl.id) !== -1) {
            check = true
          }
        }
        if(check) {
          if(Moment(item2.data.date, 'DD/MM/YYYY HH:mm').isAfter(max)) {
            max = Moment(item2.data.date, 'DD/MM/YYYY HH:mm')
          }
          if(Moment(item2.data.date, 'DD/MM/YYYY HH:mm').isBefore(min)) {
            min = Moment(item2.data.date, 'DD/MM/YYYY HH:mm')
          }
        }
      }
      p1 = max.diff(min, 'months')
      if(min.isAfter(max)) {
        p1 = 0
      }
      total += p1
    }
    label = (total/this.state.clientsall.length).toFixed(1)
    return label
  }


  setSelected(event) {
    this.setState({
      selected: event.target.value
    });
  }


  selectClient(id) {
    global.currentClient = id;
  }



  // Display stuff ------------------------------------------------------------



  renderAvatar(item) {
    for(var client of global.clients) {
      if(client.id === item) {
        return (
          HelperClients.getImage(client)
        )
      }
    }
  }


  renderClients() {
    if(this.state.clients.length === 0) {
      return (
        <div className="pt-50">
          <div className="empty small">
            <h4>{lang.t('billing:label.noclients')}</h4>
          </div>
        </div>
      )
    } else {
      
      var list = this.state.clients
      list.sort((a,b) => b.income - a.income);
      return (
        <div className="clear mt-10">
          {list.slice(0, 3).map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/clients/'+item.id+'/details'}>
              {this.renderAvatar(item.id)}
                <div className="main">
                  <h4>{item.name}</h4>
                  <p>{global.curSym}{item.income === undefined ? '0.00' : item.income.toFixed(2)}<br/>{item.payments} {item.payments === 1 ? lang.t('billing:label.payment-s') : lang.t('billing:label.payments-s')}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderClientSessions(item) {
    var label = '-'
    if(item.credits !== undefined) {
      for(var cred of item.credits) {
        if(cred.data.sessionsPaid > cred.data.sessionsTotal) {
          label = cred.data.sessionsPaid-cred.data.sessionsTotal+' '+lang.t('billing:label.unusedsessions')
          if(cred.data.sessionsPaid-cred.data.sessionsTotal === 1) {
            label = '1 '+lang.t('billing:label.unusedsession')
          }
        }
        if(cred.data.sessionsTotal > cred.data.sessionsPaid) {
          label = cred.data.sessionsTotal-cred.data.sessionsPaid+' '+lang.t('billing:label.unpaidsessions')
          if(cred.data.sessionsTotal-cred.data.sessionsPaid === 1) {
            label = '1 '+lang.t('billing:label.unpaidsession')
          }
        }
      }
    }
    return label
  }


  renderInactive() {
    if(this.state.inactive.length === 0) {
      return (
        <div className="col-12">
          <div className="listheader">
            <h3>{lang.t('header:title.clientswithoutpayments')}</h3>
          </div>
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('insights')}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t('billing:label.noclientswithoutpayments')}</h3>
            </div>
          </div>
        </div>
      )
    } else {
      var list = this.state.inactive
      list.sort((a,b) => a.data.name - a.data.name)
      return (
        <div className="col-12">
          <div className="listheader">
            <h3>{this.state.inactive.length} {this.state.inactive.length === 1 ? lang.t('header:title.clientwithoutpayments') : lang.t('header:title.clientswithoutpayments')}</h3>
          </div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/clients/'+item.id+'/details'} onClick={() => this.selectClient(item.id)}>
                <div className="col-6">
                  {this.renderAvatar(item.id)}
                  <p className="lft"><strong>{item.data.name}</strong></p>
                </div>
                <div className="col-4"><p>{this.renderClientSessions(item)}</p></div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('billing:button.viewclient')}</button>
                </div>
              </Link>
            </div>
          ))}
          <div className="sv-40 clear"></div>
        </div>
      )
    }
  }


  renderCLV() {
    var list = this.state.clientsall
    list.sort((a, b) => a.data.name.localeCompare(b.data.name))
    return (
      <div className="col-12 mb-10">
        <div className="listheader">
          <h3 className="lft mt-5">{lang.t('header:title.clv')}</h3>
          <select className="light rgt" onChange={event => this.setSelected(event)} value={this.state.selected}>
            {list.map(item => (
              <option value={item.id} key={item.id}>{item.data.name}</option>
            ))}
          </select>
        </div>
        <div className="clear sv-20"></div>
        <div className='info large col-3 clear'>
          <label>{lang.t('billing:label.lifetimevalue')}</label>
          {this.getLifetimeClient()}
          <span>{this.getLifeName()}</span>
        </div>
        <div className='info large col-3'>
          <label>{lang.t('billing:label.retention')}</label>
          <p>{this.getRetention('total')} {lang.t('billing:label.months')}</p>
          <span>{this.getRetention('dates')}</span>
        </div>
        <div className='info large col-3'>
          <label>{lang.t('billing:label.averageclv')}</label>
          {this.getLifetime()}
          <span>{lang.t('billing:label.allclients')}</span>
        </div>
        <div className='info large col-3'>
          <label>{lang.t('billing:label.averageretention')}</label>
          <p>{this.getRetAll()} {lang.t('billing:label.months')}</p>
          <span>{lang.t('billing:label.allclients')}</span>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderInsights() {
    return (
      <div>
        <div className="col-4 mt-20">
          <h3 className="mb-20">{lang.t('header:title.paymentspermonth')}</h3>
          <WidgetChartBar data={this.state.dataTop1} key1='Payments' colors={[global.themeColors[0]]} max={this.state.m1}/>
        </div>
        <div className="col-4 mt-20">
          <h3 className="mb-20">{lang.t('header:title.expensespermonth')}</h3>
          <WidgetChartBar data={this.state.dataTop2} key1='Expenses' colors={[global.themeColors[1]]} max={this.state.m2}/>
        </div>
        <div className="col-4 mt-20">
          <h3 className="mb-20">{lang.t('header:title.profitpermonth')}</h3>
          <WidgetChartBar data={this.state.dataTop3} key1='Profit' colors={[global.themeColors[2]]} max={this.state.m3} min={this.state.m3a}/>
        </div>
        <div className="col-12 clear mt-30">
          <h3 className="mb-20">{lang.t('header:title.incomeweek')}</h3>
          <WidgetChartGraph data={this.state.dataTop}/>
        </div>
        <div className="col-12 mb-20 mt-30">
          <div className='info large col-3'>
            <label>{lang.t('billing:label.incomegross')}</label>
            <p>{global.curSym}{this.state.income === undefined ? '0.00' : this.state.income.toFixed(2)}</p>
          </div>
          <div className='info large col-3'>
            <label>{lang.t('billing:label.incomenet')}</label>
            <p>{global.curSym}{this.state.net.toFixed(2)}</p>
          </div>
          <div className='info large col-3'>
            <label>{lang.t('billing:label.payments')}</label>
            <p>{this.state.transactions}</p>
          </div>
          <div className='info large col-3'>
            <label>{lang.t('billing:label.clientscharged')}</label>
            <p>{this.state.active}</p>
          </div>
          <div className="clear"></div>
        </div>

        <div className="col-6 mb-50">
          <h3 className="mb-20">{lang.t('header:title.paymentsweek')}</h3>
          <WidgetChartGraph data={this.state.dataClients}/>
        </div>
        <div className="col-3 mb-50">
          <h3 className="mb-20">{lang.t('header:title.paymenttypes')}</h3>
          <WidgetChartPie data={this.state.dataTypes} colors={[global.themeColors[0], global.themeColors[1]]}/>
        </div>
        <div className="col-3 mb-50">
          <h3 className="mb-20">{lang.t('header:title.clientspayments')}</h3>
          <WidgetChartPie data={this.state.dataActive} colors={[global.themeColors[0], global.themeColors[1]]}/>
        </div>

        <div className="col-4 mb-20 clear">
          <h3 className="mb-20">{lang.t('header:title.expensesweek')}</h3>
          <WidgetChartGraph data={this.state.dataExp}/>
        </div>
        <div className="col-3 mb-20">
          <h3 className="mb-20">{lang.t('header:title.expensetypes')}</h3>
          <WidgetChartPie data={this.state.dataCat} colors={[global.themeColors[0], global.themeColors[1]]}/>
        </div>
        <div className="col-3 mb-20">
          <h3>{lang.t('header:title.mostincome')}</h3>
          {this.renderClients()}
        </div>
        <div className="col-2 mb-50">
          <h3 className="mb-30">{lang.t('header:title.expenses')}</h3>
          <div className='info large clear mb-30'>
            <label>{lang.t('billing:label.totalspent')}</label>
            <p>{global.curSym}{this.state.amounte.toFixed(2)}</p>
          </div>
          <div className='info large clear'>
            <label>{lang.t('billing:label.expenseslogged')}</label>
            <p>{this.state.spent}</p>
          </div>
        </div>

        {this.renderCLV()}
        {this.renderInactive()}
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      if(global.maxPlans === 0) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='insights-locked2'/>
              <div className="sv-20 clear"></div>
            </div>
            <Header type='billing' active='insights' dates={true} date1={this.state.date1} date2={this.state.date2} setDate1={(event) => this.setDateStart(event)} setDate2={(event) => this.setDateEnd(event)}/>
            <Navigation active='billing' />
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              {this.renderInsights()}
              <div className="sv-20 clear"></div>
            </div>
            <Header type='billing' active='insights' dates={true} date1={this.state.date1} date2={this.state.date2} setDate1={(event) => this.setDateStart(event)} setDate2={(event) => this.setDateEnd(event)} setDates={() => this.setDates()}/>
            <Navigation active='billing' />
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-container">
            <ListEmpty id='insights-locked' type='simple'/>
          </div>
          <Header type='simple' title='Billing & Finance'/>
          <Navigation active='billing' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header'])(withRouter(withAuthorization(condition)(BillingInsightsPage)));