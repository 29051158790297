import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Message from '../../components/Message';
import Moment from 'moment';
import Resizer from "react-image-file-resizer";
import Connector from '../../data/Connector';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../../components/Session';




const SignUp2Page = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <SignUp2/>
    )}
  </AuthUserContext.Consumer>
);



class SignUp2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'form',
      name: '',
      phone: '',
      birth: '',
      code: '',
      error: [false, false, false],
      types: ['', '', '', '', ''],
      btype: 0,
      file: null,
      fileDisplay: null,
      loading: false,
      move1: '',
      move2: 'in',
      excus: '',
      exsub: '',
      country: 'au',
      lbs: false
    };
  }


  componentDidMount() {
    //Firebase.auth().signOut()
    document.title = 'Business Portal - PT Mate'
    global.uid = Firebase.auth().currentUser.uid;
    global.showOverview = false

    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    var ctry = 'us'
    var lbs = true
    if(tz.indexOf('Australia') !== -1) {
      ctry = 'au'
      lbs = false
    }
    this.setState({
      country: ctry,
      lbs: lbs
    })

    Connector.loadSubscriptions((result) => {
      for(var item of global.allSubs) {
        if(item.id === Firebase.auth().currentUser.uid) {
          this.setState({
            exsub: item.sub,
            excus: item.stripe,
          })
        }
      }
    })
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  onChangeBirth = event => {
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    if(valid) {
      this.setState({birth: Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')});
    }
  };


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  displayImage() {
    if(this.state.file === null) {
      return (
        <div className="avatar"><img src="/img/data-client.svg" alt="Select your avatar"/></div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}></div>
      )
    }
  }


  setCountry(event) {
    var lbs = false
    if(event.target.value === 'us') {
      lbs = true
    }
    this.setState({
      country: event.target.value,
      lbs: lbs
    });
  }


  checkUpdate() {
    var tmp = [false, false, false]
    this.setState({error: [false, false, false]})
    if(this.state.name !== '' && this.state.phone !== '' && this.state.birth !== '') {
      this.updateUser()
    } else {
      if(this.state.name === '') {
        tmp[0] = true
      }
      if(this.state.phone === '') {
        tmp[1] = true
      }
      if(this.state.birth === '') {
        tmp[2] = true
      }
      this.setState({error: tmp})
    }
  }


  updateUser() {
    global.uid = Firebase.auth().currentUser.uid;


    var bus = this.state.code
    if(bus === '') {
      bus = this.state.name
    }

    Connector.loadSubscriptions((result) => {
      for(var item of global.allSubs) {
        if(item.id === Firebase.auth().currentUser.uid) {
          this.setState({
            exsub: item.sub,
            excus: item.stripe,
          })
        }
      }
    })
    if(this.state.exsub === '' && this.state.excus === '') {
      var createStripe = Firebase.functions().httpsCallable('createStripeCustomerWeb');
      createStripe({email: Firebase.auth().currentUser.email, name: this.state.name, code: '', user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
        //
      })
    }
    Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
      owner: this.state.name,
      phone: '',
      name: bus,
      country: this.state.country,
      lbs: this.state.lbs
    })
    Firebase.database().ref('/public/'+Firebase.auth().currentUser.uid).update({
      color: 'blue',
      name: bus,
      logo: ''
    })
    Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
      name: this.state.name,
      phone: '',
      birth: this.state.birth,
      business: bus,
      country: this.state.country,
      lbs: this.state.lbs
    }).then(() => {
      this.setState({
        allowClick: false,
        move1: 'out'
      })
      setTimeout(() => {
        this.setState({
          type: 'form2',
          allowClick: true
        })
      }, 250);
      setTimeout(() => {
        this.setState({
          move2: '',
        })
      }, 300);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({
        type: 'form',
        move1: '',
        move2: 'in',
      })
    })
  }


  uploadImage() {
    var user = Firebase.auth().currentUser
    const sr = Firebase.storage().ref()
    const fr = sr.child('images/users/'+Firebase.auth().currentUser.uid+'.jpg')
    user.updateProfile({
      photoURL: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg'
    })
    global.userCurrency = 'aud'
    if(this.state.country === 'us') {
      global.userCurrency = 'usd'
    }
    Resizer.imageFileResizer(
      this.state.file,
      800,
      800,
      "JPEG",
      70,
      0,
      (uri) => {
        var ar = uri.split(',')
        fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
          Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
            image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
            imageDate: Moment().format('DD/MM/YYYY HH:mm')
          })
          Firebase.database().ref('/public/'+Firebase.auth().currentUser.uid).update({
            logo: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
          })
          Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
            image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
            imageDate: Moment().format('DD/MM/YYYY HH:mm')
          }).then(() => {
            if(this.state.exsub === '' && this.state.excus === '') {
              /*var createStripe = Firebase.functions().httpsCallable('createStripeCustomerWeb');
              createStripe({email: Firebase.auth().currentUser.email, name: this.state.name, code: '', user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
                this.props.history.push(ROUTES.OVERVIEW)
              })*/
              this.props.history.push(ROUTES.OVERVIEW)
            } else {
              this.updateUserSub()
            }
            global.uid = ''
            global.uidUser = ''
            //this.setState({type: 'form2'})
          })
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
          this.setState({loading: false})
        })
      },
      "base64",
      200,
      200
    );
  }
  

  updateUserSub() {
    var callFunction = Firebase.functions().httpsCallable('retrieveSubscription');
    callFunction({uid: Firebase.auth().currentUser.uid, sub: this.state.exsub, customer: this.state.excus}).then(function(result) {
      this.props.history.push(ROUTES.OVERVIEW)
    });
  }


  updateToggle(num, value) {
    var tmp = this.state.types
    if(tmp[num] === '') {
      tmp[num] = value
    } else {
      tmp[num] = ''
    }
    this.setState({
      types: tmp
    })
  }


  setBtype(value) {
    this.setState({
      btype: value
    })
  }


  sendToggle() {
    global.userCurrency = 'aud'
    if(this.state.country === 'us') {
      global.userCurrency = 'usd'
    }
    global.signup = true
    this.setState({loading: true})
    if(this.state.btype === 0) {
      var types = ''
      for(var item of this.state.types) {
        if(item !== '') {
          types += ','+item
        }
      }
      this.initIntercom(this.state.name, types)
      var user = Firebase.auth().currentUser
      user.updateProfile({
        displayName: this.state.name,
      })
      Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
        pin: this.state.phone,
        phone: this.state.phone,
      })
      Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
        types: types,
        experience: 0,
        phone: this.state.phone,
      }).then(() => {
        if(this.state.file !== null) {
          this.uploadImage()
        } else {
          if(this.state.exsub === '' && this.state.excus === '') {
            /*var createStripe = Firebase.functions().httpsCallable('createStripeCustomerWeb');
            createStripe({email: Firebase.auth().currentUser.email, name: this.state.name, code: '', user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
              this.props.history.push(ROUTES.OVERVIEW)
            })*/
            this.props.history.push(ROUTES.OVERVIEW)
          } else {
            this.updateUserSub()
          }
          global.uid = ''
          global.uidUser = ''
        }
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    } else {
      user.updateProfile({
        displayName: 'staff',
      })
      var createStripe = Firebase.functions().httpsCallable('sendStaffInternal');
      createStripe({name: this.state.name, user: Firebase.auth().currentUser.uid}).then(function(result) {})

      Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).remove()
      Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).remove()
      Firebase.database().ref('/usersStaff/'+Firebase.auth().currentUser.uid).update({
        phone: this.state.phone,
        name: this.state.name,
        birth: this.state.birth,
        uid: Firebase.auth().currentUser.uid,
        role: 'assistant',
        email: this.state.email,
        pushToken: '',
        space: '',
        country: this.state.country,
        status: 'active',
        image: '',
        imageDate: Moment().format('DD/MM/YYYY HH:mm'),
        lbs: this.state.lbs
      }).then(() => {
        window.location.pathname = '/staff/search'
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    }
  }


  initIntercom(name, types) {
    if(Firebase.auth().currentUser.displayName !== 'staff' && Firebase.auth().currentUser.displayName !== 'client') {
      window.Intercom("boot", {
        app_id: "gwe4xp9u",
        name: name, // Full name
        email: Firebase.auth().currentUser.email, // Email address
        phone: this.state.phone,
        user_id: Firebase.auth().currentUser.uid,
        created_at: Moment().format('X'),
        "Experience": 0,
        "Types": types
      });
      window.Intercom("update");
    }
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    var label = ''
    if(this.state.error[0]) {
      label += ' '+lang.t('auth:new.fullname')
    }
    if(this.state.error[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:new.phone')
    }
    if(this.state.error[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:new.dob')
    }
    if(this.state.error[0] || this.state.error[1] || this.state.error[2]) {
      return (
        <p className="error">{lang.t('auth:error.pleasereview')}: {label}</p>
      )
    }
  }


  renderForm2() {
    if(this.state.type === 'form2') {
      return (
        <div className={'inner '+this.state.move2}>
          <h1 className={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:new.page3.title')}</h1>
          <p className="mb-30">{lang.t('auth:new.page3.text')}</p> 
          <div className="form">
            <div className={this.state.types[0] === '' ? 'toggle clear' : 'toggle active'} onClick={() => this.updateToggle(0, 'pt')}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label" style={{textAlign: 'left'}}>{lang.t('auth:new.type1')}</p>
            <div className="clear sv-10"></div>
            <div className={this.state.types[1] === '' ? 'toggle clear' : 'toggle active'} onClick={() => this.updateToggle(1, 'group')}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label" style={{textAlign: 'left'}}>{lang.t('auth:new.type2')}</p>
            <div className="clear sv-10"></div>
            <div className={this.state.types[2] === '' ? 'toggle' : 'toggle active'} onClick={() => this.updateToggle(2, 'functional')}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label" style={{textAlign: 'left'}}>{lang.t('auth:new.type3')}</p>
            <div className="clear sv-10"></div>
            <div className={this.state.types[3] === '' ? 'toggle' : 'toggle active'} onClick={() => this.updateToggle(3, 'virtual')}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label" style={{textAlign: 'left'}}>{lang.t('auth:new.type4')}</p>
            <div className="clear sv-10"></div>
            <div className={this.state.types[4] === '' ? 'toggle' : 'toggle active'} onClick={() => this.updateToggle(4, 'online')}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label" style={{textAlign: 'left'}}>{lang.t('auth:new.type5')}</p>
            <div className="clear sv-20"></div>
            <label>{lang.t('auth:new.youare')}</label>
            <div className="radios">
              <button style={{marginTop:0}} className={this.state.btype === 0 ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setBtype(0)}>{lang.t('auth:new.owner')}</button>
              <button style={{marginTop:0}} className={this.state.btype === 1 ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setBtype(1)}>{lang.t('auth:new.staff')}</button>
            </div>

            <div className="sv-40 clear"></div>
            <button className="btn primary" onClick={() => this.sendToggle()}>{lang.t('auth:button.alldone')}</button>
          </div>
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.type === 'form') {
      return (
        <div className={'inner '+this.state.move1}>
          <div className="none">{this.displayImage()}
          <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
          <div className="space-10"></div></div>
          <h1 style={{marginBottom: 10}}>{lang.t('auth:new.page2.title')}</h1>
          <p className="mb-30" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:new.page2.text')}</p>
          <label>{lang.t('auth:new.fullname')}*</label>
          <input type="text" placeholder={lang.t('auth:new.fullname.placeholder')} name="name" value={this.state.name} onChange={this.onChange}/>
          <label>{lang.t('auth:new.phone')}*</label>
          <input type="number" placeholder={lang.t('auth:new.phone.placeholder')} name="phone" value={this.state.phone} onChange={this.onChange}/>
          <label>{lang.t('auth:new.dob')}*</label>
          <input type="date" placeholder="Your date of birth" value={Moment(this.state.birth, 'DD/MM/YYYY').format('YYYY-MM-DD')} onChange={this.onChangeBirth}/>
          <label>{lang.t('auth:new.businessname')}</label>
          <input type="text" placeholder={lang.t('auth:new.businessname.placeholder')} name="code" value={this.state.code} onChange={this.onChange}/>
          {this.renderError()}
          <label>{lang.t('auth:new.country')}</label>
          <select value={this.state.country} onChange={event => this.setCountry(event)}>
            <option value='au'>{lang.t('auth:new.country.au')}</option>
            <option value='us'>{lang.t('auth:new.country.us')}</option>
          </select>
          <div className="sv-50"></div>   
          <button className="btn primary" onClick={() => this.checkUpdate()}>{lang.t('auth:button.continue')}</button>
        </div>
      )
    }
  }


  renderLoading() {
    if(this.state.loading) {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p>{lang.t('auth:new.settingyouup')}</p>
        </div>
      )
    }
  }


  render() {
    return (
      <div className="register">
        <div className="content hero1">
          {this.renderForm()}
          {this.renderForm2()}
        </div>
        <div className="hero">
          <div className="reg-hero hero2">
            <div className="gradient"></div>
          </div>
        </div>
        {this.renderLoading()}
        <Message/>
      </div>
    )
  }
}


const condition = authUser => !!authUser;

export default withTranslation(['auth','common'])(withAuthorization(condition)(SignUp2Page));

