import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class HeaderProgramming extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? 'header',
      active: props.active ?? '',
    };
  }


  renderPlans() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.PLANS} className={this.state.active === 'plans' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:prog.plans')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.PLANS} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:prog.plans')}</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div>
          <Link to={ROUTES.PROGRAMMING} className={this.state.active === 'programs' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:prog.programs')}</p>
            <div className="bg"></div>
          </Link>
          {this.renderPlans()}
          <Link to={ROUTES.EXERCISES} className={this.state.active === 'movements' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:prog.movements')}</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.PROGRAMMING} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:prog.programs')}</div>
          </Link>
          {this.renderPlans()}
          <Link to={ROUTES.EXERCISES} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:prog.movements')}</div>
          </Link>
        </div>
      )
    }
  }
}


export default withTranslation(['nav'])(HeaderProgramming);