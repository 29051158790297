import React, { Component } from 'react';
import Moment from 'moment';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperPage from '../../helper/Page';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      session: '',
      date: '',
      program: null,
      exercises: global.exercises,
      clients: global.clients,
      user: '',
      best: null,
      attendees: [],
      current: 0,
      // Edit elements
      blocks: [],
      mins: [],
      secs: [],
      changed: false,
      isTraining: false,
      bestGroup: null
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Session Results - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveTrainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClients() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients
      }, () => {
        this.configureData()
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var tblk = []
      for(var j=0; j<60; j++) {
        tmp1.push(j+' min')
        tmp2.push(j+' sec')
      }

      var user = ''
      var program = null
      var id = ''
      var session = ''
      var date = ''
      var list = global.sessions
      var isTraining = false
      var attendees = []

      var arr = this.props.location.pathname.split('/')
      session = arr[arr.length-1]

      for(var s1 of global.archive) {
        if(s1.id === session) {
          list = global.archive
        }
      }

      for(var s2 of global.sessionsTraining) {
        if(s2.id === session) {
          list = global.sessionsTraining
          isTraining = true
        }
      }

      for(var s3 of global.archiveTraining) {
        if(s3.id === session) {
          list = global.archiveTraining
          isTraining = true
        }
      }

      for(var item of list) {
        if(item.id === session) {
          program = item.program[0]
          id = item.program[0].id
          date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('lll')
          if(item.data.group) {
            attendees = item.data.clients
          }

          for(var client of global.clients) {
            if(client.id === item.data.client) {
              user = client.id
              if(client.data.uid !== '') {
                user = client.data.uid
              }
              Connector.loadClientBest(user)
              setTimeout(() => {
                this.setState({
                  best: global.clientBest
                })
              }, 3500);
            }
          }

          if(item.data.group) {
            global.clientBestGroup = []
            for(var client2 of item.data.clients) {
              for(var cl of global.clients) {
                if(cl.id === client2 && cl.data.uid !== '') {
                  Connector.loadClientBestGroup(cl.data.uid)
                }
                if(cl.id === client2 && cl.data.uid === '') {
                  Connector.loadClientBestGroup(cl.id)
                }
              }
            }
            setTimeout(() => {
              this.setState({
                bestGroup: global.clientBestGroup
              })
            }, 3500);
          }

          for(var block of item.program[0].blocks) {
            var min = parseInt(block.data.rounds/60)
            var rmin = parseInt(block.data.rounds/60)
            var rsec = parseInt(block.data.rounds)-(rmin*60)
            if(block.data.timeRes !== undefined && block.data.timeRes !== null) {
              rmin = parseInt(block.data.timeRes/60)
              rsec = block.data.timeRes-(rmin*60)
            }
            var rming = []
            var rsecg = []
            var arrt = []
            var cycles = 1
            var simple = false

            if(block.data.cycles !== undefined) {
              cycles = block.data.cycles
            }
            if(item.data.group) {
              if(block.data.timeResGroup !== undefined && block.data.timeResGroup !== null) {
                arrt = block.data.timeResGroup
              }
              if(arrt.length === 0) {
                var att1 = 0
                while(att1 < attendees.length) {
                  rming.push(0)
                  rsecg.push(0)
                  att1++
                }
              } else {
                for(var tme of arrt) {
                  rming.push(parseInt(tme/60))
                  rsecg.push(tme-(parseInt(tme/60)*60))
                }
              }
            }

            if(block.data.simple !== undefined) {
              simple = block.data.simple
            }

            var ex = []
            for(var i=0; i<block.data.exName.length; i++) {
              var min1 = parseInt(block.data.exWork[i]/60)
              var min2 = parseInt(block.data.exRest[i]/60)
              var min3 = parseInt(block.data.exResWeight[i]/60)

              var arr1 = []
              var arr2 = []
              var m = []
              var s = []
              

              var rreps = ""
              var rweight = ""
              if(block.data.exRepsRounds !== undefined) {
                if(block.data.exRepsRounds.length > i) {
                  rreps = block.data.exRepsRounds[i]
                }
              }
              if(block.data.exWeightRounds !== undefined) {
                if(block.data.exWeightRounds.length > i) {
                  rweight = block.data.exWeightRounds[i]
                }
              }

              if(item.data.group) {
                arr1 = block.data.exResRepsGroup[i].split('-')
                if(arr1[0] === '' || arr1[0] === undefined || isNaN(arr1[0])) {
                  arr1.splice(0,1)
                }
                arr2 = block.data.exResWeightGroup[i].split('-')
                if(arr2[0] === '' || arr2[0] === undefined || isNaN(arr2[0])) {
                  arr2.splice(0,1)
                }
                

                if(arr1.length === 0) {
                  var at1 = 0
                  while(at1 < attendees.length) {
                    arr1.push(0)
                    at1++
                  }
                } 
                if(arr2.length === 0) {
                  var at2 = 0
                  while(at2 < attendees.length) {
                    arr2.push(0)
                    at2++
                  }
                }
                for(var im of arr2) {
                  var mins = parseInt(im/60)
                  m.push(parseInt(im/60))
                  s.push(parseInt(im)-(mins*60))
                }
              }

              var exWeightType = []
              var ei = 0
              while(ei < block.data.exName.length) {
                exWeightType.push('per')
                ei++
              }
              if(block.data.exWeightType !== undefined) {
                exWeightType = block.data.exWeightType
              }

              var rrr = ''
              if(block.data.exResRepsRounds !== undefined) {
                rrr = block.data.exResRepsRounds[i]
              }

              var wrr = ''
              if(block.data.exResWeightRounds !== undefined) {
                wrr = block.data.exResWeightRounds[i]
              }

              var unit = ''
              if(block.data.exUnits !== undefined) {
                if(block.data.exUnits.length > i) {
                  unit = block.data.exUnits[i]
                }
              }

              var notes = ''
              if(block.data.exNotes !== undefined) {
                if(block.data.exNotes.length > i) {
                  notes = block.data.exNotes[i]
                }
              }
              
              ex.push({
                id: block.data.exId[i],
                name: block.data.exName[i],
                tool: block.data.exTool[i],
                image: block.data.exImage[i],
                cat: block.data.exCat[i],
                type: block.data.exType[i],
                reps: block.data.exReps[i],
                repsRound: rreps,
                resReps: block.data.exResReps[i],
                resRepsRound: rrr,
                resRepsGroup: arr1,
                weight: block.data.exWeight[i],
                weightRound: rweight,
                resWeight: block.data.exResWeight[i],
                resWeightGroup: arr2,
                resWeightRound: wrr,
                weightType: exWeightType[i],
                work: block.data.exWork[i],
                rest: block.data.exRest[i],
                wmin: min1,
                wsec: block.data.exWork[i]-(min1*60),
                rmin: min2,
                rsec: block.data.exRest[i]-(min2*60),
                resMin: min3,
                resSec: block.data.exResWeight[i]-(min3*60),
                resMinGroup: m,
                resSecGroup: s,
                unit: unit,
                notes: notes,
              })
            }
            tblk.push({
              type: block.data.type,
              cat: block.data.cat,
              rounds: block.data.rounds,
              cycles: cycles,
              emom: block.data.emom,
              notes: block.data.notes,
              resNotes: block.data.notesRes,
              exercises: ex,
              min: min,
              sec: block.data.rounds-(min*60),
              resMin: rmin,
              resSec: rsec,
              resMinGroup: rming,
              resSecGroup: rsecg,
              show: false,
              simple: simple
            })
          }
        }
      }
    }

    this.setState({
      id: id,
      session: session,
      date: date,
      mins: tmp1,
      secs: tmp2,
      exercises: global.exercises,
      program: program,
      blocks: tblk,
      user: user,
      isTraining: isTraining,
      attendees: attendees
    })
  }


  getClientName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  setTimeMin(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resMin = event.target.value
    } else {
      tmp[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSec(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resSec = event.target.value
    } else {
      tmp[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeReps(event, num, index) {
    var tmp = this.state.blocks
    var val =  parseInt(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resReps = val
    } else {
      tmp[num].exercises[index].resRepsGroup[this.state.current] = val
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeRepsRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-]/g, "")
    val = val.replace('--', '-')
    var reps = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        reps += parseInt(a)
      }
      
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resReps = reps
      tmp[num].exercises[index].resRepsRound = val
    } else {
      tmp[num].exercises[index].resRepsGroup[this.state.current] = reps
      var vals = ''
      var ar2 = tmp[num].exercises[index].resRepsRound.split('|')
      ar2[this.state.current] = val
      for(var a2 of ar2) {
        vals += a2+'|'
      }
      tmp[num].exercises[index].resRepsRound = vals
    }
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeight(event, num, index) {
    var tmp = this.state.blocks
    var val = parseFloat(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resWeight = val
    } else {
      tmp[num].exercises[index].resWeightGroup[this.state.current] = val
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeightRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    var vals = ''
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-.]/g, "")
    val = val.replace('--', '-')
    val = val.replace('.-', '-')
    val = val.replace('. ', '')
    val = val.replace('..', '.')
    var weight = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        if(parseFloat(a) > weight) {
          weight = parseFloat(a)
        }
      }
      
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resWeight = weight
      tmp[num].exercises[index].resWeightRound = val
    } else {
      tmp[num].exercises[index].resWeightGroup[this.state.current] = weight
      vals = ''
      var ar2 = tmp[num].exercises[index].resWeightRound.split('|')
      ar2.splice(ar2.length-1,1)
      ar2[this.state.current] = val
      for(var a2 of ar2) {
        vals += a2+'|'
      }
      tmp[num].exercises[index].resWeightRound = vals
    }
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeMinEx(event, num, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resMin = event.target.value
    } else {
      tmp[num].exercises[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSecEx(event, num, index) {
    var tmp = this.state.blocks 
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resSec = event.target.value
    } else {
      tmp[num].exercises[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  toggleNotes(index) {
    var tmp = this.state.blocks
    tmp[index].show = !tmp[index].show
    this.setState({
      blocks: tmp,
    })
  }


  setNotes(event, index) {
    var tmp = this.state.blocks
    tmp[index].resNotes = event.target.value
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setAR1(event, index) {
    var tmp = this.state.blocks
    var reps = 0
    if(tmp.tmp2 !== undefined && tmp.tmp1 !== '') {
      reps = parseInt(tmp.tmp2)
    }
    for(var ex of tmp[index].exercises) {
      if(this.state.attendees.length === 0) {
        ex.resReps = ex.reps*parseInt(event.target.value)
      } else {
        ex.resRepsGroup[this.state.current] = ex.reps*parseInt(event.target.value)
      }
      if(reps > 0) {
        if(reps > ex.reps) {
          if(this.state.attendees.length === 0) {
            ex.resReps += ex.reps
          } else {
            ex.resRepsGroup[this.state.current] += ex.reps
          }
          reps -= ex.reps
        } else {
          if(this.state.attendees.length === 0) {
            ex.resReps += reps
          } else {
            ex.resRepsGroup[this.state.current] += reps
          }
          reps = 0
        }
      }
    }
    tmp.tmp1 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }


  setAR2(event, index) {
    var tmp = this.state.blocks
    var rounds = 0
    var reps = 0
    if(event.target.value !== '') {
      reps = parseInt(event.target.value)
    }
    if(tmp.tmp1 !== undefined && tmp.tmp1 !== '') {
      rounds = parseInt(tmp.tmp1)
    }
    for(var ex of tmp[index].exercises) {
      //ex.resReps = ex.reps*rounds
      if(this.state.attendees.length === 0) {
        ex.resReps = ex.reps*rounds
      } else {
        ex.resRepsGroup[this.state.current] = ex.reps*rounds
      }
      if(reps > 0) {
        if(reps > ex.reps) {
          if(this.state.attendees.length === 0) {
            ex.resReps += ex.reps
          } else {
            ex.resRepsGroup[this.state.current] += ex.reps
          }
          reps -= ex.reps
        } else {
          if(this.state.attendees.length === 0) {
            ex.resReps += reps
          } else {
            ex.resRepsGroup[this.state.current] += reps
          }
          reps = 0
        }
      }
    }
    tmp.tmp2 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }



  // Update results ------------------------------------------------------------



  changeClient(event) {
    this.updateResults()
    setTimeout(() => {
      this.setState({
        current: event
      })
    }, 200);
  }


  updateResults() {
    var tmp  = this.state.program.blocks
    for(var i=0; i<this.state.blocks.length; i++) {
      var block = this.state.blocks[i]
      var weight = []
      var reps = []
      var repsr = []
      var rarr = []
      var warr = []
      var tgarr = []
      var weightr = []
      for(var ex of block.exercises) {
        reps.push(ex.resReps)
        var rrl = ex.resRepsRound
        if(rrl[0] === '-') {
          rrl = rrl.substring(1, rrl.length)
        }
        if(rrl[rrl.length-1] === '-') {
          rrl = rrl.substring(0, rrl.length-1)
        }

        var wrl = ex.resWeightRound
        if(wrl[0] === '-') {
          wrl = wrl.substring(1, wrl.length)
        }
        if(wrl[wrl.length-1] === '-') {
          wrl = wrl.substring(0, wrl.length-1)
        }

        if(this.state.attendees.length > 0) {
          var ar1 = rrl.split('|')
          rrl = ''
          for(var a1 of ar1) {
            if(a1[0] === '-') {
              a1 = a1.substring(1, a1.length)
            }
            if(a1[a1.length-1] === '-') {
              a1 = a1.substring(0, a1.length-1)
            }
            if(a1 !== '') {
              rrl += a1+'|'
            }
          }
          var ar2 = wrl.split('|')
          wrl = ''
          for(var a2 of ar2) {
            if(a2[0] === '-') {
              a2 = a2.substring(1, a2.length)
            }
            if(a2[a2.length-1] === '-') {
              a2 = a2.substring(0, a2.length-1)
            }
            if(a2 !== '') {
              wrl += a2+'|'
            }
          }
        }

        repsr.push(rrl)
        weightr.push(wrl)

        if(this.state.attendees.length > 0) {
          var rass = ''
          for(var i1=0; i1<this.state.attendees.length; i1++) { 
            rass += '-'+ex.resRepsGroup[i1]
          }
          var trass = rass.replace("undefined", "0")
          rass = trass.substring(1,trass.length)
          rarr.push(rass)
        }
        if(ex.tool !== 6 && ex.tool !== 7) {
          weight.push(ex.resWeight)
          if(this.state.attendees.length > 0) {
            var wass1 = ''
            for(var i2=0; i2<this.state.attendees.length; i2++) {
              wass1 += '-'+ex.resWeightGroup[i2]
            }
            var wasf1 = wass1.replace("undefined", "0")
            wasf1 = wasf1.substring(1,wasf1.length)
            warr.push(wasf1)
          }
        } else {
          weight.push(parseInt(ex.resMin)*60+parseInt(ex.resSec))
          if(this.state.attendees.length > 0) {
            var wass2 = ''
            for(var i3=0; i3<this.state.attendees.length; i3++) {
              wass2 += '-'+(parseInt(ex.resMinGroup[i3])*60+parseInt(ex.resSecGroup[i3]))
            }
            var wasf2 = wass2.replace("undefined", "0")
            warr.push(wasf2)
          }
        }
        if(this.state.attendees.length === 0) {
          this.updateBest(ex)
        } else {
          this.updateBestGroup(ex)
        }
        
      }

      if(block.type === 0 || block.type === 5) {
        if(this.state.attendees.length > 0) {
          for(var bi1=0; bi1<this.state.attendees.length; bi1++) {
            var num = parseInt(block.resMinGroup[bi1])*60+parseInt(block.resSecGroup[bi1])
            if(isNaN(num)) {
              tgarr.push(0)
            } else {
              tgarr.push((parseInt(block.resMinGroup[bi1])*60+parseInt(block.resSecGroup[bi1])))
            }
          }
          tmp[i].data.timeResGroup = tgarr
          
        }
      }
      tmp[i].data.notesRes = block.resNotes
      tmp[i].data.timeRes = parseInt(block.resMin)*60+parseInt(block.resSec)
      tmp[i].data.exResWeight = weight
      tmp[i].data.exResWeightGroup = warr
      tmp[i].data.exResReps = reps
      tmp[i].data.exResRepsGroup = rarr

      tmp[i].data.exResRepsRounds = repsr
      tmp[i].data.exResWeightRounds = weightr

      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.session+'/workout/'+this.state.program.id+'/blocks/'+tmp[i].id).update(
        tmp[i].data
      ).then((data)=>{
        EventEmitter.dispatch('showMessage', 'Results successfully updated')
        setTimeout(() => {
          this.setState({
            changed: false
          })
        }, 100);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }


  updateBest(ex) {
    var update = true
    var final = ex.resWeight
    var actual = ex.resWeight
    var per = 0
    var tmp = this.state.best
    var type = "kg"
    if(global.spaceLbs) {
        type = "lb"
    }
    if(ex.weightType !== "per") {
        type = ex.weightType
    }
    for(var item of tmp) {
      if(item.id === ex.id) {
        if(ex.tool !== 6 && ex.tool !== 7) {
          if(ex.weight > 0 && ex.weightType === 'per') {
            final = ex.resWeight*(100/ex.weight)
            per = ex.weight
          }
          
          var rval = item.data.value
          var rtype = ""
          if(item.data.type !== undefined) {
            rtype = item.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = item.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = item.data.value*global.lbsDown
          }
          if(rval > final) {
            update = false
          }
        }
        if(item.data.value < ex.resWeight && (ex.tool === 6 || ex.tool === 7)) {
          update = false
        }
        if(update) {
          item.data.value = final
          this.setState({
            best: tmp
          })
        }
        
      }
      
    }
    var unit = ''
    if(ex.unit !== undefined) {
      unit = ex.unit
    }
    if(update && ex.tool !== 0 && ex.tool !== 9) {
      Firebase.database().ref('/records/'+this.state.user+'/'+ex.id).update({
        date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
        id: ex.id,
        name: ex.name,
        tool: ex.tool,
        value: final,
        actual: actual,
        percent: per,
        unit: unit,
        type: type
      })
    }
  }


  updateBestGroup(ex) {
    
    //for(var i=0; i<this.state.attendees.length; i++) {
      var update = true
      var final = 0
      var actual = 0
      var per = 0
      var id = this.state.attendees[this.state.current]
      var tmp = this.state.bestGroup
      var type = "kg"
      if(global.spaceLbs) {
          type = "lb"
      }
      if(ex.weightType !== "per") {
          type = ex.weightType
      }

      for(var client of global.clients) {
        if(client.id === this.state.attendees[this.state.current] && client.data.uid !== '') {
          id = client.data.uid
        }
      }
      if(ex.resWeightGroup !== undefined && ex.resWeightGroup.length > this.state.current) {
        final = parseFloat(ex.resWeightGroup[this.state.current])
        actual = parseFloat(ex.resWeightGroup[this.state.current])
        if(ex.weight > 0 && ex.weightType === 'per') {
          final = parseFloat(ex.resWeightGroup[this.state.current])*(100/ex.weight)
          per = ex.weight
        }
      }

      if(ex.tool === 0 && ex.tool === 9) {
        update = false
      }
      if(tmp !== null) {
        for(var cb of tmp) {
          if(cb.client === id && cb.id === ex.id) {
            var rval = cb.data.value
            var rtype = ""
            if(cb.data.type !== undefined) {
              rtype = cb.data.type
            }
            if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
              rval = cb.data.value*global.lbsUp
            }
            if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
              rval = cb.data.value*global.lbsDown
            }
            if(rval > final || rval === final) {
              update = false
            }

            if(update) {
              cb.data.value = final
              this.setState({
                bestGroup: tmp
              })
            }
          }
        }
      }

      if(final === 0) {
        update = false
      }
      var unit = ''
      if(ex.unit !== undefined) {
        unit = ex.unit
      }
      if(update) {
        Firebase.database().ref('/records/'+id+'/'+ex.id).update({
          //date: Moment().format('DD/MM/YYYY HH:mm'),
          date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
          id: ex.id,
          name: ex.name,
          tool: ex.tool,
          value: final,
          actual: actual,
          percent: per,
          unit: unit,
          type: type
        })
      }
    //}
  }



  // Display stuff ------------------------------------------------------------



  getSetTitle(block) {
    var label = 'set'
    if(block.exercises.length > 1) {
      label = 'dropset'
      var id = ''
      for(var ex of block.exercises) {
        if(ex.id !== id && id !== '') {
          label = 'superset'
        }
      }
    }
    return label
  }


  renderBlockTypeStatic(item) {
    var label = ''
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' round'+s
      if(item.emom) {
        label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMin(parseInt(item.exercises[0].work))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' rounds'
      if(item.rounds === 1) {
        label = ' - 1 round'
      }
      if(item.type === 2 && item.emom) { 
        if(item.exercises[0].rest === 0) {
          label += ' of '+HelperCal.getDurationMin(parseInt(item.exercises[0].work))+' work & no rest'
        } else {
          label += ' of '+HelperCal.getDurationMin(parseInt(item.exercises[0].work))+' work & '+HelperCal.getDurationMin(parseInt(item.exercises[0].rest))+' rest'
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.getSetTitle(item)
      if(item.rounds > 1) {
        label += 's'
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' rounds - Cap: '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderRepsTitleStatic(item, type, unit) {
    var label = 'Target reps'
    if(type === 'result') {
      label = 'Total reps done'
    }
    if(unit !== '' && unit !== undefined && unit !== 'reps') {
      if(unit === 'dist') {
        label = 'Target distance'
        if(type === 'result') {
          label = 'Distance done (m)'
        }
      }
      if(unit === 'cals') {
        label = 'Target calories'
        if(type === 'result') {
          label = 'Calories done (cal)'
        }
      }
      if(unit === 'time') {
        label = 'Target time'
        if(type === 'result') {
          label = 'Time done (s)'
        }
      }
    } else {
      if(item === 6 || item === 7) {
        label = 'Target distance'
        if(type === 'result') {
          label = 'Distance done (m)'
        }
      }
    }
    return label
  }


  renderRepsStatic(item, num) {
    var label = item.reps
    var wlabel = ''
    if(item.reps === 0) {
      label = ''
    }

    if(item.weight !== 0 && item.weight !== 100 && item.weightType === 'per') {
      wlabel = ' at '+item.weight+'% of 1RM'
    }
    if(item.weight !== 0 && item.weight !== 100 && item.weightType !== 'per') {
      wlabel += ' with '+item.weight+' '+item.weightType
    }
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 5) {
      label = ''
      if(item.repsRound !== "" && item.repsRound !== "0") {
        label = item.repsRound.replaceAll('-', ' - ')
      }
      if(item.weightRound !== "" && item.weightRound !== '0') {
        if(item.weightType === 'kg') {
          wlabel = ' with '+item.weightRound.replaceAll('-', ' - ')+' kg'
        } else if(item.weightType === 'lb') {
          wlabel = ' with '+item.weightRound.replaceAll('-', ' - ')+' lb'
        } else {
          wlabel = ' at '+item.weightRound.replaceAll('-', ' - ')+' %'
        }
      }
    } 

    if(item.unit !== undefined && item.unit !== '') {
      if(item.unit === 'dist') {
        label += 'm'
      }
      if(item.unit === 'cals') {
        label += 'cal'
      }
      if(item.unit === 'time') {
        label += 's'
      }
    } else {
      if(item.tool === 6 || item.tool === 7) {
        label += 'm'
      }
      if(item.tool === 27) {
        label += 'cal'
      }
    }
    
    return [label, wlabel]
  }


  renderRepField(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={item.resRepsRound} onChange={(val) => this.onChangeRepsRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resReps} onChange={(val) => this.onChangeReps(val, num, index)}/>
      )
    }
  }


  getResGR(item) {
    var label = ''
    if(item.resRepsRound !== undefined) {
      var ar = item.resRepsRound.split('|')
      if(ar.length > this.state.current) {
        label = ar[this.state.current]
      }
    }
    if(label === '') {
      label = item.resRepsGroup[this.state.current]
    }
    return label
  }


  renderRepFieldGroup(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={this.getResGR(item)} onChange={(val) => this.onChangeRepsRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resRepsGroup[this.state.current]} onChange={(val) => this.onChangeReps(val, num, index)}/>
      )
    }
  }


  renderReps(item, num, index) {
    if(this.state.blocks[num].type !== 0) {
      if(this.state.isTraining) {
        return (
          <div className="col-4 info">
            <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
            <p>{item.resReps}</p>
          </div>
        )
      } else {
        if(this.state.attendees.length === 0) {
          return (
            <div className="col-4">
              <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
              {this.renderRepField(item, num, index)}
            </div>
          )
        } else {
          return (
            <div className="col-4">
              <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
              {this.renderRepFieldGroup(item, num, index)}
            </div>
          )
        }
      }
    }
  }


  renderWeightField(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={item.resWeightRound} onChange={(val) => this.onChangeWeightRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resWeight} onChange={(val) => this.onChangeWeight(val, num, index)}/>
      )
    }
  }


  getWeightGR(item) {
    var label = ''
    if(item.resWeightRound !== undefined && item.resWeightRound !== '') {
      var ar = item.resWeightRound.split('|')
      if(ar.length > this.state.current) {
        label = ar[this.state.current]
      }
    } else if(item.resWeightGroup !== undefined) {
      if(item.resWeightGroup.length > this.state.current) {
        label = item.resWeightGroup[this.state.current]
      }
    }
    return label
  }


  renderWeightFieldGroup(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={this.getWeightGR(item)} onChange={(val) => this.onChangeWeightRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resWeightGroup[this.state.current]} onChange={(val) => this.onChangeWeight(val, num, index)}/>
      )
    }
  }


  renderStaticWeight(item) {
    var label = item.resWeight+' '+(global.spaceLbs ? 'lb' : 'kg')
    if(item.weightType !== 'per') {
      label = item.resWeight+' '+item.weightType
    }
    if(item.resWeightRound !== '') {
      var tmp = item.resWeightRound
      if(tmp.charAt(tmp.length-1) === '-') {
        tmp = item.resWeightRound.substring(0, item.resWeightRound.length-1)
      }
      label = tmp+' '+(global.spaceLbs ? 'lb' : 'kg')
      if(item.weightType !== 'per') {
        label = tmp+' '+item.weightType
      }
    }
    return label
  }


  renderWeight(item, num, index) {
    if(item.tool !== 0 && item.tool !== 6 && item.tool !== 7 && item.tool !== 9) {
      if(this.state.isTraining) {
        return (
          <div className="col-4 info">
            <label>Weight used</label>
            <p>{this.renderStaticWeight(item)}</p>
          </div>
        )
      } else {
        if(this.state.attendees.length === 0) {
          return (
            <div className="col-4">
              <label>Weight used ({item.weightType !== 'per' ? item.weightType : (global.spaceLbs ? 'lb' : 'kg')})</label>
              {this.renderWeightField(item, num, index)}
            </div>
          )
        } else {
          return (
            <div className="col-4">
              <label>Weight used ({item.weightType !== 'per' ? item.weightType : (global.spaceLbs ? 'lb' : 'kg')})</label>
              {this.renderWeightFieldGroup(item, num, index)}
            </div>
          )
        }
      }
    }
  }


  renderNotesStatic(item) {
    if(item.notes !== '') {
      return (
        <div className="clear" style={{paddingTop: 5}}>
          <span className="program-notes movement">{item.notes}</span>
        </div>
      )
    }
  }


  renderExerciseRest(block, index) {
    if(block.type === 2 && !block.emom && block.exercises[index].rest !== 0 && block.exercises[index].rest !== undefined && block.exercises[index].rest !== '') {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
            <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>Rest</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].rest))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderTimeStatic(block, index) {
    if((block.type === 1 || block.type === 2) && !block.emom) {
      return (
        <div className="col-2 program-time">
          <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].work))}</p>
        </div>
      )
    }
  }


  renderExercisesStatic(num) {
    var block = this.state.blocks[num]
    if(!block.simple) {
      return (
        <div>
          {block.exercises.map((item, index) => (
            <div>
              <div className="box list simple mb-10" key={index}>
                <div className="col-10">
                  <div className="icon primary mr-10">
                    <div className={'inner '+global.exCatsIcons[block.cat]}></div>
                  </div>
                  <p className="lft"><strong>{this.renderRepsStatic(item, num)[0]} {item.name}</strong> {this.renderRepsStatic(item, num)[1]}</p>
                </div>
                {this.renderTimeStatic(block, index)}
                <div className="clear"></div>
                <div className="program-results">
                  {this.renderReps(item, num, index)}
                  {this.renderWeight(item, num, index)}
                  {this.renderNotesStatic(item)}
                  <div className="clear"></div>
                </div>
                <div className="clear"></div>
              </div>
              {this.renderExerciseRest(block, index)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderSessionNotes(item, index) {
    if(this.state.isTraining) {
      if(item.resNotes !== '') {
        return (
          <p className="small mb-20 pl-10" style={{whiteSpace: 'pre-wrap'}}><strong>Session notes</strong><br/>{item.resNotes}</p>
        )
      }
    } else {
      return (
        <div>
          <button className={item.show ? 'width-12 none' : 'width-12 btn tertiary small add'} onClick={() => this.toggleNotes(index)}>Show session notes</button>
          <div className={item.show ? 'notes' : 'notes none'}>
            <button className="width-12 btn tertiary small minus" onClick={() => this.toggleNotes(index)}>Hide sesion notes</button>
            <div className="sv-10"></div>
            <textarea value={item.resNotes} onChange={(event) => this.setNotes(event, index)}></textarea>
          </div>
        </div>
      )
    }
  }


  renderStaticNotes(item) {
    if(item.notes !== '') {
      return <p className={item.simple ? 'mb-20 pl-10' : 'small mb-20 pl-10'} style={{whiteSpace: 'pre-wrap'}}>{item.notes}</p>
    }
  }


  renderTime(item, index) {
    if(item.type === 5) {
      if(this.state.isTraining) {
        return (
          <div className="info">
            <label>Completion time</label>
            <p>{HelperCal.getDurationMin(item.resMin*60+item.resSec)}</p>
          </div>
        )
      } else {
        if(this.state.attendees.length === 0) {
          return (
            <div className="mb-20">
              <div className="form-item col-3">
                <label>Completion time</label>
                <select value={item.resMin} onChange={event => this.setTimeMin(event, index)}>
                  {this.state.mins.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <select value={item.resSec} onChange={event => this.setTimeSec(event, index)}>
                  {this.state.secs.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="clear"></div>
            </div>
          )
        } else {
          return (
            <div className="mb-20">
              <div className="col-3">
                <label>Completion time</label>
                <select value={item.resMinGroup[this.state.current]} onChange={event => this.setTimeMin(event, index)}>
                  {this.state.mins.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <select value={item.resSecGroup[this.state.current]} onChange={event => this.setTimeSec(event, index)}>
                  {this.state.secs.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="clear"></div>
            </div>
          )
        }
      }
    } else if(item.type === 0) {
      return (
        <div className="mb-20">
          <div className="col-3">
            <label>Finished rounds</label> 
            <input type="number" className="small"  onChange={(val) => this.setAR1(val, index)}/>
          </div>
          <div className="col-3">
            <label>Reps</label>
            <input type="number" className="small" onChange={(val) => this.setAR2(val, index)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderStaticRest(block) {
    if(block.type === 4 && block.exercises[0].work !== 0 && block.exercises[0].work !== undefined) {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
          <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>Rest</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[0].work))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBlockTitleStatic(item) {
    if(item.name !== '' && item.name !== undefined) {
      return item.name
    } else {
      return global.exCats[item.cat]
    }
  }


  renderBlocksStatic() {
    return (
      <div>
        {this.state.blocks.map((item, index) => (
          <div key={index}>
            <div className="clear mb-50">
              <label>{this.renderBlockTitleStatic(item)}</label>
              <h3 className="clear lft mb-20">{global.proTypes[item.type]} {this.renderBlockTypeStatic(item)}</h3>
              <div className="clear"></div>
              {this.renderTime(item, index)}
              {this.renderExercisesStatic(index)}
              {this.renderStaticRest(item)}
              {this.renderStaticNotes(item)}
              {this.renderSessionNotes(item, index)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderButtonTop() {
    if(this.state.changed) {
      return (
        <div className="program-float">
          <p className="mb-10">There are unsaved changes</p>
          <button className="btn tertiary small width-12" onClick={() => this.updateResults()}>Update results</button>
        </div>
      )
    }
  }


  renderButton() {
    if(this.state.changed) {
      return (
        <div>
          <div className="width-6">
            <button className="btn primary" onClick={() => this.updateResults()}>Update results</button>
          </div>
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderHeaderSelect() {
    if(this.state.attendees.length > 0) {
      return (
        <div className="rgt">
          <label className="lft mr-10">Client</label>
          <select value={this.state.current} className="lft light large" onChange={(event) => this.changeClient(event.target.value)}>
            {this.state.attendees.map((item, index) => (
              <option value={index} key={item}>{this.getClientName(item)}</option>
            ))}
          </select>
        </div>
      )
    }
  }


  renderBenchmark() {
    if(this.state.program.data.benchmark) {
      return (
        <div className="infobox large green mb-20">
          <p>This is a Benchmark Program</p>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
      if(this.state.program === null) {
        return (
          <div></div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar mt-20">
              {this.renderBenchmark()}
              <div className="program-header mb-60">
                <div className={'icon large lft mr-20 '+HelperCal.getColor(this.state.program.data.time)}>
                  <p>{this.state.program.data.time}'</p>
                </div>
                <div className="main">
                  <h2>{this.state.program.data.name}</h2>
                  <p className="mb-10">{this.state.program.data.desc}</p>
                </div>
                <div className="clear"></div>
              </div>
              {this.renderBlocksStatic()}
              <div className="clear sv-30"></div>
              {this.renderButton()}
            </div>
            <div className="header">
              <div className="content">
                <Link to={this.state.attendees.length > 0 ? '/calendar/group-session/'+this.state.session : '/calendar/session/'+this.state.session} className="back lft mr-10">
                  <div className="circle"></div>
                  <div className="arrow"></div>
                </Link>
                <h2 className="lft">{this.state.isTraining ? 'Training Results' : 'Session Results'} - {this.state.date}</h2>
                {this.renderHeaderSelect()}
                <div className="clear"></div>
              </div>
            </div>
            <Navigation active='calendar' />
            {this.renderButtonTop()}
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='results-locked' type='simple'/>
          </div>
          <Header title='Session Results' link={ROUTES.CALENDAR}/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ResultsPage));