import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HelperClients from '../../helper/Clients';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ListAvatar extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      client:  props.client,
      columns: props.columns,
      link: props.link ?? '',
      button: props.button ?? lang.t('common:button.viewdetails')
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      client:  props.client,
      columns: props.columns,
      link: props.link ?? '',
      button: props.button ?? lang.t('common:button.viewdetails')
    }
  }


  render() {
    var list = []
    if(this.state.columns !== undefined) {
      for(var col of this.state.columns) {
        list.push(col)
      }
    }
    list.splice(0, 1)
    if(this.state.link !== '') {
      return (
        <div className="box list simple highlight mb-10">
          <Link to={this.state.link}>
            <div className={"col-"+this.state.columns[0].width+(this.state.columns[0].w1280 === undefined ? '' : ' col-'+this.state.columns[0].w1280+'-1280')}>
            {HelperClients.getImage(this.state.client)}
              <p><strong>{this.state.columns[0].text}</strong></p>
            </div>
            {list.map(item => (
              <div className={"col-"+item.width+(item.w1280 === undefined ? '' : ' col-'+item.w1280+'-1280')} key={item.text}>
                <p>{item.text}</p>
              </div>
            ))}
            <div className="col-2">
              <button className="btn tertiary small rgt">{this.state.button}</button>
            </div>
            <div className="clear"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div className="box list simple highlight mb-10" onClick={() => this.props.clickElement()}>
          <div className={"col-"+this.state.columns[0].width+(this.state.columns[0].w1280 === undefined ? '' : ' col-'+this.state.columns[0].w1280+'-1280')}>
          {HelperClients.getImage(this.state.client)}
            <p><strong>{this.state.columns[0].text}</strong></p>
          </div>
          {list.map(item => (
            <div className={"col-"+item.width+(item.w1280 === undefined ? '' : ' col-'+item.w1280+'-1280')} key={item.text}>
              <p>{item.text}</p>
            </div>
          ))}
          <div className="col-2">
            <button className="btn tertiary small rgt">{this.state.button}</button>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }
}


export default withTranslation(['common'])(ListAvatar);