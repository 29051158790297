import React, { Component } from 'react';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import EventEmitter from '../../helper/Emitter';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import ListEmpty from '../List/empty';
import Moment from 'moment';



class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: global.chats,
      chatsGroup: global.chatsGroup,
      clients: global.clients,
      staff: global.userStaff,
      show: false,
      hidden: 'hidden',
      mode: 'list',
      name: 'Client',
      search: '',
      chat: '',
      messages: [],
      text: '',
      client: '',
      images: [],
      // Form
      available: [],
      selected: [],
      selectedInit: [],
      savailable: [],
      sselected: [],
      sselectedInit: [],
      value: '',
      error: ['', '']
    };
  }

  componentDidMount() {
  	EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('chatsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('chatsGroupLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var tmp = []
    var tmp2 = []
    var tmp3 = []
    var tmp4 = []

    var list = []
    var list2 = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
      for(var st of global.userStaff) {
        list2.push(st.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
        if(loc.id === global.loc && loc.data.staff !== undefined) {
          list2 = loc.data.staff
        }
      }
    }


  	for(var item of global.clients) {
      if(item.data.uid !== '' && list.indexOf(item.id) !== -1) {
        tmp2.push(item)
        if(this.state.search === '') {
          tmp.push(item)
        } else if(this.state.search !== '' && item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
          tmp.push(item)
        }
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.data.uid !== '' && list.indexOf(item.id) !== -1) {
        tmp2.push(item2)
        if(this.state.search === '') {
          tmp.push(item2)
        } else if(this.state.search !== '' && item2.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
          tmp.push(item2)
        }
      }
    }
    for(var item3 of global.userStaff) {
      if(list2.indexOf(item3.id) !== -1) {
        if(item3.data.role === 'manager' || item3.data.role === 'trainer' || item3.data.role === 'admin' || item3.data.role === 'cs') {
          tmp3.push(item3)
        }
      }
    }
    for(var item4 of global.chatsGroup) {
      if(this.state.search === '') {
        tmp4.push(item4)
      } else if(this.state.search !== '' && item4.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
        tmp4.push(item4)
      }
    }

    var messages = []
    if(this.state.mode === 'chat' || this.state.mode === 'edit') {
      for(var chat of global.chats) {
        if(chat.id === this.state.chat) {
          messages = chat.messages
        }
      }
      for(var chat2 of global.chatsGroup) {
        if(chat2.id === this.state.chat) {
          messages = chat2.messages
        }
      }
    }
    for(var msg of messages) {
      msg.date = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss')
      if(msg.data.timestamp !== undefined) {
        msg.date = Moment(msg.data.timestamp, 'X')
      }
    }
    this.setState({
      clients: tmp,
      chats: global.chats,
      chatsGroup: tmp4,
      messages: messages,
      available: tmp2,
      staff: tmp3,
      savailable: tmp3,
    }, () => {
      if(this.state.mode === 'chat' && this.state.chat !== '') {
        this.scrollToBottom()
      }
    })
  }


  toggleChat() {
    var tmp = this.state.show
    if(!tmp) {
      this.setState({
        show: true,
        mode: 'list',
        hidden: ''
      })
    } else {
      if(this.state.chat !== '' && this.state.mode === 'chat') {
        this.closeChat()
      }
      this.setState({
        hidden: 'hidden',
        show: false
      })
    }
  }


  getInfo(item) {
    var hasDate = false
    var label = ''
    for(var chat of this.state.chats) {
      if(chat.data.client === item) {
        var date = Moment('01/01/1900', 'DD/MM/YYYY').format('X')
        for(var msg of chat.messages) {
          var mdate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
          var ddate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss')
          if(msg.data.timestamp !== undefined) {
            mdate = Moment(msg.data.timestamp, 'X').format('X')
            ddate = Moment(msg.data.timestamp, 'X')
          }
          if(mdate > date) {
            hasDate = true
            date = mdate
            label = this.getDate(ddate)
          }
        }
      }
    }
    if(hasDate) {
      return (
        <p>Last message<br/>{label}</p>
      )
    } else {
      return (
        <p>Click to start<br/>a conversation</p>
      )
    }
  }


  getInfoGroup(item) {
    var hasDate = false
    var label = ''
    var date = Moment('01/01/1900', 'DD/MM/YYYY').format('X')
    for(var msg of item.messages) {
      var mdate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
      var ddate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss')
      if(msg.data.timestamp !== undefined) {
        mdate = Moment(msg.data.timestamp, 'X').format('X')
        ddate = Moment(msg.data.timestamp, 'X')
      }
      if(mdate > date) {
        hasDate = true
        date = mdate
        label = this.getDate(ddate)
      }
    }
    if(hasDate) {
      return (
        <p>{item.clients.length+item.staff.length+1} members<br/>Last: {label}</p>
      )
    } else {
      return (
        <p>{item.clients.length+item.staff.length+1} members<br/>Start a conversation</p>
      )
    }
  }


  getBullet(item) {
    var show = false
    for(var chat of this.state.chats) {
      if(chat.id === item.data.chat) {
        for(var msg of chat.messages) {
          if(parseInt(Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')) > parseInt(Moment(chat.data.dateTrainer, 'DD/MM/YYYY HH:mm:ss').format('X')) && msg.data.sender !== global.uidUser) {
            show = true
          }
        }
      }
    }
    for(var chat2 of this.state.chatsGroup) {
      if(chat2.id === item.id) {
        for(var msg2 of chat2.messages) {
          if(parseInt(Moment(msg2.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')) > parseInt(Moment(chat2.data.dateTrainer, 'DD/MM/YYYY HH:mm:ss').format('X')) && msg2.data.sender !== global.uidUser) {
            show = true
          }
        }
      }
    }
    if(show) {
      return (
        <div className="dot"></div>
      )
    }
  }


  getDate(date) {
    var label =  Moment(date).format('llll')
    var dt = Moment(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY') 
    var tdy = Moment().format('DD/MM/YYYY')
    var yst = Moment().add(-1, 'days').format('DD/MM/YYYY')
    if(dt === tdy) {
      label = 'Today '+Moment(date, 'DD/MM/YYYY HH:mm:ss').format('LT')
    }
    if(dt === yst) {
      label = 'Yesterday '+Moment(date, 'DD/MM/YYYY HH:mm:ss').format('LT')
    }
    return label
  }


  getImage(name) {
    var tmp = this.state.images
    var found = false
    var label = ''
    for(var image of this.state.images) {
      if(image.indexOf(name) !== -1) {
        found = true
        label = image
      }
    }
    if(!found) {
      var img = '/images/messaging/'+this.state.chat+'/'+name+'.jpg'
      Firebase.storage().ref().child(img).getDownloadURL().then((url) => {
        tmp.push(url)
        this.setState({images: tmp})
      }).catch((error) => {})
    }

    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.data.uid === id) {
        client = item
      }
    }
    return client
  }


  getClientName(id) {
    var label = 'Deleted Client'
    for(var item of global.clients) {
      if(item.data.uid === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.userStaff) {
      if(item2.id === id) {
        label = item2.data.name
      }
      if(item2.id === global.uidUser) {
        label = 'Yourself'
      }
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  filterClients(event) {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureData()
    })
  }


  selectChat(item) {
    var id = global.uidUser+item.data.uid
    var messages = []
    for(var chat of this.state.chats) {
      if(chat.id === id) {
        messages = chat.messages
      }
    }
    for(var msg of messages) {
      if(Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss') < Moment().add(-30, 'days')) {
        if(msg.data.image !== undefined && msg.data.image !== "") {
          Firebase.storage().ref('images/'+chat.id+'/'+msg.data.image+'.jpg').delete()
        }
        Firebase.database().ref('/messaging/'+id+'/messages/'+msg.id).remove().then(() => {
          this.setMessages()
        }).catch((error)=>{})
      }
    }
    this.setState({
      mode: 'chat',
      name: item.data.name,
      chat: id,
      messages: messages,
      text: '',
      client: item.data.uid,
      images: [],
    }, () => {
      setTimeout(() => {
        this.scrollToBottom()
      }, 50);
    })
  }


  selectChatGroup(id) {
    var messages = []
    for(var chat of this.state.chatsGroup) {
      if(chat.id === id) {
        messages = chat.messages
        for(var m of chat.messages) {
          if(m.data.image !== "") {
            console.log(m.data.image)
          }
         }
      }
    }
    for(var msg of messages) {
      if(Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss') < Moment().add(-30, 'days')) {
        if(msg.data.image !== undefined && msg.data.image !== "") {
          Firebase.storage().ref('images/'+chat.id+'/'+msg.data.image+'.jpg').delete()
        }
        Firebase.database().ref('/messagingGroup/'+global.uidUser+'/'+id+'/messages/'+msg.id).remove().then(() => {
          this.setMessages()
        }).catch((error)=>{})
      }
    }
    this.setState({
      mode: 'chat',
      name: 'Group Chat',
      chat: id,
      messages: messages,
      client: 'group',
      text: '',
      images: [],
    }, () => {
      setTimeout(() => {
        this.scrollToBottom()
      }, 50);
    })
  }


  scrollToBottom() {
    const scrollHeight = this.divElement.scrollHeight;
    const height = this.divElement.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.divElement.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }


  setMessages() {
    var messages = []
    for(var chat of this.state.chats) {
      if(chat.id === this.state.chat) {
        messages = chat.messages
      }
    }
    for(var chat2 of this.state.chatsGroup) {
      if(chat2.id === this.state.chat) {
        messages = chat2.messages
      }
    }
    this.setState({
      messages: messages
    })
  }


  closeChat() {
    this.setState({
      mode: 'list',
      chat: ''
    })
    if(this.state.client !== 'group') {
      if(HelperBusiness.getRoleIncl('')) {
        Firebase.database().ref('/messaging/'+this.state.chat).update({
          dateTrainer: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timeTrainer: parseInt(Moment().format('X')),
        })
      } else {
        Firebase.database().ref('/messaging/'+this.state.chat).update({
          dateTrainer: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timeTrainer: parseInt(Moment().format('X')),
        })
      }
      
      var hasChat = false
      for(var item of this.state.chats) {
        if(item.id === this.state.chat) {
          hasChat = true
        }
      }
      if(!hasChat) {
        Firebase.database().ref('/messaging/'+this.state.chat).update({
          dateTrainer: Moment().format('DD/MM/YYYY HH:mm:ss'),
          dateClient: '01/01/1900 00:00:00',
          timeTrainer: parseInt(Moment().format('X')),
          nameTrainer: global.userName,
          nameClient: this.state.name,
          trainer: global.uidUser,
          client: this.state.client,
          id: this.state.chat
        })
      } else {
        this.updateItem()
      }
    } else {
      if(global.uidUser === global.uid) {
        Firebase.database().ref('/messagingGroup/'+global.uidUser+'/'+this.state.chat).update({
          dateTrainer: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timeTrainer: parseInt(Moment().format('X')),
        })
      } else {
        Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+"/staff/"+global.uidUser).update({
          date: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timestamp: parseInt(Moment().format('X')),
        })
      }
      
    }
    
  }


  onChange(event) {
    this.setState({text: event.target.value});
  };


  sendMessage() {
    if(this.state.text !== '' && this.state.client !== 'group') {
      var text = this.state.text
      var hasChat = false
      for(var item of this.state.chats) {
        if(item.id === this.state.chat) {
          hasChat = true
        }
      }
      if(!hasChat) {
        Firebase.database().ref('/messaging/'+this.state.chat).update({
          dateTrainer: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timeTrainer: parseInt(Moment().format('X')),
          dateClient: '01/01/1900 00:00:00',
          nameTrainer: global.userName,
          nameClient: this.state.name,
          trainer: global.uidUser,
          client: this.state.client,
          id: this.state.chat
        })
      }
      Firebase.database().ref('/messaging/'+this.state.chat+'/messages').push({
        date: Moment().format('DD/MM/YYYY HH:mm:ss'),
        timestamp: parseInt(Moment().format('X')),
        image: '',
        sender: global.uidUser,
        text: text
      })
      var name = ''
      if(HelperBusiness.getRole('trainer,admin,cs')) {
        name = ' ('+global.userBusiness+')'
      }
      for(var client1 of global.clients) {
        if(client1.data.uid === this.state.client && client1.data.pushToken !== undefined && client1.data.pushToken !== '') {
          Connector.sendPushNotification(client1.data.pushToken, global.userName+name, text, 'chat', this.state.chat)
        }
      }
      
      this.setState({
        text: ''
      })
    } else if(this.state.text !== '' && this.state.client === 'group') {
      var text2 = this.state.text
      var name = global.userName
      if(HelperBusiness.getRole('manager,trainer,admin,cs')) {
        name += ' ('+global.userBusiness+')'
      }
      var name2 = ''
      if(HelperBusiness.getRole('trainer,admin,cs')) {
        name2 = ' ('+global.userBusiness+')'
      }
      Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+'/messages').push({
        date: Moment().format('DD/MM/YYYY HH:mm:ss'),
        timestamp: parseInt(Moment().format('X')),
        image: '',
        sender: global.uidUser,
        text: text2,
        name: global.userName+name2
      })
      
      var sent = []
      for(var cht of this.state.chatsGroup) {
        if(cht.id === this.state.chat) {
          for(var client of cht.clients) {
            for(var item2 of global.clients) {
              if(client.id === item2.data.uid && sent.indexOf(item2.data.pushToken) === -1 && item2.data.pushToken !== undefined && item2.data.pushToken !== "") {
                sent.push(item2.data.pushToken)
              }
            }
          }
        }
      }
      if(sent.length > 0) {
        Connector.sendPushNotification('', global.userName+' @ '+cht.data.name, text2, 'chat', this.state.chat, sent)
      }

      this.setState({
        text: ''
      })
      
    }
  }


  updateItem() {
    var tmp = this.state.chats
    for(var item of tmp) {
      if(item.id === this.state.chat) {
        item.data.dateTrainer = Moment().format('DD/MM/YYYY HH:mm:ss')
      }
    }
    this.setState({
      chats: tmp
    })
  }


  saveNote(msg) {
    var sender = msg.data.name
    var id = 'undefined'
    if(msg.data.sender === global.uidUser) {
      sender = 'You'
    }
    for(var item of global.clients) {
      if(this.state.chat === global.uid+item.data.uid) {
        id = item.id
      }
    }
    if(id !== 'undefined') {
      Firebase.database().ref('/clients/'+global.uid+'/'+id+'/history/'+msg.id).update({
        date: Moment(msg.data.date, 'DD/MM/YYYY HH:mm:aa').format('DD/MM/YYYY HH:mm'),
        title: "Chat message",
        desc: sender+": "+msg.data.text
      })
      EventEmitter.dispatch('showMessage', 'Message saved to client notes')
    }
    
  }



  // Group chat forms ------------------------------------------------------------



  showEditNew() {
    var tmp = []
    var tmp2 = []
    for(var item of global.clients) {
      if(item.data.uid !== '') {
        tmp.push(item)
      }
    }
    for(var item2 of global.userStaff) {
      if(item2.data.role === 'manager' || item2.data.role === 'trainer' || item2.data.role === 'admin' || item2.data.role === 'cs') {
        tmp2.push(item2)
      }
    }
    this.setState({
      mode: 'edit',
      value: '',
      selected: [],
      selectedInit: [],
      available: tmp,
      sselected: [],
      sselectedInit: [],
      savailable: tmp2,
    })
  }


  editChat() {
    var name = ''
    var tmp = []
    var tmp1 = []
    var tmp2 = []
    var tmps = []
    var tmps1 = []
    var tmps2 = []
    for(var item of this.state.chatsGroup) {
      if(item.id === this.state.chat) {
        name = item.data.name
        for(var client of item.clients) {
          tmp.push(client.id)
          tmp1.push(client.id)
        }
        for(var staff of item.staff) {
          tmps.push(staff.id)
          tmps1.push(staff.id)
        }
      }
    }
    for(var item2 of global.clients) {
      if(item2.data.uid !== '' && tmp.indexOf(item2.data.uid) === -1) {
        tmp2.push(item2)
      }
    }
    for(var item3 of global.userStaff) {
      if((item3.data.role === 'manager' || item3.data.role === 'trainer' || item3.data.role === 'admin' || item3.data.role === 'cs') && tmps.indexOf(item3.id) === -1) {
        tmps2.push(item3)
      }
    }
    this.setState({
      mode: 'edit',
      value: name,
      selected: tmp,
      selectedInit: tmp1,
      available: tmp2,
      sselected: tmps,
      sselectedInit: tmps1,
      savailable: tmps2
    })
  }


  cancelEdit() {
    var mode = 'list'
    if(this.state.chat !== '') {
      mode = 'chat'
    }
    this.resetEdit()
    this.setState({
      mode: mode,
    })
  }


  resetEdit() {
    var tmp = []
    var tmp2 = []
    for(var item of global.clients) {
      if(item.data.uid !== '') {
        tmp.push(item)
      }
    }
    for(var item2 of global.userStaff) {
      if(item.id !== '') {
        tmp.push(item2)
      }
    }
    this.setState({
      value: '',
      selected: [],
      available: tmp,
      sselected: [],
      savailable: tmp2,
      error: ['', '']
    })
  }


  updateName(event) {
    this.setState({
      value: event.target.value
    })
  }


  addClient(id) {
    var tmp = this.state.selected
    var tmp2 = []
    tmp.push(id)
    for(var item of global.clients) {
      if(item.data.uid !== '' && this.state.selected.indexOf(item.data.uid) === -1) {
        tmp2.push(item)
      }
    }
    this.setState({
      selected: tmp,
      available: tmp2
    })
  }


  removeClient(id) {
    var tmp = this.state.selected
    var tmp2 = []
    for(var i=0; i<this.state.selected.length; i++) {
      if(this.state.selected[i] === id) {
        tmp.splice(i, 1)
      }
    }
    for(var item of global.clients) {
      if(this.state.selected.indexOf(item.data.uid) === -1) {
        tmp2.push(item)
      }
    }
    this.setState({
      selected: tmp,
      available: tmp2
    })
  }


  addStaff(id) {
    var tmp = this.state.sselected
    var tmp2 = []
    tmp.push(id)
    for(var item of this.state.staff) {
      if(item.data.role === 'manager' || item.data.role === 'trainer' || item.data.role === 'admin' || item.data.role === 'cs') {
        tmp2.push(item)
      }
    }
    this.setState({
      sselected: tmp,
      savailable: tmp2
    })
  }


  removeStaff(id) {
    var tmp = this.state.sselected
    var tmp2 = []
    for(var i=0; i<this.state.sselected.length; i++) {
      if(this.state.sselected[i] === id) {
        tmp.splice(i, 1)
      }
    }
    for(var item of this.state.staff) {
      if(tmp.indexOf(item.id) === -1) {
        tmp2.push(item)
      }
    }
    this.setState({
      sselected: tmp,
      savailable: tmp2
    })
  }


  checkCreateChat() {
    var tmp = ['', '']
    if(this.state.value !== '' && this.state.selected.length > 0) {
      if(this.state.chat === '') {
        this.createChat()
      } else {
        this.updateChat()
      }
      
    } else {
      if(this.state.value === '') {
        tmp[0] = 'Select a name'
      }
      if(this.state.selected.length === 0) {
        tmp[1] = 'Select at least one client'
      }
    }
    this.setState({
      error: tmp
    })
  }


  createChat() {
    global.chatCreate = true
    var obj = Firebase.database().ref('/messagingGroup/'+global.uid).push()
    obj.set({
      dateTrainer: Moment().format('DD/MM/YYYY HH:mm:ss'),
      timeTrainer: parseInt(Moment().format('X')),
      name: this.state.value,
      nameTrainer: global.userName,
      trainer: global.uidUser,
      clients: []
    }).then(() => {
      for(var item of this.state.selected) {
        var name = ''
        for(var client of global.clients) {
          if(client.data.uid === item) {
            name = client.data.name
          }
        }
        Firebase.database().ref('/messagingGroup/'+global.uid+'/'+obj.key+'/clients/'+item).update({
          name: name,
          date: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timestamp: parseInt(Moment().format('X')),
        })
      }
      // Staff members
      for(var item2 of this.state.sselected) {
        var name2 = ''
        for(var staff of global.userStaff) {
          if(staff.id === item2) {
            name2 = staff.data.name+' ('+global.userBusiness+')'
          }
        }
        Firebase.database().ref('/messagingGroup/'+global.uidUser+'/'+obj.key+'/staff/'+item2).update({
          name: name2,
          date: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timestamp: parseInt(Moment().format('X')),
        })
      }
      setTimeout(() => {
        global.chatCreate = false
        this.resetEdit()
      }, 2000);
      EventEmitter.dispatch('showMessage', 'Chat successfully created');
      this.setState({
        mode: 'list',
      })
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  updateChat() {
    global.chatCreate = true
    Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat).update({
      name: this.state.value,
    }).then(() => {
      var add = []
      var rem = []
      var sadd = []
      var srem = []
      for(var item of this.state.selected) {
        if(this.state.selectedInit.indexOf(item) === -1) {
          add.push(item)
        }
      }
      for(var item2 of this.state.selectedInit) {
        if(this.state.selected.indexOf(item2) === -1) {
          rem.push(item2)
        }
      }
      for(var ri of rem) {
        Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+'/clients/'+ri).remove()
      }
      for(var ai of add) {
        var name = ''
        for(var client of global.clients) {
          if(client.data.uid === ai) {
            name = client.data.name
          }
        }
        Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+'/clients/'+ai).update({
          name: name,
          date: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timestamp: parseInt(Moment().format('X'))
        })
      }
      // Staff
      for(var items of this.state.sselected) {
        if(this.state.sselectedInit.indexOf(items) === -1) {
          sadd.push(items)
        }
      }
      for(var items2 of this.state.sselectedInit) {
        if(this.state.sselected.indexOf(items2) === -1) {
          srem.push(items2)
        }
      }
      for(var sri of srem) {
        Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+'/staff/'+sri).remove()
      }
      for(var sai of sadd) {
        var sname = ''
        for(var staff of global.userStaff) {
          if(staff.id === sai) {
            sname = staff.data.name+' ('+global.userBusiness+')'
          }
        }
        Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+'/staff/'+sai).update({
          name: sname,
          date: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timestamp: parseInt(Moment().format('X'))
        })
      }
      setTimeout(() => {
        global.chatCreate = false
        this.resetEdit()
      }, 2000);
      EventEmitter.dispatch('showMessage', 'Chat successfully updated');
      //this.selectChat(this.state.chat)
      this.setState({
        mode: 'list',
      })
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  deleteChat() {
    Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat).remove()
    EventEmitter.dispatch('showMessage', 'Chat successfully closed');
    this.setState({
      mode: 'list',
      chat: ''
    })
  }


  saveMessage() {

  }



  // Display stuff ------------------------------------------------------------



  renderMessageContent(item) {
    if(item.data.image === '') {
      return (
        <div className="message-text">{item.data.text}</div>
      )
    } else {
      return (
        <div className="message-text">
          <img src={this.getImage(item.data.image)} alt="Chat"/>
        </div>
      )
    }
  }


  renderMessageUser(item) {
    var label = ''
    if(item.data.name !== undefined && item.data.sender !== global.uidUser) {
      label = item.data.name+' - '
    }
    return label
  }


  getChatInter() {
    var label = ''
    if(this.state.client !== 'group') {
      label = 'interactive'
    }
    return label
  }


  renderMessages() {
    if(this.state.messages.length === 0) {
      return (
        <div className="chat-messages" ref={ (divElement) => { this.divElement = divElement }}>
          <ListEmpty id='chat' type='simple'/>
        </div>
      )
    } else {
      var list = this.state.messages
      list.sort((a,b) => Moment(a.date, 'DD/MM/YYYY HH:mm:aa') - Moment(b.date, 'DD/MM/YYYY HH:mm:aa'));
      return (
        <div className="chat-messages" ref={ (divElement) => { this.divElement = divElement }}>
          {list.map(item => (
            <div className={item.data.sender === global.uidUser ? 'chat-message user '+this.getChatInter() : 'chat-message '+this.getChatInter()} key={item.id} onClick={() => this.saveNote(item)}>
              <p className="date">{this.renderMessageUser(item)}{Moment(item.data.date, 'DD/MM/YYYY HH:mm:aa').format('lll')}<span>Save to notes</span></p>
              {this.renderMessageContent(item)}
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderEdit() {
    if(this.state.client === 'group') {
      return (
        <button className="btn tertiary small rgt edit" style={{marginRight: 15}} onClick={() => this.editChat()}>Edit</button>
      )
    }
  }


  renderChat() {
    return (
      <div className="chat-base">
        <div className="chat-header">
          <h4 className="lft">{this.state.name}</h4>
          <button className="btn tertiary small rgt close" onClick={() => this.closeChat()}>Close</button>
          {this.renderEdit()}
        </div>
        {this.renderMessages()}
        <div className="chat-control">
          <textarea placeholder="Type something" value={this.state.text} onChange={(event) => this.onChange(event)}></textarea>
          <div className="col-6 pt-10">
            <button className={this.state.text === '' ? 'none' : 'btn primary'} onClick={() => this.sendMessage()}>Send</button>
          </div>
        </div>
      </div>
    )
  }


  renderList() {
    var list = []
    for(var client of this.state.clients) {
      var id = global.uid+client.id
      for(var item of this.state.chats) {
        if(item.data.client === client.data.uid) {
          id = item.id
        }
      }
      list.push({
        id: client.id,
        data: {
          name: client.data.name,
          chat: id,
          uid: client.data.uid,
          image: client.data.image,
          avatar: client.data.avatar
        }
      })
    }
    list.sort((a,b) => a.data.name.localeCompare(b.data.name))
    return (
      <div>
        {list.map((item, index) => (
          <div className="list bare" key={item.id} onClick={() => this.selectChat(item)}>
            {HelperClients.getImage(item)}
            <div className="main">
              <h4><span className="lft">{item.data.name}</span>{this.getBullet(item)}</h4>
              <div className="clear"></div>
              {this.getInfo(item.data.uid)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderListGroup() {
    var list = this.state.chatsGroup
    list.sort((a,b) => a.data.name.localeCompare(b.data.name))
    return (
      <div>
        {list.map((item, index) => (
          <div className="list bare" key={item.id} onClick={() => this.selectChatGroup(item.id)}>
            <div className="icon primary">
              <div className="inner group"></div>
            </div>
            <div className="main">
            <h4><span className="lft">{item.data.name}</span>{this.getBullet(item)}</h4>
              <div className="clear"></div>
              {this.getInfoGroup(item)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderSelected() {
    if(this.state.selected.length > 0) {
      return (
        <div className="form-item">
          <label>Chat members</label>
          {this.state.selected.map(item => (
            <div className="client small" key={item}>
              {HelperClients.getImage(this.getClient(item))}
              <p className="lft">{this.getClientName(item)}</p>
              <button className="btn tertiary rgt small close" style={{fontSize: 10, marginTop: 7}} onClick={() => this.removeClient(item)}>Remove</button>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderSelectedStaff() {
    if(global.showHabits) {
      return (
        <div className="form-item">
          <label>Chat staff members</label>
          <div className="client small">
            <div className="avatar" style={{backgroundImage: 'url(/img/data-client.svg)',backgroundSize: 'contain'}}></div>
            <p>{HelperBusiness.getRoleIncl('') ? 'Yourself' : global.spaceOwner}</p>
            <div className="clear"></div>
          </div>
          {this.state.sselected.map(item => (
            <div className="client small" key={item}>
              <div className="avatar" style={{backgroundImage: 'url(/img/data-client.svg)',backgroundSize: 'contain'}}></div>
              <p className="lft">{this.getClientName(item)}</p>
              <button className="btn tertiary rgt small close" style={{fontSize: 10, marginTop: 7}} onClick={() => this.removeStaff(item)}>Remove</button>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderError(num) {
    if(this.state.error[num] !== '') {
      return (
        <p className="error">{this.state.error[num]}</p>
      )
    }
  }


  renderDelete() {
    if(this.state.chat !== '') {
      return (
        <div>
          <div className="space-20"></div>
          <button className="btn tertiary width-12 close" onClick={() => this.deleteChat()}>Close this chat</button>
        </div>
      )
    }
  }


  renderAddStaff() {
    if(this.state.staff.length > 0 && global.showHabits) {
      var list = this.state.savailable
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="sv-10"></div>
          <label>Add staff members to your chat</label>
          <select onChange={event => this.addStaff(event.target.value)}>
            <option>Select a staff member</option>
            {list.map((item, index) => (
              <option key={item.id} value={item.id}>{item.data.name}</option>
            ))}
          </select>
          <div className="sv-20"></div>
        </div>
      )
    }
  }


  renderContent() {
    if(this.state.mode === 'list') {
      if(this.state.clients.length === 0 && this.state.chatsGroup.length === 0) {
        return (
          <div className="chat-base">
            <div className="chat-header">
              <input type="text" value={this.state.search} placeholder="Filter by name" onChange={(event) => this.filterClients(event)}/>
            </div>
            <div className="chat-content">
              <div className="empty small pt-60">
                <h4>No chats</h4>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="chat-base">
            <div className="chat-header">
              <input type="text" value={this.state.search} placeholder="Filter by name" onChange={(event) => this.filterClients(event)}/>
            </div>
            <div className="chat-content">
              {this.renderListGroup()}
              {this.renderList()}
            </div>
          </div>
        )
      }
    } else if(this.state.mode === 'edit') {
      var list = this.state.available
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="chat-base">
          <div className="chat-header">
            <h4 className="lft">{this.state.chat === '' ? 'New' : 'Edit'} Group Chat</h4>
            <button className="btn tertiary small rgt close" onClick={() => this.cancelEdit()}>Cancel</button>
          </div>
          <div className="chat-content">
            <div className="form-item">
              <label>Name*</label>
              <input type="text" placeholder="Name your chat" value={this.state.value} onChange={(val) => this.updateName(val)}/>
              {this.renderError(0)}
            </div>
            {this.renderAddStaff()}
            <div className="form-item">
              <label>Add clients to your chat</label>
              <select onChange={event => this.addClient(event.target.value)}>
                <option>Select a client</option>
                {list.map((item, index) => (
                  <option key={item.id} value={item.data.uid}>{item.data.name}</option>
                ))}
              </select>
              {this.renderError(1)}
            </div>
            {this.renderSelectedStaff()}
            {this.renderSelected()}
            <div className="sv-40"></div>
            <button className="btn primary mb-20" onClick={() => this.checkCreateChat()}>{this.state.chat === '' ? 'Create chat' : 'Update chat'}</button>
            {this.renderDelete()}
          </div>
        </div>
      )
    } else {
      return (
        this.renderChat()
      )
    }
  }


  renderAdd() {
    if(this.state.mode === 'list') {
      return (
        <button className="btn tertiary add small rgt" onClick={() => this.showEditNew()}>New Group Chat</button>
      )
    }
  }


  renderBar() {
    return (
      <div className={'chat-container '+this.state.hidden}>
        <h3 className="mb-10 lft">Messaging</h3>
        {this.renderAdd()}
        <div className="clear"></div>
        {this.renderContent()}
      </div>
    )
  }


  renderMainBullet() {
    var show = false
    for(var item of this.state.chats) {
      for(var msg of item.messages) {
        var dt = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
        if(msg.data.timestamp !== undefined) {
          dt = Moment(msg.data.timestamp, 'X').format('X')
        }
        if(parseInt(dt) > parseInt(Moment(item.data.dateTrainer, 'DD/MM/YYYY HH:mm:ss').format('X')) && msg.data.sender !== global.uidUser) {
          show = true
        }
      }
    }
    for(var item2 of this.state.chatsGroup) {
      for(var msg2 of item2.messages) {
        var dt2 = Moment(msg2.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
        if(msg2.data.timestamp !== undefined) {
          dt2 = Moment(msg2.data.timestamp, 'X').format('X')
        }
        if(parseInt(dt2) > parseInt(Moment(item2.data.dateTrainer, 'DD/MM/YYYY HH:mm:ss').format('X')) && msg2.data.sender !== global.uidUser) {
          show = true
        }
      }
    }
    if(show) {
      return (
        <div className="dot"></div>
      )
    }
  }


  render() {
    if(HelperBusiness.getRoleIncl('trainer,admin,cs') && global.maxClients !== 5) {
      return (
        <div>
          {this.renderBar()}
          <div className={this.state.show ? 'chat active' : 'chat'} onClick={() => this.toggleChat()}>
            {this.renderMainBullet()}
          </div>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}


export default Chat;