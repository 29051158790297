import React, { Component } from 'react';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListIcon from '../../components/List/icon';
import ModalBilling from '../../components/Modals/billing';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      products: global.products,
      productsList: global.products,
      stripe: global.userStripeConnect,
      bank: global.userStripeBank,
      search: '',
      showModalLocked: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Products - PT Mate', ROUTES.PRODUCTS)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients,
        products: global.products,
        productsList: global.products,
        stripe: global.userStripeConnect,
        bank: global.userStripeBank
      })
    }
  }


  getNumber(type) {
    var count = 0
    for(var item of this.state.products) {
      if(item.data.type === type) {
        count++
      }
    }
    return count
  }


  filterProducts = event => {
    this.setState({
      search: event.target.value
    }, () => {
      var tmp = []
      for(var item of global.products) {
        var name = item.data.name
        if(this.state.search === '' || (name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '')) {
          tmp.push(item)
        }
      }
      this.setState({
        productsList: tmp
      })
    });
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalLocked: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderType(type) {
    var label = lang.t('billing:label.packofsessions')
    if(type === 'subscription') {
      label = lang.t('billing:label.membership')
    }
    if(type === 'other') {
      return lang.t('billing:label.otherproduct')
    }
    return label
  }


  renderSessions(item) {
    var label = item.data.sessions+' '+lang.t('billing:label.sessions')
    if(item.data.sessions === 1) {
      label = '1 '+lang.t('billing:label.session')
    } else if(item.data.type === 'subscription') {
      label = lang.t('billing:label.per'+item.data.billing)
    } else if(item.data.type === 'other') {
      label = '-'
    }
    return label
  }


  renderIcon(item) {
    if(item.data.type === 'other') {
      return ['product','tertiary']
    } else {
      var color = 'secondary'
      if(item.data.type === 'sessions') {
        color = 'primary'
      }
      if(item.data.stype !== '11') {
        return ['group',color]
      } else {
        return ['session',color]
      }
    }
  }


  renderProducts() {
    if(this.state.productsList.length === 0) {
      if(this.state.products.length === 0) {
        return (
          <ListEmpty id='noproducts'/>
        )
      } else {
        return (
          <div>
            <div className="listheader">
              <div className="mt-5 lft">
                <ListHeader id='products' number={this.state.productsList.length}/>
              </div>
              <input type="text" className="search light large rgt" placeholder={lang.t('billing:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterProducts(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='products'/>
          </div>
        )
      }
    } else {
      var list = this.state.productsList
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="listheader">
            <div className="mt-5 lft">
              <ListHeader id='products' number={this.state.productsList.length}/>
            </div>
            <input type="text" className="search light large rgt" placeholder={lang.t('billing:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterProducts(val)} />
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListIcon
              key={item.id}
              icon={this.renderIcon(item)[0]}
              color={this.renderIcon(item)[1]}
              link={'/billing/products/'+item.id}
              button='edit'
              columns={[{text: item.data.name, width: 4}, {text: this.renderType(item.data.type), width: 3}, {text: global.curSym+item.data.price.toFixed(2), width: 2}, {text: this.renderSessions(item), width: 2}]}
            />
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWPRODUCT)}>{lang.t('billing:button.addanotherproduct')}</button>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'newproduct', link: '/billing/products/new'}
    if(HelperBusiness.getRoleIncl('')) {
      //no change
    } else if(HelperBusiness.getRole('admin, trainer')) {
      item1 = {id: '', link: ''}
    } else {
      item1 = {id: 'report', link: ROUTES.REPORT}
    }
    return (
      <WidgetActions
        item1={item1}
        item2={{id: '', link: ''}}
        item3={{id: '', link: ''}}
      />
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'prodtotal', value: this.state.products.length, prev: ''}}
                item2={{id: 'prodpacks', value: this.getNumber('sessions'), prev: ''}}
                item3={{id: 'debits', value: this.getNumber('subscription'), prev: ''}}
                item4={{id: '', value: ''}}
              />
            </div>
            {this.renderActions()}
            <div className="col-12 clear">
              {this.renderProducts()}
            </div>
            <div className="sv-80 clear"></div>
          </div>
          <Header type='billing' active='products'/>
          <Navigation active='billing' />
          <ModalBilling type='locked' show={this.state.showModalLocked} onHide={() => this.hideModals()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='products-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.billing')}/>
          <Navigation active='billing' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withTranslation(['billing','common','header','nav'])(withRouter(withAuthorization(condition)(ProductsPage)));

