import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import HelperBilling from '../../helper/Billing';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import WidgetProgram from '../../components/Widgets/program';
import WidgetComments from '../../components/Widgets/comments';
import ListClient from '../../components/List/client';
import InfoData from '../../components/Form/info';
import ModalItems from '../../components/Modals/items';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalMessageDouble from '../../components/Modals/message-double';
import ModalOptions from '../../components/Modals/options';
import ModalBlock from '../../components/Modals/block';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class SessionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      client: null,
      back: ROUTES.CALENDAR,
      title: '1:1 Session',
      comment: '',
      margin: 0,
      timeEdit: false,
      staff: global.userStaff,
      showModalDelete: false,
      showModalSpark: false,
      showModalPrograms: false,
      showModalReplace: false,
      showModalNoshow: false,
      showModalBlock: false,
      showModalAttended: false,
      showModalReminder: false,
      block: null
    };
  }


  componentDidMount() {
    Connector.setUser()
    HelperPage.checkMessage()
    document.title = 'Session Details - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveTrainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.setPrograms())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    Connector.loadEmailUsage()
    
    if(global.message !== '') {
      EventEmitter.dispatch('showMessage', global.message);
      global.message = ''
    }

    this.configureData()
    this.setPrograms()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var back = ROUTES.CALENDAR

      var id = arr[arr.length-1]
      var item = null
      var cl = null
      var found = false
      var dur = 30
      var notes = ''
      var desc = ''
      var day = ''
      var hrs = ''
      var min = ''
      var preview = true
      var title = lang.t('calendar:session.11')
      var tid = global.uidUser
      var tname = global.userName
      var link = ''

      global.linkCal = '/calendar/session/'+id

      back = global.currentPage
      if(global.linkCli !== '') {
        back = global.linkCli
      }
      if(global.linkPro !== '') {
        back = global.linkPro
      }

      if(id !== '') {
        // Sessions
        for(var session of global.sessions) {
          if(session.id === id) {
            found = true
            item = session
            dur = session.data.duration
            notes = session.data.notes
            day = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
            hrs = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
            min = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
            if(!session.data.preview && session.data.preview !== undefined) {
              preview = false
            }
            for(var client1 of global.clients) {
              if(client1.id === session.data.client) {
                cl = client1
              }
            }
            for(var client2 of global.clientsInactive) {
              if(client2.id === session.data.client) {
                cl = client2
              }
            }
            if(session.data.trainerId !== undefined) {
              tid = session.data.trainerId
              tname = session.data.trainerName
            }
            if(session.data.link !== undefined) {
              link = session.data.link
            }
            if(session.data.desc !== undefined) {
              desc = session.data.desc
            }
          }
        }
        // Archive
        if(!found) {
          for(var ar of global.archive) {
            if(ar.id === id) {
              found = true
              item = ar
              dur = ar.data.duration
              notes = ar.data.notes
              day = Moment(ar.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
              hrs = Moment(ar.data.date, 'DD/MM/YYYY HH:mm').format('HH')
              min = Moment(ar.data.date, 'DD/MM/YYYY HH:mm').format('mm')
              if(!ar.data.preview && ar.data.preview !== undefined) {
                preview = false
              }
              for(var client3 of global.clients) {
                if(client3.id === ar.data.client) {
                  cl = client3
                }
              }
              if(ar.data.trainerId !== undefined) {
                if(global.userStaff.indexOf(session.data.trainerId) !== -1) {
                  tid = session.data.trainerId
                  tname = session.data.trainerName
                }
              }
              if(ar.data.link !== undefined) {
                link = ar.data.link
              }
              if(ar.data.desc !== undefined) {
                desc = ar.data.desc
              }
            }
          }
        }
        // Training session
        if(!found) {
          for(var tr1 of global.sessionsTraining) {
            if(tr1.id === id) {
              found = true
              item = tr1
              title = lang.t('calendar:session.training')
              var cid = this.getClient(tr1.data.uid)
              for(var client33 of global.clients) {
                if(client33.id === cid || client33.data.uid === cid) {
                  cl = client33
                }
              }
            }
          }
        }
        // Training archive
        if(!found) {
          for(var tr2 of global.archiveTraining) {
            if(tr2.id === id) {
              found = true
              item = tr2
              title = lang.t('calendar:session.training')
              var cid2 = this.getClient(tr2.data.uid)
              for(var client4 of global.clients) {
                if(client4.id === cid2 || client4.data.uid === cid2) {
                  cl = client4
                }
              }
            }
          }
        }
      }

      var cdur = 75
      if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
        cdur = dur
        dur = 99
      }
      if(cl !== null) {
        if(cl.data.uid !== '') {
          Connector.getClientToken(cl.data.uid)
        }
      }
    
      if(cl === null) {
        cl = global.clients[0]
      }

      var tmps = []
      if(item !== null) {
        for(var items of global.userStaff) {
          if((items.data.role === 'assistant' || items.data.role === 'assistant2' || items.data.role === 'trainer') && items.data.status === 'active') {
            tmps.push(items)
          }
        }
      }

      this.setState({
        clients: global.clients,
        client: cl,
        item: item,
        id: id,
        back: back,
        dur: dur,
        cdur: cdur,
        notes: notes,
        desc: desc,
        date: Moment(day, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        hour: hrs,
        mins: min,
        preview: preview,
        title: title,
        trainerId: tid,
        trainerName: tname,
        link: link,
        staff: tmps,
      })
    }
  }


  getClient(uid) {
    var label = ''
    for(var item of global.clients) {
      if(item.data.uid === uid) {
        label = item.id
      }
    }
    return label
  }


  setPrograms() {
    var tmp = []
    for(var item1 of global.programs) {
      tmp.push(item1)
    }
    this.setState({
      programs: tmp,
      programsList: tmp,
    })
  }



  // Client update stuff ------------------------------------------------------------



  updateAttendance(num) {
    // check credits
    if((num === 3 || num === 5) && this.state.item.data.attendance === 2 && global.userChargeSessions) {
      if(num === 3 || (num === 5 && global.spaceNoshow !== 'fee')) {
        var mainClient = this.state.client
        if(this.state.client.data.parent !== undefined && this.state.client.data.parent !== '') {
          for(var mcl of global.clients) {
            if(mcl.id === this.state.client.data.parent) {
              mainClient = mcl
            }
          }
        }
        var hasSub = false
        var credit = ''
        var credite = ''
        var amount = 1
        var total = 0
        var datee = Moment('01/01/3000', 'DD/MM/YYYY')
        for(var sub of mainClient.subscriptions) {
          if(!sub.data.group && sub.data.status !== 'trialing' && (sub.data.sessions === 0 || sub.data.sessions === undefined) && sub.data.status !== 'trialing' && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === this.state.client)) {
            hasSub = true
          }
          if(sub.data.is11 && sub.data.status !== 'trialing' && (sub.data.sessions11 === 0 || sub.data.sessions11 === undefined) && sub.data.status !== 'trialing' && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === this.state.client)) {
            hasSub = true
          }
        }

        var avail = 0
        if(!hasSub) {
          avail = HelperBilling.getClientSessionDebit(this.state.client, false, this.state.client.id, false, [])
          if(avail === undefined) {
            avail = 0
          }
        }

        if(avail > 0 && !hasSub) {
          HelperBilling.chargeClientSessionDebit(mainClient, false, this.state.client.id, [])
        }

        if(!hasSub && avail === 0) {
          var bl = 0
          // Family specific
          for(var cred of mainClient.credits) {
            total = cred.data.sessionsTotal
            if(!cred.data.group && cred.data.account === this.state.client.id) {
              credit = cred.id
              amount = total+1
              bl = cred.data.sessionsPaid-cred.data.sessionsTotal
            }
          }
          // Common and non-family
          for(var cred1 of mainClient.credits) {
            total = cred1.data.sessionsTotal
            if(!cred1.data.group && bl < 1 && (cred1.data.account === '' || cred1.data.account === undefined)) {
              if(credit === '' || cred1.data.sessionsPaid > cred1.data.sessionsTotal) {
                credit = cred1.id
                amount = total+1
              }
            }
          }
          bl = 0
          // Family specific
          for(var crede of mainClient.creditse) {
            total = crede.data.sessionsTotal
            if(!crede.data.group && Moment(crede.data.expires, 'X') > Moment() && Moment(crede.data.expires, 'X') < datee && crede.data.sessionsPaid > crede.data.sessionsTotal) {
              if(!crede.data.group && crede.data.account === this.state.client.id) {
                credite = crede.id
                amount = total+1
                datee = Moment(crede.data.expires, 'X')
                bl = crede.data.sessionsPaid-crede.data.sessionsTotal
              }
            }
          }
          // Common and non-family
          for(var crede1 of mainClient.creditse) {
            total = crede1.data.sessionsTotal
            if(!crede1.data.group && Moment(crede1.data.expires, 'X') > Moment() && Moment(crede1.data.expires, 'X') < datee && crede1.data.sessionsPaid > crede1.data.sessionsTotal) {
              if(!crede1.data.group && bl < 1 && (crede1.data.account === '' || crede1.data.account === undefined)) {
                if(credite === '' || crede1.data.sessionsPaid > crede1.data.sessionsTotal) {
                  credite = crede1.id
                  amount = total+1
                  datee = Moment(crede1.data.expires, 'X')
                }
              }
            }
          }
          if(credit === '' && credite === '') {
            var acc = ''
            if(mainClient.id !== this.state.client.id || this.state.client.data.linked !== undefined) {
              acc = this.state.client.id
            }
            Firebase.database().ref('/clients/'+global.uid+'/'+mainClient.id+'/credits').push({
              group: false,
              sessionsPaid: 0,
              sessionsTotal: 1,
              type: 'sessions',
              account: acc
            })
          } else {
            if(credite === '') {
              Firebase.database().ref('/clients/'+global.uid+'/'+mainClient.id+'/credits/'+credit).update({
                sessionsTotal: amount,
              })
            } else {
              Firebase.database().ref('/clients/'+global.uid+'/'+mainClient.id+'/credits/'+credite).update({
                sessionsTotal: amount,
              })
            }
          }
        }
      }
      // update fee
      if(num === 5 && global.spaceNoshow !== 'session') {
        var add = ''
        var client = this.state.item.data.client
        for(var cl2 of global.clients) {
          if(cl2.id === this.state.item.data.client && cl2.data.parent !== undefined) {
            if(cl2.data.parent !== '') {
              client = cl2.data.parent
              add = ' ('+cl2.data.name+')'
            }
          }
          if(cl2.data.linked !== undefined) {
            if(cl2.data.linked.length > 0) {
              add = ' ('+cl2.data.name+')'
            }
          }
        }
        Firebase.database().ref('/clients/'+global.uid+'/'+client+"/noshow").push({
          amount: global.spaceNoshowFee,
          session: '1:1 session - '+Moment(this.state.item.data.timestamp, 'X').format('D MMM HH:mm')+' h'+add,
          id: this.state.item.id
        })
      }
    }
    // Update attendance
    var parent = '/sessions/'
    if(this.state.back === ROUTES.CALARCHIVE) {
      parent = '/archive/'
    }
    Firebase.database().ref(parent+global.uid+'/'+this.state.item.id).update({
      attendance: num,
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Session successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  startSession() {
    global.badgeLocked = true
    setTimeout(() => {
      this.props.history.push('/calendar/run-session/'+this.state.item.id)
    }, 500);
    setTimeout(() => {
      EventEmitter.dispatch('updateBadges', 'attendance');
    }, 1000);
  }
  


  // Comments ------------------------------------------------------------



  sendComment(key) {
    var id = global.uid
    var token = this.state.client.data.pushToken
    if(this.state.item.data.client === 'Training Session') {
      id = this.state.client.data.uid
    }
    if(this.state.comment !== "") {
      if(key === '') {
        Firebase.database().ref('/sessions/'+id+'/'+this.state.item.id+'/comments').push({
          sender: global.uidUser,
          date: parseInt(Moment().format('X')),
          text: this.state.comment
        }).then((data)=>{
          this.setState({
            comment: '',
            writeComment: false
          })
          EventEmitter.dispatch('showMessage', 'Comment successfully sent');
          if(token !== '' && token !== undefined) {
            Connector.sendPushNotification(token, 'New session comment', global.userName+' commented on the PT Session '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id)
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      } else {
        Firebase.database().ref('/sessions/'+id+'/'+this.state.item.id+'/comments/'+key).update({
          sender: global.uidUser,
          text: this.state.comment
        }).then((data)=>{
          this.setState({
            comment: '',
            editComment: ''
          })
          EventEmitter.dispatch('showMessage', 'Comment successfully update');
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }  
    }
  }



  // Program ------------------------------------------------------------



  clickProgramEdit() {
    if(global.userSubStatus === 'active' && global.userSubPlan === 'spark') {
      this.setState({showModalSpark: true})
    } else {
      this.props.history.push('/calendar/session/'+this.state.item.id+'/edit-program/'+this.state.item.program[0].id)
    }
  }


  clickProgramCreate() {
    if(global.userSubStatus === 'active' && global.userSubPlan === 'spark') {
      this.setState({showModalSpark: true})
    } else {
      this.props.history.push('/calendar/session/'+this.state.item.id+'/new-program')
    }
  }


  clickProgramReplace() {
    this.hideModals()
    setTimeout(() => {
      this.setState({
        showModalPrograms: true
      });
    }, 500);
  }


  deleteProgram() {
    this.hideModals()
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout').remove()
  }



  // Modals ------------------------------------------------------------



  showModalNoshow() {
    if(global.userChargeSessions) {
      this.setState({showModalNoshow: true})
    } else {
      this.updateNotAttended(4)
    }
  }


  showModalBlock(item) {
    for(var bl of this.state.item.program[0].blocks) {
      if(bl.id === item) {
        this.setState({
          block: bl,
          showModalBlock: true
        })
      }
    }
    
  }


  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalSpark: false,
      showModalPrograms: false,
      showModalReplace: false,
      showModalNoshow: false,
      showModalBlock: false,
      showModalAttended: false,
      showModalReminder: false,
    })
  }


  clickSpark() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }


  selectProgram(item) {
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout').remove()
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout/'+item.id).update(
      item.data
    ).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Program successfully added');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    this.hideModals()
  }


  updateNotAttended(num) {
    this.hideModals()
    this.updateAttendance(num)
  }


  deleteSession() {
    this.hideModals()
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).remove()
    global.message = 'Session successfully deleted'
    var token = this.state.client.data.pushToken
    let text = "Your PT session "+Cal.getPushDate(this.state.item.data.date)+" has been canceled."
    if(token !== '' && token !== undefined && Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm') > Moment()) {
      Connector.sendPushNotification(token, global.userName, text, 'session', this.state.item.id)
    }
    if(this.state.client.data.email !== '' && global.spaceEmailReminder && (this.state.client.data.emailReminder || this.state.client.data.emailReminder === undefined) && Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm') > Moment()) {
      var base = 0
      if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
        base = global.spaceEmailUsage[Moment().format('MM-YYYY')]+1
      }
      Firebase.database().ref('/admin/emails/'+global.uid).update({
        [Moment().format('MM-YYYY')]: base
      })
      var callFunction = Firebase.functions().httpsCallable('sendReminder');
      callFunction({type: 'canceled', email: this.state.client.data.email, name: global.userBusiness, id: global.uid, session: 'PT Session', time: Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('llll')}).then(function(result) {});
    }
    this.props.history.push(this.state.back)
  }


  sendReminder() {
    var token = this.state.client.data.pushToken
    var text = 'You are now booked in for a PT session '+Cal.getPushDate(this.state.item.data.date)
    if(token !== '' && token !== undefined) {
      Connector.sendPushNotification(token, 'Session Reminder', text, 'session', this.state.item.id)
    }
    // Email reminders
    if(this.state.client.data.email !== '' && global.spaceEmailReminder && (this.state.client.data.emailReminder || this.state.client.data.emailReminder === undefined)) {
      var base = 0
      if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
        base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
      }
      var callFunction = Firebase.functions().httpsCallable('sendReminder');
      callFunction({type: 'booked', email: this.state.client.data.email, name: global.userBusiness, id: global.uid, session: 'PT Session', time: Moment(this.state.item.data.timestamp, 'X').locale(lang.language).format('llll')}).then(function(result) {});
      base++
      Firebase.database().ref('/admin/emails/'+global.uid).update({
        [Moment().format('MM-YYYY')]: base
      })
    }
    EventEmitter.dispatch('showMessage', 'Session reminder successfully sent');
  }



  // Display stuff ------------------------------------------------------------



  renderAttendance() {
    var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(this.state.item.data.duration, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date && this.state.item.data.uid === global.uid) {
      if(this.state.item.data.attendance === 0 || this.state.item.data.attendance === 3) {
        return <InfoData label={lang.t('calendar:label.attendance')} value={lang.t('calendar:label.sessionattended')}/>
      } else if(this.state.item.data.attendance === 1 || this.state.item.data.attendance === 4 || this.state.item.data.attendance === 5) {
        return <InfoData label={lang.t('calendar:label.attendance')} value={lang.t('calendar:label.sessionnotattended')}/>
      } else {
        return (
          <div className="form-item">
            <label>{lang.t('calendar:label.attendance')}</label>
            <div className="radios">
              <button className="radio col-6" onClick={() => this.updateAttendance(3)}>{lang.t('calendar:label.attended')}</button>
              <button className="radio col-6" onClick={() => this.showModalNoshow()}>{lang.t('calendar:label.notattended')}</button>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  renderButtonUpdateAttendance() {
    if(!this.state.item.data.group && this.state.item.data.attendance === 3) {
      return (
        <div className="mb-20 mt-20">
          <button className="btn secondary" onClick={() => this.updateAttendance(4)}>Mark as not attended</button>
          <div className="small" style={{fontSize:'11px',textAlign:'center', opacity: .5, paddingTop: 5}}>{global.userChargeSessions ? lang.t('calendar:label.attendance.note') : ''}</div>
        </div>
      )
    } else if(!this.state.item.data.group && this.state.item.data.attendance === 4) {
      return (
        <div className="mt-20">
          <button className="btn secondary mb-20" onClick={() => this.updateAttendance(3)}>{lang.t('calendar:button.markasattended')}</button>
        </div>
      )
    }
  }


  renderButtonAttendance() {
    if(this.state.attendance === 2 && this.state.item.program.length === 0) {
      return (
        <div className="clear">
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.updateAttendance(3)}>{lang.t('calendar:button.marksessionasdone')}</button>
        </div>
      )
    }
  }


  renderButtonDelete() {
    if(this.state.back !== ROUTES.CALARCHIVE) {
      if(HelperBusiness.getRoleIncl('trainer') && this.state.item.data.uid === global.uidUser) {
        return (
          <div className="clear">
            <div className="sv-20"></div>
            <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})} style={{width: '100%'}}>{lang.t('calendar:button.deletesession')}</button>
          </div>
        )
      } else if(HelperBusiness.getRole('trainer') && this.state.item.data.trainerId === global.uidUser) {
        return (
          <div className="clear">
            <div className="sv-20"></div>
            <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})} style={{width: '100%'}}>{lang.t('calendar:button.deletesession')}</button>
          </div>
        )
      }
    }
  }


  renderButtonVirtual() {
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var date1 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date1 && now < date2 && this.state.item.data.uid === global.uid) {
      if(this.state.item.data.link !== undefined && this.state.item.data.link !== '') {
        return (
          <div>
            <div className="sv-20"></div>
            <a href={this.state.item.data.link} target="_blank" className="btn secondary" rel="noopener noreferrer">{lang.t('calendar:button.openvirtuallink')}</a>
          </div>
        )
      }
    }
  }


  renderButtonStart() {
    var date2 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    var date1 = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(now > date1 && now < date2 && this.state.item.data.uid === global.uid && this.state.item.program.length !== 0) {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => {this.updateAttendance(3); this.startSession()}}>{lang.t('calendar:button.startsession')}</button>
        </div>
      )
    }
  }


  renderButtonEdit() {
    if(Moment(this.state.item.data.timestamp, 'X') > Moment()) {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <div className="clear mb-20">
            <Link to={'/calendar/edit-session/'+this.state.item.id} className="btn primary">{lang.t('calendar:button.editsessiondetails')}</Link>
          </div>
        )
      } else if(HelperBusiness.getRole('trainer') && this.state.item.data.trainerId === global.uidUser) {
        return (
          <div className="clear mb-20">
            <Link to={'/calendar/edit-session/'+this.state.item.id} className="btn primary">{lang.t('calendar:button.editsessiondetails')}</Link>
          </div>
        )
      }
    }
  }


  renderMainButtons() {
    if(this.state.title !== 'Training Session') {
      return (
        <div className="clear">
          <div className="sv-20"></div>
          {this.renderButtonEdit()}
          {this.renderButtonStart()}
          {this.renderButtonAttendance()}
          {this.renderButtonUpdateAttendance()}
          {this.renderButtonVirtual()}
          {this.renderButtonDelete()}
        </div>
      )
    }
  }


  renderStatusInfo() {
    var now = parseInt(Moment().format('X'))
    var date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(-5, 'minutes').format('X'))
    if(this.state.item.program.length !== 0) {
      date = parseInt(Moment(this.state.item.data.timestamp, 'X').add(this.state.item.data.duration, 'minutes').format('X'))
    }
    if(now > date && this.state.item.data.attendance === 2) {
      
      return (
        <div className="infobox yellow mb-20">
          <p>{lang.t('calendar:message.confirm.text2')}<br/><button className="btn tertiary small" onClick={() => this.updateAttendance(3)}>{lang.t('calendar:message.confirm.button2')}</button></p>
        </div>
      )
    }
    if(this.state.item.data.attendance === 3) {
      return (
        <div className="infobox green mb-20">
          <p>{lang.t('calendar:message.attended2')}</p>
        </div>
      )
    }
  }


  renderPreview() {
    var date = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-30, 'minutes').format('X'))
    var now = parseInt(Moment().format('X'))
    if(!this.state.item.data.preview && this.state.item.data.preview !== undefined && now < date) {
      return <InfoData label={lang.t('calendar:label.sessionpreview')} value={lang.t('calendar:label.sessionpreview.cant')}/>
    }
  }


  renderNotes() {
    if(this.state.item.data.notes !== '') {
      return <InfoData label={lang.t('calendar:label.internalnotes')} value={this.state.item.data.notes}/>
    }
  }


  renderDesc() {
    if(this.state.item.data.desc !== undefined && this.state.item.data.desc !== '') {
      return <InfoData label={lang.t('calendar:label.description')} value={this.state.item.data.desc}/>
    }
  }


  renderLocation() {
    if(this.state.item.data.locationName !== undefined) {
      return (this.state.item.data.locationName === '' ? '' : ' - '+this.state.item.data.locationName)
    }
  }


  renderTrainerInfo() {
    if(HelperBusiness.getRole('assistant,assistant2')) {
      if(this.state.item.data.trainerName !== undefined && this.state.item.data.trainerName !== global.userName) {
        return <InfoData label={lang.t('calendar:label.trainer')} value={this.state.item.data.trainerName}/>
      } else if(this.state.item.data.trainerName !== undefined && this.state.item.data.trainerName === global.userName) {
        return <InfoData label={lang.t('calendar:label.trainer')} value={lang.t('calendar:label.assignedtoyou')}/>
      }
    } else {
      if(this.state.item.data.trainerName !== undefined && this.state.item.data.trainerName !== global.userName && this.state.item.data.trainerName !== '') {
        return <InfoData label={lang.t('calendar:label.trainer')} value={this.state.item.data.trainerName}/>
      }
    }
  }


  renderVirtual() {
    if(this.state.item.data.link !== undefined && this.state.item.data.link !== '') {
      return <span className="pill primary" style={{marginLeft: 5, position: 'relative', top: '-2px'}}>{lang.t('calendar:label.virtual')}</span>
    }
  }


  renderReminder() {
    if(Moment(this.state.item.data.timestamp, 'X') > Moment() && global.spaceEmailReminder) {
      return (
        <div>
          <div className="sv-30 clear"></div>
          <button className="btn tertiary width-12 small" onClick={() => this.setState({showModalReminder: true})}>{lang.t('calendar:button.sendreminder')}</button>
        </div>
      )
    }
  }


  renderContent() {
    if(this.state.item !== null) {
      return (
        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <div className="box">
              {this.renderStatusInfo()}
              <h4 style={{marginBottom: 5}}>{Cal.getSpecialDate(this.state.item, true)}{this.renderVirtual()}</h4>
              <h3 className="mb-20">{Cal.getDuration(this.state.item.data.duration)} {this.renderLocation()}</h3>
              <ListClient client={this.state.client} type='static border'/>
              <div className="clear"></div>
              <WidgetProgram session={this.state.item} clickList={() => this.setState({showModalPrograms: true})} clickNew={() => this.clickProgramCreate()} clickEdit={() => this.clickProgramEdit()} clickReplace={() => this.setState({showModalReplace: true})} clickResults={() => this.props.history.push('/calendar/results/'+this.state.item.id)} clickElement={(event) => this.showModalBlock(event)}/>
              {this.renderAttendance()}
              {this.renderTrainerInfo()}
              {this.renderDesc()}
              {this.renderNotes()}
              {this.renderPreview()}
              {this.renderMainButtons()}
            </div>
          </div>
          <div className="col-6 sidebar mt-20">
            <WidgetComments session={this.state.item} onChange={(event) => this.setState({comment: event})} sendComment={(event) => this.sendComment(event)}/>
          </div>
          {this.renderReminder()}
          <div className="clear sv-40"></div>
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={'theme-'+global.spaceTheme}>
            {this.renderContent()}
            <div className="sv-20"></div>
            <Header title={this.state.title} link={this.state.back}/>
            <Navigation active='calendar' />
            <ModalMessageSimple type='deletesession' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteSession()}/>
            <ModalMessageSimple type='sparksession' show={this.state.showModalSpark} onHide={() => this.hideModals()} clickMainButton={() => this.clickSpark()}/>
            <ModalItems show={this.state.showModalPrograms} type={'programs'} items={global.programs} onHide={() => this.hideModals()} clickElement={(event) => this.selectProgram(event)}/>
            <ModalMessageDouble type='noshow' show={this.state.showModalNoshow} onHide={() => this.hideModals()} clickMainButton={() => this.updateNotAttended(4)} var={global.maxClients} clickSecondaryButton={() => this.updateNotAttended(5)}/>
            <ModalOptions show={this.state.showModalReplace} onHide={() => this.hideModals()} clickButton1={() => this.clickProgramCreate()}  clickButton2={() => this.clickProgramReplace()} clickButton3={() => this.deleteProgram()}/>
            <ModalBlock show={this.state.showModalBlock} block={this.state.block} onHide={() => this.hideModals()}/>
            <ModalMessageDouble type='notattended' show={this.state.showModalAttended} onHide={() => this.hideModals()} clickMainButton={() => this.updateNotAttended(4)} clickSecondaryButton={() => this.updateNotAttended(5)}/>
            <ModalMessageSimple type='sessionreminder11' show={this.state.showModalReminder} onHide={() => this.hideModals()} clickMainButton={() => this.sendReminder()}/>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common'])(withRouter(withAuthorization(condition)(SessionPage)));