import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import 'firebase/functions';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import Cal from '../../helper/Calendar';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListSimple from '../../components/List/simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class InvoicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: global.expenses,
      invoicesList: global.invoices,
      categories: global.expensesCat,
      dateRange: global.dateRange,
      show: 'all',
      search: '',
      status: ''
    };
  }


  componentDidMount() {
    HelperPage.initialize('Invoices - PT Mate', ROUTES.INVOICES)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var val1 = 0
      var val2 = 0
      var num1 = 0
      var num2 = 0
      var minDate = Cal.getDateRangeDiff()[0]
      var minDate2 = Cal.getDateRangeDiff()[2]

      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }

      var tmp = []
      for(var item of global.invoices) {
        if(list.indexOf(item.data.client) !== -1) {
          var dt = Moment(item.data.date, 'X').format('X')
          if(dt > minDate) {
            tmp.push(item)
            num1++
            val1 += item.data.amount
          }
          if(dt > minDate2 && dt <= minDate) {
            num2++
            val2 += item.data.amount
          }
        }
      }
      this.configureList(tmp)

      this.setState({
        invoices: tmp,
      })
    }
  }


  configureList(invoices) {
    var tmp = []
    for(var item of invoices) {
      if(this.state.search === '' && this.state.status === '') {
        tmp.push(item)
      } else {
        var addc = false
        var adds = false
        for(var cl of global.clients) {
          if(cl.id === item.data.client) {
            if(cl.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
              addc = true
            }
          }
        }
        for(var cl of global.clientsInactive) {
          if(cl.id === item.data.client) {
            if(cl.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
              addc = true
            }
          }
        }
        if(this.state.status === '') {
          adds = true
        }
        if(item.data.status === 'paid' && this.state.status === 'paid') {
          adds = true
        }
        if(item.data.status === 'void' && this.state.status === 'void') {
          adds = true
        }
        if((item.data.status === 'open' && Moment(item.data.due, 'X') > Moment().add(-1, 'day')) && this.state.status === 'open') {
          adds = true
        }
        if((item.data.status === 'open' && Moment(item.data.due, 'X') < Moment().add(-1, 'day')) && this.state.status === 'overdue') {
          adds = true
        }
        if(addc && adds) {
          tmp.push(item)
        }
      }
    }
    this.setState({
      invoicesList: tmp
    })
  }


  getInvoices(type) {
    var num = 0
    for(var item of this.state.invoicesList) {
      if(item.data.status === 'open' && type === 'open' && Moment(item.data.due, 'X') > Moment().add(-1, 'day')) {
        num++
      } else if(item.data.status === 'paid' && type === 'paid') {
        num++
      } else if(item.data.status === 'void' && type === 'void') {
        num++
      } else if(item.data.status === 'open' && type === 'overdue' && Moment(item.data.due, 'X') < Moment().add(-1, 'day')) {
        num++
      }
    }
    return num
  }
  

  getClient(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  setDateRange() {
    this.setState({
        dateRange: global.dateRange
    }, () => {
        this.configureData()
    });
  }


  setStatus(event) {
    this.setState({
      status: event.target.value
    }, () => {
      this.configureList(this.state.invoices)
    })
  }


  filterInvoices = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList(this.state.invoices)
    });
  }



  // Display stuff ------------------------------------------------------------



  getPastTime() {
    var label = lang.t('billing:label.last30days')
    if(this.state.dateRange === '7days') {
      label = lang.t('billing:label.last7days')
    } else if(this.state.dateRange === 'month') {
      label = lang.t('billing:label.thismonth')
    } else if(this.state.dateRange === 'week') {
      label = lang.t('billing:label.thisweek')
    } else if(this.state.dateRange === '60days') {
      label = lang.t('billing:label.last60days')
    }
    return label
  }


  renderStatus(item) {
    if(item.data.status === 'paid') {
      return (
        <span className="pill green" style={{position: 'relative', top: '-2px'}}>{lang.t('billing:label.paid')}</span>
      )
    } else if(item.data.status === 'paid') {
      return (
        <span className="pill" style={{position: 'relative', top: '-2px'}}>{lang.t('billing:label.void')}</span>
      )
    } else if(item.data.status === 'open' && Moment(item.data.due, 'X') > Moment().add(-1, 'day')) {
      return (
        <span className="pill yellow" style={{position: 'relative', top: '-2px'}}>{lang.t('billing:label.open')}</span>
      )
    } else if(item.data.status === 'open' && Moment(item.data.due, 'X') < Moment().add(-1, 'day')) {
      return (
        <span className="pill red" style={{position: 'relative', top: '-2px'}}>{lang.t('billing:label.overdue')}</span>
      )
    }
  }


  renderInvoices() {
    if(this.state.invoicesList.length === 0) {
      if(this.state.search === '') {
        return (
          <ListEmpty id='noinvoices' type='simple' var={this.getPastTime()}/>
        )
      } else {
        return (
          <div>
            <div className="listheader">
              <div className="mt-5 lft">
                <ListHeader id='invoices' number={this.state.invoicesList.length}/>
              </div>
              <select className="light rgt" onChange={event => this.setStatus(event)} value={this.state.status}>
                <option value='' key='none'>{lang.t('billing:label.allstatuses')}</option>
                <option value='open'>{lang.t('billing:label.open')}</option>
                <option value='paid'>{lang.t('billing:label.paid')}</option>
                <option value='overdue'>{lang.t('billing:label.overdue')}</option>
              </select>
              <input type="text" className="search light large rgt" placeholder={lang.t('billing:label.filterbyclientname')} value={this.state.search} onChange={(val) => this.filterInvoices(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='invoices'/>
          </div>
        )
      }
      
    } else {
      var list = this.state.invoicesList
      list.sort((a,b) => Moment(b.data.due, 'X') - Moment(a.data.due, 'X'));
      return (
        <div>
          <div className="listheader">
            <div className="mt-5 lft">
              <ListHeader id='invoices' number={this.state.invoicesList.length}/>
            </div>
            <select className="light rgt" onChange={event => this.setStatus(event)} value={this.state.status}>
              <option value='' key='none'>{lang.t('billing:label.allstatuses')}</option>
              <option value='open'>{lang.t('billing:label.open')}</option>
              <option value='paid'>{lang.t('billing:label.paid')}</option>
              <option value='overdue'>{lang.t('billing:label.overdue')}</option>
            </select>
            <input type="text" className="search light large rgt" placeholder={lang.t('billing:label.filterbyclientname')} value={this.state.search} onChange={(val) => this.filterInvoices(val)} />
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListSimple
              key={item.id}
              link={'/billing/invoice/'+item.id}
              columns={[{text: 'Invoice '+item.data.number, width: 2, w1280: 5}, {text: this.getClient(item.data.client), width: 3, w1280: 0}, {text: global.curSym+item.data.price.toFixed(2), width: 2}, {text: <span>{lang.t('billing:label.due')} {Moment(item.data.due, 'X').locale(lang.language).format('D MMM YYYY')} {this.renderStatus(item)}</span>, width: 3}]}
            />
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.INVOICENEW)}>{lang.t('billing:button.createanotherinvoice')}</button>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'report', link: ROUTES.REPORT}
    if(HelperBusiness.getRoleIncl('admin')) {
      item1 = {id: 'newinvoice', link: ROUTES.INVOICENEW}
    }
    return (
      <WidgetActions
        item1={item1}
        item2={{id: '', link: ''}}
        item3={{id: '', link: ''}}
      />
    )
  }


  renderContent() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="col-9">
            <WidgetOverview
              item1={{id: 'invtotal', value: this.state.invoicesList.length, prev: ''}}
              item2={{id: 'invopen', value: this.getInvoices('open'), prev: ''}}
              item3={{id: 'invpaid', value: this.getInvoices('paid'), prev: ''}}
              item4={{id: 'invoverdue', value: this.getInvoices('overdue'), prev: ''}}
            />
          </div>
          {this.renderActions()}
          <div className="col-12 clear">
            {this.renderInvoices()}
            <div className="sv-80 clear"></div>
          </div>
          <div className="sv-20 clear"></div>
        </div>
        <Header type='billing' active='invoices' selector={true} updateSelect={() => this.setDateRange()}/>
        <Navigation active='billing' />
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRole('assistant,assistant2,trainer,cs')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='billing-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.billing')}/>
          <Navigation active='billing' />
        </div>
      )
    } else {
      if(global.showHabits) {
        return (
          this.renderContent()
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='invoices-locked'/>
              <div className="sv-20 clear"></div>
            </div>
            <Header type='billing' active='invoices'/>
            <Navigation active='billing' />
          </div>
        )
      }
      
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header','nav'])(withRouter(withAuthorization(condition)(InvoicesPage)));