import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class InfoPrice extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? '',
      id: props.id,
      product: props.product,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      type: props.type ?? '',
      id: props.id,
      product: props.product,
    }
  }


  renderBilled() {
    var label = lang.t('widgets:form.price.billedweekly')
    if(this.state.product.data.billing === 'fortnight') {
      label = lang.t('widgets:form.price.billedfortnightly')
    }
    if(this.state.product.data.billing === 'month') {
      label = lang.t('widgets:form.price.billedmonthly')
    }
    return label
  }


  renderAvailable() {
    if(this.state.type === 'payment') {
      if(this.state.id !== '') {
        var label = lang.t('widgets:form.price.sessionsdontexpire')
        if(this.state.product.data.expires !== undefined) {
          label = lang.t('widgets:form.price.sessionsexpire')+' '+Moment().add(this.state.product.data.expires, this.state.product.data.expType).locale(lang.language).format('D MMM YYYY')
        }
        if(this.state.product.data.type === 'other') {
          label = ''
        }
        return (
          <p className="small">{label}</p>
        )
      }
    } else if(this.state.type === 'debit') {
      return (
        <p className="small">{this.renderBilled()}</p>
      )
    } else if(this.state.type === 'client') {
      return (
        <p className="small">{lang.t('widgets:form.price.selectclient')}</p>
      )
    }
  }


  renderStock() {
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      return <p className="small">{this.state.product.data.stock} {lang.t('widgets:form.price.instock')}</p>
    }
  }


  renderPriceLabel() {
    if(this.state.product.data.type === 'other') {
      return lang.t('widgets:form.price.amountdue')
    } else {
      var label = lang.t('widgets:form.price.sessions')
      if(this.state.product.data.stype === 'group') {
        label = this.state.product.data.sessions+' '+lang.t('widgets:form.price.groupsession')
        if(this.state.product.data.sessions !== 1) {
          label = this.state.product.data.sessions+' '+lang.t('widgets:form.price.groupsessions')
        }
        if(this.state.product.data.type === 'subscription' && this.state.product.data.sessions === 0) {
          label = lang.t('widgets:form.price.unlimitedgroup')
        }
      } else if(this.state.product.data.stype === '11') {
        label = this.state.product.data.sessions+' '+lang.t('widgets:form.price.11session')
        if(this.state.product.data.sessions !== 1) {
          label = this.state.product.data.sessions+' '+lang.t('widgets:form.price.11sessions')
        }
        if(this.state.product.data.type === 'subscription' && this.state.product.data.sessions === 0) {
          label = lang.t('widgets:form.price.unlimited11')
        }
      } else if(this.state.product.data.stype === 'both') {
        label = this.state.product.data.sessions+' '+lang.t('widgets:form.price.groupsession')
        if(this.state.product.data.sessions !== 1) {
          label = this.state.product.data.sessions+' '+lang.t('widgets:form.price.groupsessions')
        }
        if(this.state.product.data.type === 'subscription' && this.state.product.data.sessions === 0) {
          label = lang.t('widgets:form.price.unlimitedgroup')
        }
        if(this.state.product.data.sessions11 !== 0) {
          label += ' & '+this.state.product.data.sessions11+' '+lang.t('widgets:form.price.11session')
          if(this.state.product.data.sessions11 !== 1) {
            label += lang.t('widgets:form.price.s')
          }
        } else {
          label += ' '+lang.t('widgets:form.price.andunlimited11')
        }
       
      }
      return label
    }
  }


  render() {
    if(this.state.id !== '') {
      return (
        <div className="info">
          <label>{this.renderPriceLabel()}</label>
          <p className="large">{global.curSym}{this.state.product.data.price.toFixed(2)}</p>
          {this.renderAvailable()}
          {this.renderStock()}
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['widgets'])(InfoPrice);