import React, { Component } from 'react';
import HelperEmpty from '../../helper/EmptyLarge';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



const DataEmptySearch  = [
  {
    id: 'clients',
    title: 'empty:search.clients.title',
    text: 'empty:search.clients.text',
  },
  {
    id: 'recurring',
    title: 'empty:search.recurring.title',
    text: 'empty:search.recurring.text',
  },
  {
    id: 'programs',
    title: 'empty:search.programs.title',
    text: 'empty:search.programs.text',
  },
  {
    id: 'plans',
    title: 'empty:search.plans.title',
    text: 'empty:search.plans.text',
  },
  {
    id: 'nutrition',
    title: 'empty:search.nutrition.title',
    text: 'empty:search.nutrition.text',
  },
  {
    id: 'habits',
    title: 'empty:search.habits.title',
    text: 'empty:search.habits.text',
  },
  {
    id: 'payments',
    title: 'empty:search.payments.title',
    text: 'empty:search.payments.text',
  },
  {
    id: 'debits',
    title: 'empty:search.debits.title',
    text: 'empty:search.debits.text',
  },
  {
    id: 'products',
    title: 'empty:search.products.title',
    text: 'empty:search.products.text',
  },
  {
    id: 'invoices',
    title: 'empty:search.invoices.title',
    text: 'empty:search.invoices.text',
  },
  {
    id: 'expenses',
    title: 'empty:search.expenses.title',
    text: 'empty:search.expenses.text',
  },
]



class ListEmpty extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      var: props.var
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      var: props.var
    }
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataEmptySearch) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      text = lang.t(data.text).replace('$var', this.state.var)
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1 static"></div>
            <div className="img2 static"></div>
            <div className="img3 static"></div>
            <div className="img4 static"></div>
            {HelperEmpty.renderImage('search', true)}
          </div>
          <h3 className="mb-20">{lang.t(data.title)}</h3>
          <p className="mb-20">{text}</p>
        </div>
      )
    }
  }
}


export default withTranslation(['empty'])(ListEmpty);