import React, { Component } from 'react';
import 'firebase/functions';
import "firebase/messaging";
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperBilling from '../../helper/Billing';
import HelperActivity from '../../helper/Activity';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import HelperEmptyLarge from '../../helper/EmptyLarge';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import ListClient from '../../components/List/client';
import ModalPayment from '../../components/Modals/payment';
import Overview from '../../components/Navigation/overview';
import WidgetChartBar from '../../components/Widgets/chart-bar';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Demo from '../../data/Demo';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class OverviewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: global.sessions,
      archive: global.archive,
      clients: global.clients,
      payments: global.payments,
      dateRange: global.dateRange,
      today: [],
      notifications: [],
      tomorrow: [],
      showSessions: 'today',
      requests: [],
      birthdays: [],
      showModal: false,
      margin: 0,
      hidden: 'hidden',
      messages: ['', ''],
      userMessage: global.userMessage,
      maxClients: global.maxClients,
      maxPrograms: global.maxPrograms,
      programs: global.programs,
      showModalInfo: false,
      hiddenInfo: 'hidden',
      olTitle: '',
      olText: '',
      trainerId: global.uidUser,
      showOnboarding: false,
      userSubEnd: global.userSubEnd,
      showModalResponse: false,
      hiddenResponse: 'hidden',
      userConnectClient: null,
      responseType: 'new',
      clientsResponse: [],
      responseSelected: '',
      typeBadge: 'init',
      unpaid: [],
      unpaid1: 0,
      unpaid2: 0,
      showModalUnpaid: false,
      hiddenUnpaid: 'hidden',
      activity: [],
      activityTypes: global.userActivityTypes,
      currentPayment: null,
      showModalClients: false,
      hiddenClients: 'hidden',
      connectClient: '',
      selectedClient: '',
      selectedExists: false,
      showModalPayment: false,
      showModalOverview: false,
      dataBilling: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
      dataBillingMax: 0,
      noclients: false,
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Overview - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';
    global.currentPage = ROUTES.OVERVIEW;
    global.linkCli = '';
    global.linkCal = '';
    global.linkPro = '';
    global.linkBil = '';
    global.linkAdm = '';
    
    global.currentDate = Moment().format('DD/MM/YYYY')
    global.currentTime = '05'

    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.setState({clients: global.clients})
      this.setRequests()
      this.setBirthdays()
      this.setUnpaid()
    })
    EventEmitter.subscribe('archiveLoaded', (event) => this.setState({archive: global.archive}))
    EventEmitter.subscribe('sessionsLoaded', (event) => {
      this.setState({sessions: global.sessions})
      this.setCalendar()
      this.setActivity()
    })
    EventEmitter.subscribe('paymentsLoaded', (event) => {
      this.setState({payments: global.payments})
      this.setPayments()
      this.setActivity()
      this.setBilling()
    })
    EventEmitter.subscribe('invoicesLoaded', (event) => {
      this.setActivity()
    })
    EventEmitter.subscribe('expensesLoaded', (event) => {
      this.setBilling()
    })
    EventEmitter.subscribe('programsLoaded', (event) => {
      this.setState({programs: global.programs})
      this.setNotifications()
    })
    EventEmitter.subscribe('userLoaded', (event) => {
      this.checkUserMessage()
      this.setRequests()
      this.setBirthdays()
      this.setUnpaid()
      this.setActivity()
      var tmp = []
      tmp = HelperActivity.getActivityAll(global.userActivityTypes)
      this.setState({
        activityTypes: global.userActivityTypes,
        activity: tmp
      })
    })
    EventEmitter.subscribe('userDataLoaded', (event) => {
      this.checkUserMessage()
      this.updateOnboarding()
      this.setRequests()
      this.setBirthdays()
      this.setUnpaid()
      this.setActivity()
    })
    EventEmitter.subscribe('publicRequestsLoaded', (event) => {
      this.setRequests()
      this.setActivity()
    })
    EventEmitter.subscribe('logLoaded', (event) => this.setRequests())
    this.setRequests()
    this.setBirthdays()
    this.setCalendar()
    this.setPayments()
    this.setNotifications()
    this.setUnpaid()
    this.setActivity()
    this.setBilling()

    if(global.portalChange) {
      this.showModalTrainer()
      global.portalChange = false
    }

    EventEmitter.subscribe('userConnectClientLoaded', (event) => this.setState({userConnectClient: global.userConnectClient}))
   
    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300); 
    }
    
    if(!global.updatingRec) {
      Connector.checkRecurring()
    }
    EventEmitter.subscribe('obUpdated', (event) => {
      this.setState({showOnboarding: global.showOnboarding})
    })

    EventEmitter.subscribe('locationSet', (event) => {
      this.setRequests()
      this.setBirthdays()
      this.setCalendar()
      this.setPayments()
      this.setNotifications()
      this.setUnpaid()
      this.setActivity()
      this.setBilling()
    })

    setTimeout(() => {
      if(global.clients.length === 0) {
        this.setState({
          noclients: true
        })
      }
    }, 1500);

    setTimeout(() => {
      this.setNotifications()
      this.setBilling()
      this.setRequests()
      this.setBirthdays()
    }, 100);
  }


  configureEvents() {
    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.setState({clients: global.clients})
      this.setRequests()
      this.setBirthdays()
      this.setUnpaid()
    })
    EventEmitter.subscribe('archiveLoaded', (event) => this.setState({archive: global.archive}))
    EventEmitter.subscribe('sessionsLoaded', (event) => {
      this.setState({sessions: global.sessions})
      this.setCalendar()
      this.setActivity()
    })
    EventEmitter.subscribe('paymentsLoaded', (event) => {
      this.setState({payments: global.payments})
      this.setPayments()
      this.setActivity()
      this.setBilling()
    })
    EventEmitter.subscribe('invoicesLoaded', (event) => {
      this.setActivity()
    })
    EventEmitter.subscribe('expensesLoaded', (event) => {
      this.setBilling()
    })
    EventEmitter.subscribe('programsLoaded', (event) => {
      this.setState({programs: global.programs})
      this.setNotifications()
    })
    /*EventEmitter.subscribe('userLoaded', (event) => {
      this.checkUserMessage()
      this.setRequests()
      this.setBirthdays()
      this.setUnpaid()
      this.setActivity()
      var tmp = []
      tmp = HelperActivity.getActivityAll(global.userActivityTypes)
      this.setState({
        activityTypes: global.userActivityTypes,
        activity: tmp
      })
    })*/
    EventEmitter.subscribe('userDataLoaded', (event) => {
      this.checkUserMessage()
      this.updateOnboarding()
      this.setRequests()
      this.setBirthdays()
      this.setUnpaid()
      this.setActivity()
    })
    EventEmitter.subscribe('publicRequestsLoaded', (event) => {
      this.setRequests()
      this.setActivity()
    })
    EventEmitter.subscribe('logLoaded', (event) => this.setRequests())
    this.setRequests()
    this.setBirthdays()
    this.setCalendar()
    this.setPayments()
    this.setNotifications()
    this.setUnpaid()
    this.setActivity()
    this.setBilling()

    if(global.portalChange) {
      global.portalChange = false
    }

    EventEmitter.subscribe('userConnectClientLoaded', (event) => this.setState({userConnectClient: global.userConnectClient}))
   
    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300); 
    }
    
    if(!global.updatingRec) {
      Connector.checkRecurring()
    }
    EventEmitter.subscribe('obUpdated', (event) => {
      this.setState({showOnboarding: global.showOnboarding})
    })
  }


  updateOnboarding() {
    var show = false
    if(global.userOnboarding[0] === '' && global.userSubStatus === 'trialing' && global.clients.length < 2) {
      show = true
    }
    if(show) {
      global.userOnboarding[0] = 'start'
      setTimeout(() => {
        //this.showStart()
        Firebase.database().ref('/users/'+global.uidUser).update({onboarding: global.userOnboarding});
      }, 300); 
    }
  }


  setActivity() {
    var tmp = HelperActivity.getActivityAll(this.state.activityTypes)
    this.setState({
      activity: tmp
    })
    this.setNotifications()
  }


  setCalendar() {
    var tmp1 = []
    var tmp2 = []
    for(var item of global.sessions) {
      if(global.loc === '' || item.data.location === global.loc) {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment().format('DD/MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(item.data.duration, 'minutes') > Moment()) {
          if(HelperBusiness.getRole('assistant,assistant2')) {
            if(item.data.trainerId === this.state.trainerId) {
              tmp1.push(item)
            }
          } else {
            tmp1.push(item)
          }
        } else if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment().add(1, 'day').format('DD/MM/YYYY')) {
          if(HelperBusiness.getRole('assistant,assistant2')) {
            if(item.data.trainerId === this.state.trainerId) {
              tmp2.push(item)
            } 
          } else {
            tmp2.push(item)
          }
        }
      }
    }
    this.setState({
      today: tmp1,
      tomorrow: tmp2
    })
  }


  setPayments() {
    var tmp = []

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    var minDate = Cal.getDateRange()
    for(var item of global.payments) {
      if(this.state.dateRange === 'all' && list.indexOf(item.data.client) !== -1) {
        tmp.push(item)
      } else {
        var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(item.data.timestamp !== undefined) {
          date = Moment(item.data.timestamp, 'X').format('X')
        }
        if(date > minDate) {
          tmp.push(item)
        }
      }
    }
    this.setState({
      payments: tmp,
    })
  }


  setUnpaid() {
    var tmp = []
    var tmp1 = 0
    var tmp2 = 0

    var clist = []
    if(global.loc === '') {
      for(var cl of this.state.clients) {
        clist.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        clist.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          clist = loc.data.clients
        }
      }
    }
    
    for(var client of this.state.clients) {
      if(clist.indexOf(client.id) !== -1) {
        var d1 = 0
        var dg = 0
        var p1 = 0
        var pg = 0
        if(client.credits !== null && (client.data.parent === '' || client.data.parent === undefined)) {
          var add1 = 0
          var add2 = 0
          add1 -= HelperBilling.getClientSessionDebit(client, false, client.id, true, [])
          add2 -= HelperBilling.getClientSessionDebit(client, true, client.id, true, [])

          var list = []
          for(var i1 of client.credits) {
            list.push(i1)
          }
          for(var i2 of client.creditse) {
            if(Moment(i2.data.expires,'X') > Moment()) {
              list.push(i2)
            }
          }

          var title = client.data.name
          var valid = ['', undefined, client.id]
          if(client.data.linked !== undefined) {
            if(client.data.linked !== undefined) {
              title = client.data.name+' ('+client.data.name+')'
              valid = [client.id]
            }
          }

          for(var item of list) {
            if(valid.indexOf(item.data.account) !== -1) {
              if(!item.data.group) {
                d1 += item.data.sessionsTotal
                p1 += item.data.sessionsPaid
              } else {
                dg += item.data.sessionsTotal
                pg += item.data.sessionsPaid
              }
            }
          }

          var f11 = add1+(d1-p1)
          var fg1 = add2+(dg-pg)
          
          if(f11 > 0) {
            tmp1 += f11
            tmp.push({
              id: client.id,
              title: title,
              info: String(f11)+' '+(f11 === 1 ? '1:1 session' : '1:1 sessions'), //lang.t('home:not.11session') : lang.t('home:not.11sessions')
              link: '/billing/payment/'+client.id+'/billing',
              client: client,
            })
          }
          if(fg1 > 0) {
            tmp2 += fg1
            tmp.push({
              id: client.id,
              title: title,
              info: String(fg1)+' '+(fg1 === 1 ? '::home:not.groupsession::' : '::home:not.groupsessions::'),
              //info: String(fg1)+' '+(fg1 === 1 ? 'group session' : 'group sessions'),//lang.t('home:not.groupsession') : lang.t('home:not.groupsessions')
              link: '/billing/payment/'+client.id+'/billing',
              client: client,
            })
          }
        
          // Family
          if(client.data.linked !== undefined) {
            var d11 = 0
            var dg1 = 0
            var p11 = 0
            var pg1 = 0

            var add11 = 0
            var add12 = 0
            add11 -= HelperBilling.getClientSessionDebit(client, false, '', true, [])
            add12 -= HelperBilling.getClientSessionDebit(client, true, '', true, [])

            for(var item2 of list) {
              if(item2.data.account === '' || item2.data.account === undefined) {
                if(!item2.data.group) {
                  d11 += item2.data.sessionsTotal
                  p11 += item2.data.sessionsPaid
                } else {
                  dg1 += item2.data.sessionsTotal
                  pg1 += item2.data.sessionsPaid
                }
              }
            }
    
            var f12 = add11+(d11-p11)
            var fg2 = add12+(dg1-pg1)
    
            if(f12 > 0) {
              tmp1 += f12
              tmp.push({
                id: client.id,
                title: client.data.name+' '+'(Family)',//lang.t('home:not.family')
                info: String(f12)+' '+(f12 === 1 ? '1:1 session' : '1:1 sessions'),//lang.t('home:not.11session') : lang.t('home:not.11sessions'))
                link: '/billing/payment/'+client.id+'/billing',
                client: client,
              })
            }
            if(fg2 > 0) {
              tmp2 += fg2
              tmp.push({
                id: client.id,
                title: client.data.name+' '+'(Family)',//lang.t('home:not.family')
                info: String(fg2)+' '+(fg2 === 1 ? 'group session' : 'group sessions'),//lang.t('home:not.groupsession') : lang.t('home:not.groupsessions'))
                link: '/billing/payment/'+client.id+'/billing',
                client: client,
              })
            }

            for(var link of client.data.linked) {
              var cl = client
              d11 = 0
              dg1 = 0
              p11 = 0
              pg1 = 0

              for(var cln of this.state.clients) {
                if(cln.id === link) {
                  cl = cln
                }
              }

              add11 -= HelperBilling.getClientSessionDebit(cln, false, cl.id, true, [])
              add12 -= HelperBilling.getClientSessionDebit(cln, true, cl.id, true, [])
              
              for(var item3 of list) {
                if(item3.data.account === link) {
                  if(!item3.data.group) {
                    d11 += item3.data.sessionsTotal
                    p11 += item3.data.sessionsPaid
                  } else {
                    dg1 += item3.data.sessionsTotal
                    pg1 += item3.data.sessionsPaid
                  }
                }
              }
      
              f12 = add11+(d11-p11)
              fg2 = add12+(dg1-pg1)
      
              if(f12 > 0) {
                tmp1 += f12
                tmp.push({
                  id: client.id,
                  title: client.data.name+' ('+cl.data.name+')',
                  info: String(f12)+' '+(f12 === 1 ? '::home:not.11session::' : '::home:not.11sessions::'),
                  //info: String(f12)+' '+(f12 === 1 ? '1:1 session' : '1:1 sessions'),//lang.t('home:not.11session') : lang.t('home:not.11sessions')
                  link: '/billing/payment/'+client.id+'/billing',
                  client: client,
                })
              }
              if(fg2 > 0) {
                tmp2 += fg2
                tmp.push({
                  id: client.id,
                  title: client.data.name+' ('+cl.data.name+')',
                  info: String(fg2)+' '+(fg2 === 1 ? '::home:not.groupsession::' : '::home:not.groupsessions::'),
                  //info: String(fg2)+' '+(fg2 === 1 ? 'group session' : 'group sessions'),//lang.t('home:not.groupsession') : lang.t('home:not.groupsessions')
                  link: '/billing/payment/'+client.id+'/billing',
                  client: client,
                })
              }

            }
          }
        }
      }
    }
    if(tmp.length > 0) {
      tmp = tmp.sort((a, b) => a.title.localeCompare(b.title))
    }
    this.setState({
      unpaid: tmp,
      unpaid1: tmp1,
      unpaid2: tmp2
    })
  }


  getClientNameNot(data) {
    var arr = data.split(' ')
    var name = lang.t('home:not.newclient')
    for(var item of global.clients) {
      if(item.id === arr[1]) {
        name = item.data.name
      }
    }
    return name
  }


  getClientIdNot(data) {
    var arr = data.split(' ')
    return arr[1]
  }


  setNotifications() {
    var tmp = []

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var client of this.state.clients) {
      if(client.subscriptions !== null && list.indexOf(client.id) !== -1) {
        for(var sub of client.subscriptions) {
          if((sub.data.status === 'past_due' || sub.data.status === 'incomplete' || sub.data.status === 'deleted' || sub.data.status === 'unpaid') && HelperBusiness.getRoleIncl('')) {
            var desc = "Your client's last membership payment failed. Click for more details." //lang.t('home:not.clientpaymentfailed')
            if(sub.data.status === 'unpaid') {
              desc = "Stripe tried to charge your client multiple times. You need to cancel the membership and reset it."//lang.t('home:not.unpaid')
            }
            tmp.push({
              id: client.id,
              color: 'red',
              icon: 'refresh',
              title: "Membership payment failed",//lang.t('home:not.membershippayment')
              desc: desc,
              link: '/billing/direct-debits/'+client.id+'/'+sub.id,
            })
          }
        }
      }
    }

    if((global.userSubStatus === 'past_due' || global.userSubStatus === 'incomplete') && HelperBusiness.getRoleIncl('')) {
      tmp.push({
        id: 'sub',
        color: 'red',
        icon: 'refresh',
        title: "Subscription payment failed",//lang.t('home:not.subpayment')
        desc: "Please review your payment method in your account to continue",//lang.t('home:not.subpayment.text')
        link: ROUTES.ACCOUNT,
      })
    }
    if(this.state.clients.length >= this.state.maxClients && this.state.maxClients > 0 && HelperBusiness.getRoleIncl('')) {
      tmp.push({
        id: 'maxclients',
        color: 'yellow',
        icon: 'active',
        title: "Client maximum reached",//lang.t('home:not.clientmax')
        desc: "To keep adding clients, please upgrade your plan",//lang.t('home:not.clientmax.text')
        link: ROUTES.SUBSCRIPTION,
      })
    }
    if(this.state.programs.length >= this.state.maxPrograms && this.state.maxPrograms > 0 && HelperBusiness.getRoleIncl('')) {
      tmp.push({
        id: 'maxprog',
        color: 'yellow',
        icon: 'training',
        title: "Program maximum reached",//lang.t('home:not.programmax.')
        desc: "To keep adding programs, please upgrade your plan",//lang.t('home:not.programmax.text')
        link: ROUTES.SUBSCRIPTION,
      })
    }
    for(var item of global.publicRequests) {
      if(item.data.status === 'later' && Moment() >= Moment(item.data.date, 'X')) {
        tmp.push({
          id: 'contactrequest',
          color: 'quarternary',
          icon: 'inbox',
          title: lang.t('home:not.contact.title1')+item.data.name+lang.t('home:not.contact.title2'),
          desc: lang.t('home:not.contact.text')+Moment(item.data.date, 'X').locale(lang.language).format('D MMM YYYY'),
          link: ROUTES.ADMINPUBLIC,
        })
      }
    }
    this.setState({
      notifications: tmp,
    })
  }


  setRequests() {
    var tmp = []
    if(global.maxPlans === 99999) {
      for(var req of global.publicRequests) {
        if(req.data.status === 'new') {
          var type = lang.t('home:not.tobecontacted')
          if(req.data.session !== '') {
            type = lang.t('home:not.trialsession')
          }
          tmp.push({
            id: req.id,
            icon: 'inbox',
            title: lang.t('home:not.newlead'),
            desc: req.data.name+lang.t('home:not.requests')+type,
            link: ROUTES.ADMINPUBLIC,
          })
        }
      }
    }
    for(var lg of global.userLog) {
      if(lg.data.type === 'assistantinvite' && lg.data.message === 'invited') {
        tmp.push({
          id: lg.id,
          icon: 'assistant',
          title: lang.t('home:not.pendinginvite'),
          desc: lang.t('home:not.pendinginvite.text1')+lg.data.title+lang.t('home:not.pendinginvite.text2'),
          link: ROUTES.ADMINASSISTANT,
        })
      }
      if(lg.data.type === 'assistant') {
        tmp.push({
          id: lg.id,
          icon: 'assistant',
          title: lang.t('home:not.invitefrom')+lg.data.title,
          desc: lg.data.title+lang.t('home:not.invitefrom.text'),
          link: ROUTES.ADMINASSISTANT,
        })
      }
      if(lg.data.type === 'request' && HelperBusiness.getRoleIncl('')) {
        tmp.push({
          id: lg.id,
          icon: 'connect',
          title: lg.data.title,
          desc: lg.data.message+lang.t('home:not.connect'),
          link: lg.id,
        })
      }
      if(lg.data.type === 'assistantinvite' && lg.data.message === 'accepted') {
        this.showModalInfo('Invite accepted', lg.data.title+" accepted your invite to become your assistant trainer.")
        Firebase.database().ref('/log/'+global.uidUser+'/'+lg.id).remove()
      }
      if(lg.data.type === 'assistantdisconnected') {
        this.showModalInfo('Trainer removed', lg.data.title+" is no longer your assistant trainer.")
        Firebase.database().ref('/log/'+global.uidUser+'/'+lg.id).remove()
      }
      if(lg.data.type === 'assistantremoved') {
        this.showModalInfo('Trainer removed', lg.data.title+" removed you from being their assistant trainer.")
        Firebase.database().ref('/log/'+global.uidUser+'/'+lg.id).remove()
      }
    }
    this.setState({
      requests: tmp,
    })
  }


  setBirthdays() {
    var tmp = []

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var client of this.state.clients) {
      if(list.indexOf(client.id) !== -1) {
        let day = Moment(client.data.birth, 'DD/MM/YYYY').format('DD/MM')
        let yr = Moment().format('YYYY')
        let date = Moment(day+'/'+yr, 'DD/MM/YYYY').format('X')
        let advance = Moment().add(5, 'days').format('X')
        if((date < advance && date >= Moment().format('X')) || day === Moment().format('DD/MM')) {
          tmp.push({
            id: client.id,
            title: client.data.name,
            desc: Moment(client.data.birth, 'DD/MM/YYYY').locale(lang.language).format('D MMMM'),
            link: '/clients/'+client.id+'/overview',
          })
        }
      }
    }
    this.setState({
      birthdays: tmp,
    })
  }


  setBilling() {
    var tmp = []
    var m1 = 0
    var start = Moment().add(-50, 'days').startOf('week').format('X')
    for(var i=0; i<10; i++) {
      var ws = Moment(start, 'X').add(i, 'weeks').format('X')
      if(Moment(ws, 'X') < Moment()) {
        tmp.push({
          date: Moment(ws, 'X').format('DD/MM'),
          income: 0,
          expenses: 0
        })
      }
    }
    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }
    for(var item1 of global.payments) {
      if(list.indexOf(item1.data.client) !== -1) {
        var dt = Moment(item1.data.date, 'DD/MM/YYYY HH:mm').startOf('week').format('DD/MM')
        for(var t1 of tmp) {
          if(t1.date === dt) {
            t1.income += (item1.data.amount/100)
          }
        }
      }
    }
    for(var item2 of global.expenses) {
      if(global.loc === '' || item2.data.location === global.loc) {
        var dt2 = Moment(item2.data.date, 'X').startOf('week').format('DD/MM')
        for(var t2 of tmp) {
          if(t2.date === dt2) {
            t2.expenses += item2.data.amount
          }
        }
      }
    }
    var tmp2 = []
    for(var t3 of tmp) {
      tmp2.push({
        "date": "Week of "+t3.date,
        [lang.t('home:label.payments')]: t3.income.toFixed(2),
        [lang.t('home:label.expenses')]: t3.expenses.toFixed(2)
      })
      if(t3.income > m1) {
        m1 = t3.income
      }
      if(t3.expenses > m1) {
        m1 = t3.expenses
      }
    }
    setTimeout(() => {
      this.setState({
        dataBilling: tmp2,
        dataBillingMax: m1,
      });
    }, 100);
  }


  checkUserMessage() {
    if((global.userSubStatus === 'past_due' || global.userSubStatus === 'incomplete') && Moment() < Moment(global.syncDate, 'X').add(5, 'minutes')) {
      this.showModalMessage(lang.t('home:sub.paymentfailed.title'), lang.t('home:sub.paymentfailed.text'))
    }
    this.setState({
      userMessage: global.userMessage,
      maxClients: global.maxClients,
      maxPrograms: global.maxPrograms,
      userSubEnd: global.userSubEnd
    }, () => {
      if(global.userPhone !== '' && this.state.userSubEnd !== 0) {
        var today = parseInt(Moment().format('X'))
        var d1 = parseInt(Moment(this.state.userSubEnd, 'X').add(-1, 'days').format('X'))
        var d4 = parseInt(Moment(this.state.userSubEnd, 'X').add(-4, 'days').format('X'))
        
        // 4 days before expiry
        if(today > d4 && today < d1 && global.userSubPlan === 'spark' && this.state.userMessage === 0 && global.userSubStatus === 'trialing') {
          this.showModalMessage(lang.t('home:sub.trialsoon.title'), lang.t('home:sub.trialsoon.text1')+Moment(this.state.userSubEnd, 'X').locale(lang.language).format('D MMMM')+lang.t('home:sub.trialsoon.text2'))
          Firebase.database().ref('/users/'+global.uidUser).update({ message: 1 });
        }

        // 1 day before expiry
        if(today > d1 && today < this.state.userSubEnd && global.userSubPlan === 'spark' && this.state.userMessage < 2 && global.userSubStatus === 'trialing') {
          this.showModalMessage(lang.t('home:sub.trialabout.title'), lang.t('home:sub.trialabout.text'))
          Firebase.database().ref('/users/'+global.uid).update({ message: 2 });
        }

        // Trial expired
        if(today > this.state.userSubEnd && global.userSubStatus === 'trialing' && this.state.userMessage < 3 && global.userSubPlan === 'spark') {
          this.showModalMessage(lang.t('home:sub.trialexpired.title'), lang.t('home:sub.trialexpired.text'))
          Firebase.database().ref('/users/'+global.uid).update({
            message: 3
          });
        }
      }
      this.setNotifications()
    });
  }


  createDemoContent() {
    EventEmitter.dispatch('showMessage', 'Setting demo content up');
    Demo.createContent()
  }



  // Unpaid modal ------------------------------------------------------------



  showModalUnpaid() {
    this.setState({
      showModalUnpaid: true,
    })
    setTimeout(() => {
      this.setState({
        hiddenUnpaid: '',
      });
    }, 100);
  }


  hideModalUnpaid() {
    this.setState({
      hiddenUnpaid: 'hidden',
    })
    setTimeout(() => {
      this.setState({showModalUnpaid: false});
    }, 500);
  }


  renderModalLine1(item) {
    var label = item
    var repl = ''
    if(label.indexOf('::') !== -1) {
      var arr = label.split('::')
      for(var a of arr) {
        if(a.indexOf('home:') !== -1) {
          repl = lang.t(a)
        }
      }
      label = ""
      for(var a2 of arr) {
        if(a2.indexOf('home:') !== -1) {
          label += repl
        } else {
          label += a2
        }
        
      }
    }
    return label
  }


  renderModalUnpaid() {
    if(this.state.showModalUnpaid) {
      return (
        <div className={'overlay '+this.state.hiddenUnpaid}>
          <div className="box clients">
            <h2 className="mb-20 lft">Unpaid sessions</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalUnpaid()}>Close</button>
            <div className="clear"></div>
            <div className="scroll">
              {this.state.unpaid.map(item => (
                <ListClient key={item.id} client={item.client} title={item.title} line1={this.renderModalLine1(item.info)} line2={' '} clickElement={() => this.selectUnpaid(item.link)}/>
              ))}
            </div>
          </div>
        </div>
      )
    }
  }


  selectUnpaid(link) {
    this.hideModalUnpaid()
    this.props.history.push(link)
  }



  // Overview Calculations ------------------------------------------------------------



  showModalMessage(title, text) {
    this.setState({
      showModal: true,
      messages: [title, text]
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalMessage() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModal: false});
    }, 500);
  }


  renderModalButton() {
    if(this.state.messages[0] === 'Payment failed') {
      return (
        <Link to={ROUTES.ACCOUNT} className="btn primary">Update payment method</Link>
      )
    } else {
      return (
        <Link to={ROUTES.SUBSCRIPTION} className="btn primary">Upgrade now</Link>
      )
    }
  }


  renderModalMessage() {
    if(this.state.showModal) {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.state.messages[0]}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalMessage()}>Close</button>
            <p className="clear mb-30">{this.state.messages[1]}</p>
            {this.renderModalButton()}
          </div>
        </div>
      )
    }
  }



  // Overview Calculations ------------------------------------------------------------



  getSessions(type) {
    var minDate = Moment().add(-31, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days')
    }

    var count = 0
    for(var item of this.state.sessions) {
      if((!item.data.group && type === 'sessions') || (item.data.group && type === 'group')) {
        if(this.state.dateRange === '7days' || this.state.dateRange === '30days') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
            count++
          }
        } else if(this.state.dateRange === 'month') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
            count++
          }
        } else {
          count++
        }
      }
    }
    for(var item2 of this.state.archive) {
      if((!item2.data.group && type === 'sessions') || (item2.data.group && type === 'group')) {
        if(this.state.dateRange === 'all') {
          count++
        }
      }
    }
    return count
  }


  getActiveClients() {
    var minDate = Moment().add(-31, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-61, 'days')
    }
    var clients = []
    for(var item of this.state.sessions) {
      var add = false
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'week') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment()) {
          add = true
        }
      }
      if(global.loc !== '' && item.data.location !== global.loc) {
        add = false
      }
      if(add && clients.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients.indexOf(client) === -1) {
                clients.push(client)
              } 
            }
          }
        }
        
      }
    }
    return clients.length
  }


  getActiveDifference(type) {
    var arr = ['', '+0']
    var today = Moment().format('X')
    var minDate = Moment().add(-31, 'days').format('X')
    var minDate2 = Moment().add(-30, 'days').format('X')
    var prevDate = Moment().add(-61, 'days').format('X')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days').format('X')
      minDate2 = Moment().add(-7, 'days').format('X')
      prevDate = Moment().add(-15, 'days').format('X')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-61, 'days').format('X')
      minDate2 = Moment().add(-60, 'days').format('X')
      prevDate = Moment().add(-121, 'days').format('X')
    }
    if(this.state.dateRange === 'week') {
      var minDate1 = Moment().startOf('week')
      minDate =  minDate1.add(-1, 'days').format('X')
      prevDate =Moment().startOf('week').add(-7, 'days').format('X')
    }
    var clients1 = []
    var clients2 = []

    for(var item of this.state.sessions) {
      var add1 = false
      var add2 = false
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days') {
        if(idate > minDate && idate < today) {
          add1 = true
        }
        if(idate > prevDate && idate < minDate2) {
          add2 = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add1 = true
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
          add2 = true
        }
      } else if(this.state.dateRange === 'week') {
        idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(idate > minDate && idate < today) {
          add1 = true
        }
        if(idate > prevDate && idate < minDate2) {
          add2 = true
        }
      }

      if(global.loc !== '' && item.data.location !== global.loc) {
        add1 = false
        add2 = false
      }

      if(add1 && clients1.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients1.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients1.indexOf(client) === -1) {
                clients1.push(client)
              } 
            }
          }
        }
      }
      if(add2 && clients2.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients2.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client2 of item.data.clients) {
              if(clients2.indexOf(client2) === -1) {
                clients2.push(client2)
              } 
            }
          }
        }
      }
    }

    arr[1] = String(clients1.length-clients2.length)
    if(clients2.length < clients1.length) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(clients2.length > clients1.length) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(this.state.dateRange !== '60days') {
        return (
          <div className={'badge '+arr[0]} title={lang.t('overview:label.changecompared')}>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title={lang.t('overview:label.changecompared')}>N/A</div>
        )
      }
    } else {
      return clients2.length
    }
  }


  setDateRange(event) {
    global.dateRange = event
    this.setState({
      dateRange: event
    }, () => {
        this.setPayments();
    });
  }


  selectClient(id) {
    global.currentClient = id;
  }



  // Calendar & Notifications ------------------------------------------------------------



  getSessionLine1(item) {
    var label = ''
    if(item.data.group) {
      label = lang.t('home:act.groupsession')
      if(item.data.client !== '') {
        label = item.data.client
      }
      if(item.data.availability !== undefined) {
        label = lang.t('home:act.available11session')
      }
    } else {
      label = this.getClientName(item.data.client)
    }
    return label
  }


  getSessionLine2(item) {
    var label = ''
    if(item.data.group) {
      if(item.data.clients === undefined) {
        label = lang.t('home:label.nobookingsyet')+' - '+Cal.getDuration(item.data.duration)
      } else {
        label = item.data.clients.length+' '+lang.t('home:label.bookedin')+' - '+Cal.getDuration(item.data.duration)
      }
      if(item.data.max !== 0 && item.data.max !== undefined && item.data.availability === undefined) {
        label += ' ('+item.data.max+' max)'
      }
    } else {
      label = lang.t('home:actclient.11session')+' - '+Cal.getDuration(item.data.duration)
    }
    return label
  }


  getClientName(id) {
    var name = ''
    for(var item of this.state.clients) {
      if(item.id === id) {
        name = item.data.name
      }
    }
    for(var inact of global.clientsInactive) {
      if(inact.id === id) {
        name = item.data.name
      }
    }
    return name
  }


  selectSession(id) {
    global.currentSession = id;
  }


  getLock(item) {
    var label = ''
    if(item.data.invitees !== undefined && item.data.invitees.length > 0) {
      label = 'lock'
    }
    return label
  }



  // Modal info ------------------------------------------------------------



  showModalInfo(title, text) {
    this.setState({
      showModalInfo: true,
      olTitle: title,
      olText: text,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenInfo: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalInfo() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenInfo: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalInfo: false});
    }, 500);
  }


  renderModalInfo() {
    if(this.state.showModalInfo) {
      return (
        <div className={'overlay '+this.state.hiddenInfo}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.state.olTitle}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalInfo()}>Close</button>
            <p className="clear mb-30">{this.state.olText}</p>
            <button className="btn primary" onClick={() => this.hideModalInfo()}>Got it</button>
          </div>
        </div>
      )
    }
  }



  // Modal response ------------------------------------------------------------



  showModalResponse(id) {
    var tmp = []
    for(var item of this.state.clients) {
      if(item.data.uid === '') {
        tmp.push(item)
      }
    }
    Connector.loadClientConnectUser(id)
    this.setState({
      showModalResponse: true,
      responseType: 'new',
      clientsResponse: tmp,
      responseSelected: ''
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenResponse: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalResponse() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenResponse: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalResponse: false});
    }, 500);
  }


  renderModalResponseContent() {
    if(this.state.userConnectClient !== null) {
      if(this.state.responseType !== 'existing') {
        return (
          <div className="clear">
            <p className="mb-30">{this.state.userConnectClient.name} wants to become your client.</p>
            <button className="btn primary" onClick={() => this.setState({responseType: 'existing'})}>Link to existing client</button>
            <div className="sv-20"></div>
            <button className="btn secondary" onClick={() => this.linkClientNew()}>Create new client</button>
          </div>
        )
      } else {
        var list = this.state.clientsResponse
        list.sort((a, b) => a.data.name.localeCompare(b.data.name))
        return (
          <div className="clear">
            <p>{this.state.userConnectClient.name} wants to become your client.</p>
            <div className="form-item">
              <label>Select client (only not connected clients are shown)</label>
              <select value={this.state.responseSelected} onChange={(event) => this.updateResponseSelected(event)}>
                <option value=''>Select a client</option>
                {list.map(item => (
                  <option value={item.id} key={item.id}>{item.data.name}</option>
                ))}
              </select>
            </div>
            <div className="sv-30"></div>
            <button className="btn primary" onClick={() => this.linkClientExisting()}>Link to selected</button>
          </div>
        )
      }
    }
  }


  renderModalResponse() {
    if(this.state.showModalResponse) {
      return (
        <div className={'overlay '+this.state.hiddenResponse}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Client request</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalResponse()}>Close</button>
            {this.renderModalResponseContent()}
          </div>
        </div>
      )
    }
  }


  updateResponseSelected(event) {
    this.setState({
      responseSelected: event.target.value
    })
  }


  linkClientNew() {
    this.hideModalResponse()
    var obj = Firebase.database().ref('/clients/'+global.uidUser).push()
    obj.set({
      name: this.state.userConnectClient.name,
      phone: this.state.userConnectClient.phone,
      email: this.state.userConnectClient.email,
      birth: this.state.userConnectClient.birth,
      height: this.state.userConnectClient.height,
      goal: this.state.userConnectClient.image,
      uid: this.state.userConnectClient.uid,
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Client successfully created')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    Firebase.database().ref('/usersClients/'+this.state.userConnectClient.uid+'/trainers/'+global.uidUser).update({
      trainer: global.uidUser,
      client: obj.key
    })
    Firebase.database().ref('/log/'+global.uidUser+'/'+this.state.userConnectClient.uid).remove()
  }


  linkClientExisting() {
    if(this.state.responseSelected !== '') {
      this.hideModalResponse()
      Firebase.database().ref('/clients/'+global.uidUser+'/'+this.state.responseSelected).update({
        name: this.state.userConnectClient.name,
        phone: this.state.userConnectClient.phone,
        email: this.state.userConnectClient.email,
        birth: this.state.userConnectClient.birth,
        height: this.state.userConnectClient.height,
        uid: this.state.userConnectClient.uid,
        image: this.state.userConnectClient.image,
        imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      }).then(() => {
        EventEmitter.dispatch('showMessage', 'Client successfully created')
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
      Firebase.database().ref('/usersClients/'+this.state.userConnectClient.uid+'/trainers/'+global.uidUser).update({
        trainer: global.uidUser,
        client: this.state.responseSelected
      })
      Firebase.database().ref('/log/'+global.uidUser+'/'+this.state.userConnectClient.uid).remove()
    } 
  }



  // Check links & activity ------------------------------------------------------------



  tapRequest(item) {
    
    if(item.icon === 'connect') {
      this.showModalResponse(item.link)
    } else {
      this.props.history.push(item.link)
    }
  }


  tapUrgent(link) {
    if(link.indexOf('olclient,') !== -1) {
      var id = link.split(',')
      this.setState({
        connectClient: id[1],
        selectedClient: '',
      })
      this.showModalClients()
    } else {
      this.props.history.push(link)
    }
  }


  getDateActivity(date) {
    var label = Moment(date).locale(lang.language).format('llll')
    var d1 = Moment(date).format('DD/MM/YYYY')
    if(d1 === Moment().format('DD/MM/YYYY')) {
      label = lang.t('home:label.today')+' '+Moment(date).format('LT')
    }
    if(d1 === Moment().add(-1, 'days').format('DD/MM/YYYY')) {
      label = lang.t('home:label.yesterday')+' '+Moment(date).format('LT')
    }
    return label
  }


  toggleActivity(type) {
    var tmp = this.state.activityTypes
    if(tmp.indexOf(type) !== -1) {
      var ar = tmp.split(',')
      for(var i=0; i<ar.length; i++) {
        if(ar[i] === type) {
          ar.splice(i, 1)
        }
      }
      tmp = ""
      for(var a of ar) {
        if(a !== '') {
        tmp += ','+a}
      }
    } else {
      tmp += ','+type
    }
    global.userActivityTypes = tmp
    var tmp1 = []
    tmp1 = HelperActivity.getActivityAll(tmp)
    this.setState({
      activityTypes: tmp,
      activity: tmp1
    })
    Firebase.database().ref('/users/'+global.uidUser).update({
      activityTypes: tmp
    })
  }


  tapActivity(item) {
    if(item.activity !== '-' && item.activity !== 'payment' && item.activity !== 'cashrefund') {
      if(item.activity === 'note') {
        this.props.history.push('/clients/'+item.client+'/details')
      }
      if(item.activity === 'group' || item.activity === 'commentgroup') {
        this.props.history.push('/calendar/group-session/'+item.id)
      }
      if(item.activity === 'event') {
        this.props.history.push('/calendar/event/'+item.id)
      }
      if(item.activity === 'availability' || item.activity === 'commentavail') {
        this.props.history.push('/calendar/availability/'+item.id)
      }
      if(item.activity === 'assessment') {
        this.props.history.push('/clients/'+item.client+'/assessments/'+item.id)
      }
      if(item.activity === 'training' || item.activity === 'session' || item.activity === 'comment') {
        this.props.history.push('/calendar/session/'+item.id)
      }
      if(item.activity === 'plan') {
        this.props.history.push('/clients/'+item.client+'/plan/'+item.id)
      }
      if(item.activity === 'trial') {
        this.props.history.push('/admin/prospects')
      }
      if(item.activity === 'badge') {
        this.props.history.push('/account/achievements')
      }
      if(item.activity === 'newclient') {
        global.currentClient = item.client
        this.props.history.push('/clients/'+item.client+'/overview')
      }
      if(item.activity === 'clientbilling') {
        global.currentClient = item.client
        this.props.history.push('/clients/'+item.client+'/billing')
      }
      if(item.activity === 'staff') {
        this.props.history.push('/admin/staff')
      }
      if(item.activity === 'invoice') {
        this.props.history.push('/billing/invoice/'+item.id)
      }
    } else if(item.activity === 'payment') {
      var paym = null
      for(var pay of global.payments) {
        if(pay.id === item.id) {
          paym = pay
        }
      }
      if(paym !== null) {
        this.setState({
          showModalPayment: true,
          currentPayment: paym
        })
      }
    }
  }



  // Connect client modal ------------------------------------------------------------



  showModalClients() {
    this.setState({
      showModalClients: true,
      selectedExists: false,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenClients: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideModalClients() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenClients: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalClients: false});
    }, 500);
  }


  renderClientExists(name) {
    if(this.state.selectedExists) {
      return (
        <div>
          <div className="space-20"></div>
          <div className="box-warning">This client is already connected. They will be disconnected and connected to {name} instead.</div>
        </div>
      )
    }
  }


  renderClientButton() {
    if(this.state.selectedClient !== '') {
      return (
        <div>
          <button className="btn primary" onClick={() => this.tapReconnect()}>{this.state.selectedExists ? 'Connect and disconnect existing' : 'Reconnect to selected'}</button>
          <div className="space-20"></div>
        </div>
      )
    }
  }


  renderModalClients() {
    if(this.state.showModalClients) {
      var name = 'Client'
      var client = null
      var clients = []
      for(var item of global.clients) {
        if(item.id === this.state.connectClient) {
          name = item.data.name
          client = item
        } else {
          if(item.data.restricted === undefined) {
            clients.push(item)
          } else {
            if(!item.data.restricted) {
              clients.push(item)
            }
          }
        }
      }
      return (
        <div className={'overlay '+this.state.hiddenClients}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Connect client</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalClients()}>Close</button>
            <div className="ol-avatar clear">{HelperClients.getImage(client)}</div>
            <h4 className="badge-title" style={{textAlign: 'center'}}>{name} is a new client</h4>
            <p className="clear mb-20">If you want to link {name} to an existing client, please select them below.</p>
            <select onChange={event => this.selectConnectClient(event)}>
              <option value=''>- Select an existing client -</option>
              {clients.map(item => (
                <option key={item.id} value={item.id}>{item.data.name}</option>
              ))}
            </select>
            {this.renderClientExists(name)}
            <div className="sv-40"></div>
            {this.renderClientButton()}
            <button className="btn tertiary width-12" onClick={() => this.tapNothing()}>Do nothing and dismiss</button>
          </div>
        </div>
      )
    }
  }


  selectConnectClient(event) {
    var tmp = false
    for(var client of this.state.clients) {
      if(client.id === event.target.value && event.target.value !== '' && client.data.uid !== '') {
        tmp = true
      }
    }
    this.setState({
      selectedClient: event.target.value,
      selectedExists: tmp
    })
  }


  tapReconnect() {
    if(this.state.selectedClient !== '') {
      // Disconnect old client if necessary
      if(this.state.selectedExists) {
        for(var item2 of global.clients) {
          if(item2.id === this.state.selectedClient) {
            Firebase.database().ref('/usersClients/'+item2.data.uid+'/trainers/'+global.uid).remove()
          }
        }
      }

      for(var item of global.clients) {
        if(item.id === this.state.connectClient) {
          this.tapNothing()
          // Update data
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selectedClient).update({
            name: item.data.name,
            phone: item.data.phone,
            email: item.data.email,
            birth: item.data.birth,
            height: item.data.height,
            goal: item.data.goal,
            image: item.data.image,
            imageDate: Moment().format('DD/MM/YYYY HH:mm'),
            uid: item.data.uid
          })
          Firebase.database().ref('/usersClients/'+item.data.uid+'/trainers/'+global.uid).update({
            trainer: global.uid,
            client: this.state.selectedClient
          })
          // Delete client
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.connectClient).remove()
        }
      }
      EventEmitter.dispatch('showMessage', 'Client successfully reconnected');
      
    }
  }


  tapNothing() {
    this.hideModalClients()
    for(var item of this.state.notifications) {
      if(item.link === 'olclient,'+this.state.connectClient) {
        Firebase.database().ref('/activity/'+global.uid+'/'+item.id).remove()
        this.setNotifications()
      }
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalPayment: false,
      showModalOverview: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderCancelInfo() {
    if(global.userSubStatus === 'unpaid' && global.userSubPlan === "spark" && HelperBusiness.getRoleIncl('')) {
      return (
        <div className="infobox large red mb-40 mt-20">
          <p>{lang.t('home:message.subscriptioncanceled')}</p>
        </div>
      )
    } else if(global.userSubStatus === 'past_due') {
      return (
        <div className="infobox large yellow mb-40 mt-20">
          <p>{lang.t('home:message.subscription')}</p>
        </div>
      )
    }
  }


  renderName() {
    if(global.userName !== '' && global.userName !== null) {
      const ar = global.userName.split(' ')
      return ar[0]
    }
  }


  renderSession(item) {
    if(item.data.group) {
      if(item.data.availability === undefined) {
        return (
          <div className="icon primary">
            <div className="inner group"></div>
          </div>
        )
      } else {
        return (
          <div className="icon secondary">
            <div className="inner session"></div>
          </div>
        )
      }
    } else {
      return (
        <div className="icon secondary">
          <div className="inner session"></div>
        </div>
      )
    }
  }


  renderAvatarBday(item) {
    for(var client of this.state.clients) {
      if(client.id === item.id) {
        return (
          HelperClients.getImage(client)
        )
      }
    }
  }


  renderOverview() {
    if(HelperBusiness.getRoleIncl('admin,finance')) {
      return (
        <WidgetOverview
          item1={{id: 'income', value: HelperBilling.getIncome(this.state.payments, '', this.state.dateRange), prev: HelperBilling.getIncomeDifference('', this.state.dateRange)}}
          item2={{id: 'activeclients', value: this.getActiveClients(), prev: this.getActiveDifference()}}
          item3={{id: 'archive', value: parseInt(Cal.getAmount(this.state.sessions, this.state.archive, 'sessions', this.state.dateRange))+parseInt(Cal.getAmount(this.state.sessions, this.state.archive, 'group', this.state.dateRange)), prev: Cal.getDifferenceTotal(this.state.sessions, this.state.dateRange, 'num')}}
          item4={{id: 'unpaid', value: this.state.unpaid.length, prev: ''}} clickButton={() => this.showModalUnpaid()}
        />
      )
    } else if(HelperBusiness.getRole('trainer,cs')) {
      return (
        <WidgetOverview
          item1={{id: 'archive', value: parseInt(Cal.getAmount(this.state.sessions, this.state.archive, 'sessions', this.state.dateRange))+parseInt(Cal.getAmount(this.state.sessions, this.state.archive, 'group', this.state.dateRange)), prev: Cal.getDifferenceTotal(this.state.sessions, this.state.dateRange, 'num')}}
          item2={{id: 'activeclients', value: this.getActiveClients(), prev: this.getActiveDifference()}}
          item3={{id: '', value: ''}}
          item4={{id: '', value: ''}}
        />
      )
    }
  }


  renderUrgent() {
    if(this.state.notifications.length > 0 && HelperBusiness.getRoleIncl('admin,finance')) {
      return (
        <div className="clear withsidebar mb-20">
          <div className="listheader">
            <h3>{lang.t('header:title.attention')}</h3>
            <div className="clear"></div>
          </div>
          {this.state.notifications.map(item => (
            <div className="box list highlight mb-10" key={item.id} onClick={() => this.tapUrgent(item.link)}>
              <div className={'icon '+item.color}>
                <div className={'inner '+item.icon}></div>
              </div>
              <div className="mainbox lft">
                <p><strong>{item.title}</strong><br/>{item.desc}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderBillingDetails() {
    if(this.state.unpaid.length > 0) {
      return (
        <button className="btn tertiary width-12" onClick={() => this.showModalUnpaid()}>{lang.t('common:button.viewdetails')}</button>
      )
    }
  }


  renderLinksBilling() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(global.userStripeConnect !== undefined && global.userStripeConnect !== '') {
        return (
          <div>
            <Link to={ROUTES.PAYMENT} className="action">{lang.t('actions:processpayment')}</Link>
            <Link to={ROUTES.DEBITNEW} className="action">{lang.t('actions:setupmembership')}</Link>
          </div>
        )
      } else {
        return (
          <Link to={ROUTES.NEWPRODUCT} className="action">{lang.t('actions:createproduct')}</Link>
        )
      }
    } else if(HelperBusiness.getRole('trainer')) {
      if(global.userStripeConnect !== undefined && global.userStripeConnect !== '') {
        return (
          <Link to={ROUTES.PAYMENT} className="action">{lang.t('actions:processpayment')}</Link>
        )
      }
    }
    
  }


  renderActions() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className="mb-40">
          <Link to={ROUTES.NEWCLIENT} className="action">{lang.t('actions:addclient')}</Link>
          <Link to={ROUTES.NEWSESSION} className="action">{lang.t('actions:new11session')}</Link>
          <Link to={ROUTES.NEWGROUP} className="action">{lang.t('actions:newgroupsession')}</Link>
          {this.renderLinksBilling()}
        </div>
      )
    } else if(HelperBusiness.getRole('admin')) {
      return (
        <div className="mb-40">
          <Link to={ROUTES.NEWCLIENT} className="action">{lang.t('actions:addclient')}</Link>
          {this.renderLinksBilling()}
        </div>
      )
    } else if(HelperBusiness.getRole('finance,cs')) {
      return (
        <div className="mb-40">
          <Link to={ROUTES.REPORT} className="action">{lang.t('actions:createreport')}</Link>
        </div>
      )
    }
  }


  renderToday() {
    if(this.state.showSessions === 'today') {
      if(this.state.today.length === 0) {
        return (
          <div className="empty small clear">
            {HelperEmpty.renderImage('calendar')}
            <h4>{lang.t('home:label.nothingcomingup')}</h4>
          </div>
        )
      } else {
        var list = this.state.today
        list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
        return (
          <div className="clear">
            {list.map(item => (
              <div className="list bare" key={item.id}>
                <Link to={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)} onClick={() => this.selectSession(item.id)}>
                  {this.renderSession(item)}
                  <div className="main">
                    <h4>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')}</h4>
                    <p>{this.getSessionLine1(item)}<br/>{this.getSessionLine2(item)}</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
            <div className="sv-10"></div>
            <Link to={ROUTES.CALENDAR} className="btn tertiary width-12 small">{lang.t('common:button.viewall')}</Link>
          </div>
        );
      }
    }
  }


  renderTomorrow() {
    if(this.state.showSessions === 'tomorrow') {
      if(this.state.tomorrow.length === 0) {
        return (
          <div className="empty small clear">
            {HelperEmpty.renderImage('calendar')}
            <h4>{lang.t('home:label.nothingcomingup')}</h4>
          </div>
        )
      } else {
        var list = this.state.tomorrow
        list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
        return (
          <div className="clear">
            {list.map(item => (
              <div className="list bare" key={item.id}>
                <Link to={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)} onClick={() => this.selectSession(item.id)}>
                  {this.renderSession(item)}
                  <div className="main">
                    <h4>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')}</h4>
                    <p>{this.getSessionLine1(item)}<br/>{this.getSessionLine2(item)}</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
            <div className="sv-10"></div>
            <Link to={ROUTES.CALENDAR} className="btn tertiary width-12 small">{lang.t('common:button.viewall')}</Link>
          </div>
        );
      }
    }
  }


  renderRequests() {
    if(this.state.requests.length === 0) {
      return (
        <div className="empty small clear">
          {HelperEmpty.renderImage('inbox')}
          <h4>{lang.t('home:label.uptodate')}</h4>
        </div>
      )
    } else {
      var list = this.state.requests
      list.sort((a, b) => a.title.localeCompare(b.title))
      return (
        <div className="clear mt-10">
          {list.map(item => (
            <div className="list bare" key={item.id} onClick={() => this.tapRequest(item)}>
              <div className="icon quarternary">
                <div className="inner inbox"></div>
              </div>
              <div className="main">
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      );
    }
  }


  renderBirthdays() {
    if(this.state.birthdays.length === 0) {
      return (
        <div className="empty small clear mb-50">
          {HelperEmpty.renderImage('party')}
          <h4>{lang.t('home:label.uptodate')}</h4>
        </div>
      )
    } else {
      var list = this.state.birthdays
      list.sort((a, b) => a.title.localeCompare(b.title))
      return (
        <div className="clear mb-50">
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <Link to={item.link} onClick={() => this.selectClient(item.id)}>
                {this.renderAvatarBday(item)}
                <div className="main">
                  <h4>{item.title}</h4>
                  <p>{item.desc}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      );
    }
  }


  renderSidebarRequests() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div>
          <div className="mb-50">
            <h3>{lang.t('header:title.requests')}</h3>
            {this.renderRequests()}
          </div>
        </div>
      )
    }
  }


  renderSidebarGraph() {
    if(HelperBusiness.getRoleIncl('admin,finance') && global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
      return (
        <div className="mb-50">
          <h3>{lang.t('header:title.billingfinance')}</h3>
          <div className="clear mb-20"></div>
          <WidgetChartBar data={this.state.dataBilling} key1={lang.t('home:label.payments')} key2={lang.t('home:label.expenses')} max={this.state.dataBillingMax}/>
        </div>
      )
    }
  }


  renderSidebar() {
    return (
      <div className="col-3">
        {this.renderActions()}
        
        <div className="mb-50">
          <h3>{lang.t('header:title.upcoming')}</h3>
          <div className="clear sv-10"></div>
          <div className="radios clear">
            <button className={this.state.showSessions === 'today' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({showSessions: 'today'})}>{lang.t('home:label.today')}</button>
            <button className={this.state.showSessions === 'tomorrow' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({showSessions: 'tomorrow'})}>{lang.t('home:label.tomorrow')}</button>
          </div>
          <div className="sv-20"></div>
          {this.renderToday()}
          {this.renderTomorrow()}
        </div>

        {this.renderSidebarRequests()}

        <div className="mb-50">
          <h3>{lang.t('header:title.birthdays')}</h3>
          <div className="clear mb-10"></div>
          {this.renderBirthdays()}
        </div>

        {this.renderSidebarGraph()}
      </div>
    )
  }


  renderActivityClient(client, type, id, title) {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === client) {
        label = item.data.name
      }
    }
    for(var item1 of global.clientsInactive) {
      if(item1.id === client) {
        label = item1.data.name
      }
    }
    for(var item2 of global.userStaff) {
      if(item2.data.name === client) {
        label = item2.data.name
      }
    }
    if(type === 'trial' || type === 'badge' || type === '-') {
      label = client
    }
    if(type === 'group' && (title.indexOf('booked') !== -1 || title.indexOf('reserva') !== -1)) {
      for(var sess of global.sessions) {
        if(sess.id === id && sess.data.clients !== undefined) {
          if(sess.data.clients.length > 2) {
            label += ' '+lang.t('home:act.and')+' '+(sess.data.clients.length-1)+' '+lang.t('home:act.others')
          } else if(sess.data.clients.length === 2) {
            label += ' '+lang.t('home:act.and')+' 1 '+lang.t('home:act.other')
          }
          
        }
      }
    }
    return label
  }


  renderActivityImage(client, type) {
    var cl = null
    for(var item of global.clients) {
      if(item.id === client) {
        cl = item
      }
    }
    for(var item1 of global.clientsInactive) {
      if(item1.id === client) {
        cl = item1
      }
    }
    if(cl === null) {
      let arr = []
      if(client !== undefined) {
        arr = client.split(' ')
      }
      var inits = '?'
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        if(client !== undefined) {
          inits = arr[0].charAt(0)
        }
      }
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    } else {
      return HelperClients.getImage(cl)
    }
  }


  renderActivityClass(item) {
    var label = 'box list highlight mb-10'
    if(global.loc !== '') {
      var list = []
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
      if(item.activity === 'group' || item.activity === 'commentgroup' || item.activity === 'event' || item.activity === 'availability' || item.activity === 'commentavail' || item.activity === 'session' || item.activity === 'comment') {
        for(var session of global.sessions) {
          if(session.id === item.id && session.data.location !== global.loc) {
            label = 'none'
          }
        }
      }
      if(item.activity === 'note' || item.activity === 'newclient' || item.activity === 'assessment' || item.activity === 'plan' || item.activity === 'clientbilling' || item.activity === 'payment' || item.activity === 'invoice' || item.activity === 'cashrefund') {
        if(list.indexOf(item.client) === -1) {
          label = 'none'
        }
      }
    }
    return label
  }


  renderActivity() {
    if(this.state.activity.length === 0) {
      if(!this.state.noclients) {
        return (
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1 static"></div>
              <div className="img2 static"></div>
              <div className="img3 static"></div>
              <div className="img4 static"></div>
              {HelperEmptyLarge.renderImage('activity', true)}
            </div>
            <h3 className="mb-20">{lang.t('empty:activity.title')}</h3>
            <p className="mb-20">{lang.t('empty:activity.text')}</p>
          </div>
        )
      } else {
        return (
          <div>
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1 static"></div>
                <div className="img2 static"></div>
                <div className="img3 static"></div>
                <div className="img4 static"></div>
                {HelperEmptyLarge.renderImage('activity', true)}
              </div>
              <h3 className="mb-20">{lang.t('home:message.demo.title')}</h3>
              <p className="mb-20">{lang.t('home:message.demo.text')}</p>
            </div>
            <div className="col-4">&nbsp;</div>
            <div className="col-4 mt-20">
              <button className="btn primary small" onClick={() => this.createDemoContent()}>{lang.t('home:button.createdemocontent')}</button>
            </div>
          </div>
        )
      }
    } else {
      var list = this.state.activity
      list.sort((a,b) => Moment(b.date) - Moment(a.date));
      return (
        <div className="withsidebar">
          {list.map((item,index) => (
            <div className={this.renderActivityClass(item)} key={item.id+item.color+item.date+index} onClick={() => this.tapActivity(item)}>
              <div className={'icon '+item.color}>
                <div className={'inner '+item.icon}></div>
              </div>
              <div className="mainbox lft">
                <p><strong>{this.renderActivityClient(item.client, item.activity, item.id, item.title)} {item.title}</strong><br/>{item.desc}<br/>{this.getDateActivity(item.date)}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderActivityBilling() {
    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      return (
        <div onClick={() => this.toggleActivity('billing')} className={this.state.activityTypes.indexOf('billing') === -1 ? 'checkbox lft ml-30' : 'checkbox active lft ml-30'}>
          <div className="check mr-10">
            <div className="inner"></div>
          </div>
          <p>{lang.t('header:option.billing')}</p>
        </div>
      )
    }
  }


  renderActivityHeader() {
    return (
      <div className="listheader clear" style={{paddingRight: 30}}>
        <h3 className="lft">{lang.t('header:title.activity')}</h3>
        <div className="rgt">
          <div onClick={() => this.toggleActivity('sessions')} className={this.state.activityTypes.indexOf('sessions') === -1 ? 'checkbox lft' : 'checkbox active lft'}>
            <div className="check mr-10">
              <div className="inner"></div>
            </div>
            <p>{lang.t('header:option.sessions')}</p>
          </div>
          {this.renderActivityBilling()}
          <div onClick={() => this.toggleActivity('client')} className={this.state.activityTypes.indexOf('client') === -1 ? 'checkbox lft ml-30' : 'checkbox active lft ml-30'}>
          <div className="check mr-10">
              <div className="inner"></div>
            </div>
            <p>{lang.t('header:option.clients')}</p>
          </div>
          <div onClick={() => this.toggleActivity('business')} className={this.state.activityTypes.indexOf('business') === -1 ? 'checkbox lft ml-30' : 'checkbox active lft ml-30'}>
          <div className="check mr-10">
              <div className="inner"></div>
            </div>
            <p>{lang.t('header:option.admin')}</p>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderNews() {
    if(HelperBusiness.getRoleIncl('')) {
      return <div className="btn-news" onClick={() => this.setState({showModalOverview: true})}>{lang.t('home:label.latestnews')}</div>
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          {this.renderCancelInfo()}
          <div className="col-9">  
            {this.renderOverview()}
            {this.renderUrgent()}
            {this.renderActivityHeader()}
            {this.renderActivity()}
          </div>
          {this.renderSidebar()}
        </div>
        {this.renderNews()}
        <Header type='home' active='activity' selector={true} updateSelect={() => this.setState({dateRange: global.dateRange} , () => { this.setPayments();})}/>
        <Navigation active='overview'/>
        {this.renderModalMessage()}
        {this.renderModalInfo()}
        {this.renderModalResponse()}
        {this.renderModalUnpaid()}
        {this.renderModalClients()}
        <ModalPayment payment={this.state.currentPayment} show={this.state.showModalPayment} onHide={() => this.hideModals()}/>
        <Overview show={this.state.showModalOverview} onHide={() => this.hideModals()}/>
      </div>
      
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }

}



const condition = authUser => !!authUser;
export default withTranslation(['home','common','header','empty','actions','overview','calendar','health'])(withRouter(withAuthorization(condition)(OverviewPage)));