import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InputDatetime from '../../components/Form/datetime';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import Textarea from '../../components/Form/textarea';
import Select from '../../components/Form/select';
import InputDuration from '../../components/Form/duration';
import InputInvited from '../../components/Form/invited';
import DataInfo from '../../components/Form/info';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalClients from '../../components/Modals/clients';
import ModalItems from '../../components/Modals/items';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewRecEventPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      events: global.events,
      dur: 30,
      name: '',
      desc: '',
      date: Moment().startOf('week').format('DD/MM/YYYY HH:mm'),
      interval: 7,
      weekday: 0,
      start: 0,
      hour: '00',
      mins: '00',
      cdur: 75,
      link: '',
      private: true,
      location: global.loc,
      error: [false, false, false, false],
      type: 'internal',
      book: '',
      invited: [],
      group: [],
      locations: [],
      showMax: false,
      max: 0,
      showEnd: false,
      dateEnd: Moment().add(1, 'months').format('DD/MM/YYYY'),
      editing: false,
      showModalClients: false,
      showModalGroups: false,
      showModalDelete: false,
      showModalUpdate: false,
      startday: 0,
    };
  }


  componentDidMount() {
    HelperPage.initialize('New Recurring Event - PT Mate', ROUTES.RECURRING)
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('eventsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('recurringLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false,
    })
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp23 = []
      for(var item of global.clients) {
        tmp.push(item)
      }
      if(global.locations.length > 0) {
        tmp23 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}]
        for(var l of global.locations) {
          tmp23.push({value: l.id, name: l.data.name})
        }
      }
      this.setState({
        events: global.events,
        locations: tmp23,
      });
      if(this.props.location.pathname.indexOf('recurring-templates/events/') !== -1) {
        this.configureTemplate()
      }
    }
  }


  configureTemplate() {
    if(!this.state.loading) {
      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      var item = null
      var dur = 30
      var day = ''
      var hrs = ''
      var min = ''
      var preview = true
      var weekday = 0
      var interval = 7
      var max = 0
      var show = false
      var name = ''
      var priv = ''
      var privte = false
      var invited = []
      var group = []
      var link = ''
      var desc = ''
      var end = Moment().add(1, 'months').format('DD/MM/YYYY')
      var showEnd = false
      var type = 'internal'
      var location = ''

      for(var session of global.recurring) {
        if(session.id === id) {
          item = session
          dur = session.data.duration
          interval = session.data.interval
          day = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
          hrs = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
          min = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
          weekday = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('d'))
          name = session.data.title
          if(session.data.max !== undefined && session.data.max !== 0) {
            max = session.data.max
            show = true
          }
          if(session.data.invitees !== undefined && session.data.invitees.length > 0) {
            priv = 'client'
            invited = session.data.invitees
          }
          if(session.data.groups !== undefined && session.data.groups.length > 0) {
            priv = 'group'
            group = session.data.groups
          }
          if(session.data.link !== undefined) {
            link = session.data.link
          }
          if(session.data.desc !== undefined) {
            desc = session.data.desc
          }
          if(session.data.private !== undefined) {
            privte = session.data.private
          }
          if(session.data.location !== undefined) {
            location = session.data.location
          }
          if(session.data.dateEnd !== undefined) {
            if(session.data.dateEnd < 32503640400) {
              end = Moment(session.data.dateEnd, 'X').format('DD/MM/YYYY')
              showEnd = true
            }
          }
          if(session.data.bookable) {
            type = 'bookable'
          }
        }
      }

      var cdur = 75
      if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
        cdur = dur
        dur = 99
      }

      var tmpc = []
      if(item !== null) {
        for(var itemc of global.clients) {
          tmpc.push(itemc)
        }
      }

      this.setState({
        clients: tmpc,
        clientsList: tmpc,
        events: global.events,
        groups: global.clientGroups,
        item: item,
        name: name,
        id: id,
        dur: dur,
        cdur: cdur,
        date: Moment(day, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm'),
        hour: hrs,
        mins: min,
        max: max,
        showMax: show,
        preview: preview,
        weekday: weekday-1,
        startday: weekday-1,
        interval: interval,
        private: privte,
        book: priv,
        invited: invited,
        location: location,
        link: link,
        desc: desc,
        showEnd: showEnd,
        dateEnd: end,
        group: group,
        type: type,
        editing: true,
      })
    }
  }


  removeItem(item) {
    if(this.state.book === 'client') {
      var tmp1 = this.state.invited
      if(tmp1.length > 0) {
        for(var i=0; i<tmp1.length; i++) {
          if(tmp1[i] === item) {
            tmp1.splice(i, 1)
          }
        }
      }
      this.setState({
        invited: tmp1
      })
    } else {
      var tmp2 = this.state.group
      if(tmp2.length > 0) {
        for(var j=0; j<tmp2.length; j++) {
          if(tmp2[j] === item) {
            tmp2.splice(j, 1)
          }
        }
      }
      this.setState({
        group: tmp2
      })
    }
  }


  setWeekday(event) {
    var date = Moment().startOf('week').add(event, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    this.setState({
      weekday: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    })
  }


  setStart(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(event, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    this.setState({
      start: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    })
  }


  setHour(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+event+':'+this.state.mins
    this.setState({
      hour: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    });
  }


  setMins(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+event
    this.setState({
      mins: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    });
  }


  checkCreateTemplate() {
    var passed = true
    var tmp = [false, false, false, false]
    if(this.state.name === '') {
      passed = false
      tmp[1] = true
    }
    if(this.state.type === 'bookable') {
      if(this.state.book === 'group' && this.state.group.length === 0) {
        tmp[2] = true
        passed = false
      }
      if(this.state.book === 'client' && this.state.invited.length === 0) {
        tmp[2] = true
        passed = false
      }
    }
    this.setState({error: tmp})
    if(passed) {
      this.prepareEvents()
    }
  }


  prepareEvents() {
    this.setState({loading: true})
    var count = 0
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }

    var max = 0
    if(parseInt(this.state.max) > 0 && this.state.showMax) {
      max = parseInt(this.state.max)
    }

    var end = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X')) //32503640400
    if(this.state.showEnd) {
      end = parseInt(Moment(this.state.dateEnd, 'DD/MM/YYYY').format('X'))
    }
    if(end < this.state.date) {
      end = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((this.state.interval), 'days').format('X'))
    }

    var endDate = parseInt(Moment().add(90, 'days').format('X'))
    if(endDate > end) {
      endDate = end
    }

    var groups = []
    var clients = []
    if(this.state.book === 'group') {
      groups = this.state.group
    }
    if(this.state.book === 'client') {
      clients = this.state.invited
    }

    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }

    for(var i=0; i<18; i++) { 
      var date = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((i*this.state.interval), 'days').format('X'))
      if(date < endDate && date > parseInt(Moment().format('X'))) {
        count++
      }
    }

    var obj = Firebase.database().ref('/recurring/'+global.uid).push()
    this.createTemplate(count, duration, obj.key, max, end, groups, clients, location, locationName)
    setTimeout(() => {
      for(var j=0; j<18; j++) { 
        var date = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.interval), 'days').format('X'))
        if(date < endDate && date > parseInt(Moment().format('X'))) {
          this.createEvent(j, duration, obj.key, max, clients, groups, location, locationName)
        }
      }
    }, 1000);

    this.setState({
      maxNum: count
    })
  }


  createTemplate(num, duration, id, max, end, groups, clients, location, locationName) {
    if(this.state.type === 'bookable') {
      Firebase.database().ref('/recurring/'+global.uid+'/'+id).update({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: this.state.date,
        timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
        groups: groups,
        invitees: clients,
        location: location,
        locationName: locationName,
        link: this.state.link,
        interval: this.state.interval,
        uid: global.uidUser,
        bookable: true,
        private: false,
        max: max,
        current: num,
        type: 'event',
        dateEnd: end,
      }).then(() => {
        //global.message = 'Event successfully created'
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/recurring/'+global.uid+'/'+id).update({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: this.state.date,
        timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
        emails: '',
        link: this.state.link,
        private: this.state.private,
        location: location,
        locationName: locationName,
        interval: this.state.interval,
        current: num,
        type: 'event',
        dateEnd: end,
        bookable: false,
        uid: global.uidUser,
      }).then(() => {
        //global.message = 'Event successfully created'
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  createEvent(num, duration, id, max, invitees, groups, location, locationName) {
    if(this.state.type === 'bookable') {
      Firebase.database().ref('/events/'+global.uid).push({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('DD/MM/YYYY HH:mm'),
        timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('X')),
        max: max,
        uid: global.uid,
        attendance: 2,
        template: id,
        templateSeq: num,
        invitees: invitees,
        groups: groups,
        location: location,
        locationName: locationName,
        link: this.state.link,
        bookable: true,
        private: false,
      }).then(() => {
        this.checkFinished(num)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    } else {
      Firebase.database().ref('/events/'+global.uid).push({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('DD/MM/YYYY HH:mm'),
        timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('X')),
        emails: '',
        link: this.state.link,
        private: this.state.private,
        location: location,
        locationName: locationName,
        template: id,
        templateSeq: num,
        bookable: false,
        uid: global.uidUser,
      }).then(() => {
        this.checkFinished(num)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    }
  }


  checkFinished(num) {
    if(num === this.state.maxNum-1) {
      global.message = 'Template successfully created'
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'recurring');
      }, 1000);
      this.props.history.push(ROUTES.RECURRING)
    }
  }



  // Update template ------------------------------------------------------------



  checkUpdateTemplate() {
    var passed = true
    var tmp = [false, false, false]
    this.setState({error: tmp})
    if(this.state.invited.length === 0 && this.state.private === 'private') {
      passed = false
      tmp[1] = true
    }
    if(this.state.group.length === 0 && this.state.private === 'group') {
      passed = false
      tmp[1] = true
    }
    this.setState({error: tmp})
    if(passed) {
      this.setState({showModalUpdate: true})
    }
  }



  updateTemplate() {
    this.hideModals()
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var max = 0
    if(this.state.showMax) {
      max = parseInt(this.state.max)
    }
    var invitees = []
    var groups = []
    if(this.state.invited.length > 0 && this.state.book === 'client') {
      invitees = this.state.invited
    }
    if(this.state.group.length > 0 && this.state.book === 'group') {
      groups = this.state.group
    }

    var end = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X')) //32503640400
    if(this.state.showEnd) {
      end = parseInt(Moment(this.state.dateEnd, 'DD/MM/YYYY').format('X'))
    }
    if(end < this.state.date) {
      end = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((this.state.interval), 'days').format('X'))
    }

    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }

    if(this.state.type === 'bookable') {
      Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).update({
        duration: duration,
        date: this.state.date,
        dateEnd: end,
        timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
        max: max,
        title: this.state.name,
        invitees: invitees,
        groups: groups,
        location: location,
        locationName: locationName,
        link: this.state.link,
        desc: this.state.desc,
      }).then(() => {
        this.updateEvents(max, duration, invitees, groups, end)
        EventEmitter.dispatch('showMessage', 'Template successfully updated')
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    } else {
      Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).update({
        duration: duration,
        date: this.state.date,
        dateEnd: end,
        timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
        max: max,
        title: this.state.name,
        invitees: invitees,
        groups: groups,
        link: this.state.link,
        desc: this.state.desc,
        private: this.state.private,
        location: location,
        locationName: locationName,
      }).then(() => {
        this.updateEvents(max, duration, invitees, groups, end, location, locationName)
        EventEmitter.dispatch('showMessage', 'Template successfully updated')
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    }
  }


  updateEvents(max, duration, invitees, groups, end, location, locationName) {
    var sess = []
    var del = []
    var date = parseInt(Moment().format('X'))
    var num = 0
    for(var session of this.state.events) {
      var sdate = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X'))
      if(session.data.template === this.state.item.id && sdate > date) {
        var nd = session.data.templateSeq*this.state.item.data.interval
        if(sdate > end) {
          del.push(session.id)
        } else {
          if(session.data.templateSeq > 0) {
            num = session.data.templateSeq
          }
          sess.push({
            id: session.id,
            date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add(nd, 'days').format( 'DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins,
          })
        }
      }
    }
    for(var item of sess) {
      var ts = parseInt(Moment(item.date, 'DD/MM/YYYY HH:mm').format('X'))
      if(item.timestamp !== undefined) {
        ts = parseInt(Moment(item.timestamp, 'X').format('X'))
      }
      if(this.state.type === 'bookable') {
        Firebase.database().ref('/events/'+global.uid+'/'+item.id).update({
          duration: duration,
          date: item.date,
          timestamp: ts,
          title: this.state.name,
          max: max,
          invitees: invitees,
          groups: groups,
          link: this.state.link,
          desc: this.state.desc,
          private: false,
          location: location,
          locationName: locationName,
        })
      } else {
        Firebase.database().ref('/events/'+global.uid+'/'+item.id).update({
          duration: duration,
          date: item.date,
          timestamp: ts,
          title: this.state.name,
          link: this.state.link,
          desc: this.state.desc,
          private: this.state.private,
          location: location,
          locationName: locationName,
        })
      }
    }
    for(var item2 of del) {
      Firebase.database().ref('/events/'+global.uid+'/'+item2).remove()
    }

    //Create additional sessions
    num++
    var endDate = parseInt(Moment().add(90, 'days').format('X'))
    if(endDate > end) {
      endDate = end
    }
    for(var j=num; j<(num+18); j++) {
      var nsdate = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.item.data.interval), 'days').format('X'))
      if(nsdate < endDate && nsdate > parseInt(Moment().format('X'))) {
        this.createAdditionalEvents(nsdate, num, duration, max, invitees, groups, location, locationName)
      }
    }
  }


  createAdditionalEvents(date, num, duration, max, invitees, groups, location, locationName) {
    if(this.state.type === 'bookable') {
      Firebase.database().ref('/events/'+global.uid).push({
        title: this.state.name,
        duration: duration,
        date: Moment(date, 'X').format('DD/MM/YYYY HH:mm'),
        timestamp: parseInt(date),
        max: max,
        attendance: 2,
        template: this.state.item.id,
        templateSeq: num,
        invitees: invitees,
        groups: groups,
        location: location,
        locationName: locationName,
        link: this.state.link,
        bookable: true
      })
    } else {
      Firebase.database().ref('/events/'+global.uid).push({
        title: this.state.name,
        duration: duration,
        date: Moment(date, 'X').format('DD/MM/YYYY HH:mm'),
        timestamp: parseInt(date),
        uid: global.uid,
        template: this.state.item.id,
        templateSeq: num,
        link: this.state.link,
        private: this.state.private,
        location: location,
        locationName: locationName,
      })
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false,
      showModalGroups: false,
      showModalDelete: false,
      showModalUpdate: false,
    })
  }


  selectClients(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      invited: tmp,
    })
  }


  selectGroups(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      group: tmp,
    })
  }


  deleteTemplate() {
    this.hideModals()
    this.setState({
      loading: true,
    }, () => {
      var ids = []
      var date = parseInt(Moment().format('X'))
      
      setTimeout(() => {
        for(var event of this.state.events) {
          var sdate = parseInt(Moment(event.data.date, 'DD/MM/YYYY HH:mm').format('X'))
          if(event.data.template === this.state.item.id && sdate > date) {
            ids.push(event.id)
          }
        }
        
        Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).remove().then(() => {
          for(var item of ids) {
            Firebase.database().ref('/events/'+global.uid+'/'+item).remove()
          }
          global.message = 'Template successfully deleted'
          this.props.history.push(ROUTES.RECURRING)
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
          this.setState({loading: false})
        })
      }, 1000);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderLocation() {
    if(global.locations.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      return <Select label={lang.t('calendar:form.location')} value={this.state.location} values={this.state.locations} onChange={(event) => this.setState({location: event})}/>
    }
  }


  renderMax() {
    if(this.state.showMax) {
      return <InputNumber label={lang.t('calendar:form.spots.field')} placeholder={lang.t('calendar:form.spots.placeholder')} value={this.state.max} onChange={(event) => this.setState({max: event})}/>
    }
  }


  renderEnd() {
    if(this.state.showEnd) {
      return <InputDate label="" value={Moment(this.state.dateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} max={Moment().add(2, 'months').format('YYYY-MM-DD')} onChange={(event) => this.setState({dateEnd: Moment(event).format('DD/MM/YYYY')})}/>
    }
  }


  renderPrivate() {
    if(global.userStaff.length > 0) {
      return <InputToggle label={lang.t('calendar:form.private.label')} value={this.state.private} onChange={() => this.setState({private: !this.state.private})} text={lang.t('calendar:form.private.text')}/>
    }
  }


  renderRepeats() {
    if(this.state.editing && this.state.item !== null) {
      return <DataInfo label={lang.t('calendar:form.repeats')} value={this.state.item.data.interval === 7 ? lang.t('calendar:label.week') : lang.t('calendar:label.fortnight')}/>
    } else {
      return (
        <InputRadio
          label={lang.t('calendar:form.repeats')}
          value={this.state.interval}
          clickElement={(event) => this.setState({interval: event})}
          values={[{name: lang.t('calendar:label.week'), value: 7}, {name: lang.t('calendar:label.fortnight'), value: 14}]}
        />
      )
    }
  }


  renderBookable() {
    if(this.state.type === 'bookable') {
      return (
        <div>
          <div className="sv-10"></div>
          <div className="col-12">
            <InputInvited type={this.state.book} clients={this.state.invited} groups={this.state.group} recurring={true} event={true} error={this.state.error[2]} clickType={(event) => this.setState({book: event})} clickClients={() => this.setState({showModalClients: true})} clickGroups={() => this.setState({showModalGroups: true})} clickRemove={(event) => this.removeItem(event)}/>
            <div className="sv-30 clear"></div>
            <InputText label={lang.t('calendar:form.eventname')+'*'} placeholder={lang.t('calendar:form.eventname.placeholder2')} value={this.state.name} error={this.state.error[1]} errormsg={lang.t('calendar:error.nameevents')} onChange={(event) => this.setState({name: event})}/>
            {this.renderRepeats()}
          </div>
          <InputDatetime type={this.state.editing ? 'recurringedit' : 'recurring'} start={this.state.start} weekday={this.state.weekday} hour={this.state.hour} min={this.state.mins} setDay={(event) => this.setWeekday(event)} setHour={(event) => this.setHour(event)} setMin={(event) => this.setMins(event)} setStart={(event) => this.setStart(event)}/>
          <div className="col-12">
            <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
            <InputToggle label={lang.t('calendar:form.spots.label')} value={this.state.showMax} onChange={() => this.setState({showMax: !this.state.showMax})} text={lang.t('calendar:form.spots.text2')}/>
            {this.renderMax()}
            {this.renderLocation()}
            <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
            <Textarea label={lang.t('calendar:form.eventnotes')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
            <InputToggle label={lang.t('calendar:form.enddate.label')} value={this.state.showEnd} onChange={() => this.setState({showEnd: !this.state.showEnd})} text={lang.t('calendar:form.enddate.text')}/>
            {this.renderEnd()}
          </div>
          <div className="sv-20 clear"></div>
        </div>
      )
    } 
  }


  renderInternal() {
    if(this.state.type === 'internal') {
      return (
        <div>
          <div className="sv-10"></div>
          <div className="col-12">
            <InputText label={lang.t('calendar:form.eventname')+'*'} placeholder={lang.t('calendar:form.eventname.placeholder')} value={this.state.name} error={this.state.error[1]} errormsg={lang.t('calendar:error.nameevents')} onChange={(event) => this.setState({name: event})}/>
            {this.renderRepeats()}
          </div>
          <InputDatetime type={this.state.editing ? 'recurringedit' : 'recurring'} start={this.state.start} weekday={this.state.weekday} hour={this.state.hour} min={this.state.mins} setDay={(event) => this.setWeekday(event)} setHour={(event) => this.setHour(event)} setMin={(event) => this.setMins(event)} setStart={(event) => this.setStart(event)}/>
          <div className="col-12">
            <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
            {this.renderLocation()}
            <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
            <Textarea label={lang.t('calendar:form.eventnotes')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
            {this.renderPrivate()}
            <InputToggle label={lang.t('calendar:form.enddate.label')} value={this.state.showEnd} onChange={() => this.setState({showEnd: !this.state.showEnd})} text={lang.t('calendar:form.enddate.text')}/>
            {this.renderEnd()}
          </div>
          <div className="sv-20 clear"></div>
        </div>
      )
    }
  }


  renderButtons() {
    if(this.state.editing) {
      return (
        <div>
          <button className="btn primary mb-20" onClick={() => this.checkUpdateTemplate()}>{lang.t('calendar:button.updatetemplate')}</button>
          <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('calendar:button.deletetemplate')}</button>
        </div>
      )
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateTemplate()}>{lang.t('calendar:button.createtemplate')}</button>
    }
  }


  renderTabs() {
    if(!this.state.editing) {
      return (
        <div className="radios tabs">
          <button className={this.state.type === 'internal' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({type: 'internal'})}>{lang.t('calendar:form.internal')}</button>
          <button className={this.state.type === 'bookable' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({type: 'bookable'})}>{lang.t('calendar:form.bookable')}</button>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className={this.state.editing ? 'content form-sidebar' : 'content form-sidebar withtabs'}>
            <div className="col-6 mt-20">
              <div className="box withtabs">
                {this.renderTabs()}
                <div className="inner clear">
                  {this.renderBookable()}
                  {this.renderInternal()}
                  {this.renderButtons()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.editing ? 'receventedit' : 'recevent'}/>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <Header title={this.state.editing ? lang.t('calendar:title.recurringtemplate') : lang.t('calendar:title.newrecurringtemplate')} link={ROUTES.RECURRING} type={this.state.editing ? 'simple' : 'recurring'} active={'event'}/>
          <Navigation active='calendar' />
          <ModalClients show={this.state.showModalClients} title={'Select clients'} button={'Confirm selection'} multiple={true} selected={this.state.invited} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
          <ModalItems show={this.state.showModalGroups} items={global.clientGroups} title={'Select groups'} button={'Confirm selection'} multiple={true} selected={this.state.group} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectGroups(event)}/>
          <ModalMessageSimple type='deleterecevent' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteTemplate()}/>
          <ModalMessageSimple type='updaterecevent' show={this.state.showModalUpdate} onHide={() => this.hideModals()} clickMainButton={() => this.updateTemplate()}/>
          <Loader show={this.state.loading} label={this.state.editing ? 'Deleting recurring template\nand events' : 'Creating recurring template\nand events'}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='recurring-locked' type='simple'/>
          </div>
          <Header title={lang.t('calendar:title.recurringtemplate')} link={ROUTES.RECURRING} type='simple'/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common'])(withRouter(withAuthorization(condition)(NewRecEventPage)));