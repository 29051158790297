import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import HelperEmptyImg from '../../helper/EmptyLarge';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperClients from '../../helper/Clients';
import ModalItems from '../../components/Modals/items';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import WidgetChartPie from '../../components/Widgets/chart-pie';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class CalendarInsightsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recurring: global.recurring,
      clients: global.clients,
      sessions: global.sessions,
      archive: global.archive,
      total: [],
      date1: Moment().add(-30, 'days').format('YYYY-MM-DD'),
      date2: Moment().format('YYYY-MM-DD'),
      inactive: [],
      trainers: global.userStaff,
      dataTop: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataClients: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataTypes: [
        {
          "id": "japan",
          "label": "java",
          "value": 47,
          "color": "#1DC5C9"
        },
      ],
      dataTime: [
        {
          "id": "japan",
          "label": "java",
          "value": 47,
          "color": "#1DC5C9"
        },
      ],
      selSessions: [],
      trainerName: 'Trainer',
      showModalSessions: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Calendar Insights - PT Mate', ROUTES.CALINSIGHTS)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp1 = []
      var tmp2 = []
      var tmp3 = []
      var tmp4 = []
      if(global.archive.length === 0 && global.uidUser !== '') {
        Connector.loadArchive((result) => {})
      }
      for(var item of global.clients) {
        if(global.loc === '') {
          tmp.push({
            id: item.id,
            name: item.data.name,
            sessions: 0,
            group: 0,
            total: 0,
            image: item.data.image,
          })
        } else {
          for(var loc1 of global.locations) {
            if(loc1.id === global.loc) {
              if(loc1.data.clients !== undefined) {
                if(loc1.data.clients.indexOf(item.id) !== -1) {
                  tmp.push(item)
                }
              }
            }
          }
        }
      }
      for(var item1 of global.recurring) {
        if(global.loc === '') {
          tmp1.push(item1)
        } else {
          if(item1.data.location === global.loc) {
            tmp1.push(item1)
          }
        }
      }
      for(var item2 of global.sessions) {
        if(global.loc === '') {
          tmp2.push(item2)
        } else {
          if(item2.data.location === global.loc) {
            tmp2.push(item2)
          }
        }
      }
      for(var item3 of global.archive) {
        if(global.loc === '') {
          tmp3.push(item3)
        } else {
          if(item3.data.location === global.loc) {
            tmp3.push(item3)
          }
        }
      }
      for(var item4 of global.userStaff) {
        if(global.loc === '') {
          tmp4.push(item4)
        } else {
          for(var loc of global.locations) {
            if(loc.id === global.loc) {
              if(loc.data.staff !== undefined) {
                if(loc.data.staff.indexOf(item4.id) !== -1) {
                  tmp4.push(item4)
                }
              }
            }
          }
        }
      }
      this.setState({
        recurring: tmp1,
        clients: tmp,
        sessions: tmp2,
        archive: tmp3,
        trainers: tmp4
      }, () => {
        this.getSessions()
        this.getTopGraph()
      });
    }
  }


  getTemplates(group) {
    var count = 0
    for(var item of this.state.recurring) {
      if(item.data.group === group) {
        count++
      }
    }
    return count
  }


  // Get sessions per user

  getSessions() {
    var tmp = this.state.clients
    for(var tp of tmp) {
      tp.sessions = 0
      tp.group = 0
      tp.total = 0
    }

    for(var item of this.state.sessions) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
      if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client of tmp) {
          if(client.id === item.data.client) {
            client.sessions += 1
            client.total += 1
          }
          if(item.data.group && item.data.clients !== undefined) {
            if(item.data.clients.indexOf(client.id) !== -1) {
              client.group += 1
              client.total += 1
            }
          }
        }
      }
    }
    for(var item2 of this.state.archive) {
      var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
      if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client2 of tmp) {
          if(client2.id === item2.data.client) {
            client2.sessions += 1
            client2.total += 1
          }
          if(item2.data.group && item2.data.clients !== undefined) {
            if(item2.data.clients.indexOf(client2.id) !== -1) {
              client2.group += 1
              client2.total += 1
            }
          }
        }
      }
    }

    this.setState({
      clients: tmp
    })
  }


  // Get graph data

  getTopGraph() {
    var tmp = []
    var tmpg = []
    var tmpp = []
    var tmpc = []
    var tmpc1 = []
    var tmpc2 = []
    var inactive = []

    var totalg = 0
    var totalp = 0
    var num30 = 0
    var num45 = 0
    var num60 = 0
    var num75 = 0
    var nummore = 0

    var cls = []

    var start = Moment(this.state.date1, 'YYYY-MM-DD').startOf('week')
    var end = Moment(this.state.date2, 'YYYY-MM-DD').add(1, 'week').startOf('week')
    var now = parseInt(Moment().format('X'))
    var weeks = end.diff(start, 'weeks')

    for(var i=0; i<parseInt(weeks); i++) {
      var num = 0
      var numg = 0
      var nump = 0
      
      var numc = []
      var numc1 = []
      var numc2 = []

      for(var item of this.state.sessions) {
        var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
        if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date >= Moment(start).add(7*i, 'days').format('X') && date < Moment(start).add(7*i+7, 'days').format('X') && date < now) {
          num++
          if(item.data.group) {
            numg++
            totalg++
            if(item.data.clients !== undefined) {
              for(var client of item.data.clients) {
                if(numc.indexOf(client) === -1) {
                  numc.push(client)
                  numc1.push(client)
                  cls.push(client)
                }
              }
            }
          } else {
            nump++
            totalp++
            if(numc.indexOf(item.data.client) === -1) {
              numc.push(item.data.client)
              numc2.push(item.data.client)
              cls.push(item.data.client)
            }
          }
          if(item.data.duration < 31) {
            num30++
          } else if(item.data.duration > 30 && item.data.duration < 46) {
            num45++
          } else if(item.data.duration > 45 && item.data.duration < 61) {
            num60++
          } else if(item.data.duration > 60 && item.data.duration < 76) {
            num75++
          } else {
            nummore++
          }
        }
      }

      for(var item2 of this.state.archive) {
        var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
        if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 >= Moment(start).add(7*i, 'days').format('X') && date2 < Moment(start).add(7*i+7, 'days').format('X') && date2 < now) {
          num++
          if(item2.data.group) {
            numg++
            totalg++
            if(item2.data.clients !== undefined) {
              for(var client2 of item2.data.clients) {
                if(numc.indexOf(client2) === -1) {
                  numc.push(item2.data.client)
                  numc1.push(item2.data.client)
                }
              }
            }
          } else {
            nump++
            totalp++
            if(numc.indexOf(item2.data.client) === -1) {
              numc.push(item2.data.client)
              numc2.push(item2.data.client)
            }
          }
          if(item2.data.duration < 31) {
            num30++
          } else if(item2.data.duration > 30 && item2.data.duration < 46) {
            num45++
          } else if(item2.data.duration > 45 && item2.data.duration < 61) {
            num60++
          } else if(item2.data.duration > 60 && item2.data.duration < 76) {
            num75++
          } else {
            nummore++
          }
        }
      }

      tmp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": num,
      })
      tmpg.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numg,
      })
      tmpp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": nump,
      })
      tmpc.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc.length,
      })
      tmpc1.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc1.length,
      })
      tmpc2.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc2.length,
      })

    }

    for(var client3 of global.clients) {
      var add = true
      for(var n of cls) {
        if(n === client3.id) {
          add = false
        }
      }
      if(add) {
        inactive.push(client3)
      }
    }

    var tmpData = [{
      "id": lang.t('calendar:insights.total'),
      "color": global.themeColors[2],
      data: tmp
    },
    {
      "id": lang.t('calendar:insights.group'),
      "color": global.themeColors[0],
      data: tmpg
    },
    {
      "id": lang.t('calendar:insights.11'),
      "color": global.themeColors[1],
      data: tmpp
    }]

    var tmpData2 = [{
      "id": lang.t('calendar:insights.total'),
      "color": global.themeColors[2],
      data: tmpc
    },
    {
      "id": lang.t('calendar:insights.group'),
      "color": global.themeColors[0],
      data: tmpc1
    },
    {
      "id": lang.t('calendar:insights.11'),
      "color": global.themeColors[1],
      data: tmpc2
    }]

    var tmpData3 = [
      {
        "id": lang.t('calendar:sessions.group'),
        "label": lang.t('calendar:insights.group'),
        "value": totalg,
        "color": global.themeColors[0]
      },
      {
        "id": lang.t('calendar:sessions.11'),
        "label": lang.t('calendar:insights.11'),
        "value": totalp,
        "color": global.themeColors[1]
      },
    ]

    var tmpData4 = [
      {
        "id": "30 min",
        "label": lang.t('calendar:insights.upto30min'),
        "value": num30,
        "color": global.themeColors[0]
      },
      {
        "id": "45 min",
        "label": "31 - 45 min",
        "value": num45,
        "color": global.themeColors[1]
      },
      {
        "id": "60 min",
        "label": "46 - 60 min",
        "value": num60,
        "color": global.themeColors[2]
      },
      {
        "id": "75 min",
        "label": "61 - 75 min",
        "value": num75,
        "color": global.themeColors[3]
      },
      {
        "id": "More",
        "label": lang.t('calendar:insights.morethan75min'),
        "value": nummore,
        "color": global.themeColors[4]
      },
    ]

    this.setState({
      dataTop: tmpData,
      dataClients: tmpData2,
      dataTypes: tmpData3,
      dataTime: tmpData4,
      inactive: inactive
    })
  }


  setDateStart(event) {
    var dt = Moment(event.target.value+' 00:00', 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
    this.setState({
        date1: dt
    });
  }


  setDateEnd(event) {
    var dt = Moment(event.target.value+' 23:59', 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
    this.setState({
        date2: dt
    });
  }


  setDates() {
    this.getSessions()
    this.getTopGraph()
  }


  selectClient(id) {
    global.currentClient = id;
  }


  getTrainerSessions(id, group) {
    var type = lang.t('calendar:sessions.11-s')
    var now = parseInt(Moment().format('X'))
    if(group) {
      type = lang.t('calendar:sessions.group-s')
    }
    var label = lang.t('calendar:label.no')+' '+type
    var count = 0
    for(var item of this.state.sessions) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
      var add = false
      if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date <= Moment(this.state.date2, 'YYYY-MM-DD').format('X') && date < now) {
        add = true
      }
      if((item.data.trainerId === id || (item.data.trainerId === undefined && id === global.uidUser)) && item.data.group === group && add) {
        count++
      }
    }
    for(var item2 of this.state.archive) {
      var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
      var add2 = false
      if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        add2 = true
      }
      if((item2.data.trainerId === id || (item2.data.trainerId === undefined && id === global.uidUser)) && item2.data.group === group && add2 && date2 < now) {
        count++
      }
    }
    if(count === 1) {
      label = '1 '+type
    } else if(count > 1) {
      label = count+' '+type
    }
    return label
  }



  // Modals ------------------------------------------------------------



  showModal(id) {
    var tmp = []
    var name = global.userName
    for(var tr of this.state.trainers) {
      if(tr.id === id) {
        name = tr.data.name
      }
    }
    for(var item of this.state.sessions) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
      if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date <= Moment(this.state.date2, 'YYYY-MM-DD').format('X') && (item.data.trainerId === id || (global.uidUser === id && item.data.trainerId === undefined))) {
        tmp.push(item)
      }
    }
    for(var item2 of this.state.archive) {
      var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
      if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 <= Moment(this.state.date2, 'YYYY-MM-DD').format('X') && (item.data.trainerId === id || (global.uidUser === id && item.data.trainerId === undefined))) {
        tmp.push(item2)
      }
    }
    this.setState({
      showModalSessions: true,
      trainer: id,
      trainerName: name,
      selSessions: tmp
    })
  }


  hideModals() {
    this.setState({
      showModalSessions: false,
    })
  }


  getSessionTitle(item) {
    var label = lang.t('calendar:session.group')
    if(item.data.client !== '') {
      label = item.data.client
    }
    if(!item.data.group) {
      label = lang.t('common:label.deletedclient')
      for(var client of this.state.clients) {
        if(client.id === item.data.client) {
          label = client.name
        }
      }
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderAvatar(item) {
    for(var client of global.clients) {
      if(client.id === item) {
        return (
          HelperClients.getImage(client)
        )
      }
    }
  }


  renderClients() {
    if(this.state.clients.length === 0) {
      return (
        <div className="pt-50">
          <div className="empty small">
            <h4>No clients</h4>
          </div>
        </div>
      )
    } else {
      
      var list = this.state.clients
      list.sort((a,b) => b.total - a.total);
      return (
        <div className="chart clear">
          {list.slice(0, 3).map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/clients/'+item.id+'/sessions'}>
              {this.renderAvatar(item.id)}
                <div className="main">
                  <h4>{item.name}</h4>
                  <p>{item.sessions} {item.sessions === 1 ? lang.t('calendar:session.11-s') : lang.t('calendar:sessions.11-s')}<br/>{item.group} {item.sessions === 1 ? lang.t('calendar:session.group-s') : lang.t('calendar:sessions.group-s')}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderClientSessions(item) {
    var label = '-'
    if(item.credits !== undefined) {
      for(var cred of item.credits) {
        if(cred.data.sessionsPaid > cred.data.sessionsTotal) {
          label = cred.data.sessionsPaid-cred.data.sessionsTotal+' '+lang.t('calendar:sessions.unused')
          if(cred.data.sessionsPaid-cred.data.sessionsTotal === 1) {
            label = '1 '+lang.t('calendar:session.unused')
          }
        }
        if(cred.data.sessionsTotal > cred.data.sessionsPaid) {
          label = cred.data.sessionsTotal-cred.data.sessionsPaid+' '+lang.t('calendar:sessions.unpaid')
          if(cred.data.sessionsTotal-cred.data.sessionsPaid === 1) {
            label = '1 '+lang.t('calendar:session.unpaid')
          }
        }
      }
    }
    return label
  }


  renderDirectDebit(item) {
    var label = '-'
    if(item.subscriptions !== undefined) {
      if(item.subscriptions.length > 0) {
        label = lang.t('calendar:label.membership')
      }
    }
    return label
  }


  renderAssistant() {
    if(this.state.trainers.length > 0) {
      var list = this.state.trainers
      list.sort((a,b) => a.data.name - a.data.name)
      return (
        <div className="col-12">
          <div className="listheader">
            <h3>{lang.t('header:title.sessionstrainer')}</h3>
          </div>
          <div className="box list simple highlight mb-10" onClick={() => this.showModal(global.uidUser)}>
            <div className="col-4">
                <p className="lft"><strong>{global.userName}</strong></p>
              </div>
              <div className="col-3"><p>{this.getTrainerSessions(global.uidUser, false)}</p></div>
              <div className="col-3"><p>{this.getTrainerSessions(global.uidUser, true)}</p></div>
              <div className="col-2">
                <button className="btn tertiary small rgt">{lang.t('common:button.viewsessions')}</button>
              </div>
          </div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id} onClick={() => this.showModal(item.id)}>
              <div className="col-4">
                <p className="lft"><strong>{item.data.name}</strong></p>
              </div>
              <div className="col-3"><p>{this.getTrainerSessions(item.id, false)}</p></div>
              <div className="col-3"><p>{this.getTrainerSessions(item.id, true)}</p></div>
              <div className="col-2">
                <button className="btn tertiary small rgt">{lang.t('common:button.viewsessions')}</button>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderInactive() {
    if(this.state.inactive.length === 0) {
      return (
        <div className="col-12 mb-50">
          <div className="listheader">
            <h3>{lang.t('header:title.clientswithout')}</h3>
          </div>
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('clients')}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t('header:title.noclientswithout')}</h3>
            </div>
          </div>
        </div>
      )
    } else {
      var list = this.state.inactive
      list.sort((a,b) => a.data.name - a.data.name)
      return (
        <div className="col-12 mb-50">
          <div className="listheader">
            <h3>{this.state.inactive.length} {this.state.inactive.length === 1 ? lang.t('header:title.clientwithout') : lang.t('header:title.clientswithout')}</h3>
          </div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/clients/'+item.id+'/sessions'} onClick={() => this.selectClient(item.id)}>
                <div className="col-4">
                  {this.renderAvatar(item.id)}
                  <p className="lft"><strong>{item.data.name}</strong></p>
                </div>
                <div className="col-3"><p>{this.renderClientSessions(item)}</p></div>
                <div className="col-3"><p>{this.renderDirectDebit(item)}</p></div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('calendar:button.viewclient')}</button>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderInsights() {
    return (
      <div>
        <div className="col-6 mt-20 mb-50">
          <h3 className="mb-20">{lang.t('header:title.sessionsweek')}</h3>
          <WidgetChartGraph data={this.state.dataTop}/>
        </div>
        <div className="col-6 mt-20 mb-50">
          <h3 className="mb-20">{lang.t('header:title.activeclientsweek')}</h3>
          <WidgetChartGraph data={this.state.dataClients}/>
        </div>

        <div className="col-4 mb-30 clear">
          <h3 className="mb-20">{lang.t('header:title.sessiontypes')}</h3>
          <WidgetChartPie data={this.state.dataTypes}/>
        </div>
        <div className="col-4 mb-30">
          <h3 className="mb-10">{lang.t('header:title.mostsessions')}</h3>
          {this.renderClients()}
        </div>
        <div className="col-4 mb-30">
          <h3 className="mb-20">{lang.t('header:title.sessionduration')}</h3>
          <WidgetChartPie data={this.state.dataTime}/>
        </div>
        {this.renderAssistant()}
        <div className="sv-20"></div>
        {this.renderInactive()}
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin,cs')) {
      if(global.maxPlans === 0 && HelperBusiness.getRoleIncl('')) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='insights-locked2'/>
              <div className="space-20 clear"></div>
            </div>
            <Header type='calendar' active='insights'/>
            <Navigation active='calendar' />
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              {this.renderInsights()}
              <div className="sv-80 clear"></div>
            </div>
            <Header type='calendar' active='insights' dates={true} date1={this.state.date1} date2={this.state.date2} setDate1={(event) => this.setDateStart(event)} setDate2={(event) => this.setDateEnd(event)} setDates={() => this.setDates()}/>
            <Navigation active='calendar' />
            <ModalItems show={this.state.showModalSessions} type={'sessions'} title={this.state.trainerName} items={this.state.selSessions} empty='No sessions' onHide={() => this.hideModals()} clickElement={(event) => this.props.history.push(event)}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='insights-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.calendar')}/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','header','nav'])(withRouter(withAuthorization(condition)(CalendarInsightsPage)));