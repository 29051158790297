import React, { Component } from 'react';



class ModalVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      url: props.url ?? ''
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      url: props.url ?? ''
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  renderPlayer() {
    var arr = this.state.url.split('/')
    if(this.state.url.indexOf('youtu') !== -1) {
      var link = arr[arr.length-1]
      if(arr[arr.length-1].indexOf('?') !== -1) {
        var ar1 = arr[arr.length-1].split('=')
        var ar11 = ar1[ar1.length-2].split('&')
        link = ar11[0]
      }
      console.log(link)
      return (
        <iframe width="100%" height="470" src={'https://www.youtube.com/embed/'+link+'?autoplay=1&loop=1&autopause=0'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      )
    } else {
      var ar2 = arr[arr.length-1].split('?')
      return (
        <iframe src={'https://player.vimeo.com/video/'+ar2[0]+'?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} frameborder="0" width="100%" height="470" title="Video player"></iframe>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box mainvideo auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Watch video</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="clear"></div>
            {this.renderPlayer()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalVideo;