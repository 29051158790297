import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import HelperPage from '../../helper/Page';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDatetime from '../../components/Form/datetime';
import Textarea from '../../components/Form/textarea';
import InputToggle from '../../components/Form/toggle';
import Select from '../../components/Form/select';
import InputDuration from '../../components/Form/duration';
import InputInvited from '../../components/Form/invited';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalClients from '../../components/Modals/clients';
import ModalItems from '../../components/Modals/items';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class EventPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: global.events,
      back: '/calendar',
      dur: 30,
      name: '',
      desc: '',
      date: Moment(global.currentDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      hour: global.currentTime,
      mins: '00',
      cdur: 75,
      invitees: '',
      location: global.loc,
      link: '',
      private: true,
      error: [false, false, false, false],
      type: 'internal',
      book: '',
      selected: [],
      invited: [],
      group: [],
      locations: [],
      showMax: false,
      max: 0,
      showModalClients: false,
      showModalGroups: false,
      showModalMessage: false,
      editing: false,
      bookable: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Event - PT Mate', ROUTES.CALENDAR)
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('eventsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())

    var back = global.currentPage
    this.setState({
      back: back
    })
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp23 = []
      for(var item of global.clients) {
        tmp.push(item)
      }
      if(global.locations.length > 0) {
        tmp23 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}]
        for(var l of global.locations) {
          tmp23.push({value: l.id, name: l.data.name})
        }
      }
      this.setState({
        events: global.events,
        clients: tmp,
        clientsList: tmp,
        groups: global.clientGroups,
        locations: tmp23,
      });
      if(this.props.location.pathname.indexOf('/edit-event/') !== -1) {
        this.configureEditing()
      }
    }
  }


  configureEditing() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      var item = null
      var name = ''
      var dur = 0
      var desc = ''
      var date = '01/01/1900'
      var hr = '00'
      var min = '00'
      var inv = ''
      var link = ''
      var priv = true
      var book = ''
      var max = 0
      var type = 'internal'
      var group = []
      var invited = []
      var bookable = false
      var location = ''
      for(var event of global.events) {
        if(event.id === id) {
          id = event.id
          item = event
          name = item.data.title
          dur = item.data.duration
          desc = item.data.desc
          date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
          hr = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('HH')
          min = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('mm')
          if(item.data.emails !== undefined && item.data.emails !== null) {
            inv = item.data.emails
          }
          if(item.data.link !== undefined && item.data.link !== null) {
            link = item.data.link
          }
          if(item.data.private !== undefined && item.data.private !== null) {
            priv = item.data.private
          }
          if(item.data.max !== undefined) {
            max = item.data.max
          }
          if(item.data.location !== undefined) {
            location = item.data.location
          }
          if(item.data.groups !== undefined && item.data.groups !== null) {
            book = 'group'
            type = 'bookable'
            group = item.data.groups
            invited = []
            bookable = true
          }
          if(item.data.invited !== undefined && item.data.invited !== null) {
            book = 'client'
            type = 'bookable'
            group = []
            invited = item.data.invited
            bookable = true
          }
        }
      }

      var cdur = 75
      if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
        cdur = dur
        dur = 99
      }

      var tmpc = []
      if(item !== null) {
        for(var itemc of global.clients) {
          tmpc.push(itemc)
        }
      }

      this.setState({
        events: global.events,
        id: id,
        item: item,
        name: name,
        dur: dur,
        cdur: cdur,
        desc: desc,
        date: date,
        hour: hr,
        mins: min,
        emails: inv,
        private: priv,
        link: link,
        max: max,
        invitees: inv,
        invited: invited,
        group: group,
        location: location,
        type: type,
        book: book,
        back: '/calendar/event/'+id,
        editing: true,
        bookable: bookable,
      });
    }
  }


  checkCreateEvent() {
    var passed = true
    var tmp = [false, false, false, false]
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var later = true
    if(parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X'))) {
      later = false
    }
    if(this.state.name === '') {
      passed = false
      tmp[1] = true
    }
    this.setState({error: [false, false, false, false]})
    if(!later) {
      tmp[0] = true
      passed = false
    }
    if(this.state.type === 'bookable') {
      if(this.state.book === 'group' && this.state.group.length === 0) {
        tmp[2] = true
        passed = false
      }
      if(this.state.book === 'client' && this.state.invited.length === 0) {
        tmp[2] = true
        passed = false
      }
    }
    this.setState({error: tmp})
    if(passed) {
      this.createEvent()
    }
  }


  createEvent() {
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var arr = this.state.invitees.split(',')
    if(arr.length > 0) {
      for(var item of arr) {
        item = item.replace(' ', '')
        var sendInvite = Firebase.functions().httpsCallable('sendEventInvite');
        sendInvite({email: item, name: global.userName, date: date, link: this.state.link, notes: this.state.desc, event: this.state.name, sender: global.userEmail, duration: duration}).then(function(result) {
          //nothing
        })
      }
    }
    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }
    if(this.state.type === 'bookable') {
      var max = 0
      if(parseInt(this.state.max) > 0 && this.state.showMax) {
        max = parseInt(this.state.max)
      }
      var groups = []
      var clients = []
      if(this.state.book === 'group') {
        for(var gr of this.state.group) {
          groups.push(gr.id)
        }
      }
      var location = null
      var locationName = null
      if(global.locations.length > 0) {
        location = this.state.location
        locationName = ""
        for(var loc of global.locations) {
          if(loc.id === this.state.location) {
            locationName = loc.data.name
          }
        }
      }
      if(this.state.book === 'client') {
        clients = this.state.invited
      }
      Firebase.database().ref('/events/'+global.uidUser).push({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: date,
        timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
        groups: groups,
        invitees: clients,
        location: location,
        locationName: locationName,
        link: this.state.link,
        uid: global.uidUser,
        bookable: true,
        private: false,
        max: max,
        attendance: 2,
      }).then(() => {
        global.message = 'Event successfully created'
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/events/'+global.uidUser).push({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: date,
        timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
        emails: this.state.invitees,
        link: this.state.link,
        private: this.state.private,
        location: location,
        locationName: locationName,
        uid: global.uidUser,
      }).then(() => {
        global.message = 'Event successfully created'
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  checkUpdateEvent() {
    var passed = true
    var tmp = [false, false]
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var later = true
    if(parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X'))) {
      later = false
    }
    if(this.state.name === '') {
      passed = false
      tmp[1] = true
    }
    this.setState({error: [false, false]})
    if(!later) {
      tmp[0] = true
      passed = false
    }
    this.setState({error: tmp})
    if(passed && later) {
      this.updateEvent()
    }
  }


  updateEvent() {
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var duration = this.state.dur
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var arr = this.state.invitees.split(',')
    if(arr.length > 0) {
      for(var item of arr) {
        item = item.replace(' ', '')
        var sendInvite = Firebase.functions().httpsCallable('sendEventInvite');
        sendInvite({email: item, name: global.userName, date: date, link: this.state.link, notes: this.state.desc, event: this.state.name, sender: global.userEmail, duration: duration}).then(function(result) {
          //nothing
        })
      }
    }
    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }
    var max = 0
    if(this.state.max > 0 && this.state.showMax) {
      max = this.state.max
    }
    if(this.state.item.data.bookable) {
      Firebase.database().ref('/events/'+global.uidUser+'/'+this.state.item.id).update({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: date,
        max: max,
        timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
        link: this.state.link,
        template: '',
        location: location,
        locationName: locationName
      }).then(() => {
        global.message = 'Event successfully updated'
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/events/'+global.uidUser+'/'+this.state.item.id).update({
        title: this.state.name,
        desc: this.state.desc,
        duration: duration,
        date: date,
        timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
        emails: this.state.invitees,
        link: this.state.link,
        private: this.state.private,
        template: '',
        location: location,
        locationName: locationName
      }).then(() => {
        global.message = 'Event successfully updated'
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  removeItem(item) {
    if(this.state.book === 'client') {
      var tmp1 = this.state.invited
      if(tmp1.length > 0) {
        for(var i=0; i<tmp1.length; i++) {
          if(tmp1[i] === item) {
            tmp1.splice(i, 1)
          }
        }
      }
      this.setState({
        invited: tmp1
      })
    } else {
      var tmp2 = this.state.group
      if(tmp2.length > 0) {
        for(var j=0; j<tmp2.length; j++) {
          if(tmp2[j] === item) {
            tmp2.splice(j, 1)
          }
        }
      }
      this.setState({
        group: tmp2
      })
    }
  }



  // Modals ------------------------------------------------------------



  showModalMessage() {
    if(global.userSubStatus === 'trialing' || global.userSubPlan === 'promonthly' || global.userSubPlan === 'proyearly' || global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly') {
      this.setState({
        type: 'bookable'
      })
    } else {
      this.setState({

      })
    }
  }


  hideModals() {
    this.setState({
      showModalClients: false,
      showModalGroups: false,
      showModalMessage: false,
    })
  }


  selectClients(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      invited: tmp,
    })
  }


  selectGroups(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      group: tmp,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderLocation() {
    if(global.locations.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      return <Select label={lang.t('calendar:form.location')} value={this.state.location} values={this.state.locations} onChange={(event) => this.setState({location: event})}/>
    }
  }

  
  renderMax() {
    if(this.state.showMax) {
      return <InputNumber label={lang.t('calendar:form.spots.field')} value={this.state.max} placeholder={lang.t('calendar:form.spots.placeholder')} onChange={(event) => this.setState({max: event})}/>
    }
  }


  renderBookable() {
    if(this.state.type === 'bookable') {
      return (
        <div>
          <div className="col-12">
            <InputInvited type={this.state.book} clients={this.state.invited} groups={this.state.group} event={true} error={this.state.error[2]} clickType={(event) => this.setState({book: event})} clickClients={() => this.setState({showModalClients: true})} clickGroups={() => this.setState({showModalGroups: true})} clickRemove={(event) => this.removeItem(event)}/>
            <div className="sv-30 clear"></div>
          </div>
          <div className="col-12">
            <InputText label={lang.t('calendar:form.eventname')+'*'} placeholder={lang.t('calendar:form.eventname.placeholder2')} value={this.state.name} error={this.state.error[1]} errormsg={lang.t('calendar:error.nameevent')} onChange={(event) => this.setState({name: event})}/>
          </div>
          <InputDatetime hour={this.state.hour} min={this.state.mins} date={this.state.date} setDay={(event) => this.setState({date: event, timeEdit: true})} setHour={(event) => this.setState({hour: event, timeEdit: true})} setMin={(event) => this.setState({mins: event, timeEdit: true})}/>
          <div className="col-12">
            <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
            <InputToggle label={lang.t('calendar:form.spots.label')} value={this.state.showMax} onChange={() => this.setState({showMax: !this.state.showMax})} text={lang.t('calendar:form.spots.text2')}/>
            {this.renderMax()}
            {this.renderLocation()}
            <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
            <Textarea label={lang.t('calendar:form.eventnotes')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
          </div>
        </div>
      )
    } 
  }


  renderPrivate() {
    if(global.userStaff.length > 0) {
      return <InputToggle label={lang.t('calendar:form.private.label')} value={this.state.private} onChange={() => this.setState({private: !this.state.private})} text={lang.t('calendar:form.private.text')}/>
    }
  }


  renderInternal() {
    if(this.state.type === 'internal') {
      return (
        <div>
          <div className="col-12">
            <InputText label={lang.t('calendar:form.eventname')+'*'} placeholder={lang.t('calendar:form.eventname.placeholder')} value={this.state.name} error={this.state.error[1]} errormsg={lang.t('calendar:error.nameevent')} onChange={(event) => this.setState({name: event})}/>
          </div>
          <InputDatetime hour={this.state.hour} min={this.state.mins} date={this.state.date} setDay={(event) => this.setState({date: event})} setHour={(event) => this.setState({hour: event})} setMin={(event) => this.setState({mins: event})}/>
          <div className="col-12">
            <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
            <InputText label={lang.t('calendar:form.invitees')} placeholder={lang.t('calendar:form.invitees.placeholder')} value={this.state.invitees} info={lang.t('calendar:form.invitees.info')} onChange={(event) => this.setState({invitees: event})}/>
            {this.renderLocation()}
            <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
            <Textarea label={lang.t('calendar:form.eventnotes')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
            {this.renderPrivate()}
          </div>
        </div>
      )
    }
  }


  renderButtons() {
    if(this.state.editing) {
      return (
        <div>
          <button className="btn primary mb-20" onClick={() => this.checkUpdateEvent()}>{lang.t('common:button.savechanges')}</button>
          <Link to={this.state.back} className="btn tertiary close width-12">{lang.t('common:button.cancel')}</Link>
        </div>
      )
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateEvent()}>{lang.t('calendar:button.createevent')}</button>
    }
  }


  renderTabs() {
    if(!this.state.editing) {
      return (
        <div className="radios tabs">
          <button className={this.state.type === 'internal' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({type: 'internal'})}>{lang.t('calendar:form.internal')}</button>
          <button className={this.state.type === 'bookable' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.showModalMessage()}>{lang.t('calendar:form.bookable')}</button>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar withtabs">
            <div className="col-6 mt-20">
              <div className="box withtabs">
                {this.renderTabs()}
                <div className="clear"></div>
                <div className="inner">
                  {this.renderBookable()}
                  {this.renderInternal()}
                  <div className="sv-10 clear"></div>
                  {this.renderButtons()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.editing ? 'caleventedit' : 'calevent'}/>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '318130')}>{lang.t('common:label.showmearound')}</div>
          <Header title={this.state.editing ? lang.t('calendar:title.editevent') : lang.t('calendar:title.newsession')} link={this.state.back} type={this.state.editing ? 'simple' : 'sessions'} active='event'/>
          <Navigation active='calendar' />
          <ModalMessageSimple type='bookableevent' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.SUBSCRIPTION)}/>
          <ModalClients show={this.state.showModalClients} title={'Select clients'} button={'Confirm selection'} multiple={true} selected={this.state.invited} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
          <ModalItems show={this.state.showModalGroups} items={global.clientGroups} title={'Select groups'} button={'Confirm selection'} multiple={true} selected={this.state.group} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectGroups(event)}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='calendar-locked2' type='simple'/>
          </div>
          <Header title={lang.t('calendar:title.newevent')} link={ROUTES.CALENDAR} type='simple'/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common'])(withRouter(withAuthorization(condition)(EventPage)));