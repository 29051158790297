import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class HeaderBilling extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? 'header',
      active: props.active ?? '',
    };
  }


  renderSettings() {
    if(HelperBusiness.getRoleIncl('')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINSETTINGS} className={this.state.active === 'settings' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:admin.settings')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINSETTINGS} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:admin.settings')}</div>
          </Link>
        )
      }
    }
  }


  renderLocations() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.LOCATIONS} className={this.state.active === 'locations' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:admin.locations')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.LOCATIONS} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:admin.locations')}</div>
          </Link>
        )
      }
    }
  }


  renderEmails() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINEMAILS} className={this.state.active === 'emails' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:admin.emails')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINEMAILS} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:admin.emails')}</div>
          </Link>
        )
      }
    }
  }


  renderLeads() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINPUBLIC} className={this.state.active === 'leads' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:admin.leads')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINPUBLIC} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:admin.leads')}</div>
          </Link>
        )
      }
    }
  }


  renderStaff() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINSTAFF} className={this.state.active === 'staff' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:admin.staff')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINSTAFF} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:admin.staff')}</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div className="track">
          <Link to={ROUTES.ADMINASSISTANT} className={this.state.active === 'overview' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:admin.overview')}</p>
            <div className="bg"></div>
          </Link>
          {this.renderStaff()}
          <Link to={ROUTES.ADMINDOC} className={this.state.active === 'forms' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:admin.forms')}</p>
            <div className="bg"></div>
          </Link>
          {this.renderLeads()}
          {this.renderEmails()}
          {this.renderLocations()}
          {this.renderSettings()}
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.ADMINASSISTANT} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:admin.overview')}</div>
          </Link>
          {this.renderStaff()}
          <Link to={ROUTES.ADMINDOC} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:admin.forms')}</div>
          </Link>
          {this.renderLeads()}
          {this.renderEmails()}
          {this.renderLocations()}
          {this.renderSettings()}
        </div>
      )
    }
  }
}


export default withTranslation(['nav'])(HeaderBilling);