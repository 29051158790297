import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import HelperClients from '../../helper/Clients';
import Cal from '../../helper/Calendar';
import HelperBilling from '../../helper/Billing';
import HelperBusiness from '../../helper/Business';
import HelperActivity from '../../helper/Activity';
import HelperEmpty from '../../helper/Empty';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/clientdetails';
import WidgetOverview from '../../components/Widgets/overview';
import ListAvatar from '../../components/List/avatar';
import ListEmpty from '../../components/List/empty';
import ModalPayment from '../../components/Modals/payment';
import ModalMessageDouble from '../../components/Modals/message-double';
import ModalMessageBasic from '../../components/Modals/message-basic';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsOverviewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: 'Client',
      sessions: global.sessions,
      sessionsAll: global.sessions,
      archive: global.archive,
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      groups: [],
      locations: [],
      dateRange: global.dateRange,
      // Billing
      payments: global.payments,
      products: global.products,
      currentPayment: null,
      credits: [],
      margin: 0,
      hiddenNote: 'hidden',
      currentNote: null,
      activity: [],
      activityTypes: global.userActivityTypes,
      family: [],
      showModalPayment: false,
      showModalInvite: false,
      showModalNote: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Client Details - Overview - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.configureData()
      if(this.state.id === '') {
        this.getEmpty()
      }
    })
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())   
    EventEmitter.subscribe('trainingLoaded', (event) => {
      this.updateTraining();
      this.getEmpty()
    })
    if(this.state.sessionsTraining.length === 0) {
      setTimeout(() => {
        this.updateTraining();
      }, 5000);
    }
    EventEmitter.subscribe('userLoaded', (event) => {
      if(this.state.item !== null) {
        var tmp = []
        tmp = HelperActivity.getActivityClient(this.state.item, global.userActivityTypes)
        this.setState({
          activityTypes: global.userActivityTypes,
          activity: tmp
        })
      }
    })
    EventEmitter.subscribe('locationSet', (event) => {
      if(this.state.item !== null) {
        var tmp = []
        tmp = HelperActivity.getActivityClient(this.state.item, global.userActivityTypes)
        this.setState({
          activity: tmp
        })
      }
      this.configureData()
    })
    this.getEmpty()

    /*setTimeout(() => {
      this.getEmpty()
      console.log("going for it")
    }, 100);*/
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getEmpty() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      this.getClient()
    }
  }


  updateTraining() {
    if(this._isMounted) {
      this.setState({
        sessionsTraining: global.sessionsTraining,
        archiveTraining: global.archiveTraining,
      }, () => {
        if(this.state.sessionsTraining.length === 0) {
          setTimeout(() => {
            this.updateTraining();
          }, 5000);
        }
      })
    }
  }


  getClient() {
    var tmp1 = 0
    var tmp2 = 0

    var id = ''
    var item = null
    var name = ''
    for(var client of global.clients) {
      if(client.id === global.currentClient) {
        if(client.data.prevSessions !== undefined) {
          tmp1 = client.data.prevSessions
        }
        if(client.data.prevSessionsGroup !== undefined) {
          tmp2 = client.data.prevSessionsGroup
        }
        id = client.id
        item = client
        name = client.data.name
      }
    }

    if(item !== null) {
      this.setState({
        id: id,
        item: item,
        name: name,
        value1: tmp1,
        value2: tmp2,
      }, () => {
        this.configureData()
        global.linkCli = '/clients/'+id+'/overview';
      });
    }
    
  }


  configureData() {
    if(this._isMounted) {
      var minDate = parseInt(Cal.getDateRange())

      var tmp = []
      var isGroup = false
      var groups = []
      var locations = []
      var credits = []

      // Credits
      if(this.state.item !== null) {
        credits.push({
          id: 'pt',
          data: {
            sessionsPaid: 0,
            sessionsTotal: 0,
            group: false
          }
        })
        credits.push({
          id: 'group',
          data: {
            sessionsPaid: 0,
            sessionsTotal: 0,
            group: true
          }
        })
        for(var cred of this.state.item.credits) {
          if(cred.data.group) {
            credits[1].data.sessionsPaid += cred.data.sessionsPaid
            credits[1].data.sessionsTotal += cred.data.sessionsTotal
          } else {
            credits[0].data.sessionsPaid += cred.data.sessionsPaid
            credits[0].data.sessionsTotal += cred.data.sessionsTotal
          }
        }
        for(var crede of this.state.item.creditse) {
          if(Moment(crede.data.expires, 'X') > Moment()) {
            if(crede.data.group) {
              credits[1].data.sessionsPaid += crede.data.sessionsPaid
              credits[1].data.sessionsTotal += crede.data.sessionsTotal
            } else {
              credits[0].data.sessionsPaid += crede.data.sessionsPaid
              credits[0].data.sessionsTotal += crede.data.sessionsTotal
            }
          }
        }
      }

      // Sessions with trainer
      for(var item of global.sessions) {
        isGroup = false
        if(item.data.group && item.data.clients !== undefined) {
          if(item.data.clients.indexOf(this.state.id) !== -1) {
            isGroup = true
          }
        }
        if(parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X')) && parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')) > minDate) {
          if(item.data.client === this.state.id && (this.state.show === 'all' || this.state.show === 'sessions')) {
            tmp.push(item)
          }
          if(isGroup && (this.state.show === 'all' || this.state.show === 'group')) {
            tmp.push(item)
          }
        }
      }
      for(var item2 of global.archive) {
        isGroup = false
        if(item2.data.group && item2.data.clients !== undefined) {
          
          if(item2.data.clients.indexOf(this.state.id) !== -1) {
            isGroup = true
          }
        }

        if(parseInt(Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')) > minDate) {
          if(item2.data.client === this.state.id && (this.state.show === 'all' || this.state.show === 'sessions')) {
            tmp.push(item2)
          }
          if(isGroup && (this.state.show === 'all' || this.state.show === 'group')) {
            tmp.push(item2)
          }
        }
      }

      // Training sessions
      if(this.state.item !== null && (this.state.show === 'all' || this.state.show === 'training')) {
        for(var item3 of global.sessionsTraining) {
          if(parseInt(Moment(item3.data.date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X')) && parseInt(Moment(item3.data.date, 'DD/MM/YYYY HH:mm').format('X')) > minDate && item3.data.attendance === 3) {
            if(item3.data.client === this.state.item.data.uid || item3.data.uid === this.state.item.data.uid) {
              tmp.push(item3)
            }
          }
          
        }
        for(var item4 of global.archiveTraining) {
          if((item4.data.client === this.state.item.data.uid || item4.data.uid === this.state.item.data.uid) && this.state.dateRange === 'all') {
            tmp.push(item4)
          }
        }
      }

      var tmp2 = []
      for(var item5 of global.payments) {
        if(this.state.dateRange === 'all') {
          if(item5.data.client === this.state.id) {
            tmp2.push(item5)
          }
        } else {
          var date = Moment(item5.data.date, 'DD/MM/YYYY HH:mm').format('X')
          if(item5.data.timestamp !== undefined) {
            date = Moment(item5.data.timestamp, 'X').format('X')
          }
          if(item5.data.client === this.state.id && date > minDate) {
            tmp2.push(item5)
          }
        }
      }

      var tmpa = []
      if(this.state.item !== null) {
        tmpa = HelperActivity.getActivityClient(this.state.item, this.state.activityTypes)
      }

      for(var itemg of global.clientGroups) {
        for(var ic of itemg.data.clients) {
          if(ic === this.state.id) {
            groups.push(itemg)
          }
        }
      }

      for(var iteml of global.locations) {
        if(iteml.data.clients !== undefined) {
          for(var il of iteml.data.clients) {
            if(il === this.state.id && global.locations.length > 0) {
              locations.push(iteml)
            }
          }
        }
      }

      var tmps = []
      for(var sess of global.sessions) {
        if(global.loc === '' || sess.data.location === global.loc) {
          tmps.push(sess)
        }
      }
      
      this.setState({
        sessions: tmp,
        sessionsAll: tmps,
        sessionsTraining: global.sessionsTraining,
        archiveTraining: global.archiveTraining,
        payments: tmp2,
        activity: tmpa,
        groups: groups,
        locations: locations,
        activityTypes: global.userActivityTypes,
        credits: credits,
        family: [],
      }, () => {
        if(this.state.item !== null) {
          if(this.state.item.data.linked !== undefined) {
            for(var lnkd of this.state.item.data.linked) {
              this.getFamilyUnpaid(lnkd)
            }
          }
        }
      })
    }
  }


  getFamilyUnpaid(item) {
    var tmp = this.state.family
    
    var client = this.state.item
    for(var cl of global.clients) {
      if(cl.id === item) {
        client = cl
      }
    }
    var add1 = HelperBilling.getClientSessionDebit(this.state.item, false, item, true, [])
    var add2 = HelperBilling.getClientSessionDebit(this.state.item, true, item, true, [])
    if(add1 === undefined) {
      add1 = 0
    }
    if(add2 === undefined) {
      add2 = 0
    }

    var total1 = 0
    var total2 = 0

    for(var item2 of this.state.item.credits) {
      if(item2.data.account === client.id) {
        if(!item2.data.group) {
          total1 += item2.data.sessionsTotal-item2.data.sessionsPaid
        } else {
          total2 += item2.data.sessionsTotal-item2.data.sessionsPaid
        }
      }
    }
    for(var item3 of this.state.item.creditse) {
      if(item3.data.account === client.id) {
        if(Moment(item3.data.expires, 'X') > Moment()) {
          if(!item3.data.group) {
            total1 += item3.data.sessionsTotal-item3.data.sessionsPaid
          } else {
            total2 += item3.data.sessionsTotal-item3.data.sessionsPaid
          }
        }
      }
    }
    total1 = total1-add1
    total2 = total2-add2
    if(total1 > 0) {
      tmp.push({
        id: client.id,
        info: '1:1 sessions',
        amount: total1,
        client: client,
      })
    }
    if(total2 > 0) {
      tmp.push({
        id: client.id,
        info: 'Group sessions',
        amount: total2,
        client: client,
      })
    }
    this.setState({
      family: tmp
    })
  }


  setDateRange(event) {
    this.setState({
        dateRange: event
    }, () => {
        this.configureData()
    });
  }


  getDate(date) {
    var label = Moment(date, 'DD/MM/YYYY HH:mm').format('llll')
    var d1 = Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    if(d1 === Moment().format('DD/MM/YYYY')) {
      label = 'Today '+Moment(date, 'DD/MM/YYYY HH:mm').format('LT')
    }
    if(d1 === Moment().add(1, 'days').format('DD/MM/YYYY')) {
      label = 'Tomorrow '+Moment(date, 'DD/MM/YYYY HH:mm').format('LT')
    }
    if(d1 === Moment().add(-1, 'days').format('DD/MM/YYYY')) {
      label = 'Yesterday '+Moment(date, 'DD/MM/YYYY HH:mm').format('LT')
    }
    return label
  }


  getDateActivity(date) {
    var label = Moment(date).format('llll')
    var d1 = Moment(date).format('DD/MM/YYYY')
    if(d1 === Moment().format('DD/MM/YYYY')) {
      label = 'Today '+Moment(date).format('LT')
    }
    if(d1 === Moment().add(-1, 'days').format('DD/MM/YYYY')) {
      label = 'Yesterday '+Moment(date).format('LT')
    }
    return label
  }


  toggleActivity(type) {
    var tmp = this.state.activityTypes
    if(tmp.indexOf(type) !== -1) {
      var ar = tmp.split(',')
      for(var i=0; i<ar.length; i++) {
        if(ar[i] === type) {
          ar.splice(i, 1)
        }
      }
      tmp = ""
      for(var a of ar) {
        if(a !== '') {
        tmp += ','+a}
      }
    } else {
      tmp += ','+type
    }
    global.userActivityTypes = tmp
    var tmp1 = []
    tmp1 = HelperActivity.getActivityAll(tmp)
    this.setState({
      activityTypes: tmp,
      activity: tmp1
    })
    Firebase.database().ref('/users/'+global.uidUser).update({
      activityTypes: tmp
    })
  }


  tapActivity(item) {
    if(item.activity !== '-' && item.activity !== 'payment') {
      if(item.activity === 'note') {
        this.props.history.push('/clients/'+this.state.id+'/details')
      }
      if(item.activity === 'group' || item.activity === 'commentgroup') {
        this.props.history.push('/calendar/group-session/'+item.id)
      }
      if(item.activity === 'event') {
        this.props.history.push('/calendar/event/'+item.id)
      }
      if(item.activity === 'availability' || item.activity === 'commentavail') {
        this.props.history.push('/calendar/availability/'+item.id)
      }
      if(item.activity === 'assessment') {
        this.props.history.push('/clients/'+this.state.id+'/assessments/'+item.id)
      }
      if(item.activity === 'training' || item.activity === 'session' || item.activity === 'comment') {
        this.props.history.push('/calendar/session/'+item.id)
      }
      if(item.activity === 'plan') {
        this.props.history.push('/clients/'+this.state.id+'/plan/'+item.id)
      }
      if(item.activity === 'clientbilling') {
        this.props.history.push('/clients/'+this.state.id+'/billing')
      }
      if(item.activity === 'invoice') {
        this.props.history.push('/billing/invoice/'+item.id)
      }
    } else if(item.activity === 'payment') {
      var paym = null
      for(var pay of global.payments) {
        if(pay.id === item.id) {
          paym = pay
        }
      }
      if(paym !== null) {
        this.showModalPayment(paym)
      }
    }
  }


  getBilling(item) {
    var label = item.data.billing
    if(label === "week") {
      for(var prod of global.products) {
        if(prod.id === item.data.plan) {
          label = prod.data.billing
        }
      }
    }
    return label
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')
    if(item.data.timestamp !== undefined) {
      label = Moment(item.data.timestamp, 'X').format('D MMM YYYY')
    }
    return label
  }



  // Modals ------------------------------------------------------------



  showModalPayment(item) {
    this.setState({
      showModalPayment: true,
      currentPayment: item
    })
    setTimeout(() => {
      this.setState({hiddenPayment: ''});
    }, 100);
  }


  showModalNote(item) {
    this.setState({
      showModalNote: true,
      currentNote: item
    })
  }


  hideModals() {
    this.setState({
      showModalPayment: false,
      showModalInvite: false,
      showModalNote: false,
    })
  }


  sendInvite() {
    var business = global.userBusiness
    if(global.userBusiness === "") {
      business = global.userName
    }
    this.hideModals()
    var callFunction1 = Firebase.functions().httpsCallable('sendClientInvite');
    callFunction1({email: this.state.item.data.email, name: global.userName, user: global.uidUser, business: business, phone: global.userPhone}).then(function(result) {})

    if(this.state.item.data.phone !== "") {
      Firebase.database().ref('/connect/'+this.state.item.id).update({
        phone: this.state.item.data.phone,
        name: global.userName,
        client: this.state.item.id,
        space: global.uid,
        email: this.state.item.data.email
      })
    }
  }



  // Display stuff ------------------------------------------------------------



  getActivityClass(item) {
    var label = 'box list highlight mb-10'
    if(global.loc !== '' && item.activity === 'group') {
      for(var sess of global.sessions) {
        if(sess.id === item.id && sess.data.location !== global.loc) {
          label = 'none'
        }
      }
    }
    return label
  }


  renderActivity() {
    if(this.state.activity.length === 0) {
      return (
        <ListEmpty id='clientactivity' type='simple'/>
      )
    } else {
      var list = this.state.activity
      list.sort((a,b) => Moment(b.date) - Moment(a.date));
      return (
        <div className="withsidebar">
          {list.map(item => (
            <div className={this.getActivityClass(item)} key={item.id} onClick={() => this.tapActivity(item)}>
              <div className={'icon '+item.color}>
                <div className={'inner '+item.icon}></div>
              </div>
              <div className="mainbox lft">
                <p><strong>{item.title}</strong><br/>{item.desc}<br/>{this.getDateActivity(item.date)}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderActivityBilling() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <div onClick={() => this.toggleActivity('billing')} className={this.state.activityTypes.indexOf('billing') === -1 ? 'checkbox lft ml-30' : 'checkbox active lft ml-30'}>
          <div className="check mr-10">
            <div className="inner"></div>
          </div>
          <p>Billing</p>
        </div>
      )
    }
  }


  renderActivityHeader() {
    return (
      <div className="listheader withsidebar clear">
        <h3 className="lft">Recent activity</h3>
        <div className="rgt">
          <div onClick={() => this.toggleActivity('sessions')} className={this.state.activityTypes.indexOf('sessions') === -1 ? 'checkbox lft' : 'checkbox active lft'}>
            <div className="check mr-10">
              <div className="inner"></div>
            </div>
            <p>Sessions</p>
          </div>
          {this.renderActivityBilling()}
          <div onClick={() => this.toggleActivity('client')} className={this.state.activityTypes.indexOf('client') === -1 ? 'checkbox lft ml-30' : 'checkbox active lft ml-30'}>
          <div className="check mr-10">
              <div className="inner"></div>
            </div>
            <p>Clients</p>
          </div>
          <div onClick={() => this.toggleActivity('business')} className={this.state.activityTypes.indexOf('business') === -1 ? 'checkbox lft ml-30' : 'checkbox active lft ml-30'}>
          <div className="check mr-10">
              <div className="inner"></div>
            </div>
            <p>Admin</p>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderFamilyUnpaid() {
    if(this.state.family.length > 0 && HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <div className="withsidebar">
          <div className="listheader clear">
            <h3>Family members with unpaid sessions</h3>
            <div className="clear"></div>
          </div>
          {this.state.family.map(item => (
            <ListAvatar
              key={item.id}
              client={item.client}
              link={'/clients/'+this.state.item.id+'/billing'}
              button='manage'
              columns={[{text: item.client.data.name, width: 4}, {text: item.info, width: 4}, {text: item.amount+(item.amount  === 1 ? 'unpaid' : ' unpaid'), width: 2}]}
            />
          ))}
        </div>
      )
    }
  }


  renderButtonsPayment() {
    if(HelperBusiness.getRoleIncl('trainer,admin') && this.state.item !== null) {
      if(this.state.item.data.uid === '') {
        if(this.state.item.data.parent === undefined || this.state.item.data.parent === '') {
          return (
            <div className="action" style={{cursor: 'pointer'}} onClick={() => this.setState({showModalInvite: true})}>Send email invite</div>
          )
        }
      } else if(global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
        if(HelperBusiness.getRoleIncl('admin')) {
          return (
            <div>
              <Link to={'/billing/payment/'+this.state.id+'/sessions'} className="action">Process a payment</Link>
              <Link to={ROUTES.DEBITNEW} className="action">Set up a membership</Link>
            </div>
          )
        } else if(HelperBusiness.getRole('trainer')) {
          return (
            <Link to={'/billing/payment/'+this.state.id+'/sessions'} className="action">Process a payment</Link>
          )
        }
        
      } 
    }
    
  }


  renderSessionType(item) {
    if(item.data.group && item.data.availability === undefined) {
      return (
        <div className="icon primary">
          <div className="inner group"></div>
        </div>
      )
    } else {
      return (
        <div className="icon primary">
          <div className="inner session"></div>
        </div>
      )
    }
  }


  renderSessionTypeText(item) {
    if(item.data.group && item.data.availability === undefined) {
      if(item.data.client === '') {
        return 'Group session'
      } else {
        return item.data.client
      }
    } else {
      return '1:1 session'
    }
  }


  renderSessions() {
    var list = []
    for(var item of this.state.sessionsAll) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment()) {
        if(item.data.group && item.data.clients !== undefined) {
          if(item.data.clients.indexOf(this.state.id) !== -1) {
            list.push(item)
          }
        }
        if(!item.data.group && item.data.client === this.state.id) {
          list.push(item)
        }
      }
    }
    list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    if(list.length === 0) {
      return (
        <div className="empty small">
          {HelperEmpty.renderImage('calendar')}
          <h4>No upcoming sessions</h4>
        </div>
      )
    } else {
      return (
        <div className="mt-10">
          {list.slice(0, 3).map(item => (
            <div className="list bare" key={item.id}>
              <Link to={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)}>
                <div className="icon tertiary">
                  {this.renderSessionType(item)}
                </div>
                <div className="main">
                  <h4>{this.renderSessionTypeText(item)}</h4>
                  <p>{this.getDate(item.data.date)}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
          <div className="sv-20"></div>
          <Link to={'/clients/'+this.state.id+'/sessions'} className="btn tertiary small width-12">View more</Link>
        </div>
      )
    }
  }


  renderPack(item) {
    var label = 'No session available'
    var num = 0
    if(item.data.sessionsPaid > item.data.sessionsTotal) {
      num = item.data.sessionsPaid-item.data.sessionsTotal
      label = num+' sessions available'
      if(num === 1) {
        label = '1 sessions available'
      }
    }
    if(item.data.sessionsTotal > item.data.sessionsPaid) {
      num = item.data.sessionsTotal-item.data.sessionsPaid
      label = num+' unpaid sessions'
      if(num === 1) {
        label = '1 unpaid'
      }
    }
    return label
  }


  renderDebits() {
    if(this.state.item !== null) {
      if(this.state.item.subscriptions.length > 0) {
        var list1 = []
        var list2 = []
        for(var sub of this.state.item.subscriptions) {
          if(sub.id === 'trial') {
            list2.push(sub)
          } else {
            list1.push(sub)
          }
        }
        return (
          <div>
            {list1.map(item => (
              <div className="list bare" key={item.id}>
                <Link to={'/billing/direct-debits/'+this.state.item.id+'/'+item.id}>
                  <div className="icon secondary">
                    <div className="inner refresh"></div>
                  </div>
                  <div className="main">
                    <h4>{item.data.name} {item.data.status === 'paused' ? '(Paused)' : ''}</h4>
                    <p>{global.curSym}{(item.data.price/100).toFixed(2)} per {this.getBilling(item)}</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
            {list2.map(item2 => (
              <div className="list bare" key={item2.id}>
                <Link to={'/clients/'+this.state.item.id+'/billing'}>
                  <div className="icon secondary">
                    <div className="inner waiting"></div>
                  </div>
                  <div className="main">
                    <h4>Free trial</h4>
                    <p>Ends {Moment(item2.data.next, 'X').format('ddd, DD MMM YYYY')}</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
          </div>
        )
      } else {
        var list = []
        for(var item of this.state.credits) {
          if(item.data.sessionsPaid > 0 || item.data.sessionsTotal > 0) {
            list.push(item)
          }
        }
        if(list.length > 0) {
          return (
            <div>
              {list.map(item => (
                <div className="list bare" key={item.id}>
                  <div className="icon secondary">
                    <div className="inner pack"></div>
                  </div>
                  <div className="main">
                    <h4>{item.data.group ? 'Group sessions' : '1:1 sessions'}</h4>
                    <p>{this.renderPack(item)}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
            </div>
          )
        }
      }
    }
  }


  renderNoPayments() {
    if(this.state.item !== null) {
      if(this.state.item.subscriptions.length === 0 && this.state.credits.length === 0) {
        return (
          <div className="empty small">
            {HelperEmpty.renderImage('card')}
            <h4>No payments</h4>
          </div>
        )
      } else {
        return (
          <div></div>
        )
      }
    }
  }


  renderPayments() {
    if(HelperBusiness.getRoleIncl('trainer') && this.state.item !== null) {
      if(this.state.item.data.parent === undefined || this.state.item.data.parent === '') {
        if(this.state.payments.length === 0) {
          return (
            <div className="mb-50">
              <h3 className="mb-10">Billing</h3>
              <div className="clear"></div>
              {this.renderDebits()}
              <div className="clear"></div>
              {this.renderNoPayments()}
            </div>
          )
        } else {
          var list = this.state.payments
          list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
          return (
            <div className="mb-50">
              <h3 className="mb-10">Billing</h3>
              <div className="clear"></div>
              {this.renderDebits()}
              <div className="clear"></div>
              {list.slice(0, 3).map(item => (
                <div className="list bare" key={item.id} onClick={() => this.showModalPayment(item)}>
                  <div className="icon secondary">
                    <div className="inner card"></div>
                  </div>
                  <div className="main">
                    <h4>{global.curSym}{(item.data.amount/100).toFixed(2)} charged</h4>
                    <p>{this.renderPaymentDate(item)}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
              <div className="sv-20"></div>
              <Link to={'/clients/'+this.state.id+'/billing'} className="btn tertiary small width-12">View more</Link>
            </div>
          )
        }
      }
    }
  }


  renderGroups() {
    if(this.state.groups.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      var list = this.state.groups
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="mb-50">
          <h3>Groups</h3>
          <div className="clear mt-10 mb-20">
            {list.map(item => (
              <div className="list bare" key={item.id}>
                <Link to={'/clients/groups/group/'+item.id}>
                  <div className="icon tertiary">
                    <div className="inner group"></div>
                  </div>
                  <div className="main">
                    <h4>{item.data.name}</h4>
                    <p>{item.data.clients.length} clients</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }


  renderLocations() {
    if(this.state.locations.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      var list = this.state.locations
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="mb-50">
          <h3>Locations</h3>
          <div className="clear mt-10 mb-20">
            {list.map(item => (
              <div className="list bare" key={item.id}>
                <Link to={'/admin/locations/location/'+item.id}>
                  <div className="icon quarternary">
                    <div className="inner location"></div>
                  </div>
                  <div className="main">
                    <h4>{item.data.name}</h4>
                    <p>{item.data.clients.length} clients</p>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }


  renderNotes() {
    if(this.state.item !== null) {
      var list = []
      if(this.state.item.history !== undefined && this.state.item.history.length !== 0) {
        for(var hi of this.state.item.history) {
          if(hi.data.important) {
            list.push(hi)
          }
        }
      }
      if(list.length > 0) {
        list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
        return (
          <div className="clear mt-10">
          {list.map(item => (
            <div className="list bare" key={item.id} onClick={() => this.showModalNote(item)}>
              <div className="icon quarternary">
                <div className="inner note"></div>
              </div>
              <div className="main">
                <h4>{item.data.title}</h4>
                <p>{this.getDate(item.data.date)}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
          <div className="sv-20"></div>
            <Link to={'/clients/'+this.state.id+'/details'} className="btn tertiary small width-12">View more</Link>
          </div>
        )
        
      } else {
        return (
          <div className="empty small">
            {HelperEmpty.renderImage('notes')}
            <h4>No notes yet</h4>
          </div>
        )
      }
    }
  }


  renderActions() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className="mb-50">
          <Link to={'/calendar/'+this.state.id+'/new-session'} className="action">New 1:1 session</Link>
          <Link to={'/clients/'+this.state.id+'/new-assessment'} className="action">New health log</Link>
          {this.renderButtonsPayment()}
        </div>
      )
    } else if(HelperBusiness.getRole('admin')) {
      return (
        <div className="mb-50">
          {this.renderButtonsPayment()}
        </div>
      )
    } else {
      return (
        <div className="mb-50">
          <Link to={'/clients/'+this.state.id+'/details'} className="action">View details</Link>
        </div>
      )
    }
  }


  renderNotesMain() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className="mb-50">
          <h3>Notes</h3>
          <div className="clear"></div>
          {this.renderNotes()}
        </div>
      )
    }
  }


  renderSidebar() {
    return (
      <div className="col-3">
        {this.renderActions()}

        <div className="mb-50">
          <h3>Next sessions</h3>
          <div className="clear"></div>
          {this.renderSessions()}
        </div>

        {this.renderPayments()}
        {this.renderGroups()}
        {this.renderLocations()}
        {this.renderNotesMain()}
      </div>
    )
  }


  renderMain() {
    var item1 = {id: '', value: ''}
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs') && global.userStripeConnect !== '') {
      item1 = {id: 'income', value: HelperBilling.getIncome(global.payments, this.state.item === null ? '' : this.state.item.id, this.state.dateRange), prev: HelperBilling.getIncomeDifference(global.currentClient, this.state.dateRange)}
    }
    return (
      <div className="col-9">
        <WidgetOverview showSelect={true} updateSelect={() => this.setDateRange()}
          item1={item1}
          item2={{id: '', value: ''}}
          item3={{id: 'sessions', value: Cal.getAmountClient(global.sessions, global.archive, 'sessions', this.state.dateRange, global.currentClient), prev: Cal.getDifferenceClient(global.sessions, false, this.state.dateRange, global.currentClient, 'num')}}
          item4={{id: 'group', value: Cal.getAmountClient(global.sessions, global.archive, 'group', this.state.dateRange, global.currentClient), prev: Cal.getDifferenceClient(global.sessions, true, this.state.dateRange, global.currentClient, 'num')}}
        />
        <div className="clear"></div>
        {this.renderFamilyUnpaid()}
        {this.renderActivityHeader()}
        {this.renderActivity()}
      </div>
    )
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          {HelperClients.getFamily(this.state.item)}
          {this.renderMain()}
          {this.renderSidebar()}
        </div>
        <Header client={this.state.item} link={global.currentPage} active='overview' selector={true} updateSelect={() => this.setState({dateRange: global.dateRange})}/>
        <Navigation active='clients' />
        <ModalPayment payment={this.state.currentPayment} show={this.state.showModalPayment} onHide={() => this.hideModals()}/>
        <ModalMessageDouble type='inviteclient' show={this.state.showModalInvite} onHide={() => this.hideModals()} clickMainButton={() => this.sendInvite()} clickSecondaryButton={() => this.hideModals()}/>
        <ModalMessageBasic show={this.state.showModalNote} onHide={() => this.hideModals()} type='note' title={this.state.currentNote !== null ? this.state.currentNote.data.title : ''} text={this.state.currentNote !== null ? this.state.currentNote.data.desc : ''}/>
      </div>
      
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','home'])(withRouter(withAuthorization(condition)(ClientsOverviewPage)));