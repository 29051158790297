import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class HeaderBilling extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      active: props.active ?? '',
      type: props.type ?? ''
    };
  }


  renderInsights() {
    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.BILINSIGHTS} className={this.state.active === 'insights' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:billing.insights')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.BILINSIGHTS} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:billing.insights')}</div>
          </Link>
        )
      }
    }
  }


  renderExpenses() {
    if(HelperBusiness.getRoleIncl('admin,finance')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.EXPENSES} className={this.state.active === 'expenses' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:billing.expenses')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.EXPENSES} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:billing.expenses')}</div>
          </Link>
        )
      }
    }
  }


  renderProducts() {
    if(HelperBusiness.getRoleIncl('')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.PRODUCTS} className={this.state.active === 'products' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:billing.products')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.PRODUCTS} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:billing.products')}</div>
          </Link>
        )
      }
    }
  }


  renderDebits() {
    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.DEBITS} className={this.state.active === 'debits' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:billing.memberships')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.DEBITS} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:billing.memberships')}</div>
          </Link>
        )
      }
    }
  }


  renderInvoices() {
    if(HelperBusiness.getRoleIncl('admin,finance')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.INVOICES} className={this.state.active === 'invoices' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:billing.invoices')}</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.INVOICES} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:billing.invoices')}</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div className="track">
          <Link to={ROUTES.BILLING} className={this.state.active === 'payments' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:billing.payments')}</p>
            <div className="bg"></div>
          </Link>
          {this.renderDebits()}
          {this.renderProducts()}
          {this.renderInvoices()}
          {this.renderExpenses()}
          {this.renderInsights()}
          <Link to={ROUTES.REPORT} className={this.state.active === 'reports' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:billing.reports')}</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.BILLING} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:billing.payments')}</div>
          </Link>
          {this.renderDebits()}
          {this.renderProducts()}
          {this.renderInvoices()}
          {this.renderExpenses()}
          {this.renderInsights()}
          <Link to={ROUTES.REPORT} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:billing.reports')}</div>
          </Link>
        </div>
      )
    }
  }
}


export default withTranslation(['nav'])(HeaderBilling);