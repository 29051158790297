import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import { ApolloClient, InMemoryCache, HttpLink, gql } from '@apollo/client';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListAvatar from '../../components/List/avatar';
import ModalMessageDouble from '../../components/Modals/message-double';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NutritionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      plansList: [],
      plansPast: [],
      clients: global.clients,
      id: global.userNutritionId,
      status: global.userSubStatus,
      end: global.userNutEnd,
      showModalBadge: false,
      hidden: 'hidden',
      margin: 0,
      loading: false,
      showModalConfirm: false
    };
  }


  componentDidMount() {
    HelperPage.initialize('Nutrition - PT Mate', ROUTES.NUTRITION)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp1 = []
      var tmp2 = []
      var dt1 = Moment().format('DD/MM/YYYY')
      for(var item of global.clients) {
        if(global.loc === '') {
          tmp.push(item)
          if(item.data.nutritionId !== undefined && item.data.nutritionId !== '') {
            var dt2 = Moment(item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
            if(Moment(dt2, 'DD/MM/YYYY') >= Moment(dt1, 'DD/MM/YYYY')) {
              tmp1.push(item)
            } else {
              tmp2.push(item)
            }
          }
        } else {
          for(var loc of global.locations) {
            if(loc.id === global.loc) {
              if(loc.data.clients !== undefined) {
                if(loc.data.clients.indexOf(item.id) !== -1) {
                  tmp.push(item)
                  if(item.data.nutritionId !== undefined && item.data.nutritionId !== '') {
                    var dt21 = Moment(item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
                    if(Moment(dt21, 'DD/MM/YYYY') >= Moment(dt1, 'DD/MM/YYYY')) {
                      tmp1.push(item)
                    } else {
                      tmp2.push(item)
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.setState({
        clients: tmp,
        plans: tmp1,
        plansList: tmp1,
        plansPast: tmp2,
        id: global.userNutritionId,
        status: global.userSubStatus,
        end: global.userNutEnd,
      })
    }
  }


  getDate(type, date) {
    var label = type+' '+Moment(date, 'X').format('D MMM YYYY')
    var dt = Moment(date, 'X').format('DD/MM/YYYY')
    if(type === lang.t('health:label.started') && Moment(date, 'X') > Moment()) {
      label = lang.t('health:label.starts')+' '+Moment(date, 'X').locale(lang.language).format('D MMM YYYY')
    }
    if(dt === Moment().format('DD/MM/YYYY')) {
      label = type+' '+lang.t('health:label.today-s')
      if(type === lang.t('health:label.started')) {
        label = lang.t('health:label.startstoday')
      }
    }
    if(dt === Moment().add(-1, 'days').format('DD/MM/YYYY')) {
      label = type+' '+lang.t('health:label.yesterday-s')
    }
    if(dt === Moment().add(1, 'days').format('DD/MM/YYYY')) {
      label = type+' '+lang.t('health:label.tomorrow-s')
      if(type === lang.t('health:label.started')) {
        label = lang.t('health:label.startstomorrow')
      }
    }
    return label
  }


  getMeals() {
    var label = 0
    var now = Moment().format('w/YYYY')
    for(var item of global.clients) {
      if(item.data.nutritionEnd !== undefined) {
        var nw1 = Moment(item.data.nutritionStart, 'X').format('DD/MM/YYYY')
        var nw2 = Moment(item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
        var diff = Moment(nw2, 'DD/MM/YYYY').diff(Moment(nw1, 'DD/MM/YYYY'), 'days')+1
        for(var i=0; i<diff; i++) {
          var dt = Moment(nw1, 'DD/MM/YYYY').add(i, 'days').format('w/YYYY')
          if(dt === now) {
            label++
          }
        }
      }
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  filterPrograms = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList()
    });
  }


  configureList() {
    var tmp = []
    for(var item of this.state.plans) {
      if((item.data.name.toLowerCase()).indexOf(this.state.search.toLowerCase()) !== -1 || this.state.search === '') {
        tmp.push(item)
      }
    }
    this.setState({
      plansList: tmp
    })
  }


  createNutritionUser() {
    var arr = global.userName.split(' ')
    var last = '-'
    if(arr.length > 1) {
      last = arr[1]
    }

    this.setState({
      loading: true
    })

    const client = new ApolloClient({
      link: new HttpLink({
        uri: 'https://ptmate-client.hasura.app/v1/graphql',
        headers: {
          'x-hasura-admin-secret': 'Ib2gLkOdCmhuemuKrYTkoPsCZbZIwn0FKfUmwV1OE0TZtdZt1qvMx440gMqM4aQ7',
        }
      }),
      cache: new InMemoryCache(),
    });

    const mutation = gql`
      mutation CreateUser($first: String!, $last: String!, $email: String!, $date: timestamp!, $date2: timestamp!) {
        insert_gym_admins_one(object: { email: $email, first_name: $first, last_name: $last, created_at: $date, updated_at: $date2}) {
          id
          created_at
        }
      }
    `;

    client.mutate({
      variables: { email: global.userEmail, first: arr[0], last: last, date: Moment().format('YYYY-MM-DD'), date2: Moment().format('YYYY-MM-DD') },
      mutation
    }).then((result) => {
      var cd = Moment(result.data.insert_gym_admins_one.created_at, 'YYYY-MM-DDTHH:mm:ss.SSS')
      Firebase.database().ref('/users/'+global.uidUser).update({
        nutritionId: String(result.data.insert_gym_admins_one.id),
        nutritionDate: parseInt(cd.format('X')),
      })
      // Business space
      Firebase.database().ref('/spaces/'+global.uidUser).update({
        nutritionId: String(result.data.insert_gym_admins_one.id),
        nutritionDate: parseInt(cd.format('X')),
      })
      this.setState({
        id: String(result.data.insert_gym_admins_one.id)
      })
      this.createNutritionGym(result.data.insert_gym_admins_one.id)
    }).catch((error) => {
      console.log(error);
    });
  }


  createNutritionGym(id) {
    //const client = this.createApolloClient()
    const client = new ApolloClient({
      link: new HttpLink({
        uri: 'https://ptmate-client.hasura.app/v1/graphql',
        headers: {
          'x-hasura-admin-secret': 'Ib2gLkOdCmhuemuKrYTkoPsCZbZIwn0FKfUmwV1OE0TZtdZt1qvMx440gMqM4aQ7',
        }
      }),
      cache: new InMemoryCache(),
    });
    const mutation = gql`
      mutation CreateGym($name: String!, $id: Int!, $date: timestamp!, $date1: timestamp!, $date2: timestamp!) {
        insert_gyms_one(object: { name: $name, gym_admin_id: $id, created_at: $date, updated_at: $date, trial_start: $date1, trial_end: $date2}) {
          id
        }
      }
    `;
    var date2 = Moment().endOf('week').add(14, 'days').format('YYYY-MM-DDTHH:mm:ss')
    client.mutate({
      variables: { name: global.userBusiness, id: id, date: Moment().format('YYYY-MM-DD'), date1: Moment().format('YYYY-MM-DDTHH:mm:ss'), date2: date2 },
      mutation
    }).then((result) => {
      this.showModalBadge()
      Firebase.database().ref('/users/'+global.uidUser).update({
        nutritionGym: String(result.data.insert_gyms_one.id),
        nutritionTrial: date2
      })
      // Business space
      Firebase.database().ref('/spaces/'+global.uidUser).update({
        nutritionGym: String(result.data.insert_gyms_one.id),
        nutritionTrial: date2
      })
      this.setState({
        loading: false
      })
    }).catch((error) => {
      console.log(error);
    });
  }



  // Activated modal ------------------------------------------------------------



  showModalBadge() {
    if(global.userBadges.indexOf('l2-nutrition') === -1 && global.userBadges.length !== 0) {
      global.userBadges.push('l2-nutrition')
      Firebase.database().ref('/users/'+global.uidUser).update({
        badges: global.userBadges
      })
    }
    this.setState({showModalBadge: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalBadge() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalBadge: false});
    }, 500);
  }


  renderModalBadge() {
    if(this.state.showModalBadge) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h3>You're ready to go!</h3>
            <button className="btn tertiary small close" onClick={() => this.hideModalBadge()}>Close</button>
            <div className="badge-ol-img">
              <img src="/img/badges/enable-nutrition.svg" alt="New Achievement!"/>
            </div>
            <h4 className="badge-title">Nutrition Badge earned!</h4>
            <p className="clear">You can now assign Nutrition plans to your clients. Please note: Nutrition plans start on Sundays and you won't be charged for any client or plan until {Moment().endOf('week').add(14, 'days').format('dddd, D MMMM')} ({global.curSym}1.10 per client per day after that).</p>
            <div className="clear space-10"></div>
            <Link className="btn primary" to={ROUTES.NEWNUTRITION}>Create your first plan</Link>
          </div>
        </div>
      )
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalConfirm: false
    })
  }



  // Display stuff ------------------------------------------------------------



  renderPlansInactive() {
    if(this.state.plansPast.length !== 0) {
      var list = this.state.plansPast
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="listheader">
            <ListHeader id='nutritioninactive' number={this.state.plansPast.length}/>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListAvatar 
              key={item.id}
              client={item}
              link={'/clients/'+item.id+'/health'}
              columns={[{text: item.data.name, width: 4}, {text: this.getDate(lang.t('health:label.started'), item.data.nutritionStart), width: 3}, {text: this.getDate(lang.t('health:label.ended'), item.data.nutritionEnd), width: 3}]}
            />
          ))}
        </div>
      )
    }
  }


  renderPlans() {
    if(this.state.plansList.length === 0) {
      if(this.state.plans.length === 0 && this.state.plansPast.length === 0) {
        return (
          <div>
            <div className="filters"></div>
            <ListEmpty id='nonutrition'/>
          </div>
        )
      } else if(this.state.plans.length === 0 && this.state.plansPast.length !== 0) {
        return (
          <div>
            <div className="filters"></div>
            <ListEmpty id='nonutrition2' type='simple'/>
          </div>
        )
      } else {
        return (
          <div>
            <div className="listheader">
              <div className="mt-5 lft">
                <ListHeader id='nutrition' number={this.state.plans.length}/>
              </div>
              <input type="text" className="search light large rgt" placeholder={lang.t('health:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterPrograms(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='nutrition'/>
          </div>
        )
      }
    } else {
      var list = this.state.plansList
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="listheader">
            <div className="mt-5 lft">
              <ListHeader id='nutrition' number={this.state.plans.length}/>
            </div>
            <input type="text" className="search light large rgt" placeholder={lang.t('health:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterPrograms(val)} />
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListAvatar 
              key={item.id}
              client={item}
              link={'/clients/'+item.id+'/health'}
              columns={[{text: item.data.name, width: 4}, {text: this.getDate(lang.t('health:label.started'), item.data.nutritionStart), width: 3}, {text: this.getDate(lang.t('health:label.ends'), item.data.nutritionEnd), width: 3}]}
            />
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWNUTRITION)}>{lang.t('health:button.addanotherplan')}</button>
        </div>
      )
    }
  }


  renderActivationMessage() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('nutrition')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:nutrition2.title')}</h3>
            <p className="mb-20">{lang.t('empty:nutrition2.text')}</p>
            <a href="https://www.ptmate.net/help/nutrition" target="_blank" className="btn tertiary width-12" rel="noopener noreferrer">{lang.t('empty:nutrition2.button')}</a>
            <div className="sv-30"></div>
            <div className="col-4">&nbsp;</div>
            <div className="col-4"><button className="btn primary" onClick={() => this.setState({showModalConfirm: true})}>{lang.t('empty:nutrition2.button2')}</button></div>
          </div>
        </div>
      )
    } else {
      return (
        <ListEmpty id='nutenabled' type='simple'/>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'newnutrition', link: ROUTES.NEWNUTRITION}
    var item2 = {id: 'nutbilling', link: ROUTES.SUBSCRIPTION}
    var item3 = {id: '', link: ''}
    if(HelperBusiness.getRoleIncl('')) {
    } else if(HelperBusiness.getRole('admin')) {
      item2 = {id: '', link: ''}
    } else {
      item1 = {id: '', link: ''}
      item2 = {id: '', link: ''}
      item3 = {id: '', link: ''}
    }
    return (
      <WidgetActions
        item1={item1} clickItem1={() => this.setState({showModalMessage: true})}
        item2={item2} clickItem2={() => this.checkBenchmark()}
        item3={item3} clickItem3={() => this.setState({showModalMovement: true})}
      />
    )
  }


  renderBase() {
    if(HelperBusiness.getRole('assistant,assistant2,finance')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <ListEmpty id='nutrition-locked' type='simple'/>
          </div>
          <Header type='health' active='nutrition'/>
          <Navigation active='health' />
        </div>
      )
    } else {
      if(this.state.id === '') {
        if(this.state.loading) {
          return (
            <div className={'theme-'+global.spaceTheme}>
              <div className="loading">
                <div className="loader-box">
                  <span className="loader"><span className="loader-inner"></span></span>
                </div>
                <p>{lang.t('health:label.settingyouup')}</p>
              </div>
            </div>
          )
        } else {
          return (
            <div className={'theme-'+global.spaceTheme}>
              <div className="content withtabs">
                {this.renderActivationMessage()}
              </div>
              <Header type='health' active='nutrition'/>
              <Navigation active='health' />
              {this.renderModalBadge()}
              <ModalMessageDouble type='enablenutrition' show={this.state.showModalConfirm} onHide={() => this.hideModals()} clickMainButton={() => this.createNutritionUser()} clickSecondaryButton={() => this.hideModals()}/>
            </div>
          )
        }
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <div className="col-9">
                <WidgetOverview showSelect={false}
                  item1={{id: 'nutactive', value: this.state.plans.length, prev: ''}}
                  item2={{id: 'nutinactive', value: this.state.plansPast.length, prev: ''}}
                  item3={{id: 'nutdays', value: this.getMeals(), prev: ''}}
                  item4={{id: '', value: ''}}
                />
              </div>
              {this.renderActions()}
              <div className="col-12 clear">
                {this.renderPlans()}
                {this.renderPlansInactive()}
                <div className="clear sv-80"></div>
              </div>
            </div>
            <Header type='health' active='nutrition'/>
            <Navigation active='health' />
            {this.renderModalBadge()}
          </div>
        )
      }
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['health','common','empty'])(withRouter(withAuthorization(condition)(NutritionPage)));