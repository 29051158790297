import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import ListClient from '../../components/List/client';
import InfoData from '../../components/Form/info';
import InfoBar from '../../components/Form/info-bar';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalForm from '../../components/Modals/form';
import WidgetChartPie from '../../components/Widgets/chart-pie';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class HabitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      back: ROUTES.HABITS,
      id: '',
      item: null,
      client: null,
      clients: global.clients,
      clientsList: global.clients,
      name: '',
      amount: 1,
      unit: 'cm',
      frequency: 'day',
      start: Moment().add(1, 'days').format('YYYY-MM-DD'),
      hasEnd: false,
      end: Moment().add(180, 'days').format('YYYY-MM-DD'),
      error: [false, false, false],
      valid1: true,
      valid2: true,
      month: Moment().format('MM/YYYY'),
      day: Moment().format('DD/MM/YYYY'),
      status: '0',
      notes: '',
      showModalDelete: false,
      showModalDay: false,
      editLink: '',
      data: [
        {
          "id": "japan",
          "label": "java",
          "value": 47,
          "color": "#1DC5C9"
        },
      ],
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Habit - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    if(global.adminClients.length === 0) {
      Connector.loadAdminClients()
    }

    var back = ROUTES.HABITS
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-4] === 'clients') {
      back = '/clients/'+arr[arr.length-3]+'/health'
    }
    this.setState({
      back: back
    })

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('habitsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var id = ''
      var item = null
      var cid = ''
      var client = null
      var values = ['', '', '', '', '']
      var hasEnd = true
      var link = ''
      var arr = this.props.location.pathname.split('/')
      for(var hab of global.habits) {
        if(hab.id === arr[arr.length-1]) {
          item = hab
          id = hab.id
          cid = item.data.client
          link = '/health/edit-habit/'+hab.id
          var frequency = 'day'
          if(item.data.interval === 7) { frequency = 'week' }
          values = [item.data.name, item.data.amount, item.data.unit, frequency, Moment(item.data.start, 'X').format('YYYY-MM-DD'), Moment(item.data.end, 'X').format('YYYY-MM-DD')]
          if(Moment(item.data.end, 'X') > Moment('01/01/2999', 'DD/MM/YYYY')) {
            hasEnd = false
            values[5] = Moment().add(180, 'days').format('YYYY-MM-DD')
          }
          // Client page edit
          if(this.props.location.pathname.indexOf('/clients/') !== -1) {
            link = '/clients/'+arr[arr.length-3]+'/edit-habit/'+hab.id
          }
        }
      }
      for(var cl of global.clients) {
        if(cl.id === cid) {
          client = cl
        }
      }
      if(item !== null) {
        var tmp1 = 0
        var tmp2 = 0
        var tmp3 = 0
        var start = Moment(item.data.start, 'X')
        var end = Moment().add(1, 'days')
        if( Moment(item.data.end, 'X') < Moment().add(1, 'days')) {
          end = Moment(item.data.end, 'X')
        }
        var d = Moment.duration(end.diff(start))
        var dys = d.asDays()
        for(var i=0; i<dys.toFixed(0); i++) {
          var found = false
          if(item.data.days !== undefined) {
            var dt = Moment(item.data.start, 'X').add(i, 'days').format('DD/MM/YYYY')
            for(var day of item.data.days) {
              if(day.indexOf(dt) !== -1 && day.indexOf('||1||') !== -1) {
                tmp1++
                found = true
              } else if(day.indexOf(dt) !== -1 && day.indexOf('||2||') !== -1) {
                tmp2++
                found = true
              }
            }
          }
          if(!found) {
            tmp3++
          }
        }
        
        var tmpData = [
          {
            "id": lang.t('health:label.achieved'),
            "label": lang.t('health:label.achieved'),
            "value": tmp1,
            "color": "#FB0044"
          },
          {
            "id": lang.t('health:label.notachieved'),
            "label": lang.t('health:label.notachieved'),
            "value": tmp2,
            "color": "#81DB24"
          },
          {
            "id": lang.t('health:label.pending'),
            "label": lang.t('health:label.pending'),
            "value": tmp3,
            "color": "#D3D7D8"
          },
        ]
      }
    }

    this.setState({
      groups: global.clientGroups,
      data: tmpData,
      id: id,
      item: item,
      client: client,
      clients: global.clients,
      clientsList: global.clients,
      name: values[0],
      amount: parseFloat(values[1]),
      unit: values[2],
      frequency: values[3],
      start: values[4],
      end: values[5],
      hasEnd: hasEnd,
      editLink: link,
    })
  }


  getCompliance(type) {
    var label = '-'
    var style = 'default'
    var tmp1 = 0
    var start = Moment(this.state.item.data.start, 'X').startOf('day')
    var end = Moment().add(1, 'days')
    if(Moment(this.state.item.data.end, 'X') < Moment()) {
      end = Moment(this.state.item.data.end, 'X')
    }
    var d = Moment.duration(end.diff(start))
    var dys = d.asDays()
    for(var i=0; i<dys.toFixed(0); i++) {
      if(this.state.item.data.days !== undefined) {
        var dt = Moment(this.state.item.data.start, 'X').add(i, 'days').format('DD/MM/YYYY')
        for(var day of this.state.item.data.days) {
          if(day.indexOf(dt) !== -1 && day.indexOf('||1||') !== -1) {
            tmp1++
          }
        }
      }
    }
    var per = tmp1/dys.toFixed(0)*100
    label = per.toFixed(0)+"%"
    if(per > 0 && per < 30) {
      style = 'red'
    }
    if(per > 29 && per < 80) {
      style = 'yellow'
    }
    if(per > 79) {
      style = 'green'
    }
    if(isNaN(per)) {
      label = '-'
      style = 'default'
    }
    if(type === 'label') {
      return label
    } else {
      return style
    }
  }


  getInterval() {
    var label = lang.t('health:label.perday')
    if(this.state.item.data.interval === 7) {
      label = lang.t('health:label.perweek')
    }
    return label
  }


  setMonth(type) {
    var tmp = this.state.month
    if(type === 'prev') {
      tmp = Moment(this.state.month, 'MM/YYYY').add(-1, 'months')
    } else {
      tmp = Moment(this.state.month, 'MM/YYYY').add(1, 'months')
    }
    this.setState({
      month: tmp.format('MM/YYYY')
    })
  }



  // Modals ------------------------------------------------------------



  showModalDay(item) {
    if(HelperBusiness.getRoleIncl('trainer')) {
      if(Moment(item, 'DD/MM/YYYY') > Moment(this.state.item.data.start, 'X').add(-1, 'day') && Moment(item, 'DD/MM/YYYY') < Moment(this.state.item.data.end, 'X').add(1, 'day') && Moment(item, 'DD/MM/YYYY') < Moment().add(1, 'day')) {
        var status = '0'
        var notes = ''
        if(this.state.item.data.days !== undefined) {
          for(var day of this.state.item.data.days) {
            if(day.indexOf(item) !== -1) {
              var ar = day.split('||')
              status = ar[1]
              notes = ar[2]
            }
          }
        }
        this.setState({
          showModalDay: true,
          day: item,
          status: status,
          notes: notes,
        })
      }
    }
  }


  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalDay: false,
    })
  }


  renderModalDayName() {
    var label = Moment(this.state.day, 'DD/MM/YYYY').format('ddd, D MMM YYYY')
    if(this.state.item.data.interval === 7) {
      var d1 = Moment(this.state.item.data.start, 'X').locale(lang.language).format('D MMM')
      var d2 = Moment(this.state.item.data.start, 'X').add(6, 'days').locale(lang.language).format('D MMM')
      
      var i = 0
      while (Moment(this.state.item.data.start, 'X').add(i*7, 'days') < Moment(this.state.day, 'DD/MM/YYYY').add(1, 'days')) {
        d1 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').locale(lang.language).format('D MMM')
        d2 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(6, 'days').locale(lang.language).format('D MMM')
        i++
      }
      label = d1+' - '+d2
      
    }
    return label
  }


  updateDay() {
    this.hideModals()
    var found = false
    var days = []

    var label = this.state.day
    if(this.state.item.data.interval === 7) {
      var d1 = Moment(this.state.item.data.start, 'X').format('DD/MM/YYYY')
      var d2 = Moment(this.state.item.data.start, 'X').add(1, 'days').format('DD/MM/YYYY')
      var d3 = Moment(this.state.item.data.start, 'X').add(2, 'days').format('DD/MM/YYYY')
      var d4 = Moment(this.state.item.data.start, 'X').add(3, 'days').format('DD/MM/YYYY')
      var d5 = Moment(this.state.item.data.start, 'X').add(4, 'days').format('DD/MM/YYYY')
      var d6 = Moment(this.state.item.data.start, 'X').add(5, 'days').format('DD/MM/YYYY')
      var d7 = Moment(this.state.item.data.start, 'X').add(6, 'days').format('DD/MM/YYYY')
      
      var i = 0
      while (Moment(this.state.item.data.start, 'X').add(i*7, 'days') < Moment(this.state.day, 'DD/MM/YYYY').add(1, 'days')) {
        d1 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').format('DD/MM/YYYY')
        d2 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(1, 'days').format('DD/MM/YYYY')
        d3 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(2, 'days').format('DD/MM/YYYY')
        d4 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(3, 'days').format('DD/MM/YYYY')
        d5 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(4, 'days').format('DD/MM/YYYY')
        d6 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(5, 'days').format('DD/MM/YYYY')
        d7 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(6, 'days').format('DD/MM/YYYY')
        i++
      }
      label = d1+','+d2+','+d3+','+d4+','+d5+','+d6+','+d7
      
    }

    if(this.state.item.data.days !== undefined) {
      days = this.state.item.data.days
      for(var i1=0; i1<days.length; i1++) {
        if(days[i1].indexOf(this.state.day) !== -1) {
          days[i1] = label+'||'+this.state.status+'||'+this.state.notes
          found = true
        }
      }
    }
    if(!found) {
      days.push(label+'||'+this.state.status+'||'+this.state.notes)
    }
    Firebase.database().ref('/habits/'+global.uid+'/'+this.state.item.id).update({
      days: days
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Day successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  deleteHabit() {
    this.hideModals()
    Firebase.database().ref('/habits/'+global.uid+'/'+this.state.item.id).remove()
    global.message = 'Habit successfully deleted'
    this.props.history.push(this.state.back)
  }



  // Display stuff ------------------------------------------------------------



  renderGraph() {
    if(Moment(this.state.item.data.start, 'X') <= Moment()) {
      if(this.state.data[0].id === 'japan') {
        return (
          <p className="empty">{lang.t('health:label.loading')}</p>
        )
      } else if(this.state.data[0].id !== 'japan') {
        return <WidgetChartPie data={this.state.data} colors={['#81DB24', '#DE1067', '#999C9F']}/>
      }
    } else {
      return (
        <div></div>
      )
    }
  }


  getDayClass(item) {
    var label = 'weekday interactive lft'
    var start = Moment(this.state.item.data.start, 'X').add(-1, 'day')
    var end = Moment()
    if( Moment(this.state.item.data.end, 'X') < Moment()) {
      end = Moment(this.state.item.data.end, 'X')
    }
    if(start < Moment(item, 'DD/MM/YYYY') && end > Moment(item, 'DD/MM/YYYY')) {
      label = 'weekday interactive pending lft'
    }
    if(this.state.item.data.days !== undefined) {
      for(var day of this.state.item.data.days) {
        if(day.indexOf(item) !== -1 && day.indexOf('||1||') !== -1) {
          label = 'weekday interactive done lft'
        }
        if(day.indexOf(item) !== -1 && day.indexOf('||2||') !== -1) {
          label = 'weekday interactive fail lft'
        }
      }
    }
    return label
  }


  renderStaticEnd() {
    if(Moment(this.state.item.data.end, 'X') < Moment('31/01/2999', 'DD/MM/YYYY')) {
      return (
        <InfoData label={lang.t('health:label.end')} value={Moment(this.state.item.data.end, 'X').locale(lang.language).format('D MMM YYYY')} small={true}/>
      )
    }
  }


  renderStaticClient() {
    if(this.state.client !== null) {
      return (
        <ListClient client={this.state.client} type='static'/>
      )
    }
  }


  renderButtons() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div>
          <Link to={this.state.editLink} className="btn primary">{lang.t('health:button.edithabit')}</Link>
          <div className="sv-20"></div>
          <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})}>{lang.t('common:button.delete')}</button>
        </div>
      )
    }
  }


  renderContent() {
    var d1 = Moment('01/'+this.state.month, 'DD/MM/YYYY').format('d')
    var d2 = Moment('01/'+this.state.month, 'DD/MM/YYYY').endOf('month').format('D')
    var list1 = []
    var list2 = []
    if(parseInt(d1) === 0) {
      d1 = 7
    }
    for(var i1=1; i1<parseInt(d1); i1++) {
      list1.push('')
    }
    for(var i2=1; i2<(parseInt(d2)+1); i2++) {
      list2.push(Moment(i2+'/'+this.state.month, 'D/MM/YYYY').format('DD/MM/YYYY'))
    }
    if(this.state.item === null) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <Header title={lang.t('health:title.habit')} link={this.state.back}/>
          <Navigation active='health' />
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                {this.renderStaticClient()}
                <div className="clear sv-20"></div>
                <InfoData label={lang.t('health:label.name')} value={this.state.item.data.name} small={true}/>
                <InfoData label={lang.t('health:label.measurement')} value={this.state.item.data.amount+' '+this.state.item.data.unit+' '+this.getInterval()} small={true}/>
                <InfoData label={lang.t('health:label.start')} value={Moment(this.state.item.data.start, 'X').locale(lang.language).format('D MMM YYYY')} small={true}/>
                {this.renderStaticEnd()}
                <InfoBar label={lang.t('health:label.compliance')} value={this.getCompliance('label')} color={this.getCompliance('style')} width={this.getCompliance('label')} small={true}/>
                <div className="sv-10"></div>
                {this.renderButtons()}
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <div style={{width: '100%', height: '300px'}}>
                  {this.renderGraph()}
                </div>
                <div className="clear sv-40"></div>
                <div className="calmini">
                  <div className="calmini-header mb-20">
                    <div className="arrow lft" onClick={() => this.setMonth('prev')}>‹</div>
                    <div className="arrow rgt" style={{float:'right'}} onClick={() => this.setMonth('next')}>›</div>
                    <h3 className="lft">{Moment('01/'+this.state.month, 'DD/MM/YYYY').locale(lang.language).format('MMMM YYYY')}</h3>
                    <div className="clear"></div>
                  </div>
                  {list1.map(item => (
                    <div className="weekday lft" key={item}></div>
                  ))}
                  {list2.map((item,index) => (
                    <div className={this.getDayClass(item)} key={item} onClick={() => this.showModalDay(item)}>{index+1}</div>
                  ))}
                </div>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <Header title={lang.t('health:title.habit')} link={this.state.back}/>
          <Navigation active='health' />
          <ModalMessageSimple type='deletehabit' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteHabit()}/>
          <ModalForm type={'habitday'} var={this.state.item.data.amount+' '+this.state.item.data.unit+' '+this.getInterval()} vartitle={this.renderModalDayName()} show={this.state.showModalDay} onHide={() => this.hideModals()} value1={this.state.status} values1={[{value: '1', name: lang.t('health:label.achieved')}, {value: '2', name: lang.t('health:label.notachieved')}]} updateValue1={(event) => this.setState({status: event})} value2={this.state.notes} updateValue2={(event) => this.setState({notes: event})} clickMainButton={() => this.updateDay()}/>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        this.renderContent()
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='habits-locked' type='simple'/>
          </div>
          <Header title={lang.t('health:title.habit')} link={this.state.back}/>
          <Navigation active='health' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['health','common',])(withRouter(withAuthorization(condition)(HabitPage)));