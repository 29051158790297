import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListAvatar from '../../components/List/avatar';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class HabitsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      habits: global.habits,
      habitsList: global.habits,
      clients: global.clients,
      groups: global.clientGroups,
      search: '',
      currentClient: '',
      currentGroup: '',
    };
  }


  componentDidMount() {
    HelperPage.initialize('Habit Tracker - PT Mate', ROUTES.HABITS)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('habitsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      for(var item of global.clients) {
        if(global.loc === '') {
          tmp1.push(item)
        } else {
          for(var loc of global.locations) {
            if(loc.id === global.loc) {
              if(loc.data.clients !== undefined) {
                if(loc.data.clients.indexOf(item.id) !== -1) {
                  tmp1.push(item)
                }
              }
            }
          }
        }
        
      }
      for(var item11 of global.habits) {
        for(var item12 of global.clients) {
          if(item11.data.client === item12.id) {
            if(global.loc === '') {
              tmp2.push(item11)
            } else {
              for(var loc2 of global.locations) {
                if(loc2.id === global.loc) {
                  if(loc2.data.clients !== undefined) {
                    if(loc2.data.clients.indexOf(item12.id) !== -1) {
                      tmp2.push(item11)
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.setState({
        clients: tmp1,
        habits: tmp2,
        habitsList: tmp2,
        groups: global.clientGroups
      });
    }
  }



  // Interactive stuff ------------------------------------------------------------



  getActiveClients() {
    var num = 0
    var clients = []

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var item of global.habits) {
      if(clients.indexOf(item.data.client) === -1 && list.indexOf(item.data.client) !== -1 && item.data.end > parseInt(Moment().format('X'))) {
        num++
        clients.push(item.data.client)
      }
    }
    return num
  }


  getAverage() {
    var num = 0
    var clients = []

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var item of global.habits) {
      if(list.indexOf(item.data.client) !== -1) {
        num++
        if(clients.indexOf(item.data.client) === -1 && item.data.end > parseInt(Moment().format('X'))) {
          clients.push(item.data.client)
        }
      }
    }
    var final = (num/clients.length).toFixed(1)
    return final
  }


  getMetric(item) {
    var unit = ' '+lang.t('health:label.perday')
    if(item.data.interval === 7) {
      unit = ' '+lang.t('health:label.perweek')
    }
    return item.data.amount+' '+item.data.unit+unit
  }


  getStatus(item) {
    var active = true
    var pending = false
    if(Moment(item.data.end, 'X') < Moment()) {
      active = false
    }
    if(Moment(item.data.start, 'X') > Moment()) {
      pending = true
    }
    if(pending) {
      return (
        <span className="pill">{lang.t('health:label.starts')} {Moment(item.data.start, 'X').locale(lang.language).format('D MMM YYYY')}</span>
      )
    } else {
      if(active) {
        return (
          <span className="pill green">{lang.t('common:label.active')}</span>
        )
      } else {
        return (
          <span className="pill grey">{lang.t('common:label.inactive')}</span>
        )
      }
    }
    
  }


  getClient(id) {
    var item = null
    for(var client of this.state.clients) {
      if(client.id === id) {
        item = client
      }
    }
    for(var inact of global.clientsInactive) {
      if(inact.id === id) {
        item = client
      }
    }
    return item
  }


  getClientName(item) {
    var label = lang.t('health:label.client')
    for(var cl of global.clients) {
      if(cl.id === item.data.client) {
        label = cl.data.name
      }
    }
    return label
  }


  setGroup(event) {
    this.setState({
      currentGroup: event.target.value
    }, then => {
      this.combineFilters()
    })
  }


  setClient(event) {
    this.setState({
      currentClient: event.target.value
    }, then => {
      this.combineFilters()
    })
  }


  filterHabits = event => {
    this.setState({
      search: event.target.value
    }, then => {
      this.combineFilters()
    })
  };


  combineFilters() {
    var tmp1 = this.state.habits
    var tmp2 = []
    var tmp3 = []
    if(this.state.search !== '') {
      tmp1 = []
      for(var item of this.state.habits) {
        if(item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
          tmp1.push(item)
        }
      }
    }

    tmp2 = tmp1
    if(this.state.currentClient !== '') {
      tmp2 = []
      for(var tmp11 of tmp1) {
        if(tmp11.data.client === this.state.currentClient) {
          tmp2.push(tmp11)
        }
      }
    }

    tmp3 = tmp2
    if(this.state.currentGroup !== '') {
      tmp3 = []
      for(var gr of this.state.groups) {
        if(gr.id === this.state.currentGroup) {
          for(var tmp21 of tmp2) {
            if(gr.data.clients.indexOf(tmp21.data.client) !== -1) {
              tmp3.push(tmp21)
            }
          }
        }
      }
    }

    this.setState({
      habitsList: tmp3
    })
  }



  // Display stuff ------------------------------------------------------------



  renderGroups() {
    if(this.state.groups.length > 0) {
      return (
        <select className="light rgt" onChange={event => this.setGroup(event)} value={this.state.currentGroup}>
          <option value=''>{lang.t('health:label.allclientgroups')}</option>
          {this.state.groups.map(item => (
            <option value={item.id} key={item.id}>{item.data.name}</option>
          ))}
        </select>
      )
    }
  }


  renderContent() {
    if(global.habits.length === 0) {
      return (
        <ListEmpty id='nohabits'/>
      )
    } else {
      var list = this.state.habitsList
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      if(list.length === 0) {
        return (
          <div className="col-12">
            <div className="listheader">
              <div className="mt-5 lft">
                <ListHeader id='habits' number={this.state.habitsList.length}/>
              </div>
              {this.renderGroups()}
              <select className="light rgt" onChange={event => this.setClient(event)} value={this.state.currentClient}>
                <option value=''>{lang.t('health:label.allclients')}</option>
                {this.state.clients.map(item => (
                  <option value={item.id} key={item.id}>{item.data.name}</option>
                ))}
              </select>
              <input type="text" className="search light rgt" placeholder={lang.t('health:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterHabits(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='habits'/>
          </div>
        )
      } else {
        return (
          <div className="col-12">
            <div className="listheader">
              <div className="mt-5 lft">
                <ListHeader id='habits' number={this.state.habitsList.length}/>
              </div>
              {this.renderGroups()}
              <select className="light rgt" onChange={event => this.setClient(event)} value={this.state.currentClient}>
                <option value=''>{lang.t('health:label.allclients')}</option>
                {this.state.clients.map(item => (
                  <option value={item.id} key={item.id}>{item.data.name}</option>
                ))}
              </select>
              <input type="text" className="search light rgt" placeholder={lang.t('health:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterHabits(val)} />
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <ListAvatar 
                key={item.id}
                client={this.getClient(item.data.client)}
                link={'/health/habit/'+item.id}
                columns={[{text: this.getClientName(item), width: 3, w1280: 5}, {text: item.data.name, width: 4, w1280: 2}, {text: this.getMetric(item), width: 2}, {text: this.getStatus(item), width: 1}]}
              />
            ))}
            <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWHABIT)}>{lang.t('health:button.addanotherhabit')}</button>
            <div className="clear sv-80"></div>
          </div>
        )
      }
    }
  }
    


  renderBase() {
    if(HelperBusiness.getRole('trainer,admin') || (HelperBusiness.getRoleIncl('') && global.showHabits)) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview showSelect={false}
                item1={{id: 'habtotal', value: this.state.habits.length, prev: ''}}
                item2={{id: 'habactive', value: this.getActiveClients(), prev: ''}}
                item3={{id: 'habaverage', value: this.getAverage(), prev: ''}}
                item4={{id: '', value: ''}}
              />
            </div>
            <WidgetActions 
              item1={{id: 'newhabit', link: ROUTES.NEWHABIT}}
              item2={{id: '', link: ''}}
              item3={{id: '', link: ''}}
            />
            {this.renderContent()}
          </div>
          <Header type='health' active='habits'/>
          <Navigation active='health' />
        </div>
      )
    } else {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
            <ListEmpty id='habits-locked'/>
            </div>
            <Header type='health' active='habits'/>
            <Navigation active='health' />
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content">
              <ListEmpty id='habits-locked2' type='simple'/>
            </div>
            <Header active='habits' type='simple'/>
            <Navigation active='health' />
          </div>
        )
      }
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['health','common'])(withRouter(withAuthorization(condition)(HabitsPage)));