import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import InputClient from '../../components/Form/client';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InfoData from '../../components/Form/info';
import InputToggle from '../../components/Form/toggle';
import Select from '../../components/Form/select';
import ModalClients from '../../components/Modals/clients';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewHabitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      back: ROUTES.HABITS,
      client: null,
      id: '',
      item: null,
      name: '',
      amount: 1,
      unit: 'cm',
      frequency: 'day',
      start: Moment().add(1, 'days').format('YYYY-MM-DD'),
      hasEnd: false,
      end: Moment().add(180, 'days').format('YYYY-MM-DD'),
      error: [false, false, false],
      showModalClients: false,
      preselected: false,
      preid: '',
      editing: false,
      units: [
        {name: 'cm', value: 'cm'},
        {name: 'm', value: 'm'},
        {name: 'km', value: 'km'},
        {name: 'in', value: 'in'},
        {name: 'mi', value: 'mi'},
        {name: 'steps', value: 'steps'},
        {name: 'l', value: 'l'},
        {name: 'gal', value: 'gal'},
        {name: 'cups', value: 'cups'},
        {name: 'kg', value: 'kg'},
        {name: 'lb', value: 'lb'},
        {name: 'cal', value: 'cal'},
        {name: 'h', value: 'h'},
        {name: 'min', value: 'min'},
        {name: 'sec', value: 'sec'},
      ],
      frequencies: [
        {name: lang.t('health:form.daily'), value: 'day'},
        {name: lang.t('health:form.weekly'), value: 'week'},
      ]
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('New Habit - PT Mate')
    this._isMounted = true

    if(global.adminClients.length === 0) {
      Connector.loadAdminClients()
    }

    var back = ROUTES.HABITS
    var preselected = false
    var preid = ''
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-3] === 'clients') {
      back = '/clients/'+arr[arr.length-2]+'/health'
      preselected = true
      preid = arr[arr.length-2]
    }

    this.setState({
      back: back,
      preselected: preselected,
      preid: preid,
    }, () => {
      this.getClients()
    })

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClients() {
    if(this._isMounted) {
      var tmp = []
      for(var item of global.clients) {
        tmp.push(item)
        if(item.id === this.state.preid && this.state.preid !== '') {
          this.setState({
            client: item
          })
        }
      }
      this.setState({
        clients: tmp,
        clientsList: tmp,
      }, () => {
        this.configureData()
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      var back = ROUTES.HABITS
      var preselected = false
      var preid = ''
      var hasEnd = true
      var editing = false

      var arr = this.props.location.pathname.split('/')
      if(arr[arr.length-3] === 'clients') {
        back = '/clients/'+arr[arr.length-2]+'/health'
        preselected = true
        preid = arr[arr.length-2]
      }
      // Edit habit
      var id = ''
      var item = null
      var cid = ''
      var client = null
      var values = ['', '', '', '', '']
      for(var hab of global.habits) {
        if(hab.id === arr[arr.length-1]) {
          preselected = true
          preid = hab.data.client
          item = hab
          id = hab.id
          cid = item.data.client
          back = '/health/habit/'+hab.id
          editing = true
          var frequency = 'day'
          if(item.data.interval === 7) { frequency = 'week' }
          values = [item.data.name, item.data.amount, item.data.unit, frequency, Moment(item.data.start, 'X').format('YYYY-MM-DD'), Moment(item.data.end, 'X').format('YYYY-MM-DD')]
          if(Moment(item.data.end, 'X') > Moment('01/01/2999', 'DD/MM/YYYY')) {
            hasEnd = false
            values[5] = Moment().add(180, 'days').format('YYYY-MM-DD')
          }
          // Client page edit
          if(this.props.location.pathname.indexOf('/clients/') !== -1) {
            back = '/clients/'+arr[arr.length-3]+'/habit/'+hab.id
          }
        }
      }
      for(var cl of global.clients) {
        if(cl.id === cid) {
          client = cl
        }
      }

      this.setState({
        groups: global.clientGroups,
        back: back,
        id: id,
        item: item,
        name: values[0],
        amount: parseFloat(values[1]),
        unit: values[2],
        frequency: values[3],
        start: values[4],
        end: values[5],
        hasEnd: hasEnd,
        client: client,
        editing: editing,
        preselected: preselected,
        preid: preid,
      })
    }
  }



  // Client update stuff ------------------------------------------------------------



  updateValues(val, key) {
    this.setState({
      [key]: val
    });
  }


  checkCreate() {
    this.setState({error: [false, false, false]})
    var tmp  = [false, false, false]
    var passed = true
    if(this.state.client === null) {
      tmp[0] = true
      passed = false
    }
    if(this.state.name === '') {
      tmp[1] = true
      passed = false
    }
    if(this.state.amount === '0' || this.state.amount === '') {
      tmp[2] = true
      passed = false
    }
    this.setState({error: tmp})
    if(passed) {
      if(this.state.editing) {
        this.updateHabit()
      } else {
        this.createHabit()
      }
    }
  }


  createHabit() {
    var interval = 1
    if(this.state.frequency === 'week') {
      interval = 7
    }
    var end = Moment(this.state.end, 'YYYY-MM-DD')
    if(end <= Moment(this.state.start, 'YYYY-MM-DD')) {
      end = Moment(this.state.start, 'YYYY-MM-DD').add(1, 'days')
    }
    if(!this.state.hasEnd) {
      end = Moment('01/01/3000', 'DD/MM/YYYY')
    }
    Firebase.database().ref('/habits/'+global.uid).push({
      name: this.state.name,
      amount: parseFloat(this.state.amount),
      unit: this.state.unit,
      client: this.state.client.id,
      interval: interval,
      start: parseInt(Moment(this.state.start, 'YYYY-MM-DD').format('X')),
      end: parseInt(Moment(end).format('X')),
    }).then(() => {
      global.message = 'Habit successfully created'
      this.props.history.push(global.currentPage)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  updateHabit() {
    var interval = 1
    if(this.state.frequency === 'week') {
      interval = 7
    }
    var end = Moment(this.state.end, 'YYYY-MM-DD')
    if(end <= Moment(this.state.start, 'YYYY-MM-DD')) {
      end = Moment(this.state.start, 'YYYY-MM-DD').add(1, 'days')
    }
    if(!this.state.hasEnd) {
      end = Moment('01/01/3000', 'DD/MM/YYYY')
    }
    Firebase.database().ref('/habits/'+global.uid+'/'+this.state.item.id).update({
      name: this.state.name,
      amount: parseFloat(this.state.amount),
      unit: this.state.unit,
      client: this.state.client.id,
      interval: interval,
      start: parseInt(Moment(this.state.start, 'YYYY-MM-DD').format('X')),
      end: parseInt(Moment(end).format('X')),
    }).then(() => {
      global.message = 'Habit successfully updated'
      this.props.history.push(this.state.back)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false
    })
  }


  modalClick(item) {
    this.hideModals()
    this.setState({
      client: item,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderCancel() {
    if(this.state.editing) {
      return (
        <div>
          <div className="sv-20"></div>
          <Link to={this.state.back} className="btn tertiary close">{lang.t('common:button.cancel')}</Link>
        </div>
      )
    }
  }


  renderEnd() {
    if(this.state.hasEnd) {
      return (
        <InputDate label={lang.t('health:form.enddate')} value={Moment(this.state.end, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().add(2, 'day').format('YYYY-MM-DD')} onChange={(event) => this.updateValues(event, 'end')}/>
      )
    }
  }


  renderStart() {
    if(this.state.editing) {
      return <InfoData label={lang.t('health:form.startdate')} value={Moment(this.state.start, 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')} small={true}/>
    } else {
      return <InputDate label={lang.t('health:form.startdate')} value={Moment(this.state.start, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().add(1, 'day').format('YYYY-MM-DD')} onChange={(event) => this.updateValues(event, 'start')}/>
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <InputClient id='habit' client={this.state.client} error={this.state.error[0]} clickElement={() => this.setState({showModalClients: true})} presel={this.state.preselected}/>
                <div className="sv-20"></div>
                <InputText label={lang.t('health:form.name')+'*'} value={this.state.name} error={this.state.error[1]} errormsg={lang.t('health:error.name')} placeholder={lang.t('health:form.name.placeholder')} onChange={(event) => this.updateValues(event, 'name')}/>
                <InputNumber label={lang.t('health:form.amount')+'*'} value={this.state.amount} error={this.state.error[2]} errormsg={lang.t('health:error.amount')} onChange={(event) => this.updateValues(event, 'amount')}/>
                <Select label={lang.t('health:form.unit')} value={this.state.unit} values={this.state.units} onChange={(event) => this.updateValues(event, 'unit')}/>
                <Select label={lang.t('health:form.frequency')} value={this.state.frequency} values={this.state.frequencies} onChange={(event) => this.updateValues(event, 'frequency')}/>
                {this.renderStart()}
                <InputToggle label={lang.t('health:form.setenddate')} value={this.state.hasEnd} onChange={(event) => this.setState({hasEnd: event})} text={lang.t('health:form.setenddate.text')}/>
                {this.renderEnd()}
                <div className="sv-10"></div>
                <button className="btn primary" onClick={() => this.checkCreate()}>{this.state.editing ? lang.t('common:button.savechanges') : lang.t('health:button.createhabit')}</button>
                {this.renderCancel()}
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.editing ? 'edithabit' : 'newhabit'}/>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <Header title={this.state.editing ? lang.t('health:title.edithabit') : lang.t('health:title.newhabit')} link={this.state.back}/>
          <Navigation active='health' />
          <ModalClients show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.modalClick(event)}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='habits-locked' type='simple'/>
          </div>
          <Header title={this.state.editing ? lang.t('health:title.edithabit') : lang.t('health:title.newhabit')} link={this.state.back}/>
          <Navigation active='health' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['health','common'])(withRouter(withAuthorization(condition)(NewHabitPage)));