import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import Textarea from '../../components/Form/textarea';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import InfoData from '../../components/Form/info';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      clients: global.clients,
      products: global.products,
      type: 'sessions',
      values: ['', '', '', ''],
      errorSessions: [false, false, false, false],
      stype: '11',
      loading: false,
      billing: 'week',
      item: null,
      message: '',
      log: global.userLog,
      limit: false,
      limitNum: 0,
      limit11: false,
      limitNum11: 0,
      public: false,
      expiry: 30,
      days: 'days',
      desc: '',
      expires: true,
      showStock: false,
      stock: 0,
      showModalDelete: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Product - PT Mate')
    this._isMounted = true

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    EventEmitter.subscribe('productsLoaded', (event) => {
      this.configureData()
      if(this.state.loading) {
        global.message = this.state.message
        if(this.state.message === 'Product successfully updated') {
          EventEmitter.dispatch('showMessage', this.state.message);
          this.setState({
            loading: false
          })
        } else {
          setTimeout(() => {
            EventEmitter.dispatch('updateBadges', 'product');
          }, 1000);
          this.props.history.push(ROUTES.PRODUCTS)
        }
      }
    })
    this.configureData()
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-1] !== 'new') {
      this.setState({id: arr[arr.length-1]})
    }
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        products: global.products,
        stripe: global.userStripeConnect,
        bank: global.userStripeBank
      }, () => {
        // Existing product
        var arr = this.props.location.pathname.split('/')
        if(arr[arr.length-1] !== 'new') {
          var prod = null
          var tmp = ['', '', '']
          var type = ''
          var stype = '11'
          var billing = 'week'
          var isPublic = false
          var expires = false
          var expiry = 12
          var days = 'months'
          var desc = ''
          var showStock = false
          var stock = 0
          for(var item of this.state.products) {
            if(item.id === arr[arr.length-1]) {
              prod = item
              type = item.data.type
              stype = item.data.stype
              var tmps = 0
              if(item.data.sessions11 !== undefined) {
                tmps = item.data.sessions11
              }
              tmp = [item.data.name, item.data.sessions, item.data.price.toFixed(2), tmps]
              billing = item.data.billing
              if(item.data.public !== undefined) {
                isPublic = item.data.public
              }
              if(item.data.expires !== undefined) {
                expires = true
                expiry = item.data.expires
                days = item.data.expType
              }
              if(item.data.desc !== undefined) {
                desc = item.data.desc
              }
              if(item.data.stock !== undefined && item.data.stock !== -1) {
                showStock = true
                stock = item.data.stock
              }
            }
          }
          this.setState({
            id: arr[arr.length-1],
            values: tmp,
            type: type,
            stype: stype,
            billing: billing,
            item: prod,
            public: isPublic,
            expiry: expiry,
            expires: expires,
            days: days,
            desc: desc,
            showStock: showStock,
            stock: stock,
          })
        }
      })
    }
  }


  configureLog() {
    if(this._isMounted) {
      this.setState({
        log: global.userLog
      }, () => {
        if(this.state.loading) {
          for(var item of this.state.log) {
            if(item.data.title === 'planerror' || item.data.title === 'planupdateerror' || item.data.title === 'plandelerror') {
              EventEmitter.dispatch('showMessageError', item.data.message);
              Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
              this.setState({
                loading: false,
              })
            }
          }
        }
      })
    }
  }



  // Membership ------------------------------------------------------------



  checkCreateDebit() {
    this.setState({errorSessions: [false, false, false, false]})
    var tmp = [false, false, false]
    if(this.state.values[0] === '' || this.state.values[0].length > 22) {
      tmp[0] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    this.setState({errorSessions: tmp})
    if(!tmp[0] && !tmp[1]) {
      if(this.state.id === '') {
        this.createDebit()
      } else {
        this.updateDebit()
      }
      
    }
  }


  createDebit() {
    var group = 'no'
    var sessions = 0
    var sessions11 = 0
    var pub = "no"
    var stock = -1
    if(this.state.limit && this.state.limitNum !== '') {
      sessions = parseInt(this.state.limitNum)
    }
    if(this.state.limit11 && this.state.limitNum11 !== '') {
      sessions11 = parseInt(this.state.limitNum11)
    }
    if(this.state.stype === 'group') {
      group = 'yes'
    } else if(this.state.stype === 'both') {
      group = 'both'
    }
    if(this.state.public) {
      pub = "yes"
    }
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    global.badgeLocked = true
    var callFunction = Firebase.functions().httpsCallable('connectedManageProduct');
    callFunction({type: "create", account: global.userStripeConnect, name: this.state.values[0], interval: this.state.billing, amount: parseInt(parseFloat(this.state.values[2])*100), group: group, sessions: sessions, sessions11: sessions11, currency: global.userCurrency, public: pub, stock: stock}).then(function(result) {});
    this.setState({
      loading: true,
      message: 'Product successfully created'
    })
  }


  updateDebit() {
    var pub = "no"
    var stock = -1
    if(this.state.public) {
      pub = "yes"
    }
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    var callFunction = Firebase.functions().httpsCallable('connectedManageProduct');
    callFunction({type: "update", account: global.userStripeConnect, name: this.state.values[0], plan: this.state.item.data.sku, product: this.state.item.data.product, public: pub, stock: stock}).then(function(result) {});
    this.setState({
      loading: true,
      message: 'Product successfully updated'
    })
  }



  // Pack of sessions ------------------------------------------------------------



  updateValues(val, num) {
    var tmp = this.state.values
    tmp[num] = val
    this.setState({
      values: tmp
    });
  }


  updateValuesNum(val, key) {
    this.setState({
      [key]: val
    });
  }


  onChangeExpiry = event => {
    this.setState({expiry: parseInt(event.target.value)});
  };


  checkCreatePack() {
    this.setState({errorSessions: [false, false, false, false]})
    var tmp = [false, false, false, false]
    if(this.state.values[0] === '' || this.state.values[0].length > 22) {
      tmp[0] = true
    }
    if(this.state.values[1] === '') {
      tmp[1] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    if(this.state.values[3] === '' && this.state.stype === 'both') {
      tmp[3] = true
    }
    this.setState({errorSessions: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2] && !tmp[3]) {
      if(this.state.id === '') {
        this.createPack()
      } else {
        this.updatePack()
      }
      
    }
  }


  createPack() {
    global.badgeLocked = true
    var sessions11 = 0
    if(this.state.values[3] !== '' && this.state.stype === 'both') {
      sessions11 = parseInt(this.state.values[3])
    }
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    if(this.state.expires) {
      var expiry = 12
      var type = 'months'
      if(this.state.expiry !== '') {
        expiry = parseInt(this.state.expiry)
        type = this.state.days
      }
      Firebase.database().ref('/packs/'+global.uid).push({
        billing: '', 
        stype: this.state.stype,
        name: this.state.values[0],
        price: parseFloat(this.state.values[2]),
        product: '',
        sessions: parseInt(this.state.values[1]),
        sessions11: sessions11,
        sku: '', 
        type: 'sessions',
        uid: global.uid,
        public: this.state.public,
        expires: expiry,
        expType: type,
        desc: this.state.desc,
        stock: stock,
      }).then(() => {
        global.message = 'Product successfully created'
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'product');
        }, 1000);
        this.props.history.push(ROUTES.PRODUCTS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/packs/'+global.uid).push({
        billing: '', 
        stype: this.state.stype,
        name: this.state.values[0],
        price: parseFloat(this.state.values[2]),
        product: '',
        sessions: parseInt(this.state.values[1]),
        sessions11: sessions11,
        sku: '', 
        type: 'sessions',
        uid: global.uid,
        public: this.state.public,
        desc: this.state.desc,
        stock: stock,
      }).then(() => {
        global.message = 'Product successfully created'
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'product');
        }, 1000);
        this.props.history.push(ROUTES.PRODUCTS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updatePack() {
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).update({
      name: this.state.values[0],
      price: parseFloat(this.state.values[2]),
      sessions: parseInt(this.state.values[1]),
      sessions11: parseInt(this.state.values[3]),
      public: this.state.public,
      desc: this.state.desc,
      stock: stock
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Product successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.expires) {
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).update({
        expires: parseInt(this.state.expiry),
        expType: this.state.days
      })
    } else {
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id+'/expires').remove()
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id+'/expType').remove()
    }
  }


  deleteProduct() {
    this.hideModals()
    if(this.state.type === 'sessions' || this.state.type === 'other') {
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).remove()
      global.message = 'Product successfully deleted'
      this.props.history.push(ROUTES.PRODUCTS)
    } else {
      var callFunction = Firebase.functions().httpsCallable('connectedManageProduct');
      callFunction({type: 'delete', account: global.userStripeConnect, plan: this.state.item.data.sku, product: this.state.item.data.product}).then(function(result) {});
      this.setState({
        loading: true,
        message: 'Product successfully deleted'
      })
      for(var item1 of global.sessions) {
        if(item1.data.memberships !== undefined) {
          if(item1.data.memberships.indexOf(this.state.id) !== -1) {
            for(var i1=0; i1<item1.data.memberships.length; i1++) {
              if(item1.data.memberships[i1] === this.state.id) {
                item1.data.memberships.split(i1, 1)
              }
            }
            Firebase.database().ref('/sessions/'+global.uid+'/'+item1.id).update({
              memberships: item1.data.memberships
            })
          }
        }
      }
      for(var item2 of global.recurring) {
        if(item2.data.memberships !== undefined) {
          if(item2.data.memberships.indexOf(this.state.id) !== -1) {
            for(var i2=0; i2<item2.data.memberships.length; i2++) {
              if(item2.data.memberships[i2] === this.state.id) {
                item2.data.memberships.split(i2, 1)
              }
            }
            Firebase.database().ref('/recurring/'+global.uid+'/'+item1.id).update({
              memberships: item2.data.memberships
            })
          }
        }
      }
    }
  }


  checkCreateOther() {
    this.setState({errorSessions: [false, false, false, false]})
    var tmp = [false, false, false]
    if(this.state.values[0] === '') {
      tmp[0] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    this.setState({errorSessions: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2]) {
      if(this.state.id === '') {
        this.createOther()
      } else {
        this.updateOther()
      }
      
    }
  }


  createOther() {
    global.badgeLocked = true
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    Firebase.database().ref('/packs/'+global.uid).push({
      billing: '', 
      group: false,
      name: this.state.values[0],
      price: parseFloat(this.state.values[2]),
      product: '',
      sessions: 0,
      sku: '', 
      type: 'other',
      uid: global.uid,
      public: this.state.public,
      desc: this.state.desc,
      stock: stock,
    }).then(() => {
      global.message = 'Product successfully created'
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'product');
      }, 1000);
      this.props.history.push(ROUTES.PRODUCTS)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  updateOther() {
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).update({
      name: this.state.values[0],
      price: parseFloat(this.state.values[2]),
      public: this.state.public,
      desc: this.state.desc,
      stock: stock,
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Product successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderButtonDelete() {
    if(this.state.id !== '') {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('billing:button.deleteproduct')}</button>
        </div>
        
      )
    }
  }


  renderProductName() {
    if(this.state.values[0].length < 23) {
      return ' ('+(22-this.state.values[0].length)+' '+lang.t('billing:form.charactersleft')+')'
    } else {
      return ' ('+(this.state.values[0].length-22)+' '+lang.t('billing:form.characterstoolong')+')'
    }
  }


  renderDesc() {
    return <Textarea label={lang.t('billing:form.description')} value={this.state.desc} onChange={(event) => this.updateValuesNum(event, 'desc')}/>
  }


  renderPublic() {
    return <InputToggle label={lang.t('billing:form.client')} value={this.state.public} onChange={() => this.setState({public: !this.state.public})} text={lang.t('billing:form.client.text')}/>
  }


  renderStock() {
    if(this.state.showStock) {
      return (
        <InputNumber label={this.state.type === 'debit' ? lang.t('billing:form.available.label') : lang.t('billing:form.limitstock.label')} value={this.state.stock} onChange={(event) => this.setState({stock: event})}/>
      )
    }
  }


  renderOther() {
    return (
      <div>
        <InputText label={lang.t('billing:form.productname')} placeholder={lang.t('billing:form.productname.placeholder')} value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg={lang.t('billing:error.productname')} onChange={(event) => this.updateValues(event, 0)}/>
        <InputNumber label={lang.t('billing:form.price')+' ('+(global.userCurrency === 'aud' ? 'AUD' : 'USD')+')*'} placeholder={lang.t('billing:form.price.placeholder')+' '+global.curSym} value={this.state.values[2]} error={this.state.errorSessions[2]} errormsg={lang.t('billing:error.price')} onChange={(event) => this.updateValues(event, 2)}/>
        {this.renderDesc()}
        {this.renderPublic()}
        <InputToggle label={lang.t('billing:form.limitstock')} value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text={lang.t('billing:form.limitstock.text')}/>
        {this.renderStock()}
        <div className="sv-30"></div>
        <button className="btn primary" onClick={() => this.checkCreateOther()}>{this.state.id === '' ? lang.t('billing:button.createproduct') : lang.t('billing:button.updateproduct')}</button>
        {this.renderButtonDelete()}
      </div>
    )
  }


  renderSessionType() {
    if(this.state.id === '') {
      return (
        <InputRadio
          label={lang.t('billing:form.sessiontype')}
          value={this.state.stype}
          clickElement={(event) => this.setState({stype: event})}
          values={[{name: lang.t('billing:form.11'), value: '11'}, {name: lang.t('billing:form.group'), value: 'group'}, {name: lang.t('billing:form.both'), value: 'both'}]}
        />
      )
    }
  }


  renderExpiry() {
    if(this.state.expires) {
      return (
        <div>
          <label>{lang.t('billing:form.sessionsexpire')}</label>
          <div className="col-6" style={{paddingLeft:0}}>
            <input type="number" placeholder="Number" value={this.state.expiry} onChange={this.onChangeExpiry}/>
          </div>
          <div className="col-6" style={{paddingRight:0}}>
            <div className="radios">
              <button className={this.state.days === 'days' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({days: 'days'})}>{lang.t('billing:form.days')}</button>
              <button className={this.state.days === 'months' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({days: 'months'})}>{lang.t('billing:form.months')}</button>
            </div>
          </div>
          <div className="clear sv-30"></div>
        </div>
      )
    }
  }


  renderSessionsBottom() {
    if(this.state.id === '') {
      return (
        <div>
          {this.renderExpiry()}
          {this.renderPublic()}
          <InputToggle label={lang.t('billing:form.limitstock')} value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text={lang.t('billing:form.limitstock.text')}/>
          {this.renderStock()}
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.checkCreatePack()}>{lang.t('billing:button.createproduct')}</button>
        </div>
      )
    } else {
      return (
        <div>
          {this.renderExpiry()}
          {this.renderPublic()}
          <InputToggle label={lang.t('billing:form.limitstock')} value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text={lang.t('billing:form.limitstock.text')}/>
          {this.renderStock()}
          <div className="info">
            <label>{lang.t('billing:form.type')}</label>
            <p>{this.state.stype === 'group' ? lang.t('billing:form.type.groupsessions') : (this.state.stype === '11' ? lang.t('billing:form.type.11sessions') : lang.t('billing:form.type.group11sessions'))}</p>
          </div>
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.checkCreatePack()}>{lang.t('billing:button.updateproduct')}</button>
          {this.renderButtonDelete()}
        </div>
      )
    }
  }


  renderSessions11() {
    if(this.state.stype === 'both') {
      return <InputNumber label={lang.t('billing:form.limit11.label')+"*"} placeholder={lang.t('billing:form.type.11sessions')} value={this.state.values[3]} error={this.state.errorSessions[3]} errormsg={lang.t('billing:error.sessions')} onChange={(event) => this.updateValues(event, 3)}/>
    }
  }


  renderSessions() {
    return (
      <div>
        <InputText label={lang.t('billing:form.productname')+this.renderProductName()} placeholder={lang.t('billing:form.productname.placeholder')} value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg={lang.t('billing:error.22characters')} onChange={(event) => this.updateValues(event, 0)}/>
        {this.renderSessionType()}
        <InputNumber label={(this.state.stype === 'both' ? lang.t('billing:form.limitgroup.label') : lang.t('billing:form.numberofsessions'))} placeholder={(this.state.stype === 'both' ? lang.t('billing:label.groupsessions') : lang.t('billing:label.sessions2'))} value={this.state.values[1]} error={this.state.errorSessions[1]} errormsg={lang.t('billing:error.sessions')} onChange={(event) => this.updateValues(event, 1)}/>
        {this.renderSessions11()}
        <InputNumber label={lang.t('billing:form.price')+' ('+(global.userCurrency === 'aud' ? 'AUD' : 'USD')+')*'} placeholder={lang.t('billing:form.price.placeholder')+' '+global.curSym} value={this.state.values[2]} error={this.state.errorSessions[2]} errormsg={lang.t('billing:error.price')} onChange={(event) => this.updateValues(event, 2)}/>
        {this.renderDesc()}
        {this.renderSessionsBottom()}
      </div>
    )
  }


  renderDebitLimitInfo() {
    if(this.state.limit || (this.state.limit11 && this.state.stype === 'both')) {
      return (
        <div className="infobox mb-10">
          <p>{lang.t('billing:form.limitsessions.info')}</p>
        </div>
      )
    }
  }


  renderDebitLimit() {
    if(this.state.limit) {
      return <InputNumber label={(this.state.stype === 'both' ? lang.t('billing:form.limitgroup.label') : lang.t('billing:form.numberofsessions'))} placeholder={lang.t('billing:form.limit11.placeholder')} value={this.state.limitNum} onChange={(event) => this.updateValuesNum(event, 'limitNum')}/>
    }
  }


  renderDebitLimit11() {
    if(this.state.limit11) {
      return <InputNumber label={lang.t('billing:form.limit11.label')} placeholder={lang.t('billing:form.limit11.placeholder')} value={this.state.limitNum11} onChange={(event) => this.updateValuesNum(event, 'limitNum11')}/>
    }
  }


  renderDebitSessions(type) {
    if(this.state.item !== null) {
      if(type === 'default') {
        if(this.state.item.data.sessions === undefined || this.state.item.data.sessions === 0) {
          return lang.t('billing:label.nolimit')
        } else {
          return this.state.item.data.sessions
        }
      } else {
        if(this.state.item.data.sessions11 === undefined || this.state.item.data.sessions11 === 0) {
          return lang.t('billing:label.nolimit')
        } else {
          return this.state.item.data.sessions11
        }
      }
      
    }
  }


  renderDebitButton() {
    if(global.userStripeConnect === '' || global.userStripeConnect === undefined) {
      return (
        <div className="infobox small yellow">
          <h5>{lang.t('billing:enable.title')}</h5>
          <p>{lang.t('billing:enable.text2')}</p>
          <div className="space-20"></div>
          <Link to={ROUTES.ACCOUNTBILLING} className="btn tertiary width-12 small" style={{textDecoration: 'none'}}>{lang.t('billing:enable.button')}</Link>
        </div>
      )
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateDebit()}>{lang.t('billing:button.createproduct')}</button>
    }
  }


  renderDebit11() {
    if(this.state.stype === 'both') {
      return <InputToggle label={lang.t('billing:form.limit11')} value={this.state.limit11} onChange={() => this.setState({limit11: !this.state.limit11})} text={lang.t('billing:form.limit11.text')}/>
    }
  }


  renderDebit() {
    if(this.state.id === '') {
      return (
        <div>
          <InputText label={lang.t('billing:form.productname')+' '+this.renderProductName()} placeholder={lang.t('billing:form.productname.placeholder')} value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg={lang.t('billing:error.22characters')} onChange={(event) => this.updateValues(event, 0)}/>
          <InputRadio
            label={lang.t('billing:form.billingcycle')}
            value={this.state.billing}
            clickElement={(event) => this.setState({billing: event})}
            values={[{name: lang.t('billing:form.weekly'), value: 'week'}, {name: lang.t('billing:form.fortnightly'), value: 'fortnight'}, {name: lang.t('billing:form.monthly'), value: 'month'}]}
          />
          <InputNumber label={lang.t('billing:form.price')+' ('+(global.userCurrency === 'aud' ? 'AUD' : 'USD')+')*'} placeholder={lang.t('billing:form.price.placeholder')+' '+global.curSym} value={this.state.values[2]} error={this.state.errorSessions[2]} errormsg={lang.t('billing:error.price')} onChange={(event) => this.updateValues(event, 2)}/>
          {this.renderSessionType()}
          {this.renderDebit11()}
          {this.renderDebitLimit11()}
          <InputToggle label={this.state.stype === 'both' ? lang.t('billing:form.limitgroup') : lang.t('billing:form.limitsessions')} value={this.state.limit} onChange={() => this.setState({limit: !this.state.limit})} text={(this.state.stype === 'both' ? lang.t('billing:form.limitgroup.text') : lang.t('billing:form.limitsessions.text'))}/>
          {this.renderDebitLimit()}
          {this.renderDebitLimitInfo()}
          {this.renderPublic()}
          <InputToggle label={lang.t('billing:form.availability')} value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text={lang.t('billing:form.availability.text')}/>
          {this.renderStock()}
          <div className="clear sv-30"></div>
          {this.renderDebitButton()}
        </div>
      )
    } else {
      return (
        <div>
          <InputText label={lang.t('billing:form.productname')+' '+this.renderProductName()} value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg={lang.t('billing:error.22characters')} onChange={(event) => this.updateValues(event, 0)}/>
          <div className="sv-10"></div>
          <InfoData label={lang.t('billing:form.billingcycle')} value={<span style={{textTransform: 'capitalize'}}>{this.state.billing}ly</span>}/>
          <InfoData label={lang.t('billing:form.price')} value={global.curSym+this.state.values[2]}/>
          <InfoData label={lang.t('billing:form.type')} value={this.state.stype === 'group' ? lang.t('billing:form.type.groupsessions') : (this.state.stype === '11' ? lang.t('billing:form.type.11sessions') : lang.t('billing:form.type.group11sessions'))}/>
          <InfoData label={lang.t('billing:label.sessionsper'+this.state.billing)} value={this.renderDebitSessions('default')}/>
          {this.renderStatic11()}
          {this.renderPublic()}
          <InputToggle label={lang.t('billing:form.availability')} value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text={lang.t('billing:form.availability.text')}/>
          {this.renderStock()}
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.checkCreateDebit()}>{lang.t('billing:button.updateproduct')}</button>
          {this.renderButtonDelete()}
        </div>
      )
    }
  }


  renderStatic11() {
    if(this.state.stype === 'both') {
      return <InfoData label={'1:1 sessions per '+this.state.billing} value={this.renderDebitSessions('11')}/>
    }
  }


  renderType() {
    if(this.state.type === 'sessions') {
      return (
        this.renderSessions()
      )
    } else if(this.state.type === 'debit' || this.state.type === 'subscription') {
      return (
        this.renderDebit()
      )
    } else {
      return (
        this.renderOther()
      )
    }
  }


  renderRadios() {
    if(this.state.id === '') {
      return (
        <div className="radios tabs">
          <button className={this.state.type === 'sessions' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({type: 'sessions'})}>{lang.t('billing:form.pack')}</button>
          <button className={this.state.type === 'debit' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({type: 'debit'})}>{lang.t('billing:form.membership')}</button>
          <button className={this.state.type === 'other' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({type: 'other'})}>{lang.t('billing:form.other')}</button>
        </div>
      )
    }
  }


  renderComments() {
    if(this.state.type === 'sessions') {
      return (
        <WidgetFormInfo id={this.state.id === '' ? 'prodnewsessions' : 'prodeditsessions'}/>
      )
    } else if(this.state.type === 'debit' || this.state.type === 'subscription') {
      return (
        <WidgetFormInfo id={this.state.id === '' ? 'prodnewdebit' : 'prodeditdebit'}/>
      )
    } else {
      return (
        <WidgetFormInfo id={this.state.id === '' ? 'prodnewother' : 'prodeditother'}/>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box withtabs">
                {this.renderRadios()}
                <div className="sv-20"></div>
                <div className="inner">
                  {this.renderType()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                {this.renderComments()}
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '321095')}>Show me around</div>
          <Header title={this.state.id === '' ? lang.t('billing:title.newproduct') : lang.t('billing:title.editproduct')} link={ROUTES.PRODUCTS}/>
          <Navigation active='billing' />
          <ModalMessageSimple type='deleteproduct' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteProduct()}/>
          <Loader show={this.state.loading}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
          <ListEmpty id='products-locked' type='simple'/>
          </div>
          <Header title={lang.t('billing:title.product')} link={global.currentPage}/>
          <Navigation active='billing' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header'])(withRouter(withAuthorization(condition)(ProductPage)));